import { Order } from '@sweep/contract';
import { isEmptyString } from 'src/utils/string';

export function isEmptyOrder(order: Order) {
  return (
    isEmptyString(order.productName) &&
    isEmptyString(order.option) &&
    isEmptyString(order.optionCode)
  );
}
