import { observer } from 'mobx-react-lite';
import { IconControlDownload } from '@sweep/asset/icons';
import { Button } from '@sweep/sds';
import { Matching } from '../../interface';
import CMHeader from '../common/CMHeader';
import MatchingList from './MatchingList';

interface PreDivideMatchedCMConfirmFormProps {
  matchings: Matching[];
  onEdit: () => void;
  onSubmit: () => void;
}

function PreDivideMatchedConfirmCM({
  matchings,
  onEdit,
  onSubmit,
}: PreDivideMatchedCMConfirmFormProps) {
  return (
    <div className="flex flex-col">
      <CMHeader />
      <MatchingList matchings={matchings} />
      <div className="m-auto flex gap-[12px] pt-5">
        <Button color="gray" onClick={onEdit} className="w-200px">
          수정하기
        </Button>
        <Button
          onClick={onSubmit}
          rightAccessory={<IconControlDownload className="text-blue-200" />}
          className="w-200px"
        >
          <div className="gap-8px flex items-center">
            <p className="text-bold-s text-white">완료하기</p>
            <div className="h-15px w-1.5px bg-blue-200" />
            <p className="text-semibold-s text-blue-200">발주서</p>
          </div>
        </Button>
      </div>
    </div>
  );
}

export default observer(PreDivideMatchedConfirmCM);
