import { RefObject, useEffect, useState } from 'react';

type Direction = 'x' | 'y' | 'x|y' | 'x&y';

export function useIsOverflow<T extends HTMLElement>(
  ref: RefObject<T>,
  direction: Direction
) {
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    if (ref.current == null) {
      return;
    }

    const isOverflow = getIsOverflow(ref.current, direction);
    setIsOverflow(isOverflow);

    const resizeObserver = new ResizeObserver(() => {
      if (ref.current == null) {
        return;
      }

      const isOverflow = getIsOverflow(ref.current, direction);
      setIsOverflow(isOverflow);
    });

    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [direction, ref]);

  return isOverflow;
}

function getIsOverflow(element: HTMLElement, direction: Direction) {
  const isHorizontalOverflow = element.scrollWidth > element.clientWidth;
  const isVerticalOverflow = element.scrollHeight > element.clientHeight;

  switch (direction) {
    case 'x':
      return isHorizontalOverflow;
    case 'y':
      return isVerticalOverflow;
    case 'x|y':
      return isHorizontalOverflow || isVerticalOverflow;
    case 'x&y':
      return isHorizontalOverflow && isVerticalOverflow;
  }
}
