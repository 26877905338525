import { FileMatching } from 'forms/partner/matching/interface';
import { Order } from '@sweep/contract';
import { createUniqueCode } from '@sweep/domain/services/order';
import { isEqualArray } from '@sweep/utils';
import { findHeaderIndex } from './findHeaderIndex';
import { findHeaderKeys } from './findHeaderKeys';
import { formatExcelData } from './formatExcelData';
import { getShoppingMall } from './getShoppingMall';

export function transformToOrders(partnerMatching: FileMatching): Order[] {
  // TODO SWP-540
  // FIXME(@hungjoon): 매칭 옵션 사용하게 해야하는데, 나머지 코드의 sync 가 맞지 않아서 그냥 header 시작 인덱스 항상 직접 찾고, 찾은 index 다음행부터 rows 로 처리하게 고정해버림
  // const matchingOptions = partnerMatching.partner.matchings?.at(-1);
  const file = partnerMatching.file;
  const sheetData = file.data;

  const searchedHeaderIndex = findHeaderIndex(sheetData);
  const isHeaderFound = searchedHeaderIndex !== -1;

  // const slicedSheetData = sheetData.slice(
  //   isHeaderFound ? searchedHeaderIndex : 0
  // );
  const headerIndex = isHeaderFound ? searchedHeaderIndex : 0;
  const rowStartIndex = isHeaderFound ? headerIndex + 1 : 1;
  const [headers, ...rows] = formatExcelData(sheetData, {
    headerStartIndex: headerIndex,
    orderStartIndex: rowStartIndex,
  });

  // const headers = slicedSheetData?.at(headerIndex); // FIXME(@hungjoon): 헤더가 여러 줄인 경우 처리
  // const rows = slicedSheetData.slice(rowStartIndex); // FIXME(@hungjoon): 올바른 행 시작 인덱스 사용하게 해야함
  // const row2 = slicedSheetData.slice(2); // FIXME(@hungjoon): 올바른 행 시작 인덱스 사용하게 해야함
  // TODO SWP-540
  if (headers == null) {
    return [];
  }

  const shoppingMall = getShoppingMall(headers);

  const matching = partnerMatching.partner.matchings?.find((matching) =>
    isEqualArray(matching.header, headers)
  );
  if (matching == null) {
    return [];
  }

  const matchingId = matching.id;
  const columnMapping = matching.columnMapping;

  const headerKeys = findHeaderKeys(headers, columnMapping);
  return rows.map<Order>((row, index) => {
    const order = row.reduce<Record<string, string | number>>(
      (acc, value, index) => {
        const key = headerKeys.at(index);
        if (key == null) {
          return acc;
        }
        const trimmedValue = value.trim();

        if (trimmedValue === '') {
          return acc;
        }

        acc[key] = trimmedValue;
        return acc;
      },
      {}
    );

    return {
      uniqueCode: createUniqueCode(),
      partnerId: partnerMatching.partner._id,
      originFile: file.name,
      originFilIndex: index,
      matchingId,
      shoppingMall: shoppingMall ?? undefined,
      ...order,
    } as Order;
  });
}
