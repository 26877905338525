import { JsonArrayInput } from './components/JsonArrayInput';
import { JsonBooleanInput } from './components/JsonBooleanInput';
import { JsonEnumInput } from './components/JsonEnumInput';
import { JsonNumberInput } from './components/JsonNumberInput';
import { JsonObjectInput } from './components/JsonObjectInput';
import { JsonStringInput } from './components/JsonStringInput';
import { Schema } from './schema';

interface JsonInputProps<T> {
  schema: Schema;
  value: T;
  onChange: (value: T) => void;
  collapse?: boolean;
}

export function JsonInput<T>({
  schema,
  value,
  onChange,
  collapse = true,
}: JsonInputProps<T>) {
  const handleChange = (value: unknown) => {
    onChange(value as T);
  };

  switch (schema.type) {
    case 'number':
      return (
        <JsonNumberInput
          schema={schema}
          value={(value ?? 0) as number}
          onChange={handleChange}
        />
      );
    case 'string':
      return (
        <JsonStringInput
          schema={schema}
          value={(value ?? '') as string}
          onChange={handleChange}
        />
      );
    case 'boolean':
      return (
        <JsonBooleanInput
          schema={schema}
          value={(value ?? false) as boolean}
          onChange={handleChange}
        />
      );
    case 'enum':
      return (
        <JsonEnumInput
          schema={schema}
          value={(value ?? '') as string}
          onChange={handleChange}
        />
      );
    case 'object':
      return (
        <JsonObjectInput
          schema={schema}
          value={(value ?? {}) as Record<string, unknown>}
          onChange={handleChange}
          collapse={collapse}
        />
      );
    case 'array':
      return (
        <JsonArrayInput
          schema={schema}
          value={(value ?? []) as unknown[]}
          onChange={handleChange}
        />
      );
  }
}
