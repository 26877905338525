import { MINUTE_IN_MS, OFFSET_BY_TIMEZONE } from './constants';
import { Timezone } from './interface';

export function getTimezoneOffset(timezone?: Timezone) {
  if (timezone == null) {
    return new Date().getTimezoneOffset() * MINUTE_IN_MS;
  }

  return OFFSET_BY_TIMEZONE[timezone];
}
