import { CombinedShipping } from '@sweep/contract';
import { isNotNil } from '@sweep/utils';
import { OMSStore } from 'src/stores/OMSStore';
import { CombinedShippingTableProduct } from '../interface';

export function toCombinedShippingTableProductsFromCombinedShipping(
  oms: OMSStore,
  combinedShipping: CombinedShipping
): CombinedShippingTableProduct[] {
  const { products, supplierId } = combinedShipping;
  return products
    .map<CombinedShippingTableProduct | null>(({ productId, option }) => {
      const product = oms.product.getProduct(productId);
      if (product == null) {
        return null;
      }
      const supplier =
        supplierId != null ? oms.supplier.getSupplier(supplierId) : null;

      return {
        productId,
        productName: product.productName,
        option,
        supplierId,
        supplierName: supplier?.name,
      };
    })
    .filter(isNotNil);
}
