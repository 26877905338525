import { z } from 'zod';

export const matchingProductSchema = z.object({
  productId: z.string(),
  unit: z.string().nullish(),
  quantity: z.number(),
});

export type MatchingProduct = z.infer<typeof matchingProductSchema>;

export const compositionMatchingSchema = z.object({
  _id: z.string(),

  option: z.string().nullish(),
  productName: z.string().nullish(),
  data: z.array(matchingProductSchema),

  count: z.number(),

  createdAt: z.number(),
  updatedAt: z.number(),
  deletedAt: z.number().nullish(),
});

export type CompositionMatching = z.infer<typeof compositionMatchingSchema>;
