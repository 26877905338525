import { observer } from 'mobx-react-lite';
import { NumberInput } from '@sweep/sds';
import { CombinedShippingFormStore } from '../../CombinedShippingFormStore';
import ProductListSubSection from './ProductListSubSection';

interface CountInputProps {
  store: CombinedShippingFormStore;
}

function CountInput({ store }: CountInputProps) {
  const isUniformed = store.isSupplierUniformed;

  return (
    <div className="gap-12px flex flex-col items-stretch">
      <div className="rounded-8px gap-8px flex-center flex w-full">
        <p className="text-medium-s whitespace-nowrap text-orange-500">총</p>
        <NumberInput
          value={store.amount}
          onChange={store.setAmount}
          className="w-54px min-w-0 bg-white"
          placeholder="0"
          min={0}
        />
        <p className="text-medium-s whitespace-nowrap text-orange-500">
          개까지 합배송
        </p>
      </div>
      <ProductListSubSection
        products={store.selectedProducts}
        helperText={
          isUniformed ? undefined : '다른 공급사의 상품은 합배송할 수 없습니다.'
        }
      />
    </div>
  );
}

export default observer(CountInput);
