import { cva } from '@sweep/tailwind';
import { useRadioContext } from './RadioContext';

interface RadioGroupItemProps
  extends React.InputHTMLAttributes<HTMLDivElement> {
  id?: string;
  value: string;
}

export function RadioGroupItem({
  id,
  value,
  className,
  ...rest
}: RadioGroupItemProps) {
  const { value: currentValue, onChange: currentOnChange } = useRadioContext();

  return (
    <div className={radio({ className })} {...rest}>
      {currentValue === value && <div className={radioChecked()} />}
      <input
        id={id}
        type="radio"
        checked={currentValue === value}
        onChange={(event) => {
          event.stopPropagation();
          currentOnChange(value);
        }}
        className={radioInput()}
      />
    </div>
  );
}

const radio = cva(
  'flex flex-center size-[21px] shrink-0 rounded-full border border-gray-300 bg-white p-0 relative'
);

const radioChecked = cva('size-11px bg-blue-500 rounded-full');

const radioInput = cva('opacity-0 absolute inset-0 cursor-pointer');
