interface FormLayoutProps {
  children: React.ReactNode;
}

export function FormLayout({ children }: FormLayoutProps) {
  return (
    <div className="px-40px pb-40px pt-20px min-h-full bg-gray-100">
      <div className="max-w-1240px">{children}</div>
    </div>
  );
}
