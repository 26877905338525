import { observer } from 'mobx-react-lite';
import { Divider, ExpandedCheckbox } from '@sweep/sds';
import { cva } from '@sweep/tailwind';

interface CombinedShippingTableHeaderProps {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
}

function CombinedShippingTableHeader({
  checked,
  onCheckedChange,
}: CombinedShippingTableHeaderProps) {
  return (
    <div className="h-36px flex items-center">
      <div className="w-36px bg-gray-200">
        <ExpandedCheckbox checked={checked} onCheckedChange={onCheckedChange} />
      </div>
      <Divider type="vertical-full" />
      <div className={headerCell({ width: 'full' })}>상품명</div>
      <Divider type="vertical-full" />
      <div className={headerCell({ width: 'full' })}>옵션</div>
      <Divider type="vertical-full" />
      <div className={headerCell({ width: 'full' })}>합배송 기준</div>
      <Divider type="vertical-full" />
      <div className={headerCell({ width: 'fixed' })}>공급사</div>
    </div>
  );
}

const headerCell = cva(
  'h-36px px-16px text-medium-s flex items-center bg-gray-100 text-gray-700',
  {
    variants: {
      width: {
        full: 'flex-1',
        fixed: 'w-136px',
      },
    },
  }
);

export default observer(CombinedShippingTableHeader);
