import { cva } from '@sweep/tailwind';
import { IconTrashcan } from '@sweep/asset/icons';

interface DeleteButtonProps {
  onClick?: () => void;
}

function DeleteButton({ onClick }: DeleteButtonProps) {
  return (
    <button className={button()} onClick={onClick}>
      <IconTrashcan className="text-gray-500" />
    </button>
  );
}

const button = cva('flex-center size-36px rounded-8px flex bg-gray-100');

export default DeleteButton;
