import { overlay } from 'overlay-kit';
import { CreateProductDTO } from '@sweep/contract';
import ProductCreateFormModal from './ProductCreateFormModal';

export function openProductCreateFormModal(productName?: string) {
  return new Promise<CreateProductDTO | null>((resolve) =>
    overlay.open(({ isOpen, close, unmount }) => (
      <ProductCreateFormModal
        productName={productName}
        open={isOpen}
        onSubmit={(product) => {
          resolve(product);
          close();
          unmount();
        }}
      />
    ))
  );
}
