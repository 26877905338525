import { HeaderMappingResult } from './types';

export const createHeaderMappingResult = (
  params: HeaderMappingResult
): HeaderMappingResult => {
  const {
    headerKeys,
    mappingColumn,
    defaultShippingCompany,
    matchReason,
    matchReasonDetail,
  } = params;

  return {
    headerKeys,
    mappingColumn,
    defaultShippingCompany,
    matchReason,
    matchReasonDetail,
  };
};
