import { cva } from '@sweep/tailwind';
import { IconControlPlus } from '@sweep/asset/icons';

interface PlusButtonProps {
  onClick: () => void;
}

function PlusButton({ onClick }: PlusButtonProps) {
  return (
    <button className={button()} onClick={onClick}>
      <IconControlPlus className="text-gray-400" />
    </button>
  );
}

const button = cva(
  'flex-center flex w-full rounded-[8px] border border-gray-300 bg-gray-50 py-[9px]'
);

export default PlusButton;
