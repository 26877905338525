import { RefObject } from 'react';
import { useWindowEvent } from '@sweep/utils/react';

interface UseSearchSelectMouseMoveProps {
  optionRefs: RefObject<(HTMLLIElement | null)[]>;
  onSelectedIndexChange: (index: number) => void;
}

export const useSearchSelectMouseMove = ({
  optionRefs,
  onSelectedIndexChange,
}: UseSearchSelectMouseMoveProps) => {
  useWindowEvent('mousemove', (event) => {
    const target = event.target;
    if (target == null || !(target instanceof Node)) {
      return;
    }

    optionRefs.current?.forEach((ref, index) => {
      if (ref == null) {
        return;
      }

      if (ref.contains(target)) {
        onSelectedIndexChange(index);
      }
    });
  });
};
