import { createContext, useContext } from 'react';

export interface TooltipOuterContextValue {
  disabled: boolean;
}

const tooltipOuterContext = createContext<TooltipOuterContextValue>({
  disabled: false,
});

export const TooltipOuterContextProvider = tooltipOuterContext.Provider;

export const useTooltipOuterContext = () => useContext(tooltipOuterContext);
