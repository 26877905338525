import { useOMSStore } from 'hooks/useOMSStore';
import { observer } from 'mobx-react-lite';
import { Plugin } from 'stores/plugin/interface';
import { pluginIds } from 'stores/plugin/PluginExecutionService';
import { Button, JsonInput, Schema } from '@sweep/sds';
import { cva } from '@sweep/tailwind';
import { InlineEdit } from './InlineEdit';

interface PluginItemProps {
  pluginId: string;
  onChange: (plugin: Partial<Plugin>) => void;
  onDelete: () => void;
}

function PluginItem({ pluginId, onChange, onDelete }: PluginItemProps) {
  const oms = useOMSStore();
  const plugin = oms.plugin.getPluginById(pluginId);

  if (plugin == null) {
    return <p>Wrong PluginId</p>;
  }

  return (
    <div>
      <div className="flex gap-2">
        <p className="text-medium-s">{plugin._id}</p>
        <Button color="lightGray" variant="line" onClick={() => onDelete()}>
          삭제
        </Button>
      </div>
      <div className={row()}>
        <span className={rowLabel()}>pluginId</span>
        <select
          className="rounded-md bg-gray-100 hover:bg-gray-200"
          value={plugin.pluginId}
          onChange={(e) => onChange({ pluginId: e.target.value })}
        >
          <option value=""></option>
          {pluginIds.map((pluginId) => (
            <option key={pluginId} value={pluginId}>
              {pluginId}
            </option>
          ))}
        </select>
      </div>
      <div className={row()}>
        <span className={rowLabel()}>config</span>
        {SCHEMAS[plugin.pluginId] != null ? (
          <JsonInput
            schema={SCHEMAS[plugin.pluginId]}
            value={plugin.config}
            onChange={(value) => onChange({ config: value })}
            collapse={false}
          />
        ) : (
          <InlineEdit
            value={JSON.stringify(plugin.config)}
            onChange={(value) => onChange({ config: JSON.parse(value) })}
          />
        )}
      </div>
      <hr className="my-4" />
    </div>
  );
}

const SCHEMAS: Record<string, Schema> = {
  'excel-function': {
    type: 'array',
    items: {
      type: 'object',
      properties: {
        target: { type: 'string' },
        value: { type: 'string', placeholder: '"카)" & {option}' },
      },
      defaultValue: {
        target: '',
        value: '',
      },
    },
  },
  numbering: {
    type: 'object',
    properties: {
      target: { type: 'string' },
      format: { type: 'string', placeholder: '{number}' },
    },
    defaultValue: {
      target: '',
    },
  },
  'extract-shipping-date': {
    type: 'object',
    properties: {
      inputFormats: {
        type: 'array',
        description:
          'ex. "발송일:{month}월{day}일 ({dayOfWeek})" or "발송일:{month}/{day} 출고"',
        items: {
          type: 'string',
        },
      },
      outputFormat: {
        type: 'string',
        description: 'ex. "MM.dd ddd 발송"',
      },
    },
    defaultValue: {
      inputFormats: [],
      outputFormat: '',
    },
  },
};

const row = cva('flex gap-2');
const rowLabel = cva('w-[80px] shrink-0 text-gray-500');

export default observer(PluginItem);
