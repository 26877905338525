import { Order } from '@sweep/contract';
import { FormatOrder } from '../interface';

export function toOrder(formatOrder: FormatOrder, order: Order): Order {
  return {
    ...order,
    productName: formatOrder.productName ?? order.productName,
    option: formatOrder.option ?? order.option,
    quantity: formatOrder.quantity ?? order.quantity,
    data: formatOrder.products ?? order.data,
  };
}
