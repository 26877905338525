import { DispatchedOrder } from './DispatchedOrder';
import { UploadedOrder } from './UploadedOrder';

/**
 * @deprecated use shippingStatus instead
 */
export type OrderProcess = 'pending' | 'shipping';

export const ORDER_SHIPPING_STATUS = {
  pending: 'pending',
  processing: 'processing',
  reviewing: 'reviewing',
  shipped: 'shipped',
} as const;

export type OrderShippingStatus =
  | 'pending'
  | 'processing'
  | 'reviewing'
  | 'shipped';

export type Order = DispatchedOrder | UploadedOrder;

export type PluginOrder<P extends Record<string, unknown>> = Order & {
  plugin?: P;
};

export type NormalizedOrder = Order & {
  uniqueCodeAfterCustomization: string;
  /**
   * @deprecated use shippingStatus instead
   */
  process?: OrderProcess;
  shippingStatus?: OrderShippingStatus;
  shippingOrderId?: string;
};

export function isDispatchedOrder(order: Order): order is DispatchedOrder {
  return order.orderStatus != null;
}

export function isNormalizedOrder(order: Order): order is NormalizedOrder {
  return order.uniqueCodeAfterCustomization != null;
}
