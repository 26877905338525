import { makeObservable } from 'mobx';
import { OMSStore } from 'stores/OMSStore';
import { PluginExecutionService } from 'stores/plugin/PluginExecutionService';
import { NormalizedOrder } from '@sweep/contract';
import { divideBySupplier } from '@sweep/domain/services/order';
import { replaceNormalizedOrders } from 'src/network/order/order';

export class DivideOrderStore {
  constructor(private oms: OMSStore) {
    makeObservable(this, {});
  }

  // TODO(@이지원): normalize가 store에서 처리하면 remainedOrder 파라미터 제거
  // TODO(@이지원): preDivideOrders(합배송)을 store에서 처리하면 preDivideOrders 파라미터 제거
  divideOrders = async (
    orders: NormalizedOrder[],
    remainedOrders: NormalizedOrder[],
    preDivideOrders: (orders: NormalizedOrder[]) => Promise<NormalizedOrder[]>
  ): Promise<NormalizedOrder[]> => {
    const preDividedOrders = await preDivideOrders(orders);

    const dividedOrders = await this.transform(preDividedOrders);

    const updatedOrders = [...dividedOrders, ...remainedOrders];
    this.oms.order.setNormalizedOrders(updatedOrders);
    await this.oms.loading.batch(async () => {
      await replaceNormalizedOrders(updatedOrders);
    });

    return dividedOrders;
  };

  transform = async (orders: NormalizedOrder[]): Promise<NormalizedOrder[]> => {
    const { ordersBySupplier, unseparatedOrders } = divideBySupplier(orders);

    for await (const [supplierId, orders] of Object.entries(ordersBySupplier)) {
      const transformedOrders = await this.transformBySupplierId(
        orders,
        supplierId
      );
      ordersBySupplier[supplierId] = transformedOrders;
    }

    const dividedOrders = [
      ...Object.values(ordersBySupplier).flat(),
      ...unseparatedOrders,
    ];

    return dividedOrders;
  };

  transformBySupplierId = async (
    orders: NormalizedOrder[],
    supplierId: string
  ): Promise<NormalizedOrder[]> => {
    const supplier = this.oms.supplier.getSupplier(supplierId);
    if (supplier == null || supplier.plugins == null) {
      return Promise.resolve(orders);
    }

    const plugins = this.oms.plugin.getPlugins(supplier.plugins);
    const pluginExecutionService = new PluginExecutionService(
      this.oms,
      plugins,
      'divide'
    );

    const transformedOrders = await pluginExecutionService.execute(orders);
    return transformedOrders as NormalizedOrder[];
  };
}
