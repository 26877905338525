import { observer } from 'mobx-react-lite';
import { UserSetting } from '@sweep/contract';
import { JsonInput, ObjectSchema, Schema } from '@sweep/sds';

interface UserSettingProps {
  value: UserSetting;
  onChange: (setting: UserSetting) => void;
}

function UserSettingInput({ value, onChange }: UserSettingProps) {
  return (
    <div>
      <p className="text-semibold-2xl">User Setting</p>
      <JsonInput
        schema={USER_SETTING_SCHEMA}
        value={value}
        onChange={onChange}
        collapse={false}
      />
    </div>
  );
}

const FORMAT_PRODUCT_NAME_PROPERTIES: ObjectSchema['properties'] = {
  setDataQuantity1: {
    type: 'boolean',
    description:
      '옵션 표현 수량 1로 설정 (ex: option: 사과 * 6, quantity: 1 > option: 사과, quantity: 6)',
  },
  setOrderQuantity1: {
    type: 'boolean',
    description:
      '주문 수량 1로 설정 (ex: option: 사과, quantity: 6 > option: 사과 * 6, quantity: 1)',
  },
  showQuantityAlways: {
    type: 'boolean',
    description: '수량 항상 표시 (default: 2개 이상일 때만 표시)',
  },

  customFormat: {
    type: 'string',
    nullable: true,
    description:
      '커스텀 상품 포맷 (default: "{productName} {unit}{customQuantityFormat}")',
    placeholder: '{productName} {unit}{customQuantityFormat}',
  },
  customQuantityFormat: {
    type: 'string',
    nullable: true,
    description: '커스텀 수량 포맷 (default: " x{quantity}")',
    placeholder: ' x{quantity}',
  },
  customSeparator: {
    type: 'string',
    nullable: true,
    description:
      '커스텀 상품 separator (default: " + ", ex: ", " > 사과, 포도)',
    placeholder: ' + ',
  },

  useProductName: {
    type: 'boolean',
    description: '상품명에 포맷팅된 결과 저장 (default: 옵션)',
  },
};

const USER_SETTING_SCHEMA: Schema = {
  type: 'object',
  properties: {
    uploadHandWrittenFile: {
      type: 'boolean',
      description: '수기 엑셀 업로드',
    },
    doseosangan: {
      type: 'object',
      description: '도서산간',
      properties: {
        enabled: {
          type: 'boolean',
        },
        standard: {
          type: 'enum',
          description: '택배사 기준',
          options: [
            { value: 'CJ', label: 'CJ대한통운' },
            { value: 'LOTTE', label: '롯데' },
          ],
        },
      },
    },
    compositionMatching: {
      type: 'object',
      description: '컴포지션 매칭',
      properties: {
        optionCodeMatching: {
          type: 'boolean',
          description: '옵션코드 매칭',
        },
      },
    },
    combinedShipping: {
      type: 'object',
      description: '합배송',
      properties: {
        combineAll: {
          type: 'boolean',
          description: '전체 합배송',
        },
        separateAll: {
          type: 'boolean',
          description: '전체 분리배송',
        },
      },
    },
    formatOrdersSettings: {
      type: 'object',
      description: '상품명 포맷팅',
      properties: {
        ...FORMAT_PRODUCT_NAME_PROPERTIES,

        suppliers: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              supplierId: {
                type: 'string',
              },
              config: {
                type: 'object',
                label: '상품명 포맷팅',
                properties: FORMAT_PRODUCT_NAME_PROPERTIES,
              },
            },
          },
        },

        rules: {
          type: 'array',
          description: '상품명 규칙',
          items: {
            type: 'object',
            properties: {
              condition: {
                type: 'object',
                description: '조건',
                properties: {
                  partnerId: {
                    type: 'string',
                  },
                  shoppingMallId: {
                    type: 'string',
                  },
                  supplierId: {
                    type: 'string',
                  },
                  productId: {
                    type: 'string',
                  },
                },
              },
              value: {
                type: 'object',
                description: '값',
                properties: {
                  prefix: {
                    type: 'string',
                  },
                  suffix: {
                    type: 'string',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

export default observer(UserSettingInput);
