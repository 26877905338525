import { isNil } from 'es-toolkit/compat';
import { action, observable, runInAction } from 'mobx';
import {
  CreateSpreadsheetHeaderMappingRequestDTO,
  SpreadsheetHeaderMapping,
} from '@sweep/contract';
import { requestCreateSpreadsheetHeaderMapping } from 'src/network/spreadsheet-header-mapping/createSpreadsheetHeaderMapping';
import { requestFindAllSpreadsheetHeaderMappingByUser } from 'src/network/spreadsheet-header-mapping/findAllSpreadsheetHeaderMappingByUser';
import { OMSStore } from './OMSStore';

export class SpreadsheetHeaderMappingStore {
  @observable
  accessor spreadsheetHeaderMappings: SpreadsheetHeaderMapping[] = [];

  constructor(private oms: OMSStore) {}

  @action.bound
  async init() {
    await this.loadSpreadsheetHeaderMappings();
  }

  @action.bound
  async loadSpreadsheetHeaderMappings() {
    const newSpreadsheetHeaderMappings =
      await requestFindAllSpreadsheetHeaderMappingByUser();

    runInAction(() => {
      this.spreadsheetHeaderMappings = newSpreadsheetHeaderMappings;
    });
  }

  @action.bound
  async createSpreadsheetHeaderMapping(
    spreadsheetHeaderMapping: CreateSpreadsheetHeaderMappingRequestDTO
  ): Promise<boolean> {
    const newSpreadsheetHeaderMapping =
      await requestCreateSpreadsheetHeaderMapping(spreadsheetHeaderMapping);

    if (isNil(newSpreadsheetHeaderMapping)) {
      return false;
    }

    runInAction(() => {
      this.spreadsheetHeaderMappings = [
        ...this.spreadsheetHeaderMappings,
        newSpreadsheetHeaderMapping,
      ];
    });

    return true;
  }
}
