import { isNil } from 'es-toolkit';
import { isEmpty } from 'es-toolkit/compat';
import { SHIPPING_ORDER_PROCESS_STATUS_CODE } from '@sweep/domain/services/order-shipping-process';
import { createLogger } from 'src/third-parties/createLogger';
import { ProcesserFunction } from './processor.type';

const debug = createLogger(
  'services:process-shipping-order-files:processShippingInfoValidation'
);

export const processShippingInfoValidation: ProcesserFunction = (item) => {
  debug('start', item.index);
  if (!item.isProcessable) {
    return item;
  }

  const shippingOrder = item.value;
  const shippingCompany = shippingOrder.shippingCompany;
  const shippingNumber = shippingOrder.shippingNumber;

  const isShippingCompanyInvalid =
    isNil(shippingCompany) || isEmpty(shippingCompany);
  if (isShippingCompanyInvalid) {
    const nextStatus =
      SHIPPING_ORDER_PROCESS_STATUS_CODE.INVALID_SHIPPING_COMPANY_EMPTY;
    const prevHistories = item.histories;
    const nextHistories = [...prevHistories, nextStatus];

    return {
      ...item,
      isProcessable: false,
      status: nextStatus,
      histories: nextHistories,
    };
  }

  const isShippingNumberInvalid =
    isNil(shippingNumber) || isEmpty(shippingNumber);
  if (isShippingNumberInvalid) {
    const nextStatus =
      SHIPPING_ORDER_PROCESS_STATUS_CODE.INVALID_SHIPPING_NUMBER_EMPTY;
    const prevHistories = item.histories;
    const nextHistories = [...prevHistories, nextStatus];

    return {
      ...item,
      isProcessable: false,
      status: nextStatus,
      histories: nextHistories,
    };
  }

  const nextStatus = SHIPPING_ORDER_PROCESS_STATUS_CODE.SUCCESS;
  const prevHistories = item.histories;
  const nextHistories = [...prevHistories, nextStatus];

  return {
    ...item,
    isProcessable: true,
    status: nextStatus,
    histories: nextHistories,
  };
};
