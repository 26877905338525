import {
  BULK_AUTO_DISPATCH_ORIGINAL_ORDER_URL,
  BulkAutoDispatchOriginalOrderPayload,
  BulkAutoDispatchOriginalOrderResponse,
} from '@sweep/contract';
import { assert } from '@sweep/utils';
import api from '../api';

export const bulkAutoDispatchOriginalOrder = async (
  payload: BulkAutoDispatchOriginalOrderPayload
): Promise<BulkAutoDispatchOriginalOrderResponse> => {
  const response = await api.post<BulkAutoDispatchOriginalOrderResponse>(
    BULK_AUTO_DISPATCH_ORIGINAL_ORDER_URL,
    payload
  );
  assert(response != null, 'Failed to bulk auto dispatch original order');

  return response.data;
};
