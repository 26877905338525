import { isNil, isNotNil } from 'es-toolkit';
import { isEmpty } from 'es-toolkit/compat';
import {
  hasKeysInArray,
  hasKeysInObject,
  isDeepArrayEqual,
} from '@sweep/utils';
import { UserSpreadsheetHeaderMappingLikeModel } from '../../../models/userSpreadsheetHeaderMappingLike.model';
import { mapKeysByColumnMapping } from '../../common/mapKeysByColumnMapping';
import { createHeaderMappingResult } from '../createHeaderMappingResult';
import {
  HeaderMappingResult,
  HeaderMatchContext,
  HeaderMatchReasonType,
} from '../types';

export const findUserSpreadsheetHeaderMappingMatch = (
  spreadsheetHeaderMappings: UserSpreadsheetHeaderMappingLikeModel[],
  context: HeaderMatchContext
): HeaderMappingResult | null => {
  if (isNil(spreadsheetHeaderMappings) || isEmpty(spreadsheetHeaderMappings)) {
    return null;
  }

  const exactMatch = spreadsheetHeaderMappings
    .map((mapping) => matchSpreadsheetHeaderMappingExact(mapping, context))
    .find(isNotNil);

  if (exactMatch) {
    return exactMatch;
  }

  return null;
};

const matchSpreadsheetHeaderMappingExact = (
  spreadsheetHeaderMapping: UserSpreadsheetHeaderMappingLikeModel,
  context: HeaderMatchContext
): HeaderMappingResult | null => {
  const { headerRow, requiredHeaderKeys } = context;
  const { headers, columnMappings } = spreadsheetHeaderMapping;

  if (isNil(columnMappings)) {
    return null;
  }

  if (!hasKeysInObject(columnMappings, requiredHeaderKeys)) {
    return null;
  }

  const isHeaderMatch = isDeepArrayEqual(headers ?? [], headerRow);

  if (!isHeaderMatch) {
    return null;
  }

  const headerKeys = mapKeysByColumnMapping(headerRow, columnMappings);
  if (!hasKeysInArray(headerKeys, requiredHeaderKeys)) {
    return null;
  }

  return createHeaderMappingResult({
    headerKeys,
    mappingColumn: columnMappings,
    defaultShippingCompany: context.defaultShippingCompany,
    matchReason:
      HeaderMatchReasonType.USER_SPREADSHEET_HEADER_MAPPING_EXACT_MATCH,
    matchReasonDetail: spreadsheetHeaderMapping.originalFilename,
  });
};
