import { cva } from '@sweep/tailwind';
import { IconCheck } from '@sweep/asset/icons';

interface SelectIconProps {
  selected?: boolean;
}

function SelectIcon({ selected = false }: SelectIconProps) {
  return <IconCheck className={icon({ selected })} />;
}

const icon = cva('', {
  variants: {
    selected: {
      true: 'text-blue-500',
      false: 'text-gray-300',
    },
  },
});

export default SelectIcon;
