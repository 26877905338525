export type DataRow = Record<string, string | undefined>;

const createRowObject = (headerKeys: string[], values: string[]): DataRow =>
  headerKeys.reduce<DataRow>(
    (acc, key, index) => ({
      ...acc,
      [key]: values[index],
    }),
    {}
  );

export function mapHeaderRowToObjects(
  headerKeys: string[],
  valueRows: string[][]
): DataRow[] {
  return valueRows.map((values) => createRowObject(headerKeys, values));
}
