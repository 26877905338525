import { z } from 'zod';
import {
  CompositionMatching,
  compositionMatchingSchema,
} from '../type/CompositionMatching';

// GET /composition-matching/fetch
export const GET_COMPOSITION_MATCHING_URL = '/composition-matching/fetch';

export type GetCompositionMatchingResponse = CompositionMatching[];

// POST /composition-matching/register
export const CREATE_COMPOSITION_MATCHING_URL = '/composition-matching/register';

export const createCompositionMatchingDTOSchema =
  compositionMatchingSchema.omit({
    _id: true,
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
    count: true,
  });
export type CreateCompositionMatchingDTO = z.infer<
  typeof createCompositionMatchingDTOSchema
>;

export const createCompositionMatchingBodySchema = z.object({
  productInfo: createCompositionMatchingDTOSchema,
});

export type CreateCompositionMatchingBody = z.infer<
  typeof createCompositionMatchingBodySchema
>;

export type CreateCompositionMatchingResponse = CompositionMatching;

// POST /composition-matching/register/many
export const CREATE_COMPOSITION_MATCHINGS_URL =
  '/composition-matching/register/many';

export const createCompositionMatchingsBodySchema = z.object({
  compositionMatchings: z.array(createCompositionMatchingDTOSchema),
});
export type CreateCompositionMatchingsBody = z.infer<
  typeof createCompositionMatchingsBodySchema
>;

export type CreateCompositionMatchingsResponse = CompositionMatching[];

// PUT /composition-matching/update
export const UPDATE_COMPOSITION_MATCHING_URL = '/composition-matching/update';

export const updateCompositionMatchingDTOSchema = compositionMatchingSchema
  .omit({
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
    count: true,
  })
  .partial();
export type UpdateCompositionMatchingDTO = z.infer<
  typeof updateCompositionMatchingDTOSchema
>;

export const updateCompositionMatchingBodySchema = z.object({
  composition: updateCompositionMatchingDTOSchema,
});
export type UpdateCompositionMatchingBody = z.infer<
  typeof updateCompositionMatchingBodySchema
>;

export type UpdateCompositionMatchingResponse = CompositionMatching;

// PUT /composition-matching/increment-counts
export const INCREMENT_COMPOSITION_MATCHING_COUNTS_URL =
  '/composition-matching/increment-counts';

export const incrementCompositionMatchingCountsBodySchema = z.object({
  compositionMatchingIds: z.array(z.string()),
});
export type IncrementCompositionMatchingCountsBody = z.infer<
  typeof incrementCompositionMatchingCountsBodySchema
>;
