import { Order } from '@sweep/contract';
import { AbstractPlugin } from '../../interface';
import { FormatProductNamePluginLegacy } from './FormatProductNamePluginLegacy';
import { formatProductName } from './services/formatProductName';
import { getRuleValue } from './services/getRuleValue';
import { toFormatOrder } from './services/toFormatOrder';
import { toOrder } from './services/toOrder';

export class FormatProductNamePlugin extends AbstractPlugin {
  get _config() {
    return this.oms.user.setting?.formatOrdersSettings;
  }

  transform = (orders: Order[]): Promise<Order[]> => {
    if (this.oms.user.deploy?.formatProductName !== true) {
      return new FormatProductNamePluginLegacy(this.oms, undefined).transform(
        orders
      );
    }

    const formattedOrders = orders.map(this.format).map(this.applyRules);
    const convertedOrders = this.convert(formattedOrders);

    return Promise.resolve(convertedOrders);
  };

  format = (order: Order): Order => {
    const supplierConfig = this._config?.suppliers?.find(
      (config) => config.supplierId === order.supplierId
    )?.config;

    const config = supplierConfig ?? this._config;
    const formatOrder = toFormatOrder(order);
    const formattedOrder = formatProductName(formatOrder, config);

    return toOrder(formattedOrder, order);
  };

  applyRules = (order: Order): Order => {
    const ruleValue = getRuleValue(order, this._config?.rules ?? []);

    if (ruleValue == null) {
      return order;
    }

    const { prefix = '', suffix = '' } = ruleValue;

    return {
      ...order,
      option: `${prefix}${order.option ?? ''}${suffix}`,
    };
  };

  // NOTE(@이지원): 해당 useCase가 더 쌓이면 개선하기
  convert = (orders: Order[]) => {
    const { enabled, settings } =
      this.oms.user.setting?.convertSpecificCompositionExpressionSetting ?? {};

    if (enabled !== true || settings == null || settings.length === 0) {
      return orders;
    }

    return orders.map((order) => {
      if (order.data == null || order.data.length === 0) {
        return order;
      }

      for (const setting of settings) {
        if (setting.object != null) {
          const isIncludeReplacementInOption =
            order.origOption != null &&
            order.origOption.includes(setting.replacement);
          const isIncludeReplacementInName =
            order.origName != null &&
            order.origName.includes(setting.replacement);

          if (isIncludeReplacementInOption || isIncludeReplacementInName) {
            const convertedOption = order.option?.replace(
              setting.object.productName,
              setting.replacement
            );

            return { ...order, option: convertedOption };
          }
        }

        if (
          setting.partnerId != null &&
          setting.productId != null &&
          setting.partnerId === order.partnerId &&
          order?.data.some((data) => data.productId === setting.productId)
        ) {
          const product = this.oms.product.getProduct(setting.productId);
          const convertedOption = order.option?.replace(
            product?.productName ?? '',
            setting.replacement
          );

          return { ...order, option: convertedOption };
        }
      }

      return order;
    });
  };
}
