import { useMemo } from 'react';

export function useSelectableTable<T>({
  items,
  selectedItems,
  disabledItems = [],
  onSelect,
  getKey,
}: {
  items: (T | T[])[];
  selectedItems: T[];
  disabledItems?: T[];
  onSelect: (items: T[]) => void;
  getKey: (data: T) => string;
}) {
  const falttenedItems = useMemo(
    () => items.flatMap((item) => (Array.isArray(item) ? item : [item])),
    [items]
  );
  const itemByKey = useMemo(() => {
    return new Map(
      [...falttenedItems, ...selectedItems, ...disabledItems].map(
        (item) => [getKey(item), item] as const
      )
    );
  }, [falttenedItems, getKey, selectedItems, disabledItems]);

  const disabledKeySet = new Set(disabledItems.map(getKey));
  const selectedKeySet = new Set(selectedItems.map(getKey));
  const isAllSelected =
    falttenedItems.length > 0 &&
    falttenedItems.every(
      (row) =>
        selectedKeySet.has(getKey(row)) || disabledKeySet.has(getKey(row))
    );

  const isSelectedItem = (row: T) => selectedKeySet.has(getKey(row));
  const isSelectedArray = (row: T[]) =>
    row.every((item) => isSelectedItem(item));
  const isSelected = (row: T | T[]) =>
    Array.isArray(row) ? isSelectedArray(row) : isSelectedItem(row);

  const isDisabledItem = (row: T) => disabledKeySet.has(getKey(row));
  const isDisabledArray = (row: T[]) =>
    row.every((item) => isDisabledItem(item));
  const isDisabled = (row: T | T[]) =>
    Array.isArray(row) ? isDisabledArray(row) : isDisabledItem(row);

  const handleAllSelectedChange = (checked: boolean) => {
    const keys = falttenedItems.map(getKey);
    const keySet = new Set(keys);
    if (checked) {
      const newSelectedKeySet = new Set([
        ...selectedKeySet,
        ...keys,
      ]).difference(disabledKeySet);
      const newSelectedItems = Array.from(newSelectedKeySet).filter(
        (key) => !disabledKeySet.has(key)
      );
      onSelect(newSelectedItems.map((key) => itemByKey.get(key)!));

      return;
    }

    const newSelectedItems = selectedItems.filter(
      (item) => !keySet.has(getKey(item))
    );
    onSelect(newSelectedItems);
  };

  const handleItemSelectedChange = (row: T) => (checked: boolean) => {
    const key = getKey(row);
    if (checked) {
      const newSelectedItems = [...selectedItems, row];
      onSelect(newSelectedItems);
      return;
    }

    const newSelectedItems = selectedItems.filter(
      (item) => getKey(item) !== key
    );
    onSelect(newSelectedItems);
  };

  const handleArraySelectedChange = (row: T[]) => (checked: boolean) => {
    const keys = row.map(getKey);
    const keySet = new Set(keys);
    if (checked) {
      const newSelectedKeySet = new Set([...selectedKeySet, ...keys]);
      onSelect(Array.from(newSelectedKeySet).map((key) => itemByKey.get(key)!));

      return;
    }

    const newSelectedItems = selectedItems.filter(
      (item) => !keySet.has(getKey(item))
    );
    onSelect(newSelectedItems);
  };

  const handleSelectedChange = (row: T | T[]) => (checked: boolean) =>
    Array.isArray(row)
      ? handleArraySelectedChange(row)(checked)
      : handleItemSelectedChange(row)(checked);

  return {
    isAllSelected,
    isSelected,
    isDisabled,
    onAllSelectedChange: handleAllSelectedChange,
    onItemSelectedChange: handleItemSelectedChange,
    onArraySelectedChange: handleArraySelectedChange,
    onSelectedChange: handleSelectedChange,
  };
}
