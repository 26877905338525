import { keyBy } from 'es-toolkit';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { NormalizedOrder } from '@sweep/contract';
import { formatDate } from '@sweep/utils';
import { OMSStore } from 'src/stores/OMSStore';
import ErrorReporter from 'src/third-parties/ErrorReporter';
import { createAdvancedShippingNoticeDocuments } from './createAdvancedShippingNoticeDocuments';
import { createExcelFromAdvancedShippingNoticeDocument } from './createExcelFromAdvancedShippingNoticeDocument';
import { createExcelFromAdvancedShippingNoticeDocumentForMall } from './createExcelFromAdvancedShippingNoticeDocumentForMall';

export const splitAndDownloadPurchaseOrder = async (params: {
  oms: OMSStore; // TODO(SWP-447): 이 파라미터 내부에서 필요한 파라미터만 받도록 수정 필요
  shippingOrders: NormalizedOrder[];
}) => {
  const { oms, shippingOrders } = params;
  const mergedOrders = oms.order.mergedOrders;

  const orderUniqueCodes = shippingOrders.flatMap((order) =>
    order.uniqueCode.split(',')
  );

  const updateOrderUniqueCodes = Array.from(new Set(orderUniqueCodes));

  const selectedOrders = mergedOrders.filter((order) =>
    updateOrderUniqueCodes.includes(order.uniqueCode)
  );

  const partners = oms.partner.partners;
  const partnerRecords = keyBy(partners, (partner) => partner._id);

  const [ASNDocumentForCustomOrders, ASNDocumentForShoppingMallOrders] =
    createAdvancedShippingNoticeDocuments(selectedOrders, partnerRecords);

  let documentCreateSuccessCount = 0;
  const zip = new JSZip();
  for (const document of ASNDocumentForCustomOrders) {
    try {
      const result = await createExcelFromAdvancedShippingNoticeDocument(
        document,
        partners,
        oms
      );

      if (result.isSuccess) {
        documentCreateSuccessCount += 1;
        zip.file(result.filename, result.buffer);
      }
    } catch (error) {
      ErrorReporter.captureError(error);
    }
  }

  for (const document of ASNDocumentForShoppingMallOrders) {
    try {
      const result = await createExcelFromAdvancedShippingNoticeDocumentForMall(
        document,
        partners,
        oms
      );

      if (result.isSuccess) {
        documentCreateSuccessCount += 1;
        zip.file(result.filename, result.buffer);
      }
    } catch (error) {
      ErrorReporter.captureError(error);
    }
  }

  const shouldCreateZipFile = documentCreateSuccessCount > 0;

  if (shouldCreateZipFile) {
    const zipContent = await zip.generateAsync({ type: 'blob' });
    saveAs(zipContent, `${getZipFilename()}.zip`);
  }

  const totalDocumentCount =
    ASNDocumentForCustomOrders.length + ASNDocumentForShoppingMallOrders.length;
  const documentCreateFailedCount =
    totalDocumentCount - documentCreateSuccessCount;

  return [documentCreateSuccessCount, documentCreateFailedCount];
};

function getZipFilename(): string {
  const formattedDate = formatDate(new Date(), 'yy.MM.dd');
  const fileName = `${formattedDate} 운송장첨부`;

  return fileName;
}
