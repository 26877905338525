import { useId } from 'react';
import { Nullable } from '@sweep/utils';
import { TextInput } from '../../TextInput';
import { StringSchema } from '../schema';

interface JsonStringInputProps {
  schema: StringSchema;
  value: Nullable<string>;
  onChange: (value: string | null) => void;
}

export function JsonStringInput({
  schema,
  value,
  onChange,
}: JsonStringInputProps) {
  const id = useId();

  const handleChange = (value: string) => {
    const newValue = schema.nullable === true && value === '' ? null : value;
    onChange(newValue);
  };

  return (
    <div className="flex flex-col gap-2px">
      <label htmlFor={id}>
        <div className="flex gap-8px">
          <p className="text-medium-m text-gray-700">{schema.label}</p>
          <p className="text-medium-s text-gray-500">{schema.description}</p>
        </div>
      </label>
      <TextInput
        id={id}
        value={value ?? ''}
        onChange={handleChange}
        placeholder={schema.placeholder}
      />
    </div>
  );
}
