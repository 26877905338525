import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { IconArrowRight, IconControlDownload } from '@sweep/asset/icons';
import { NormalizedOrder } from '@sweep/contract';
import {
  Button,
  SearchInput,
  SelectableTable,
  TableCount,
  TableSelectControl,
} from '@sweep/sds';
import { formatDate } from '@sweep/utils';
import { If } from '@sweep/utils/react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { useSearchState } from 'src/hooks/useSearchState';
import { createOrderExcel } from 'src/services/file/excel/create';
import { useTableFilterColumnsWithEdit } from '../hooks/useTableFilterColumnsWithEdit';
import { processShippingOrderToShipped } from '../services/process-shipping-order-to-shipped/processShippingOrderToShipped';
import { OrderShippingReviewingStore } from '../stores/OrderShippingReviewingStore';

function OrderShippingSectionShipped() {
  const oms = useOMSStore();
  const store = oms.getStore(OrderShippingReviewingStore);
  const reviewingOrders = store.shippingStatusReviewingOrders;

  const [search, setSearch, filterSearch] = useSearchState({
    keys: oms.user.excelHeaderKeys,
  });
  const searchFilteredOrders = useMemo(
    () => filterSearch(reviewingOrders),
    [filterSearch, reviewingOrders]
  );

  const isUploadHandWrittenFile =
    oms.user.setting?.uploadHandWrittenFile === true;
  const { columns, filteredOrders } = useTableFilterColumnsWithEdit({
    orders: searchFilteredOrders,
    useUniqueCodeSub: !isUploadHandWrittenFile, // SWP-582 뉴스고 보여주기, uploadHandWrittenFile 이 켜져있으면 비활성화
  });

  const [selectedOrders, setSelectedOrders] = useState<NormalizedOrder[]>([]);
  const currentOrders =
    selectedOrders.length > 0 ? selectedOrders : filteredOrders;

  const download = () => {
    const isUploadHandWrittenFile =
      oms.user.setting?.uploadHandWrittenFile === true;
    // SWP-582 뉴스고 보여주기, uploadHandWrittenFile 이 켜져있으면 비활성화
    const tempModifiedOrders = isUploadHandWrittenFile
      ? currentOrders
      : currentOrders.map((order) => ({
          ...order,
          uniqueCode: order.uniqueCodeAfterCustomization,
        }));

    createOrderExcel(
      oms,
      tempModifiedOrders, // SWP-582 뉴스고 보여주기
      formatDate(new Date(), 'yy.MM.dd 통합엑셀'),
      oms.user.excelHeaders,
      oms.user.excelColumnMapping
    );
  };

  const splitPurchaseOrder = async () => {
    oms.loading.batch(async () => {
      await processShippingOrderToShipped({
        shippingOrders: currentOrders,
        oms,
      });
    });
  };

  return (
    <div className="shadow-line p-20px rounded-8px gap-16px flex flex-col bg-white">
      <div className="flex justify-between">
        <SearchInput
          value={search}
          onChange={setSearch}
          placeholder="검색어를 입력하세요."
          className="max-w-400px"
        />
      </div>
      <hr />
      <div>
        <TableCount count={filteredOrders.length} />
        <div className="flex items-end justify-between">
          <TableSelectControl
            count={selectedOrders.length}
            onClear={() => setSelectedOrders([])}
          />
          <div className="gap-10px flex">
            <Button
              color="gray"
              rightAccessory={<IconControlDownload />}
              onClick={download}
            >
              엑셀 파일
            </Button>
            <Button
              rightAccessory={<IconArrowRight />}
              onClick={splitPurchaseOrder}
            >
              운송장 등록
            </Button>
          </div>
        </div>
      </div>
      <If is={reviewingOrders.length > 0}>
        <SelectableTable
          items={filteredOrders}
          columns={columns}
          selectedItems={selectedOrders}
          onSelect={setSelectedOrders}
          getKey={(order) => order.uniqueCodeAfterCustomization}
          className="max-h-[calc(100vh-300px)]"
        />
      </If>
    </div>
  );
}

export default observer(OrderShippingSectionShipped);
