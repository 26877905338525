import { z } from 'zod';
import { SpreadsheetHeaderMappingSchema } from '../../type/SpreadsheetHeaderMapping';

export const CREATE_SPREADSHEET_HEADER_MAPPING_URL = `spreadsheet-header-mapping`;

export const CreateSpreadsheetHeaderMappingRequestDTOSchema = z.object({
  userId: z.string(),
  headers: z.array(z.string()),
  columnMappings: z.record(z.string()),
  originalFilename: z.string(),
});

export type CreateSpreadsheetHeaderMappingRequestDTO = z.infer<
  typeof CreateSpreadsheetHeaderMappingRequestDTOSchema
>;

export const CreateSpreadsheetHeaderMappingResponseDTOSchema = z.object({
  spreadsheetHeaderMapping: SpreadsheetHeaderMappingSchema,
});

export type CreateSpreadsheetHeaderMappingResponseDTO = z.infer<
  typeof CreateSpreadsheetHeaderMappingResponseDTOSchema
>;
