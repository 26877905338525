import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconArrowRight, IconHeaderLogotype } from '@sweep/asset/icons';
import { cva } from '@sweep/tailwind';
import MetaTags from 'src/components/MetaTags';
import { useView } from 'src/hooks/useView';
import { imageFullLogoAliExpress, imageLogos } from 'src/images';
import { amplitude } from 'src/third-parties/amplitude';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import videoAli from '../../assets/videos/video-ali.mp4';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import videoAli1 from '../../assets/videos/video-ali-1.mp4';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import videoAli2 from '../../assets/videos/video-ali-2.mp4';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import videoAli3 from '../../assets/videos/video-ali-3.mp4';
import ExampleVideo from './components/ExampleVideo';
import FadeIn from './components/FadeIn';
import SlideUp from './components/SlideUp';

function Gap({ height }: { height: number }) {
  return <div style={{ height: `${height}px` }} />;
}

function AliExpressScreen() {
  const navigate = useNavigate();
  const navigateToRegistration = () => {
    amplitude.track('[Ali] Click Register Button');
    navigate('/ali-express/register');
  };

  const [isButton1Visible, setIsButton1Visible] = useState(true);
  const [isButton2Visible, setIsButton2Visible] = useState(false);
  const button1Ref = useRef<HTMLButtonElement>(null);
  const button2Ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsButton1Visible(true);
          return;
        }
        setIsButton1Visible(false);
      },
      { threshold: 0.6 }
    );

    if (button1Ref.current) {
      observer.observe(button1Ref.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsButton2Visible(true);
          return;
        }
        setIsButton2Visible(false);
      },
      { threshold: 0.1 }
    );

    if (button2Ref.current) {
      observer.observe(button2Ref.current);
    }

    return () => observer.disconnect();
  }, []);

  const logosRef = useView<HTMLImageElement>(() =>
    amplitude.track('[Ali] View Logos')
  );
  const video1Ref = useView<HTMLDivElement>(() =>
    amplitude.track('[Ali] View Video 1')
  );
  const video2Ref = useView<HTMLDivElement>(() =>
    amplitude.track('[Ali] View Video 2')
  );
  const video3Ref = useView<HTMLDivElement>(() =>
    amplitude.track('[Ali] View Video 3')
  );
  const benefit1Ref = useView<HTMLDivElement>(() =>
    amplitude.track('[Ali] View Benefit 1')
  );
  const benefit2Ref = useView<HTMLDivElement>(() =>
    amplitude.track('[Ali] View Benefit 2')
  );
  const benefit3Ref = useView<HTMLDivElement>(() =>
    amplitude.track('[Ali] View Benefit 3')
  );
  const lastOfferRef = useView<HTMLDivElement>(() =>
    amplitude.track('[Ali] View Last Offer')
  );

  return (
    <>
      <MetaTags
        title="AliExpress × Sweep"
        description="무료로 알리 주문을 3분 만에 처리하세요."
        image="https://sweep-og-images.s3.ap-northeast-2.amazonaws.com/og-image-ali-express.png"
      />
      <div className="relative flex w-full flex-col items-center justify-start bg-white">
        <div className="flex w-full flex-col items-center bg-gradient-to-b from-red-100 to-white">
          <div className="flex w-full items-center justify-start p-[24px]">
            <IconHeaderLogotype className="h-auto w-1/5 text-blue-500" />
          </div>
          <Gap height={60} />
          <SlideUp>
            <div className="flex items-center gap-[10px]">
              <img
                src={imageFullLogoAliExpress}
                alt="ali-express"
                className="h-[26px] translate-y-[2px]"
              />
              <span className="text-center text-[28px] font-bold leading-[48px] tracking-tight text-gray-700">
                주문처리
              </span>
            </div>
          </SlideUp>
          <SlideUp>
            <div className="flex items-center">
              <IconHeaderLogotype className="h-[31px] w-auto text-blue-500" />
              <span className="text-center text-[28px] font-bold leading-[48px] tracking-tight text-gray-700">
                으로 3분 만에 끝내기
              </span>
            </div>
          </SlideUp>
          <Gap height={24} />
          <SlideUp>
            <p className="text-[16px] font-bold text-gray-700">
              주소 교정부터 부분 운송장 등록까지
            </p>
          </SlideUp>
          <Gap height={36} />
          <FadeIn delay={500}>
            <button
              ref={button1Ref}
              onClick={navigateToRegistration}
              className="flex items-center gap-[12px] rounded-full bg-red-500 py-[8px] pl-[24px] pr-[8px] text-[20px] font-bold text-white"
            >
              무료로 시작하기
              <div className="flex size-[32px] items-center justify-center rounded-full bg-white">
                <IconArrowRight className="size-[32px] text-red-500" />
              </div>
            </button>
          </FadeIn>
          <Gap height={80} />
          <div className="w-[90%] rounded-[32px] bg-gradient-to-b from-red-300 to-red-200 p-[6px]">
            <video
              className="rounded-[26px] object-cover"
              autoPlay
              loop
              muted
              playsInline
            >
              <source src={videoAli} />
            </video>
          </div>
        </div>
        <Gap height={60} />
        <div className="text-center text-[20px] font-bold leading-[36px] tracking-tight text-gray-700">
          100곳 이상의 고객사와 함께
          <br />
          <div className="flex items-center justify-center gap-[8px]">
            <img
              src={imageFullLogoAliExpress}
              alt="ali-express"
              className="h-[19px] translate-y-[2px]"
            />
            <span>주문을 3분 만에 처리하세요.</span>
          </div>
        </div>
        <Gap height={40} />
        <img
          ref={logosRef}
          src={imageLogos}
          alt="logos"
          className="w-[90%] bg-white object-cover"
        />
        {/* <Interviews /> */}
        <Gap height={60} />
        <div className="h-[120px] w-full bg-gradient-to-b from-white to-gray-900" />
        <div className="flex w-full flex-col items-center justify-center bg-gray-900 px-[20px] py-[40px]">
          <div ref={video1Ref} className="flex w-[90%] flex-col items-start">
            <ExampleVideo src={videoAli1} />
            <Gap height={30} />
            <div className={videoTitle()}>
              <SlideUp delay={250}>
                <p>빠른 주문 수집</p>
              </SlideUp>
              <SlideUp delay={250}>
                <p>정교한 주소 교정</p>
              </SlideUp>
            </div>
            <Gap height={10} />
            <SlideUp delay={250}>
              <p className={videoDescription()}>
                천 건이 넘는 주문도 빠르게 수집하고 자동으로 주소를 교정합니다.
              </p>
            </SlideUp>
          </div>
          <Gap height={100} />
          <div ref={video2Ref} className="flex w-[90%] flex-col items-start">
            <ExampleVideo src={videoAli2} />
            <Gap height={30} />
            <div className={videoTitle()}>
              <SlideUp delay={250}>
                <p>간편한 주문 확인과</p>
              </SlideUp>
              <SlideUp delay={250}>
                <p>취소 주문 처리</p>
              </SlideUp>
            </div>
            <Gap height={10} />
            <SlideUp delay={250}>
              <p className={videoDescription()}>
                클릭 한 번으로 주문 확인, 일괄 취소 승인 및 거부가 가능합니다.
              </p>
            </SlideUp>
          </div>
          <Gap height={100} />
          <div ref={video3Ref} className="flex w-[90%] flex-col items-start">
            <ExampleVideo src={videoAli3} />
            <Gap height={30} />
            <div className={videoTitle()}>
              <SlideUp delay={250}>
                <p>운송장 파일 자동 업로드</p>
              </SlideUp>
            </div>
            <Gap height={10} />
            <SlideUp delay={250}>
              <p className={videoDescription()}>
                운송장 파일을 업로드하면 자동으로 알리 익스프레스 판매자 센터에
                반영됩니다.
              </p>
            </SlideUp>
          </div>
        </div>
        <div className="h-[120px] w-full bg-gradient-to-b from-gray-900 to-white" />
        <div className="flex w-[90%] flex-col items-start py-[60px]">
          <div ref={benefit1Ref} className={benefitContainer()}>
            <FadeIn>
              <p className={benefitBadge({ section: 1 })}>혜택 1</p>
            </FadeIn>
            <p className={benefitTitle()}>쉽고 빠른 세팅</p>
            <p className={benefitDescription()}>
              바로 사용하실 수 있도록 자동 세팅을 지원합니다.
            </p>
          </div>
          <Gap height={40} />
          <div ref={benefit2Ref} className={benefitContainer()}>
            <FadeIn>
              <p className={benefitBadge({ section: 2 })}>혜택 2</p>
            </FadeIn>
            <p className={benefitTitle()}>월 3천 건까지 무료 제공</p>
            <p className={benefitDescription()}>
              주문 수가 월 3천 건 미만인 경우 무료로 사용하실 수 있습니다.
            </p>
            <p className="text-[14px] font-medium leading-[22px] tracking-tight text-gray-500">
              - 월 3천 건 이상 1만 건 미만: ₩19,000/월
              <br />- 월 1만 건 이상: ₩39,000/월
              <br />
              <span className="text-[12px]">* VAT 별도</span>
            </p>
          </div>
          <Gap height={40} />
          <div ref={benefit3Ref} className={benefitContainer()}>
            <FadeIn>
              <p className={benefitBadge({ section: 3 })}>혜택 3</p>
            </FadeIn>
            <p className={benefitTitle()}>일주일 무료 체험</p>
            <p className={benefitDescription()}>
              주문 수에 관계 없이 일주일 무료 체험 후 결정할 수 있습니다.
            </p>
          </div>
        </div>
        <div
          ref={lastOfferRef}
          className="flex w-4/5 flex-col items-center justify-center rounded-[32px] bg-gray-800 px-[20px] py-[40px] shadow-2xl"
        >
          <p className="text-center text-[14px] font-semibold leading-[24px] tracking-tighter text-white">
            솔루션을 써도 여전히 수기 작업을 하고 계신가요?
          </p>
          <Gap height={20} />
          <p className="text-center text-[22px] font-semibold leading-[38px] text-white">
            알리 익스프레스 주문 처리는
          </p>
          <div className="flex items-center text-center text-[22px] font-semibold leading-[38px] text-white">
            <span className="pr-[10px]">무조건</span>
            <IconHeaderLogotype className="h-[34px] w-auto text-white" />
            <span>입니다.</span>
          </div>
          <Gap height={32} />
          <button
            ref={button2Ref}
            onClick={navigateToRegistration}
            className="flex items-center gap-[12px] rounded-full bg-red-500 py-[8px] pl-[24px] pr-[8px] text-[20px] font-bold text-white"
          >
            무료로 시작하기
            <div className="flex size-[32px] items-center justify-center rounded-full bg-white">
              <IconArrowRight className="size-[32px] text-red-500" />
            </div>
          </button>
        </div>
        <Gap height={80} />
        <button
          onClick={navigateToRegistration}
          className={floatingButton({
            visible: !isButton1Visible && !isButton2Visible,
          })}
        >
          무료로 시작하기
        </button>
      </div>
    </>
  );
}

const floatingButton = cva(
  'fixed bottom-0 flex w-full max-w-[480px] items-center justify-center gap-[12px] rounded-t-[12px] bg-red-500 py-[12px] text-[22px] font-bold text-white transition-all duration-500',
  {
    variants: {
      visible: {
        true: '',
        false: 'translate-y-full',
      },
    },
  }
);

const videoTitle = cva(
  'text-[32px] font-bold leading-[48px] tracking-tight text-white'
);

const videoDescription = cva(
  'text-[16px] font-bold leading-[32px] tracking-tight text-white'
);

const benefitContainer = cva(
  'flex w-full flex-col items-start gap-[4px] rounded-[24px] bg-white p-[20px] shadow-[0_6px_16px_-2px_#00000020]'
);

const benefitBadge = cva(
  'flex h-[34px] items-center justify-center rounded-[8px] border px-[12px] text-[14px] font-semibold leading-[24px] tracking-tight transition-opacity duration-500',
  {
    variants: {
      section: {
        1: 'border-[#00CAE9] bg-[#E4F6F8] text-[#00CAE9]',
        2: 'border-[#2869FF] bg-[#E4EAF8] text-[#2869FF]',
        3: 'border-[#9428FF] bg-[#EEE4F8] text-[#9428FF]',
      },
    },
  }
);

const benefitTitle = cva(
  'text-[24px] font-bold leading-[36px] tracking-tight text-gray-700'
);

const benefitDescription = cva(
  'text-[16px] font-medium leading-[24px] tracking-tight text-gray-700'
);

export default AliExpressScreen;
