import { useEffect, useRef } from 'react';

interface ExampleVideoProps {
  src: string;
}

function ExampleVideo({ src }: ExampleVideoProps) {
  const ref = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (ref.current == null) {
          return;
        }
        if (entry.isIntersecting) {
          ref.current.play();
        } else {
          ref.current.pause();
        }
      },
      { threshold: 1 }
    );
    if (ref.current != null) {
      observer.observe(ref.current);
    }
    return () => observer.disconnect();
  }, []);

  return (
    <video ref={ref} className="h-auto w-full" autoPlay loop muted playsInline>
      <source src={src} />
    </video>
  );
}

export default ExampleVideo;
