import { UserSettingLikeModel } from '../../../models/userSettingLike.model';
import { mapKeys } from '../../common/mapKeys';
import { createHeaderMappingResult } from '../createHeaderMappingResult';
import {
  HeaderMappingResult,
  HeaderMatchContext,
  HeaderMatchReasonType,
} from '../types';

export const createFallbackHeaderMapping = (
  settings: UserSettingLikeModel | null | undefined,
  context: HeaderMatchContext
): HeaderMappingResult => {
  const { headerRow } = context;
  const defaultShippingCompany =
    settings?.defaultShippingCompany ?? context.defaultShippingCompany;
  const mappingColumn = settings?.columnTranslation ?? {};

  const headerKeys = mapKeys(headerRow);

  return createHeaderMappingResult({
    headerKeys,
    mappingColumn,
    defaultShippingCompany,
    matchReason: HeaderMatchReasonType.DEFAULT_MATCH,
  });
};
