import { useRef, useState } from 'react';
import { If } from '@sweep/utils/react';

interface InlineEditProps {
  value: string;
  onChange: (value: string) => void;
}

export function InlineEdit({
  value: givenValue,
  onChange: givenOnChange,
}: InlineEditProps) {
  const [value, setValue] = useState(givenValue);
  const [isEditing, setIsEditing] = useState(false);
  const ref = useRef<HTMLTextAreaElement>(null);

  const onEditing = () => {
    setIsEditing(true);
    setTimeout(() => {
      ref.current?.focus();
      ref.current?.setSelectionRange(0, ref.current.value.length);
    });
  };

  const handleChange = (value: string) => {
    setValue(value);
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (value === givenValue) {
      return;
    }

    givenOnChange(value);
  };

  return (
    <div className="w-full">
      <If is={isEditing}>
        <textarea
          className="w-full"
          ref={ref}
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          onBlur={handleBlur}
        />
      </If>
      <If is={!isEditing}>
        <div
          className="h-full min-w-[100px] rounded-md hover:bg-gray-200"
          onDoubleClick={onEditing}
        >
          <p>{value}</p>
        </div>
      </If>
    </div>
  );
}
