import { createLogger } from 'src/third-parties/createLogger';
import { ProcesserFunction } from './processor.type';

const debug = createLogger(
  'services:process-shipping-order-files:processDefaultShippingCompany'
);

export const processDefaultShippingCompany: ProcesserFunction = (
  item,
  context
) => {
  debug('start', item.index);
  if (!item.isProcessable) {
    return item;
  }

  const { defaultShippingCompany } = context;

  const prevValue = item.value;
  const nextValue = {
    ...prevValue,
    shippingCompany: defaultShippingCompany,
  };

  const prevShippingCompany = prevValue.shippingCompany;
  const changeHistory = `${prevShippingCompany} -> ${defaultShippingCompany}`;
  const nextStatus = 'DEFAULT_SHIPPING_COMPANY_UPDATED';

  const prevHistories = item.histories;
  const nextHistories = [...prevHistories, changeHistory];

  return {
    ...item,
    isProcessable: true,
    value: nextValue,
    histories: nextHistories,
    status: nextStatus,
  };
};
