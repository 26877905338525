import { memo } from 'react';
import { cva } from '@sweep/tailwind';
import { ColumnDef } from '../interface';
import { TableHeaderCellRenderer } from './TableHeaderCellRenderer';

interface TableHeaderProps<T> {
  columns: ColumnDef<T>[];
}

function _TableHeader<T>({ columns }: TableHeaderProps<T>) {
  return (
    <div className={header()}>
      {columns
        .map((column) => column.header)
        .map((header, index) => (
          <TableHeaderCellRenderer
            key={index}
            header={header}
            context={{ lastColumn: index === columns.length - 1 }}
            index={index}
          />
        ))}
    </div>
  );
}

const header = cva('min-h-36px flex w-full sticky top-0 left-0 z-[1]');

export const TableHeader = memo(_TableHeader) as typeof _TableHeader;
