import { DispatchedOrder } from '../../type/order/DispatchedOrder';

// POST /confirm-order/confirm-update-orders
export const CONFIRM_ORDERS_URL = '/confirm-order/confirm-update-orders';

export type ConfirmOrdersBody = {
  // TODO(@이지원): uniqueCodes로 변경
  orders: DispatchedOrder[];
};

export type ConfirmedOrderResult =
  | ({ success: true } & DispatchedOrder)
  | { success: false; failReason: string; uniqueCode: string };

export type ConfirmOrdersResponse = ConfirmedOrderResult[];

export function toDispatchedOrder(
  order: ConfirmedOrderResult
): DispatchedOrder | null {
  if (order.success === false) {
    return null;
  }
  const { success, ...rest } = order;
  return rest;
}
