import { CombinedShippingTableProduct } from '../interface';

export function compareCombinedShippingTableProduct(
  a: CombinedShippingTableProduct,
  b: CombinedShippingTableProduct
) {
  if (a.supplierName !== b.supplierName) {
    if (a.supplierName == null) {
      return -1;
    }

    if (b.supplierName == null) {
      return 1;
    }

    return a.supplierName.localeCompare(b.supplierName);
  }

  const comparedByProductName = a.productName.localeCompare(b.productName);
  if (comparedByProductName !== 0) {
    return comparedByProductName;
  }

  if (a.option == null) {
    return -1;
  }

  if (b.option == null) {
    return 1;
  }

  return a.option.localeCompare(b.option);
}
