import { Product } from '@sweep/contract';
import { uniq } from '@sweep/utils';
import { OMSStore } from 'src/stores/OMSStore';
import { CombinedShippingTableProduct } from '../interface';

export function toCombinedShippingTableProductsFromProduct(
  oms: OMSStore,
  product: Product
): CombinedShippingTableProduct[] {
  if (product.useSupplierByOption === true) {
    return (
      product.units?.map((unit) => ({
        productId: product._id,
        productName: product.productName,
        option: unit.unit,
        supplierId: unit.supplierId,
        supplierName: getSupplierName(oms, unit.supplierId),
      })) ?? []
    );
  }

  if (product.useSupplierBySalesChannel === true) {
    const supplierIdsBySalesChannel =
      product.salesChannelSupplierIds?.map(
        (salesChannelSupplierId) => salesChannelSupplierId.supplierId ?? null
      ) ?? [];
    const supplierIds = uniq([
      ...supplierIdsBySalesChannel,
      product.supplierId ?? null,
    ]);

    const options = product.units?.map((unit) => unit.unit) ?? [];
    if (options.length > 0) {
      return options
        .map((option) =>
          supplierIds.map((supplierId) => ({
            productId: product._id,
            productName: product.productName,
            option,
            supplierId,
            supplierName: getSupplierName(oms, supplierId),
          }))
        )
        .flat();
    }

    return supplierIds.map((supplierId) => ({
      productId: product._id,
      productName: product.productName,
      supplierId,
      supplierName: getSupplierName(oms, supplierId),
    }));
  }

  const options = product.units?.map((unit) => unit.unit) ?? [];
  if (options.length > 0) {
    return options.map((option) => ({
      productId: product._id,
      productName: product.productName,
      option,
      supplierId: product.supplierId,
      supplierName: getSupplierName(oms, product.supplierId),
    }));
  }

  return [
    {
      productId: product._id,
      productName: product.productName,
      supplierId: product.supplierId,
      supplierName: getSupplierName(oms, product.supplierId),
    },
  ];
}

function getSupplierName(oms: OMSStore, supplierId: string | null | undefined) {
  return supplierId != null ? oms.supplier.getSupplier(supplierId)?.name : null;
}
