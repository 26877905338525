import { useCallback } from 'react';
import { useDebounceState } from '@sweep/utils/react';
import { isNotEmptyString } from 'src/utils/string';

interface UseSearchStateOptions {
  defaultValue?: string;
  keys: string[];
}

/**
 * @returns [search, setSearch, filterSearch]
 */
export function useSearchState(options: UseSearchStateOptions) {
  const { defaultValue = '', keys } = options;
  const [search, setSearch, debouncedSearch] = useDebounceState(
    defaultValue,
    80
  );

  const handleSearchChange = (value: string) => {
    setSearch(value);
  };

  const filterSearch = useCallback(
    <T extends Record<string, unknown>, U = T | T[]>(items: U[]): U[] => {
      const searchItems = debouncedSearch
        .split(',')
        .map((item) => item.trim())
        .filter((item) => item !== '');
      if (searchItems.length === 0) {
        return items;
      }

      return items.filter((item) =>
        searchItems.some((search) => {
          const itemArray = (Array.isArray(item) ? item : [item]) as T[];
          return itemArray.some((item) =>
            isContainIgnoreCase(search, keys, item)
          );
        })
      );
    },
    [debouncedSearch, keys]
  );

  return [search, handleSearchChange, filterSearch] as const;
}

function isContainIgnoreCase<T extends Record<string, unknown>>(
  search: string,
  keys: string[],
  order: T
) {
  return keys.some((key) => {
    const value = getStringValue(order[key]);

    return (
      isNotEmptyString(value) &&
      value.toLowerCase().includes(search.toLowerCase())
    );
  });
}

function getStringValue(value: unknown): string | null {
  if (typeof value === 'string') {
    return value;
  }

  if (typeof value === 'number') {
    return value.toString();
  }

  return null;
}
