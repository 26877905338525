import { isNil, keyBy } from 'es-toolkit';
import { isEmpty } from 'lodash';
import { toast } from 'sonner';
import {
  ORDER_SHIPPING_STATUS,
  UpdateShippingOrderPayload,
} from '@sweep/contract';
import { SHIPPING_ORDER_PROCESS_STATUS_CODE } from '@sweep/domain/services/order-shipping-process';
import { formatDate } from '@sweep/utils';
import { bulkUpdateShippingOrder } from 'src/network/order-shipping-process/bulkUpdateShippingOrder';
import { createOrderExcel } from 'src/services/file/excel/create';
import { readExcelV3 } from 'src/services/file/excel/readExcel-v3';
import { createLogger } from 'src/third-parties/createLogger';
import { processFileHeaderMapping } from './processFileHeaderMapping';
import { initializeProcessingItem } from './processors/initializeProcessingItem';
import { passThrough } from './processors/passThrough';
import { processDefaultShippingCompany } from './processors/processDefaultShippingCompany';
import { processDuplicateKeyError } from './processors/processDuplicateKeyError';
import { ProcessingContext, ProcessingItem } from './processors/processor.type';
import { processShippingInfoUpdate } from './processors/processShippingInfoUpdate';
import { processShippingInfoValidation } from './processors/processShippingInfoValidation';
import {
  ProcessShippingOrderFileContext,
  ProcessShippingOrderFileParams,
} from './processShippingOrderFile.type';
import { getFailedShippingOrderWithReason } from './shared/getFailedShippingOrderWithReason';
import reportFallbackUsage from './shared/reportFallbackUsage';

const debug = createLogger('services:process-shipping-order-files');

export async function processShippingOrderFileForRetailer(
  params: ProcessShippingOrderFileParams,
  context: ProcessShippingOrderFileContext
): Promise<void> {
  const { file } = params;
  const { oms } = context;
  const filename = file.name;

  debug('step 01 - API 호출 - 엑셀 파일 읽기');
  const rawExcelData = await readExcelV3(file, {
    tagUploadSource: 'shipping-order-retailer',
  });

  if (rawExcelData == null) {
    const errorMessage = `엑셀 파일 읽기 실패: ${filename}`;
    toast.error(errorMessage);
    return;
  }

  debug('step 02-a - 서비스 로직 - 엑셀 파일 헤더 매칭');
  debug('step 02-b - 사용자 인터렉션 - 엑셀 파일 헤더 추가 매칭');
  debug('step 03 - 서비스 로직 - 데이터 변환 (rows > shippingOrders)');
  const { isSuccess, shippingOrders, defaultShippingCompany } =
    await processFileHeaderMapping(
      { rawSpreadsheetData: rawExcelData, filename },
      context
    );

  debug(isSuccess);
  debug(shippingOrders);
  debug(defaultShippingCompany);

  if (!isSuccess) {
    const errorMessage = '운송장 파일 업로드에 실패했습니다.';
    toast.error(errorMessage);
    return;
  }

  debug('step 04 - 서비스 로직 - 주문 데이터 변환 후처리');

  const existingOrders = oms.order.normalizedOrders;
  const existingOrdersByUniqueCode = keyBy(
    existingOrders,
    (order) => order.uniqueCodeAfterCustomization
  );

  const processingContext: ProcessingContext = {
    shippingOrderMap: existingOrdersByUniqueCode,
    alreadyProcessedKeys: new Set(),
    defaultShippingCompany: defaultShippingCompany ?? undefined,
  };

  const shouldApplyDefaultShippingCompany = defaultShippingCompany != null;

  const processingSteps: Array<
    (item: ProcessingItem, context: ProcessingContext) => ProcessingItem
  > = [
    shouldApplyDefaultShippingCompany
      ? processDefaultShippingCompany
      : passThrough,
    processShippingInfoValidation,
    processShippingInfoUpdate,
    processDuplicateKeyError,
  ];
  const appliedProcessorNames = processingSteps.map((fn) => fn.name);
  debug(`사용한 프로세서 목록: ${appliedProcessorNames.join(', ')}`);

  const initialProcessingItems = shippingOrders.flatMap((order, index) =>
    initializeProcessingItem(order, index)
  );
  debug(`before: ${initialProcessingItems.length}`);

  const processedItems = initialProcessingItems.map((item) =>
    processingSteps.reduce((acc, fn) => fn(acc, processingContext), item)
  );

  debug(`after: ${processedItems.filter((item) => item.isProcessable).length}`);

  debug('step 05 - API 호출 - 엑셀 파일 파싱 결과 업데이트');

  const shippingOrderUpdatePayloads = processedItems
    .filter((item) => item.isProcessable)
    .map(
      (item): UpdateShippingOrderPayload => ({
        uniqueCode: item.value.uniqueCode,
        uniqueCodeAfterCustomization: item.key,
        shippingNumber: item.value.shippingNumber,
        shippingCompany: item.value.shippingCompany,
        shippingStatus: item.value.shippingStatus,
      })
    );

  debug(shippingOrderUpdatePayloads);

  const updateResults = await bulkUpdateShippingOrder({
    orders: shippingOrderUpdatePayloads,
  });
  const updateFailedShippingOrderKeys = new Set(
    updateResults
      .filter((result) => !result.isSuccess)
      .map((result) => result.data.uniqueCodeAfterCustomization)
  );

  debug(updateResults);

  // NOTE(@형준): API 요청 실패한 item 의 status 업데이트
  const updatedProcessedItems = processedItems.map((item) => {
    const isUpdateNotRequested = item.isProcessable === false;
    if (isUpdateNotRequested) {
      return item;
    }

    const shippingOrderKey = item.key;
    const isUpdateRequestSuccess =
      !updateFailedShippingOrderKeys.has(shippingOrderKey);
    if (isUpdateRequestSuccess) {
      return item;
    }

    const nextStatus =
      SHIPPING_ORDER_PROCESS_STATUS_CODE.API_BULK_UPDATE_SHIPPING_INFO_FAILED;
    const nextHistories = [...item.histories, nextStatus];
    const nextShippingOrder = {
      ...item.value,
      shippingStatus: ORDER_SHIPPING_STATUS.processing,
    };

    return {
      ...item,
      isProcessable: false,
      status: nextStatus,
      histories: nextHistories,
      value: nextShippingOrder,
    };
  });

  debug(updatedProcessedItems);

  const updatedShippingOrders = existingOrders.map((order) => {
    const key = order.uniqueCodeAfterCustomization;
    const isUpdateFailed = updateFailedShippingOrderKeys.has(key);

    if (isUpdateFailed) {
      return { ...order };
    }

    const updateItem = updatedProcessedItems.find(
      (item) => item.isProcessable === true && item.key === key
    );

    if (isNil(updateItem)) {
      return { ...order };
    }

    const { shippingNumber, shippingCompany, shippingStatus } =
      updateItem.value;

    return {
      ...order,
      shippingNumber,
      shippingCompany,
      shippingStatus,
    };
  });

  debug(updatedShippingOrders);
  oms.order.setNormalizedOrders(updatedShippingOrders);

  debug('step 06 - 결과 노출 (토스트, 실패 엑셀, 로그 저장)');

  reportFallbackUsage(updatedProcessedItems);

  const failedItems = updatedProcessedItems.filter(
    (item) => item.isProcessable === false
  );

  debug('failedItems', failedItems);
  const hasFailedErrors = !isEmpty(failedItems);

  if (hasFailedErrors) {
    const updateFailedShippingOrdersWithReason =
      getFailedShippingOrderWithReason(failedItems);

    const failedItemCount = failedItems.length;
    const errorMessage = `운송장 등록에 실패한 주문 건이 있습니다. (${failedItemCount}건) 실패 사유는 엑셀 파일을 확인해주세요.`;
    toast.error(errorMessage);

    await createOrderExcel(
      oms,
      updateFailedShippingOrdersWithReason,
      formatDate(new Date(), 'yy.MM.dd 운송장 입력 실패 건'),
      ['실패 사유', ...oms.user.excelHeaders],
      { failReason: '실패 사유', ...oms.user.excelColumnMapping }
    );
  } else {
    toast.success('운송장이 입력되었습니다.');
  }
}
