import { useNavigate } from 'react-router-dom';
import { IconHeaderLogotype } from '@sweep/asset/icons';
import { Button } from '@sweep/sds';

interface HeaderProps {
  onOpen: () => void;
}

function Header({ onOpen }: HeaderProps) {
  const navigate = useNavigate();

  return (
    <div
      className="fixed top-0 z-50 flex h-[80px] w-full justify-center bg-white bg-opacity-[40%] shadow-[0_0_4px_0_rgba(0,0,0,0.1)] backdrop-blur-[80px]"
      style={{
        borderBottom: '1px solid',
        borderImageSource:
          'radial-gradient(100% 100% at 0% 50%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%)',
        borderImageSlice: 1,
      }}
    >
      <div className="px-8px flex w-[1200px] items-center justify-between">
        <IconHeaderLogotype className="h-32px text-blue-500" />
        <div className="gap-10px flex">
          <Button
            variant="line"
            className="hover:bg-blue-50"
            onClick={() => navigate('/sign-in')}
          >
            로그인
          </Button>
          <Button
            variant="solid"
            className="hover:bg-blue-600"
            onClick={onOpen}
          >
            사전신청하기
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Header;
