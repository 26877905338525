export function uniqBy<T>(array: T[], key: (item: T) => string | number): T[] {
  const uniqueItems: T[] = [];
  const seen: Set<string | number> = new Set();

  array.forEach((item) => {
    const keyValue = key(item);
    if (!seen.has(keyValue)) {
      seen.add(keyValue);
      uniqueItems.push(item);
    }
  });

  return uniqueItems;
}
