import { Order } from '@sweep/contract';
import { isNotNil } from '@sweep/utils';
import { AbstractPlugin } from '../../interface';
import { combineOrders } from './services/combineOrders';
import { divideByCombinable } from './services/divideByCombinable';
import { groupByCombinedShipping } from './services/groupByCombinedShipping';
import { groupByKey } from './services/groupByKey';
import { mergeOrders } from './services/mergeOrders';
import { mergeRecordsByKey } from './services/mergeRecordsByKey';
import { separate } from './services/separate';

export class CombinedShippingPlugin extends AbstractPlugin<undefined> {
  get combinedShippings() {
    return this.oms.combinedShipping.combinedShippings;
  }

  get separateAll() {
    return this.oms.user.setting?.combinedShipping?.separateAll ?? false;
  }

  get combineAll() {
    return this.oms.user.setting?.combinedShipping?.combineAll ?? false;
  }

  transform = (orders: Order[]): Promise<Order[]> => {
    return Promise.resolve(this._transform(orders));
  };

  _transform = (orders: Order[]): Order[] => {
    const disableCombinedShipping =
      this.combinedShippings.length === 0 &&
      this.separateAll === false &&
      this.combineAll === false;

    if (disableCombinedShipping) {
      return orders;
    }

    const { combinableOrders, unCombinableOrders } =
      this.divideByCombinable(orders);

    const separatedOrders = separate(combinableOrders);
    if (this.separateAll) {
      return [...separatedOrders, ...unCombinableOrders];
    }

    const groupedOrdersByKey = groupByKey(separatedOrders);
    if (this.combineAll) {
      const combinedOrders = Object.values(groupedOrdersByKey)
        .map(mergeOrders)
        .filter(isNotNil);

      return [...combinedOrders, ...unCombinableOrders];
    }

    const groupedOrderRecordsByCombinedShipping = Object.values(
      groupedOrdersByKey
    ).map((orders) => groupByCombinedShipping(orders, this.combinedShippings));
    const groupedOrdersListByCombinedShipping = mergeRecordsByKey(
      groupedOrderRecordsByCombinedShipping
    );

    const combinedOrders = this.combine(
      groupedOrdersListByCombinedShipping
    ).flat();

    // uniquecode 2개 이상이면 플래그 추가
    combinedOrders.forEach((order) => {
      if (order.uniqueCode.split(',').length > 1) {
        order.isCombinedOrder = true;
      }
    });

    return [...combinedOrders, ...unCombinableOrders];
  };

  divideByCombinable = (
    orders: Order[]
  ): { combinableOrders: Order[]; unCombinableOrders: Order[] } => {
    if (this.separateAll || this.combineAll) {
      return { combinableOrders: orders, unCombinableOrders: [] };
    }

    return divideByCombinable(orders, this.combinedShippings);
  };

  combine = (groupedOrdersList: Record<string, Order[][]>): Order[][] => {
    return Object.entries(groupedOrdersList).map(
      ([combinedShippingId, ordersList]) => {
        if (this.combineAll) {
          return ordersList.flatMap(mergeOrders).filter(isNotNil);
        }

        const combinedShipping = this.combinedShippings.find(
          (c) => c._id === combinedShippingId
        );
        return combinedShipping == null
          ? ordersList.flat()
          : ordersList.flatMap((orders) =>
              combineOrders(this.oms, orders, combinedShipping)
            );
      }
    );
  };
}
