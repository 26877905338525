import { CombinedShipping, Order } from '@sweep/contract';
import { isOrderInCombinedShipping } from './isOrderInCombinedShipping';

export function divideByCombinable(
  orders: Order[],
  combinedShippings: CombinedShipping[]
): { combinableOrders: Order[]; unCombinableOrders: Order[] } {
  const combinableOrders: Order[] = [];
  const unCombinableOrders: Order[] = [];

  orders.forEach((order) => {
    const isCombinable =
      combinedShippings.some((combinedShipping) =>
        isOrderInCombinedShipping(order, combinedShipping)
      ) ?? false;

    isCombinable
      ? combinableOrders.push(order)
      : unCombinableOrders.push(order);
  });

  return {
    combinableOrders,
    unCombinableOrders,
  };
}
