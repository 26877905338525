import { z } from 'zod';

const userDeploySchema = z.object({
  combinedShipping: z.boolean().optional(),
  formatProductName: z.boolean().optional(),
  aliexpressDispatch: z.boolean().optional(),
});

export type UserDeploy = z.infer<typeof userDeploySchema>;

const userCompanyTypeSchema = z.enum(['retailer', 'supplier']);

export type UserCompanyType = z.infer<typeof userCompanyTypeSchema>;

export const userSchema = z.object({
  _id: z.string(),
  email: z.string(),
  password: z.string(),
  name: z.string(),
  companyType: userCompanyTypeSchema,

  dispatchPlugins: z.array(z.string()).optional(),
  mergeDispatchPlugins: z.array(z.string()).optional(),
  plugins: z.array(z.string()).optional(),

  trialEndsAt: z.number().nullish(),

  deploy: userDeploySchema.optional(),
  admin: z.boolean().optional(),
});

export type User = z.infer<typeof userSchema>;

export const userUpdateDTOSchema = userSchema.omit({ _id: true }).partial();

export type UpdateUserDTO = z.infer<typeof userUpdateDTOSchema>;
