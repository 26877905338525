import { deepEqual } from 'utils/index';
import { Order } from '@sweep/contract';
import { isNotNil, partition } from '@sweep/utils';
import { isNotEmptyString } from 'src/utils/string';
import { AbstractPlugin } from '../../interface';
import { openMatchedCMForm } from './components/MatchedCMForm/openMatchedCMForm';
import { openUnmatchedCMForm } from './components/UnmatchedCMForm/openUnmatchedCMForm';
import {
  CreateMatching,
  MatchedOrder,
  Matching,
  MatchingOrder,
} from './interface';
import { isEmptyOrder } from './services/isEmptyOrder';
import { matchByOptionCode } from './services/matchByOptionCode';
import { matchByProductNameOption } from './services/matchByProductNameOption';
import { separateOrdersByMatched } from './services/separateOrdersByMatched';
import { toCreateCompositionMatching } from './services/toCreateCompositionMatching';
import { toCreateOptionCodeMatching } from './services/toCreateOptionCodeMatching';
import { toMatching } from './services/toMatching';
import { toMatchingOrder } from './services/toMatchingOrder';
import { uniqueByOptionCode } from './services/uniqueByOptionCode';
import { uniqueByProductNameOption } from './services/uniqueByProductNameOption';

export type CompositionMatchingStage = 'normalize' | 'pre-divide';

export interface CompositionMatchingConfig {
  stage: CompositionMatchingStage;
}

export class CompositionMatchingPlugin extends AbstractPlugin<CompositionMatchingConfig> {
  get stage() {
    return this.config.stage;
  }

  get usingOptionCodeMatching() {
    return (
      this.oms.user.setting?.compositionMatching?.optionCodeMatching ?? false
    );
  }

  transform = async (orders: Order[]): Promise<Order[]> => {
    const { emptyOrders, nonEmptyOrders } =
      this.separateProductEmptyOrders(orders);
    const { matched: initialMatched, unmatched } = this.match(nonEmptyOrders);

    if (unmatched.length !== 0) {
      const matchingOrders = unmatched.map(toMatchingOrder);
      const uniqeMatchingOrders = this.uniqMatchingOrders(matchingOrders);

      const createMatchings = await openUnmatchedCMForm(
        this.oms.order.render,
        uniqeMatchingOrders,
        this.stage
      );

      if (createMatchings == null) {
        return [];
      }

      if (createMatchings.length > 0) {
        await this.createMatchings(createMatchings);
      }
    }

    const matched =
      unmatched.length === 0
        ? initialMatched
        : this.match(nonEmptyOrders).matched;

    const matchings = matched.map(toMatching).filter(isNotNil);
    const uniqueMatchings = this.uniqMatchings(matchings);

    if (uniqueMatchings.length > 0) {
      const confirmedMatchings = await openMatchedCMForm(
        this.oms.order.render,
        uniqueMatchings,
        this.stage
      );
      await this.updateMatchings(confirmedMatchings, uniqueMatchings);
    }

    const { matched: matchedFinal, unmatched: unmatchedFinal } =
      this.match(nonEmptyOrders);

    await this.incrementCounts(matchedFinal);

    if (this.oms.user.deploy?.formatProductName === true) {
      return [...emptyOrders, ...matchedFinal, ...unmatchedFinal];
    }

    return [
      ...emptyOrders.map((order) => ({ ...order, data: [] })),
      ...matchedFinal.map((order) => ({
        ...order,
        data: order.data!.map((product) => ({
          ...product,
          // TODO(@이지원): 합배송, formatOrders를 ts로 전환한 후 없애기
          unit: product.unit ?? '',
        })),
      })),
      ...unmatchedFinal.map<Order>((order) => {
        const productNameOption = [order.productName, order.option]
          .filter(isNotNil)
          .join(' ');

        return {
          ...order,
          data: [
            {
              productId: '',
              unit: '',
              productName: productNameOption,
              quantity: 1,
            },
          ],
        };
      }),
    ];
  };

  separateProductEmptyOrders = (orders: Order[]) => {
    const emptyOrders: Order[] = [];
    const nonEmptyOrders: Order[] = [];

    orders.forEach((order) => {
      isEmptyOrder(order)
        ? emptyOrders.push(order)
        : nonEmptyOrders.push(order);
    });

    return { emptyOrders, nonEmptyOrders };
  };

  match(orders: Order[]): { matched: MatchedOrder[]; unmatched: Order[] } {
    if (this.usingOptionCodeMatching) {
      const optionCodeMatchedOrders = matchByOptionCode(orders, this.oms);
      const { matched: optionCodeMatched, unmatched: optionCodeUnmatched } =
        separateOrdersByMatched(optionCodeMatchedOrders);

      const productNameOptionMatchedOrders = matchByProductNameOption(
        optionCodeUnmatched,
        this.oms
      );

      const {
        matched: productNameOptionMatched,
        unmatched: productNameOptionUnMatched,
      } = separateOrdersByMatched(productNameOptionMatchedOrders);

      return {
        matched: [...optionCodeMatched, ...productNameOptionMatched],
        unmatched: productNameOptionUnMatched,
      };
    }

    const productNameOptionMatchedOrders = matchByProductNameOption(
      orders,
      this.oms
    );
    return separateOrdersByMatched(productNameOptionMatchedOrders);
  }

  uniqMatchingOrders(orders: MatchingOrder[]): MatchingOrder[] {
    const uniqueOrdersByProductNameOption = uniqueByProductNameOption(
      orders,
      (order) => order
    );

    if (this.usingOptionCodeMatching) {
      return uniqueByOptionCode(
        uniqueOrdersByProductNameOption,
        (order) => order
      );
    }

    return uniqueOrdersByProductNameOption;
  }

  uniqMatchings(matchings: Matching[]) {
    const uniqueOrdersByProductNameOption = uniqueByProductNameOption(
      matchings,
      (order) => order.order
    );

    if (this.usingOptionCodeMatching) {
      return uniqueByOptionCode(
        uniqueOrdersByProductNameOption,
        (order) => order.order
      );
    }

    return uniqueOrdersByProductNameOption;
  }

  createMatchings = async (
    createMatchings: CreateMatching[]
  ): Promise<void> => {
    if (this.usingOptionCodeMatching) {
      const [optionCodeCreateMatchings, compositionCreateMatchings] = partition(
        createMatchings,
        (m) => isNotEmptyString(m.optionCode)
      );
      const createOptionCodeMatchings = optionCodeCreateMatchings.map(
        toCreateOptionCodeMatching
      );
      const createCompositionMatchings = compositionCreateMatchings.map(
        toCreateCompositionMatching
      );

      const promises = [
        createOptionCodeMatchings.length > 0
          ? this.oms.cm.createOptionCodeMatchings(createOptionCodeMatchings)
          : null,
        createCompositionMatchings.length > 0
          ? this.oms.cm.createCompositionMatchings(createCompositionMatchings)
          : null,
      ].filter(isNotNil);

      await Promise.all(promises);

      if (createCompositionMatchings.length > 0) {
        await this.oms.cm.createCompositionMatchings(
          createCompositionMatchings
        );
      }

      return;
    }

    const createCompositionMatchings = createMatchings.map(
      toCreateCompositionMatching
    );

    if (createCompositionMatchings.length > 0) {
      await this.oms.cm.createCompositionMatchings(createCompositionMatchings);
    }
  };

  updateMatchings = async (
    matchings: Matching[],
    prevMatchings: Matching[]
  ) => {
    await Promise.all(
      matchings.map(async (matching, index) => {
        if (deepEqual(matching, prevMatchings.at(index))) {
          return;
        }

        if (matching.compositionMatchingId != null) {
          await this.oms.cm.updateCompositionMatching({
            _id: matching.compositionMatchingId,
            data: matching.products,
          });
        }

        if (matching.optionCodeMatchingId != null) {
          await this.oms.cm.updateOptionCodeMatching({
            _id: matching.optionCodeMatchingId,
            products: matching.products,
          });
        }
      })
    );
  };

  incrementCounts = async (orders: MatchedOrder[]) => {
    const compositionMatchingIds = orders
      .map((order) => order.plugin?.cm?.compositionMatchingId)
      .filter(isNotNil);

    const optionCodeMatchingIds = orders
      .map((order) => order.plugin?.cm?.optionCodeMatchingId)
      .filter(isNotNil);

    const promises = [
      compositionMatchingIds.length > 0
        ? this.oms.cm.incrementCompositionMatchingCounts(compositionMatchingIds)
        : null,
      optionCodeMatchingIds.length > 0
        ? this.oms.cm.incrementOptionCodeMatchingCounts(optionCodeMatchingIds)
        : null,
    ].filter(isNotNil);

    await Promise.all(promises);
  };
}
