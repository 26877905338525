import { useId } from 'react';
import { IconInfo } from '@sweep/asset/icons';
import { Nullable } from '@sweep/utils';
import { Checkbox } from '../../Checkbox';
import { Tooltip } from '../../Tooltip';
import { BooleanSchema } from '../schema';

interface JsonBooleanInputProps {
  schema: BooleanSchema;
  value: Nullable<boolean>;
  onChange: (value: boolean) => void;
}

export function JsonBooleanInput({
  schema,
  value,
  onChange,
}: JsonBooleanInputProps) {
  const id = useId();
  return (
    <div className="flex gap-8px">
      <Checkbox
        id={id}
        checked={value ?? schema.defaultValue ?? false}
        onCheckedChange={onChange}
        className="mt-4px"
      />
      <div className="flex gap-4px">
        <label htmlFor={id} className="text-medium-m text-gray-700">
          <p className="text-medium-m text-gray-700">{schema.label}</p>
        </label>
        {schema.description != null && (
          <Tooltip placement="right">
            <Tooltip.Trigger>
              <IconInfo className="text-gray-400" />
            </Tooltip.Trigger>
            <Tooltip.Content>{schema.description}</Tooltip.Content>
          </Tooltip>
        )}
      </div>
    </div>
  );
}
