import { CombinedShipping } from '@sweep/contract';
import { isNotNil, uniq } from '@sweep/utils';
import { OMSStore } from 'src/stores/OMSStore';

export function filterCombinedShipping(
  oms: OMSStore,
  search: string,
  combinedShipping: CombinedShipping
) {
  const { products: combinedShippingProducts, supplierId } = combinedShipping;

  const options = combinedShippingProducts
    .map((product) => product.option)
    .filter(isNotNil);
  if (options.some((option) => option.includes(search))) {
    return true;
  }

  const productIds = uniq(
    combinedShippingProducts.map((product) => product.productId)
  );
  const productNames = productIds
    .map((productId) => oms.product.getProduct(productId)?.productName)
    .filter(isNotNil);
  if (productNames.some((productName) => productName.includes(search))) {
    return true;
  }

  if (supplierId == null) {
    return false;
  }

  const supplier = oms.supplier.getSupplier(supplierId);

  return supplier?.name.includes(search) ?? false;
}
