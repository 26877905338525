import { isValid } from '../../../utils/utils';
import { useOMSStore } from '../../useOMSStore';

function useCombineOrder() {
  const oms = useOMSStore();

  /** address가 같은것이 여러개거나 uniquecode가 두개이상인 것의 isRearragedOrder를 true로 */
  const hightlightCombinedOrders = (orders) => {
    const addressMap = new Map();
    for (const order of orders) {
      if (!addressMap.has(order.address)) {
        addressMap.set(order.address, []);
      }
      addressMap.get(order.address).push(order);
    }
    for (const [address, ordersList] of addressMap.entries()) {
      if (ordersList.length > 1) {
        ordersList.forEach((order) => {
          order.isMultipleAddress = true;
        });
      }
    }

    orders.forEach((order) => {
      if (order.uniqueCode.split(',').length > 1) {
        order.isCombinedOrder = true;
      }
    });

    orders.forEach((order) => {
      if (
        oms.user.setting?.excelVisualSettings
          ?.isIncludedSpecialCharacterInName &&
        /[^\uAC00-\uD7A3a-zA-Z\s]/.test(order.name)
      ) {
        // 특수문자를 포함할 경우
        order.isIncludedSpecialCharacterInName = true;

        // 특수문자 제거
        order.name = order.name.replace(/[^\uAC00-\uD7A3a-zA-Z\s]/g, '');

        if (order.name && order.name.length < 2) {
          order.name = '고객님'; // '고객님'을 사용
        }
      }
    });

    orders.forEach((order) => {
      if (order.postCode && order.postCode.toString().length > 5) {
        order.isPostCodeOutdated = true;
      }
    });

    if (isValid(oms.user.setting?.excelVisualSettings?.isQuantityMoreThanOne)) {
      orders.forEach((order) => {
        if (order.quantity > 1) {
          order.isQuantityMoreThanOne = true;
        }
      });
    }

    return orders;
  };

  return { hightlightCombinedOrders };
}

export default useCombineOrder;
