import { Button } from '../../Button';
import { JsonInput } from '../JsonInput';
import { ArraySchema } from '../schema';

interface JsonArrayInputProps {
  schema: ArraySchema;
  value: unknown[];
  onChange: (value: unknown[]) => void;
}

export function JsonArrayInput({
  schema,
  value,
  onChange,
}: JsonArrayInputProps) {
  const handleAdd = () => {
    onChange([...value, schema.items.defaultValue]);
  };

  const handleDelete = (index: number) => () => {
    const newArray = [...value];
    newArray.splice(index, 1);
    onChange(newArray);
  };

  const handleChange = (index: number) => (newValue: unknown) => {
    const newArray = [...value];
    newArray[index] = newValue;
    onChange(newArray);
  };

  return (
    <div className="gap-8px flex flex-col">
      <div className="flex gap-8px items-center">
        <p className="text-medium-m text-gray-700">{schema.label}</p>
        <p className="text-medium-s text-gray-500">{schema.description}</p>
      </div>
      <div className="flex flex-col gap-4px">
        {value.map((item, index) => (
          <div key={index} className="flex gap-4px">
            <JsonInput
              schema={{
                ...schema.items,
                description: schema.items.description ?? `item ${index}`,
              }}
              value={item}
              onChange={handleChange(index)}
            />
            <Button
              size="xsmall"
              onClick={handleDelete(index)}
              className="w-fit"
            >
              삭제
            </Button>
          </div>
        ))}
        <Button size="xsmall" onClick={handleAdd} className="w-fit">
          추가
        </Button>
      </div>
    </div>
  );
}
