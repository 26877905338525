import { isNil } from 'es-toolkit';
import {
  hasKeysInArray,
  hasKeysInObject,
  isEitherSubsequenceOf,
} from '@sweep/utils';
import { UserSettingLikeModel } from '../../../models/userSettingLike.model';
import { mapKeysByColumnMapping } from '../../common/mapKeysByColumnMapping';
import { createHeaderMappingResult } from '../createHeaderMappingResult';
import {
  HeaderMappingResult,
  HeaderMatchContext,
  HeaderMatchReasonType,
} from '../types';

export const findTemplateHeaderMatch = (
  settings: UserSettingLikeModel | null | undefined,
  context: HeaderMatchContext
): HeaderMappingResult | null => {
  const { headerRow, requiredHeaderKeys } = context;
  const templateHeaders = settings?.shippingExcelTemplate?.headers ?? [];
  const templateMappingColumn =
    settings?.shippingExcelTemplate?.headerMatchingMap;

  if (isNil(templateMappingColumn)) {
    return null;
  }

  if (!hasKeysInObject(templateMappingColumn, requiredHeaderKeys)) {
    return null;
  }

  const isHeaderMatch = isEitherSubsequenceOf(templateHeaders, headerRow);
  if (!isHeaderMatch) {
    return null;
  }

  const headerKeys = mapKeysByColumnMapping(headerRow, templateMappingColumn);
  if (!hasKeysInArray(headerKeys, requiredHeaderKeys)) {
    return null;
  }

  return createHeaderMappingResult({
    headerKeys,
    mappingColumn: templateMappingColumn,
    defaultShippingCompany:
      settings?.defaultShippingCompany ?? context.defaultShippingCompany,
    matchReason: HeaderMatchReasonType.TEMPLATE_MATCH,
  });
};
