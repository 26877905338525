import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Button, Modal, SearchInput } from '@sweep/sds';
import { cva } from '@sweep/tailwind';
import { Separated } from '@sweep/utils/react';
import { useOMSStore } from 'src/hooks/useOMSStore';

interface ProductUpdateSupplierFormModalProps {
  open: boolean;
  onSubmit: (supplierId: string | null) => void;
}

function ProductUpdateSupplierFormModal({
  open,
  onSubmit: givenOnSubmit,
}: ProductUpdateSupplierFormModalProps) {
  const oms = useOMSStore();
  const suppliers = oms.supplier.suppliers;

  const onClose = () => givenOnSubmit(null);

  const [supplierId, setSupplierId] = useState<string | null>(null);
  const [search, setSearch] = useState('');

  const filteredSuppliers = suppliers.filter((supplier) =>
    supplier.name.includes(search)
  );

  const onSubmit = () => givenOnSubmit(supplierId);

  return (
    <Modal open={open} onClose={onClose} className="gap-20px flex flex-col">
      <Modal.Title>공급사 일괄 수정</Modal.Title>
      <SearchInput
        value={search}
        onChange={setSearch}
        placeholder="공급사를 입력하세요."
      />
      <div className={optionContainer()}>
        <Separated with={<hr />}>
          {search === '' && (
            <div
              onClick={() => setSupplierId('')}
              className={option({
                type: 'empty',
                selected: supplierId === '',
              })}
            >
              없음
            </div>
          )}
          {filteredSuppliers.map((supplier) => (
            <div
              key={supplier._id}
              onClick={() => setSupplierId(supplier._id)}
              className={option({
                type: 'normal',
                selected: supplierId === supplier._id,
              })}
            >
              {supplier.name}
            </div>
          ))}
          {filteredSuppliers.length === 0 && (
            <div className={option({ type: 'empty', selected: false })}>
              검색 결과가 없습니다.
            </div>
          )}
        </Separated>
      </div>
      <div className="gap-12px flex">
        <Modal.Close color="lightGray" className="flex-1">
          취소하기
        </Modal.Close>
        <Button
          className="flex-1"
          onClick={onSubmit}
          disabled={supplierId == null}
        >
          수정하기
        </Button>
      </div>
    </Modal>
  );
}

const optionContainer = cva(
  'max-h-308px rounded-8px flex w-full flex-col overflow-y-scroll border border-gray-300'
);
const option = cva(
  [
    'h-40px px-16px flex shrink-0 cursor-pointer items-center hover:bg-gray-100',
    'text-medium-s',
  ],
  {
    variants: {
      type: {
        empty: '',
        normal: '',
      },
      selected: {
        true: 'bg-gray-100 text-blue-500',
        false: '',
      },
    },
    compoundVariants: [
      {
        type: 'empty',
        selected: false,
        className: 'text-gray-400',
      },
      {
        type: 'normal',
        selected: false,
        className: 'text-gray-700',
      },
    ],
  }
);

export default observer(ProductUpdateSupplierFormModal);
