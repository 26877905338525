import { ForwardedRef, forwardRef } from 'react';
import { cva } from '@sweep/tailwind';
import { useUncontrollableState } from '../../hooks/useUncontrollableState';

interface NumberInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  value?: number;
  onChange?: (value: number) => void;
  placeholder?: string;
}

function _NumberInput(
  {
    value: givenValue,
    onChange: givenOnChange,
    className,
    ...rest
  }: NumberInputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const [value, setValue] = useUncontrollableState({
    value: givenValue,
    onChange: givenOnChange,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (isNaN(value)) {
      return;
    }
    setValue(value);
  };

  return (
    <div className={container({ className })}>
      <input
        ref={ref}
        type="number"
        value={value?.toString() ?? ''}
        onChange={handleChange}
        className={input()}
        {...rest}
      />
    </div>
  );
}

const container = cva(
  'h-42px flex items-center border rounded-8px border-gray-300 bg-white'
);

const input = cva([
  'px-15px min-w-0 outline-none text-medium-s placeholder:text-gray-400 text-gray-700',
  '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none',
]);

export const NumberInput = forwardRef(_NumberInput);
