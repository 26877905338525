import { ForwardedRef, forwardRef } from 'react';
import { cva } from '@sweep/tailwind';

interface BadgeProps {
  color?: 'gray' | 'blue' | 'orange' | 'green' | 'lightGray';
  variant?: 'solid' | 'soft';
  size?: 'small' | 'medium';
  className?: string;
  children?: React.ReactNode;
}

function _Badge(
  {
    color = 'blue',
    variant = 'solid',
    size = 'medium',
    className,
    children,
  }: BadgeProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  return (
    <div ref={ref} className={badge({ color, variant, size, className })}>
      {children}
    </div>
  );
}

const badge = cva('text-medium-s size-fit', {
  variants: {
    color: {
      gray: '',
      blue: '',
      orange: '',
      green: '',
      lightGray: '',
    },
    variant: {
      solid: '',
      soft: '',
    },
    size: {
      small: 'px-8px py-4px rounded-4px',
      medium: 'px-12px py-4px rounded-8px',
    },
  },
  compoundVariants: [
    {
      color: 'gray',
      variant: 'soft',
      className: 'bg-gray-100 text-gray-700',
    },
    {
      color: 'blue',
      variant: 'soft',
      className: 'bg-gray-100 text-blue-700',
    },
    {
      color: 'orange',
      variant: 'soft',
      className: 'bg-gray-100 text-orange-500',
    },
    {
      color: 'green',
      variant: 'soft',
      className: 'bg-gray-100 text-green-500',
    },
    {
      color: 'lightGray',
      variant: 'soft',
      className: 'bg-gray-100 text-gray-400',
    },
  ],
});

export const Badge = forwardRef(_Badge);
