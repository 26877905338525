import { useHover } from 'react-aria';
import { cva } from '@sweep/tailwind';
import { Checkbox, CheckboxProps } from './Checkbox';

interface ExpandedCheckboxProps extends CheckboxProps {}

export function ExpandedCheckbox({
  checked,
  onCheckedChange,
  disabled = false,
  className,
  ...rest
}: ExpandedCheckboxProps) {
  const handleClick = (event: React.MouseEvent) => {
    if (disabled) {
      return;
    }

    event.stopPropagation();
    onCheckedChange?.(!checked);
  };

  const { isHovered, hoverProps } = useHover({});

  return (
    <div
      className={container({ disabled, className })}
      onClick={handleClick}
      {...hoverProps}
    >
      <Checkbox
        checked={checked}
        onCheckedChange={onCheckedChange}
        isHovered={isHovered}
        disabled={disabled}
        {...rest}
      />
    </div>
  );
}

const container = cva('size-36px flex shrink-0 items-center justify-center', {
  variants: {
    disabled: {
      true: 'cursor-not-allowed',
      false: 'cursor-pointer',
    },
  },
});
