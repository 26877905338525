import { IconMatching } from '@sweep/asset/icons';

interface MatchedCMTitleProps {}

export function MatchedCMTitle({}: MatchedCMTitleProps) {
  return (
    <div className="gap-8px flex flex-col">
      <div className="gap-8px flex items-center">
        <IconMatching />
        <p className="text-bold-l texr-gray-700">이번에 사용된 매칭 내역</p>
      </div>
      <p className="text-medium-s text-gray-500">
        매칭이 3회 이하로 사용된 경우 화면에 나타납니다. 전체 매칭 내역을
        보시려면 더보기를 열어주세요.
      </p>
    </div>
  );
}
