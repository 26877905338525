import { WithUniqueCode } from '../../models/order.model';
import {
  PREFIX_FOR_UNIQUE,
  SUFFIX_FOR_UNIQUE_CODE_SUB,
  UNIQUE_CODE_MERGER_UNDERSCORE,
  UNIQUE_CODE_SEPARATOR_COMMA,
} from '../common/order.constants';

export function createUniqueCode() {
  const date = new Date();
  const formattedDate = `${date.getTime()}`;
  const randomNum = Math.floor(Math.random() * 100000000)
    .toString()
    .padStart(8, '0');

  const uniqueCode = `${formattedDate}${randomNum}`;
  return uniqueCode;
}

/**
 * @deprecated
 * ALIAS: uniqueCodeAfterCustomization
 */
function createUniqueCodeSub() {
  const date = new Date();
  const formattedDate = `${date.getTime()}`;
  const randomNum = Math.floor(Math.random() * 100000000)
    .toString()
    .padStart(8, '0');

  const uniqueCode = `${PREFIX_FOR_UNIQUE}-${formattedDate}${randomNum}-${SUFFIX_FOR_UNIQUE_CODE_SUB}`;
  return uniqueCode;
}

/**
 * ALIAS: uniqueCodeAfterCustomization
 */
export function createUniqueCodeSubV2<T extends WithUniqueCode>(order: T) {
  const newCode = createUniqueCodeSub();

  const prevUniqueCode = order?.uniqueCode ?? '';
  const prevUniqueCodes =
    prevUniqueCode?.split(UNIQUE_CODE_SEPARATOR_COMMA).filter(Boolean) ?? [];
  const prevUniqueCodeExists = prevUniqueCodes?.length > 0;

  if (prevUniqueCodeExists) {
    return [...prevUniqueCodes, newCode].join(UNIQUE_CODE_MERGER_UNDERSCORE);
  }

  return newCode;
}

export function isValidUniqueCodeSub(code?: string | null) {
  if (code == null || code === '') {
    return false;
  }

  // check if it ends with SUFFIX_FOR_UNIQUE_CODE_SUB
  const codeIsValid = code?.endsWith(SUFFIX_FOR_UNIQUE_CODE_SUB);

  return codeIsValid;
}
