import { cva } from '@sweep/tailwind';
import { IconSortDown, IconSortUp } from '@sweep/asset/icons';
import { SortType } from '../TableFilterStore';

interface SortIconProps {
  type: SortType;
}

function SortIcon({ type }: SortIconProps) {
  return (
    <div className="w-14px h-15px relative">
      {type && (
        <>
          <IconSortUp
            className={icon({ position: 'top', active: type === 'ASC' })}
          />
          <IconSortDown
            className={icon({ position: 'bottom', active: type === 'DESC' })}
          />
        </>
      )}
    </div>
  );
}

const icon = cva('', {
  variants: {
    active: {
      true: 'text-blue-500',
      false: 'text-gray-300',
    },
    position: {
      top: 'top-0',
      bottom: 'bottom-0',
    },
  },
});

export default SortIcon;
