export function mergeRecordsByKey<T>(
  records: Array<Record<string, T>>
): Record<string, T[]> {
  return records.reduce<Record<string, T[]>>((acc, record) => {
    Object.entries(record).forEach(([key, value]) => {
      const prevValues = acc[key] ?? [];
      acc[key] = [...prevValues, value];
    });
    return acc;
  }, {});
}
