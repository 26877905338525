import { cva } from '@sweep/tailwind';

interface DividerProps extends React.HTMLAttributes<HTMLDivElement> {
  type: 'horizontal' | 'vertical' | 'horizontal-full' | 'vertical-full';
}

export function Divider({ type, className, ...rest }: DividerProps) {
  return <div className={divider({ type, className })} {...rest} />;
}

export const divider = cva('shrink-0 bg-gray-200', {
  variants: {
    type: {
      vertical: 'w-1px',
      horizontal: 'h-1px',
      'horizontal-full': 'h-1px w-full',
      'vertical-full': 'w-1px h-full',
    },
  },
});
