import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { MatchingProduct } from '@sweep/contract';
import { Button } from '@sweep/sds';
import { cva } from '@sweep/tailwind';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { toast } from 'src/third-parties/toast';
import { MatchingState } from '../../interface';
import { isNotEmptyMatchingProduct } from '../../services/isNotEmptyMatchingProduct';
import { toMatchingProducts } from '../../services/toMatchingProducts';
import MatchingOrderLabel from './MatchingOrderLabel';
import MatchingProductsInput from './MatchingProductsInput';

interface MatchingStateInputProps {
  value: MatchingState;
  onChange: (value: MatchingState) => void;
}

function MatchingStateInput({ value, onChange }: MatchingStateInputProps) {
  const oms = useOMSStore();
  const [required, setRequired] = useState(false);
  const { order, products, status } = value;

  const handleProductsChange = (
    partialProducts: Partial<MatchingProduct>[]
  ) => {
    const products = toMatchingProducts(partialProducts, oms);
    onChange({
      ...value,
      products: partialProducts,
      status:
        products.length !== partialProducts.length ? 'pending' : value.status,
    });
  };

  const toggleStatus = () => {
    if (value.status === 'confirm') {
      onChange({ ...value, status: 'pending' });
      return;
    }

    const nonEmptyProducts = value.products.filter(isNotEmptyMatchingProduct);

    if (nonEmptyProducts.length === 0) {
      onChange({ ...value, products: [{ quantity: 1 }] });
      setRequired(true);
      toast.error('상품을 선택해주세요.');
      return;
    }

    const products = toMatchingProducts(nonEmptyProducts, oms);
    if (products.length !== nonEmptyProducts.length) {
      onChange({ ...value, products: nonEmptyProducts });
      setRequired(true);
      toast.error('옵션을 선택해주세요.');
      return;
    }

    onChange({
      ...value,
      status: 'confirm',
      products,
    });
  };

  return (
    <>
      <div className={container({ status })}>
        <div className="gap-42px flex flex-1">
          <MatchingOrderLabel
            matchingOrder={order}
            filled={status === 'confirm'}
          />
          <MatchingProductsInput
            value={products}
            onChange={handleProductsChange}
            required={required}
            filled={status === 'confirm'}
          />
        </div>
        <div className="flex gap-[8px]">
          <Button
            size="xlarge"
            color={status === 'confirm' ? 'blue' : 'lightGray'}
            variant="line"
            onClick={toggleStatus}
          >
            확정
          </Button>
        </div>
      </div>
      <hr className="h-px border-0 bg-gray-200" />
    </>
  );
}

const container = cva('gap-40px py-20px pr-20px flex justify-between', {
  variants: {
    status: {
      confirm: 'bg-gray-100',
      pending: '',
    },
  },
});

export default observer(MatchingStateInput);
