import { CombinedShippingProduct } from '@sweep/contract';
import { OMSStore } from 'src/stores/OMSStore';
import { ProductNameOptionAmount } from '../interface';

export function toProductNameOptionAmount(
  oms: OMSStore,
  combinedShippingProduct: CombinedShippingProduct
): ProductNameOptionAmount | null {
  const product = oms.product.getProduct(combinedShippingProduct.productId);
  if (product == null) {
    return null;
  }

  if (combinedShippingProduct.option == null) {
    return {
      type: 'productName',
      name: product.productName,
      amount: product.amount,
    };
  }

  const option = product.units?.find(
    (unit) => unit.unit === combinedShippingProduct.option
  );

  if (option == null) {
    return null;
  }

  return {
    type: 'option',
    name: combinedShippingProduct.option,
    amount: option.amount,
  };
}
