import { z } from 'zod';
import { CombinedShipping } from '../type/CombinedShipping';
import { Product, ZProduct } from '../type/Product';

// GET /product
export const GET_PRODUCTS_URL = '/product';

export type GetProductsResponse = Product[] | null;

// POST /product
export const CREATE_PRODUCT_URL = '/product';

export const ZCreateProductDTO = ZProduct.omit({ _id: true });
export type CreateProductDTO = z.infer<typeof ZCreateProductDTO>;

export const ZCreateProductBody = z.object({
  productInfo: ZCreateProductDTO,
});
export type CreateProductBody = z.infer<typeof ZCreateProductBody>;

export type CreateProductResponse = Product;

// PUT /product
export const UPDATE_PRODUCT_URL = '/product';

export const ZUpdateProductDTO = ZProduct.omit({ _id: true }).partial();
export type UpdateProductDTO = z.infer<typeof ZUpdateProductDTO>;

export const ZUpdateProductBody = z.object({
  productId: z.string(),
  productInfo: ZUpdateProductDTO,
});
export type UpdateProductBody = z.infer<typeof ZUpdateProductBody>;

export type UpdateProductResponse = {
  product: Product;
  combinedShippingById: Record<string, CombinedShipping | null> | null;
  deletedCompositionMatchingIds: string[] | null;
  deletedOptionCodeMatchingIds: string[] | null;
} | null;

// PUT /product/many
export const UPDATE_PRODUCT_MANY_URL = '/product/many';

export const ZUpdateProductManyDTO = ZProduct.partial().extend({
  _id: z.string(),
});
export type UpdateProductManyDTO = z.infer<typeof ZUpdateProductManyDTO>;

export const ZUpdateManyBody = z.array(ZUpdateProductManyDTO);
export type UpdateManyBody = z.infer<typeof ZUpdateManyBody>;

export type UpdateProductManyResponse = { product: Product }[];

// DELETE /product/:id
export const DELETE_PRODUCT_URL = (id: string) => `/product/${id}`;

export type DeleteProductResponse = {
  combinedShippingById: Record<string, CombinedShipping | null> | null;
  deletedCompositionMatchingIds: string[] | null;
  deletedOptionCodeMatchingIds: string[] | null;
};
