import { z } from 'zod';
import { ShippingOrderProcessStatusCode } from '../../../../domain/src/services/order-shipping-process/orderShippingInfo.type';
import { BulkAPIResponse } from '../../type/BulkUpdateAPIResponse';
import { NormalizedOrder, OrderShippingStatus } from '../../type/order/Order';

// UPDATE_SHIPPING_ORDER_URL
export const UPDATE_SHIPPING_ORDER_URL =
  '/order-shipping-process/shipping-order/:uniqueCodeAfterCustomization';

export const UpdateShippingOrderPayloadSchema = z.object({
  uniqueCode: z.string(),
  uniqueCodeAfterCustomization: z.string(),
  shippingCompany: z.string().optional().nullable(),
  shippingNumber: z.string().optional().nullable(),
  shippingStatus: z.custom<OrderShippingStatus>().optional(),
});

export type UpdateShippingOrderPayload = z.infer<
  typeof UpdateShippingOrderPayloadSchema
>;

export const UpdateShippingOrderResponseSchema = z.object({
  success: z.boolean(),
  message: z.string().optional(),
});

export type UpdateShippingOrderResponse = z.infer<
  typeof UpdateShippingOrderResponseSchema
>;

// BULK_UPDATE_SHIPPING_ORDER_URL
export const BULK_UPDATE_SHIPPING_ORDER_URL =
  '/order-shipping-process/shipping-order/bulk-update';

export const BulkUpdateShippingOrderPayloadSchema = z.object({
  orders: z.array(UpdateShippingOrderPayloadSchema),
});

export type BulkUpdateShippingOrderPayload = z.infer<
  typeof BulkUpdateShippingOrderPayloadSchema
>;

/* require changes
z.object({
  successOrders: z.array(z.custom<NormalizedOrder>()),
  failedOrders: z.array(
    z.object({
      uniqueCode: z.string(),
      uniqueCodeAfterCustomization: z.string(),
      errorCode: z.string(),
    })
  ),
});
*/

export type BulkUpdateShippingOrderResponseItem = BulkAPIResponse<
  NormalizedOrder,
  ShippingOrderProcessStatusCode
>;

export type BulkUpdateShippingOrderResponse =
  BulkUpdateShippingOrderResponseItem[];
