import {
  ACCEPT_CANCEL_ORDERS_URL,
  AcceptCancelOrdersBody,
  AcceptCancelResponse,
  DispatchedOrder,
  REJECT_CANCEL_ORDERS_URL,
  RejectCancelOrdersBody,
  RejectCancelResponse,
} from '@sweep/contract';
import { amplitude } from 'src/third-parties/amplitude';
import api, { APIResponse } from '../api';

export async function acceptCanceledOrdes(orders: DispatchedOrder[]) {
  const response = await api.post<
    APIResponse<AcceptCancelResponse>,
    AcceptCancelOrdersBody
  >(ACCEPT_CANCEL_ORDERS_URL, { orders });
  const data = response?.data;

  if (
    data?.success === true &&
    data.data.some((order) => order.success === true)
  ) {
    amplitude.track('Approve Order-cancellation (Server)', {
      'Order Count': orders.length,
    });
  }

  return data;
}

export async function rejectCanceledOrder(
  orders: DispatchedOrder[],
  rejectReason: string
) {
  const response = await api.post<
    APIResponse<RejectCancelResponse>,
    RejectCancelOrdersBody
  >(REJECT_CANCEL_ORDERS_URL, { orders, rejectReason });
  const data = response?.data;

  if (
    data?.success === true &&
    data.data.some((order) => order.success === true)
  ) {
    amplitude.track('Reject Order-cancellation (Server)', {
      'Order Count': orders.length,
    });
  }

  return data;
}
