import {
  DISPATCH_ORDER_POllING_URL,
  SEARCH_DISPATCHED_ORDER_URL,
  DispatchOrderPollingQuery,
  DispatchOrderPollingResponse,
  SearchDispatchedOrderQuerySchema,
  SearchDispatchedOrderResponse,
  GET_SHOPPING_MALLS_URL,
  GetShoppingMallsResponse,
  SearchDispatchedOrderQuery,
} from '@sweep/contract';
import { tryCatch } from '@sweep/utils';
import { amplitude } from 'src/third-parties/amplitude';
import api, { APIResponse } from '../api';

export async function getShoppingMalls() {
  const response = await tryCatch(() =>
    api.get<APIResponse<GetShoppingMallsResponse>>(GET_SHOPPING_MALLS_URL)
  );

  return response?.data;
}

export type GetFetchOrdersRes =
  | {
      message: 'Success';
      result: {
        requestId: string;
      };
    }
  | {
      message: 'Failed';
      result: { err: string };
    };

export async function requestDispatchOrders(
  startTime: number,
  endTime: number,
  shoppingMall: string
) {
  const url = '/dispatch-order/fetch-orders';
  const response = await api.get<GetFetchOrdersRes>(url, {
    startTime,
    endTime,
    shoppingMall,
  });

  if (response?.data?.message === 'Success') {
    amplitude.track('Dispatch Order (Server)', { 'Store Name': shoppingMall });
  }

  return response?.data;
}

export async function getDispatchedOrdersPolling(
  query: DispatchOrderPollingQuery
) {
  const response = await api.get<APIResponse<DispatchOrderPollingResponse>>(
    DISPATCH_ORDER_POllING_URL,
    query
  );

  return response?.data;
}

export async function searchDispatchedOrders(
  query: SearchDispatchedOrderQuery
) {
  const response = await tryCatch(() =>
    api.get<APIResponse<SearchDispatchedOrderResponse>>(
      SEARCH_DISPATCHED_ORDER_URL,
      query
    )
  );

  if (response?.data?.success === true) {
    amplitude.track('Fetch Order (Server)', {
      Store: query.shoppingMall ?? 'all',
      'Order Number': query.orderNumber,
      'Phone Number': query.contactNumber,
      Name: query.name,
      'Order Count': response.data.data.length,
    });
  }

  return response?.data;
}
