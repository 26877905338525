import { CreateOptionCodeMatchingDTO } from '@sweep/contract';
import { CreateMatching } from '../interface';

export function toCreateOptionCodeMatching(
  matching: CreateMatching
): CreateOptionCodeMatchingDTO {
  return {
    optionCode: matching.optionCode!,
    products: matching.products,
  };
}
