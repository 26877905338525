import { useOMSStore } from 'hooks/useOMSStore';
import { observer } from 'mobx-react-lite';
import { overlay } from 'overlay-kit';
import { Button, SearchInput } from '@sweep/sds';
import { isNotNil } from '@sweep/utils';
import { usePreventBeforeUnload } from '../../hooks/usePreventBeforeUnload';
import { CreateMatching, MatchingOrder } from '../../interface';
import { toMatchingProducts } from '../../services/toMatchingProducts';
import CMHeader from '../common/CMHeader';
import CMProgress from '../common/CMProgress';
import { FormLayout } from '../common/FormLayout';
import MatchingStateInput from '../common/MatchingStateInput';
import NotMatchedDialog from './NotMatchedDialog';
import { UnmatchedCMFormStore } from './UnmatchedCMFormStore';

interface UnmatchedCMFormProps {
  orders: MatchingOrder[];
  onSubmit: (value: CreateMatching[] | null) => void;
}

function PreDivideUnmatchedCMForm({
  orders: givenCMOrders,
  onSubmit: givenOnSubmit,
}: UnmatchedCMFormProps) {
  const oms = useOMSStore();
  const store = oms.getStore(UnmatchedCMFormStore, givenCMOrders);

  usePreventBeforeUnload();

  const isSaveDisabled = store.matchings.some(
    (matching) => matching.status !== 'confirm'
  );

  const onSubmit = (matchings: CreateMatching[] | null) => {
    oms.deleteStore(UnmatchedCMFormStore);
    givenOnSubmit(matchings);
  };

  const handleExit = () => {
    onSubmit(null);
  };

  const handleSave = () => {
    const compositionMatchings = store.matchings
      .filter((matching) => matching.status === 'confirm')
      .map<CreateMatching | null>((matching) => {
        const { order: cmOrder, products: partailProducts } = matching;
        const products = toMatchingProducts(partailProducts, oms);

        if (products.length === 0) {
          return null;
        }

        return {
          productName: cmOrder.productName,
          option: cmOrder.option,
          optionCode: cmOrder.optionCode,
          products,
        };
      })
      .filter(isNotNil);

    if (compositionMatchings.length !== store.matchings.length) {
      overlay.open(({ isOpen, close }) => (
        <NotMatchedDialog
          open={isOpen}
          onClose={close}
          onSave={() => onSubmit(compositionMatchings)}
        />
      ));
      return;
    }

    onSubmit(compositionMatchings);
  };

  return (
    <FormLayout>
      <div className="h-42px flex items-center justify-between">
        <p className="text-extrabold-l">상품 구성 매칭</p>
      </div>
      <div className="h-[40px]" />
      <CMProgress>
        <CMProgress.Title index={1} active>
          상품 구성 매칭
        </CMProgress.Title>
        <CMProgress.Description>
          <span className="text-blue-500">{store.matchings.length}개</span>의
          상품을 매칭해주세요.
        </CMProgress.Description>
        <CMProgress.Description>
          <span className="text-blue-500">{store.matchedCount}</span>/
          {store.matchings.length}
        </CMProgress.Description>
      </CMProgress>
      <div className="h-20px" />
      <div className="shadow-line p-20px gap-16px flex flex-col rounded-[8px] bg-white">
        <SearchInput
          className="max-w-432px"
          placeholder="검색어를 입력해주세요."
          value={store.search}
          onChange={store.setSearch}
        />
        <hr />
        <div className="gap-8px flex flex-col">
          <CMHeader withConfirm />
          <div>
            {store.filteredMatchings.map((matching, index) => (
              <MatchingStateInput
                key={index}
                value={matching}
                onChange={(value) => store.setMatching(index, value)}
              />
            ))}
          </div>
        </div>
        <div className="gap-12px mx-auto flex w-fit">
          <Button color="lightGray" onClick={handleExit}>
            나가기
          </Button>
          <Button color="blue" onClick={handleSave} disabled={isSaveDisabled}>
            매칭 저장하기
          </Button>
        </div>
      </div>
    </FormLayout>
  );
}

export default observer(PreDivideUnmatchedCMForm);
