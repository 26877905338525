import { isNil, keyBy } from 'es-toolkit';
import { isEmpty } from 'es-toolkit/compat';
import {
  OriginalOrder,
  ResultStatusCode,
  SHIPPING_ORDER_PROCESS_STATUS_CODE,
  ShippingOrder,
  ShippingOrderProcessStatusCode,
  ShippingOrderResult,
} from './orderShippingInfo.type';

export function createOriginalOrderUpdatePayload<
  T extends ShippingOrder,
  U extends OriginalOrder,
>(params: {
  shippingOrder: T;
  originalOrders: U[];
  onError?: (error: unknown) => void;
}): {
  processedShippingOrder: ShippingOrderResult<T>;
  updatedOriginalOrders: U[];
} {
  try {
    const { originalOrders, shippingOrder } = params;

    const originalOrdersKeyByUniqueCode: Record<string, U> = keyBy(
      originalOrders,
      (order) => order.uniqueCode
    );

    const processedShippingOrders: ShippingOrderResult<T>[] = [];
    const updatedOriginalOrders: U[] = [];

    const shippingOrderUniqueCodes = shippingOrder.uniqueCode.split(',');

    for (const uniqueCode of shippingOrderUniqueCodes) {
      const originalOrder = originalOrdersKeyByUniqueCode[uniqueCode];

      const isOriginalOrderNotFound = originalOrder == null;
      if (isOriginalOrderNotFound) {
        return {
          processedShippingOrder: {
            statusCode:
              SHIPPING_ORDER_PROCESS_STATUS_CODE.INVALID_ORIGINAL_ORDER_NOT_FOUND,
            shippingOrder,
          },
          updatedOriginalOrders: [],
        };
      }

      const [modifiedOriginalOrder, errorCode] = createUpdatedOriginalOrder(
        originalOrder,
        shippingOrder
      );
      const isUpdateFailed = errorCode != null;

      if (isUpdateFailed) {
        return {
          processedShippingOrder: {
            statusCode: errorCode,
            shippingOrder,
          },
          updatedOriginalOrders: [],
        };
      }

      updatedOriginalOrders.push(modifiedOriginalOrder);
      processedShippingOrders.push({
        statusCode: SHIPPING_ORDER_PROCESS_STATUS_CODE.SUCCESS,
        shippingOrder,
      });
    }

    return {
      processedShippingOrder: {
        statusCode: SHIPPING_ORDER_PROCESS_STATUS_CODE.SUCCESS,
        shippingOrder,
      },
      updatedOriginalOrders,
    };
  } catch (error) {
    params.onError?.(error);
    return {
      processedShippingOrder: {
        statusCode: SHIPPING_ORDER_PROCESS_STATUS_CODE.INVALID_UNKNOWN_ERROR,
        shippingOrder: params.shippingOrder,
      },
      updatedOriginalOrders: [],
    };
  }
}

function createUpdatedOriginalOrder<
  T extends ShippingOrder,
  U extends OriginalOrder,
>(originalOrder: U, shippingOrder: T): [U, ResultStatusCode | null] {
  const { shippingCompany, shippingNumber } = shippingOrder;

  const isInvalidPayload = shippingCompany == null || shippingNumber == null;
  if (isInvalidPayload) {
    return [
      originalOrder,
      SHIPPING_ORDER_PROCESS_STATUS_CODE.INVALID_SHIPPING_COMPANY_EMPTY,
    ];
  }

  const shippingInfoValidationResult = validateShippingInfo(shippingOrder);
  const isShippingInfoValid =
    shippingInfoValidationResult ===
      SHIPPING_ORDER_PROCESS_STATUS_CODE.SUCCESS ||
    shippingInfoValidationResult ===
      SHIPPING_ORDER_PROCESS_STATUS_CODE.PASS_NO_SHIPPING_NUMBER_RULE;

  if (!isShippingInfoValid) {
    return [originalOrder, shippingInfoValidationResult];
  }

  const newShippingNumber = mergeShippingNumber(
    originalOrder.shippingNumber,
    shippingNumber
  );

  return [
    {
      ...originalOrder,
      shippingCompany,
      shippingNumber: newShippingNumber,
    },
    null,
  ];
}

export function validateShippingInfo(
  shippingOrder: ShippingOrder
): ShippingOrderProcessStatusCode {
  const { shippingCompany, shippingNumber } = shippingOrder;

  if (isNil(shippingCompany) || isEmpty(shippingCompany)) {
    return SHIPPING_ORDER_PROCESS_STATUS_CODE.INVALID_SHIPPING_COMPANY_EMPTY;
  }

  if (isNil(shippingNumber) || isEmpty(shippingNumber)) {
    return SHIPPING_ORDER_PROCESS_STATUS_CODE.INVALID_SHIPPING_NUMBER_EMPTY;
  }

  return SHIPPING_ORDER_PROCESS_STATUS_CODE.SUCCESS;
}

function mergeShippingNumber(
  originalShippingNumber: string | null | undefined,
  newShippingNumber: string
): string {
  const isOriginalShippingNumberValid =
    !isNil(originalShippingNumber) && !isEmpty(originalShippingNumber);

  return isOriginalShippingNumberValid
    ? `${originalShippingNumber},${newShippingNumber}`
    : newShippingNumber;
}
