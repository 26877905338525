import { z } from 'zod';
import { UserSetting, userSettingSchema } from '../type/UserSetting';

// GET /user/custom-excel
export const GET_EXCEL_SETTING_URL = '/user/custom-excel';

export type GetExcelSettingResponse = UserSetting;

// PUT /user/setting
export const UPDATE_USER_SETTING_URL = '/user/setting';

export const ZUpdateUserSettingDTO = userSettingSchema
  .omit({
    _id: true,
  })
  .partial();

export type UpdateUserSettingDTO = z.infer<typeof ZUpdateUserSettingDTO>;

export type UpdateUserSettingResponse = UserSetting;
