import { action, computed, observable, runInAction } from 'mobx';
import { uniqueByUniqueCode } from 'services/order/uniqueByUniqueCode';
import { OMSStore } from 'stores/OMSStore';
import {
  DispatchedOrder,
  isDispatchedOrder,
  ShoppingMall,
} from '@sweep/contract';
import { isNotNil } from '@sweep/utils';
import { getShoppingMalls } from 'src/network/order/dispatchOrder';
import { PluginExecutionService } from '../plugin/PluginExecutionService';

export class DispatchOrderStore {
  @observable
  accessor shoppingMalls: ShoppingMall[] = [];

  @observable
  accessor dispatchedOrders: DispatchedOrder[] = [];

  @observable
  accessor lastDispatchedAts: Record<string, number | undefined> = {};

  @observable
  accessor rejectedShoppingMallNames: string[] = [];

  @computed
  get isNotLinkedShoppingMall() {
    return this.shoppingMallNames.length === 0;
  }

  @computed
  get lastestDispatchedAt() {
    const lastDispatchedAts = Object.values(this.lastDispatchedAts).filter(
      isNotNil
    );

    if (lastDispatchedAts.length === 0) {
      return null;
    }

    return Math.max(...lastDispatchedAts);
  }

  @computed
  get shoppingMallNames() {
    return this.shoppingMalls.map((mall) => mall.name);
  }

  @computed
  get dispatchedOrdersByUniqueCode() {
    return new Map(
      this.dispatchedOrders.map((order) => [order.uniqueCode, order])
    );
  }

  getDispatchedOrder(uniqueCode: string) {
    return this.dispatchedOrdersByUniqueCode.get(uniqueCode);
  }

  constructor(private oms: OMSStore) {}

  @action.bound
  async init() {
    await this.loadShoppingMalls();
  }

  @action.bound
  async loadShoppingMalls() {
    const response = await getShoppingMalls();

    if (response?.success !== true) {
      return;
    }

    runInAction(() => {
      this.shoppingMalls = response.data;
    });
  }

  @action.bound
  pushRejectedShoppingMallName(shoppingMallName: string) {
    if (this.rejectedShoppingMallNames.includes(shoppingMallName)) {
      return;
    }

    this.rejectedShoppingMallNames = [
      ...this.rejectedShoppingMallNames,
      shoppingMallName,
    ];
  }

  @action.bound
  removeRejectedShoppingMallName(shoppingMallNames: string[]) {
    const nameSet = new Set(this.rejectedShoppingMallNames);
    shoppingMallNames.forEach((name) => {
      nameSet.delete(name);
    });
    this.rejectedShoppingMallNames = Array.from(nameSet);
  }

  @action.bound
  async updateLastDispatchedAt(shoppingMallName: string, date: number) {
    this.lastDispatchedAts = {
      ...this.lastDispatchedAts,
      [shoppingMallName]: date,
    };
  }

  @action.bound
  setDispatchedOrders(orders: DispatchedOrder[]) {
    this.dispatchedOrders = orders;
  }

  @action.bound
  pushDispatchedOrders(orders: DispatchedOrder[]) {
    this.dispatchedOrders = uniqueByUniqueCode([
      ...orders,
      ...this.dispatchedOrders,
    ]);
  }

  @action.bound
  removeDispatchedOrdersByUniqueCodes(uniqueCodes: string[]) {
    const uniqueCodeSet = new Set(uniqueCodes);
    const filteredOrders = this.dispatchedOrders.filter(
      (order) => !uniqueCodeSet.has(order.uniqueCode)
    );
    this.dispatchedOrders = filteredOrders;
  }

  async transformOrders(orders: DispatchedOrder[]): Promise<DispatchedOrder[]> {
    const plugins = this.oms.plugin.getPlugins(this.oms.user.dispatchPlugins);
    const executionService = new PluginExecutionService(
      this.oms,
      plugins,
      'dispatch'
    );

    const transformedOrders = await executionService.execute(orders);
    const dispatchedOrders = transformedOrders.filter(isDispatchedOrder);
    const uniquedOrders = uniqueByUniqueCode(dispatchedOrders);

    return uniquedOrders;
  }
}
