import { useState } from 'react';

interface Options {
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  onSelectedIndexChange: (index: number) => void;
  onClose: () => void;
}

export function useIsOpenOptions({
  inputRef,
  onSelectedIndexChange,
  onClose,
}: Options) {
  const [isOpenOptions, setIsOpenOptions] = useState(false);

  const openOptions = () => {
    setIsOpenOptions(true);
  };

  const closeOptions = () => {
    setIsOpenOptions(false);
    inputRef.current?.blur();
    onSelectedIndexChange(0);
    onClose();
  };

  return {
    isOpenOptions,
    closeOptions,
    openOptions,
  };
}
