export const SHIPPING_ORDER_STATUS = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  REVIEWING: 'reviewing',
  SHIPPED: 'shipped',
} as const;

export type OrderShippingStatus =
  (typeof SHIPPING_ORDER_STATUS)[keyof typeof SHIPPING_ORDER_STATUS];

export type OriginalOrder = {
  uniqueCode: string;
  shippingCompany?: string | null;
  shippingNumber?: string | null;
  autoFulfilled?: boolean;
};

export type ShippingOrder = {
  uniqueCode: string;
  uniqueCodeAfterCustomization: string;
  shippingCompany?: string | null;
  shippingNumber?: string | null;
  shippingStatus?: OrderShippingStatus | null;
};

export type OrderWithBasicInfo = {
  contactNumber?: string | null;
  address?: string | null;
  name?: string | null;
};

export const SHIPPING_ORDER_PROCESS_STATUS_CODE = {
  INVALID_UNKNOWN_ERROR: 'INVALID_UNKNOWN_ERROR',

  INVALID_SHIPPING_COMPANY: 'INVALID_SHIPPING_COMPANY',
  INVALID_SHIPPING_COMPANY_EMPTY: 'INVALID_SHIPPING_COMPANY:EMPTY',
  INVALID_SHIPPING_COMPANY_UNMATCHED: 'INVALID_SHIPPING_COMPANY:UNMATCHED',
  INVALID_SHIPPING_COMPANY_INVALID: 'INVALID_SHIPPING_COMPANY:INVALID',
  INVALID_SHIPPING_COMPANY_UNKNOWN: 'INVALID_SHIPPING_COMPANY:UNKNOWN',

  INVALID_SHIPPING_NUMBER: 'INVALID_SHIPPING_NUMBER',
  INVALID_SHIPPING_NUMBER_EMPTY: 'INVALID_SHIPPING_NUMBER:EMPTY',
  INVALID_SHIPPING_NUMBER_INVALID: 'INVALID_SHIPPING_NUMBER:INVALID',
  INVALID_SHIPPING_NUMBER_UNKNOWN: 'INVALID_SHIPPING_NUMBER:UNKNOWN',
  INVALID_SHIPPING_NUMBER_DUPLICATE: 'INVALID_SHIPPING_NUMBER:DUPLICATE',

  INVALID_SHIPPING_STATUS: 'INVALID_SHIPPING_STATUS',

  INVALID_UPDATE_FAILED: 'INVALID_UPDATE_FAILED',
  INVALID_ORIGINAL_ORDER_NOT_FOUND: 'INVALID_ORIGINAL_ORDER_NOT_FOUND',
  INVALID_ORIGINAL_ORDER_NOT_FOUND_BY_BASIC_INFO:
    'INVALID_ORIGINAL_ORDER_NOT_FOUND_BY_BASIC_INFO',
  INVALID_AUTO_DISPATCH_FAILED: 'INVALID_AUTO_DISPATCH_FAILED',
  INVALID_DUPLICATE_KEY: 'INVALID_DUPLICATE_KEY',

  PASS_NO_SHIPPING_NUMBER_RULE: 'PASS_NO_SHIPPING_NUMBER_RULE',

  SUCCESS: 'SUCCESS',
  SUCCESS_STEP_INITIALIZE: 'SUCCESS_STEP_INITIALIZE',
  SUCCESS_STEP_DEFAULT_SHIPPING_COMPANY:
    'SUCCESS_STEP_DEFAULT_SHIPPING_COMPANY',
  SUCCESS_STEP_SHIPPING_INFO_VALIDATION:
    'SUCCESS_STEP_SHIPPING_INFO_VALIDATION',

  SUCCESS_STEP_SHIPPING_INFO_UPDATE_BY_KEY:
    'SUCCESS_STEP_SHIPPING_INFO_UPDATE_BY_KEY',
  SUCCESS_STEP_SHIPPING_INFO_UPDATE_BY_BASIC_INFO:
    'SUCCESS_STEP_SHIPPING_INFO_UPDATE_BY_BASIC_INFO',

  API_BULK_UPDATE_SHIPPING_INFO_SUCCESS:
    'API_BULK_UPDATE_SHIPPING_INFO_SUCCESS',
  API_BULK_UPDATE_SHIPPING_INFO_FAILED: 'API_BULK_UPDATE_SHIPPING_INFO_FAILED',
  API_BULK_UPDATE_SHIPPING_STATUS_FAILED:
    'API_BULK_UPDATE_SHIPPING_STATUS_FAILED',

  API_BULK_UPDATE_ORIGINAL_INFO_SUCCESS:
    'API_BULK_UPDATE_ORIGINAL_INFO_SUCCESS',
  API_BULK_UPDATE_ORIGINAL_INFO_FAILED: 'API_BULK_UPDATE_ORIGINAL_INFO_FAILED',

  STEP_AUTO_DISPATCH_FAILED_UNKNOWN_ERROR:
    'STEP_AUTO_DISPATCH_FAILED_UNKNOWN_ERROR',
  STEP_MANUAL_DISPATCH_FAILED_UNKNOWN_ERROR:
    'STEP_MANUAL_DISPATCH_FAILED_UNKNOWN_ERROR',

  API_BULK_AUTO_DISPATCH_ORIGINAL_ORDER_FAILED:
    'API_BULK_AUTO_DISPATCH_ORIGINAL_ORDER_FAILED',

  STEP_UPDATE_SHIPPING_INFO_FAILED_UNKNOWN_ERROR:
    'STEP_UPDATE_SHIPPING_INFO_FAILED_UNKNOWN_ERROR',
} as const;

export type ShippingOrderProcessStatusKey =
  keyof typeof SHIPPING_ORDER_PROCESS_STATUS_CODE;

export type ShippingOrderProcessStatusCode =
  (typeof SHIPPING_ORDER_PROCESS_STATUS_CODE)[ShippingOrderProcessStatusKey];

export const SHIPPING_ORDER_PROCESS_STATUS_CODE_MESSAGE: Record<
  ShippingOrderProcessStatusCode,
  string
> = {
  ['INVALID_UNKNOWN_ERROR']: '알 수 없는 오류가 발생했습니다.',

  ['INVALID_SHIPPING_COMPANY']: '배송업체를 다시 확인해주세요.',
  ['INVALID_SHIPPING_COMPANY:EMPTY']: '배송업체가 없는 주문입니다.',
  ['INVALID_SHIPPING_COMPANY:UNMATCHED']: '배송업체가 일치하지 않습니다.',
  ['INVALID_SHIPPING_COMPANY:INVALID']: '배송업체 정보가 유효하지 않습니다.',
  ['INVALID_SHIPPING_COMPANY:UNKNOWN']: '인식할 수 없는 배송업체입니다.',

  ['INVALID_SHIPPING_NUMBER']: '운송장 번호를 다시 확인해주세요.',
  ['INVALID_SHIPPING_NUMBER:INVALID']: '운송장 번호가 유효하지 않습니다.',
  ['INVALID_SHIPPING_NUMBER:EMPTY']: '운송장 번호가 없는 주문입니다.',
  ['INVALID_SHIPPING_NUMBER:DUPLICATE']: '이미 등록된 운송장 번호입니다.',
  ['INVALID_SHIPPING_NUMBER:UNKNOWN']: '올바르지 않은 운송장 번호입니다.',

  ['INVALID_SHIPPING_STATUS']:
    '운송장을 업로드할 수 없는 상태의 주문입니다. 주문 상태를 확인해주세요.',

  ['INVALID_UPDATE_FAILED']: '상태 변경에 실패했습니다.',
  ['INVALID_ORIGINAL_ORDER_NOT_FOUND']:
    '일치하는 주문이 없습니다. 주문 상태를 확인해주세요.',
  ['INVALID_ORIGINAL_ORDER_NOT_FOUND_BY_BASIC_INFO']:
    '스윕발주고유번호가 없고 수령인 정보가 중복된 주문이 있어 인식할 수 없습니다.',

  ['INVALID_AUTO_DISPATCH_FAILED']: '쇼핑몰 운송장 등록에 실패했습니다.',

  ['PASS_NO_SHIPPING_NUMBER_RULE']: '운송장 번호 검증 규칙이 없습니다.',

  ['INVALID_DUPLICATE_KEY']: '동일한 고유번호가 여러개 있어요.',

  ['SUCCESS']: '상태 변경에 성공했습니다.',
  ['SUCCESS_STEP_INITIALIZE']: '초기화 완료',
  ['SUCCESS_STEP_DEFAULT_SHIPPING_COMPANY']: '기본 배송업체 설정 완료',
  ['SUCCESS_STEP_SHIPPING_INFO_VALIDATION']: '배송정보 검증 완료',

  ['SUCCESS_STEP_SHIPPING_INFO_UPDATE_BY_KEY']:
    '배송정보 업데이트 완료 (스윕고유번호)',
  ['SUCCESS_STEP_SHIPPING_INFO_UPDATE_BY_BASIC_INFO']:
    '배송정보 업데이트 완료 (수령인 정보)',

  ['API_BULK_UPDATE_SHIPPING_INFO_SUCCESS']: '배송정보 업데이트 완료',
  ['API_BULK_UPDATE_SHIPPING_INFO_FAILED']: '배송정보 업데이트 실패',

  ['STEP_AUTO_DISPATCH_FAILED_UNKNOWN_ERROR']:
    '쇼핑몰 운송장 등록에 실패했습니다.',
  ['STEP_MANUAL_DISPATCH_FAILED_UNKNOWN_ERROR']:
    '수동 운송장 등록에 실패했습니다.',

  ['STEP_UPDATE_SHIPPING_INFO_FAILED_UNKNOWN_ERROR']:
    '배송정보 업데이트에 실패했습니다.',
  ['API_BULK_UPDATE_SHIPPING_STATUS_FAILED']:
    '배송상태 업데이트에 실패했습니다.',

  ['API_BULK_UPDATE_ORIGINAL_INFO_SUCCESS']: '원본 주문 일괄 업데이트 완료',
  ['API_BULK_UPDATE_ORIGINAL_INFO_FAILED']: '원본 주문 일괄 업데이트 실패',

  ['API_BULK_AUTO_DISPATCH_ORIGINAL_ORDER_FAILED']:
    '원본 주문 자동 발송에 실패했습니다.',
} as const;

export type ShippingOrderError = {
  errorCode: ShippingOrderProcessStatusCode;
  errorMessage: string;
};

export type ResultStatusCode =
  (typeof SHIPPING_ORDER_PROCESS_STATUS_CODE)[keyof typeof SHIPPING_ORDER_PROCESS_STATUS_CODE];

export type ShippingOrderResult<T extends ShippingOrder> = {
  statusCode: ResultStatusCode;
  shippingOrder: T;
};

export type OriginalOrderResult<T extends OriginalOrder> = {
  statusCode: ResultStatusCode;
  originalOrders: T;
};
