import { formatDate } from '@sweep/utils';
import { Partner } from 'src/models/Partner';
import { createOrderExcelBuffer } from 'src/services/file/excel/create/createOrderExcelBuffer';
import { createOrderExcelFromTemplate } from 'src/services/file/excel/create/createOrderExcelFromTemplate';
import { OMSStore } from 'src/stores/OMSStore';
import { addMissingRequiredColumns } from './addMissingRequiredColumns';
import { AdvancedShippingNoticeDocument } from './createAdvancedShippingNoticeDocuments';
import { findExcelHeaderMatchFromPartner } from './findExcelHeaderMatch';

export async function createExcelFromAdvancedShippingNoticeDocument(
  AdvancedShippingNoticeDocument: AdvancedShippingNoticeDocument,
  partners: Partner[],
  oms: OMSStore
): Promise<
  | {
      isSuccess: true;
      buffer: Buffer;
      filename: string;
    }
  | {
      isSuccess: false;
      failedReason: string;
    }
> {
  const firstSampledOrder = AdvancedShippingNoticeDocument.orders[0];

  const matchResult = findExcelHeaderMatchFromPartner(
    partners,
    firstSampledOrder
  );

  if (matchResult == null) {
    return {
      isSuccess: false,
      failedReason: 'matchResult is null',
    };
  }

  if (matchResult.isTemplateEnabled) {
    try {
      const buffer = await createOrderExcelFromTemplate(
        matchResult.headerMatch.columnNames,
        matchResult.headerMatch.columnMapping,
        AdvancedShippingNoticeDocument.orders,
        {
          headerRowIndex: matchResult.options.headerStartIndex ?? 1,
          minRowIndex: matchResult.options.orderStartIndex ?? 2,
          templateFile: matchResult.template?.templateFile ?? '',
        }
      );

      if (buffer == null) {
        return {
          isSuccess: false,
          failedReason: 'buffer is null',
        };
      }

      return {
        isSuccess: true,
        buffer: buffer as Buffer,
        filename: getFilename(AdvancedShippingNoticeDocument.documentName),
      };
    } catch (e) {
      const errorMessage = e instanceof Error ? e.message : 'Unknown error';
      return {
        isSuccess: false,
        failedReason: errorMessage,
      };
    }
  }

  try {
    const filename = getFilename(AdvancedShippingNoticeDocument.documentName);
    const { columnNames, columnMapping } = addMissingRequiredColumns(
      matchResult.headerMatch.columnKeys,
      matchResult.headerMatch.columnNames,
      matchResult.headerMatch.columnMapping
    );

    const buffer = await createOrderExcelBuffer(
      oms,
      AdvancedShippingNoticeDocument.orders,
      filename,
      columnNames,
      columnMapping
    );

    return {
      isSuccess: true,
      buffer: buffer as Buffer,
      filename,
    };
  } catch (e) {
    const errorMessage = e instanceof Error ? e.message : 'Unknown error';
    return {
      isSuccess: false,
      failedReason: errorMessage,
    };
  }
}

const getFilename = (documentName: string) => {
  const formattedDate = formatDate(new Date(), 'yy.MM.dd');

  return `${formattedDate} ${documentName}_운송장첨부.xlsx`;
};
