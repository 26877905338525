import { forwardRef } from 'react';
import { cva } from '@sweep/tailwind';

export interface SearchSelectOptionProps {
  active: boolean;
  onMouseDown: () => void;
  className?: string;
  children: string;
}

function SearchSelectOption(
  { active, onMouseDown, className, children }: SearchSelectOptionProps,
  ref: React.Ref<HTMLLIElement>
) {
  return (
    <li
      ref={ref}
      className={optionClass({ active, className })}
      onMouseDown={onMouseDown}
    >
      <span className={textClass()}>{children}</span>
    </li>
  );
}

const optionClass = cva(
  'px-16px py-8px flex flex-1 cursor-pointer items-center',
  {
    variants: {
      active: {
        true: 'bg-gray-100',
      },
    },
  }
);

const textClass = cva('text-medium-s');

export default forwardRef(SearchSelectOption);
