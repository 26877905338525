import { Workbook } from 'exceljs';
import { overlay } from 'overlay-kit';
import { useEffect, useRef, useState } from 'react';
import { useBeforeUnload, useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import {
  IconArrowRightXX,
  IconDownloadFiles,
  IconTextRight,
  IconTextWrong,
} from '@sweep/asset/icons';
import Footer from '../../components/Footer';
import LoadingBar from '../../components/LoadingBarPlus';
import SimpleTable from '../../components/RenderTable/SimpleTable';
import ScrollingImages from '../../components/ScrollingImages';
import useUpload from '../../hooks/useUpload';
import {
  imageExcelBlue,
  imageExcelGrey,
  imageLogo11,
  imageLogoAliexpress,
  imageLogoAuction,
  imageLogoGmarket,
  imageLogoKakao,
  imageLogoNaver,
} from '../../images';
import excel_gif from '../../images/Microsoft Excel.gif';
import Sweep from '../../images/Sweep.png';
import BannerLarge from '../../images/SweepBanner.png';
import NewModal from '../../modals/NewModal';
import backendApi from '../../utils/backendApi';
import { translateHeaderRowInEnglish } from '../../utils/headerColumnMapping';
import { readExcelFile } from '../../utils/readExcelFile_for_non_login_users';
import { isValid } from '../../utils/utils';
import UserOpinionModal from './components/UserOpinionModal';

const AddressValidScreen = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addressColumn, setAddressColumn] = useState(-1);
  const [postCodeColumn, setPostCodeColumn] = useState(-1);
  const [isFinish, setIsFinish] = useState(false);
  const [state, setState] = useState('start');
  const [showContents, setShowContents] = useState(true);
  const [exampleModal, setExampleModal] = useState(false);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const fadeDuration = 300;
  const textDisplayTime = 4000;

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeout(() => {
        setCurrentTextIndex((prevIndex) => {
          return (prevIndex + 1) % texts.length;
        });
      }, fadeDuration);
    }, textDisplayTime);

    return () => clearInterval(interval);
  }, []);

  function numberToExcelColumn(number) {
    let column = '';
    while (number > 0) {
      let remainder = (number - 1) % 26;
      column = String.fromCharCode(65 + remainder) + column;
      number = Math.floor((number - 1) / 26);
    }
    return column;
  }

  const [error, setError] = useState('');

  const [files, setFiles] = useState([]);

  const [clickDeleteClickCount, setDeleteClickCount] = useState(0);
  // 마지막 클릭 시간 상태
  const [lastDeleteClickTime, setLastDeleteClickTime] = useState(0);
  const [xlsxMade, setXlsxMade] = useState(false);
  useEffect(() => {
    // 3번 클릭되었는지 확인
    if (clickDeleteClickCount === 5) {
      // 현재 시간과 마지막 클릭 시간의 차이가 5초 이내인지 확인
      const now = Date.now();
      if (now - lastDeleteClickTime <= 5000) {
        const isConfirmed = window.confirm(
          '모든 엑셀 파일을 한 번에 삭제하시겠습니까?'
        );

        if (isConfirmed) {
          setFiles([]);
          alert('모든 엑셀 파일이 삭제되었어요.');
        }

        // 카운트와 시간 리셋
        setDeleteClickCount(0);
      } else {
        // 5초가 지났으면 클릭 카운트만 리셋
        setDeleteClickCount(1);
      }
      setLastDeleteClickTime(now);
    }
  }, [clickDeleteClickCount, lastDeleteClickTime]);

  useEffect(() => {
    if (!isValid(files)) {
    }
  }, [files]);
  const [rows, setRows] = useState([]);
  const readColumnFromExcelFile = async (e) => {
    try {
      setFiles(e.target.files);
      let file = e.target.files[0];
      let Rows = await readExcelFile(file);
      setRows(Rows);
      const firstRow = Rows[0]; // 첫 번째 행을 가져옵니다
      let engHeader = translateHeaderRowInEnglish(firstRow);
      const addressIndex = engHeader.findIndex(
        (header) => header && header.toLowerCase() === 'address'
      );
      setAddressColumn(addressIndex);
      const postCodeIndex = engHeader.findIndex(
        (header) => header && header.toLowerCase() === 'postcode'
      );
      setPostCodeColumn(postCodeIndex);
      handleStateChange('uploaded');
    } catch (error) {
      if (error.message.trim() === 'Header row not found.') {
        setError('파일의 첫 행이 없습니다.');
      } else {
        setError(error.message);
      }

      handleStateChange('start');
      setIsModalOpen(true);

      console.error('Error reading excel file:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (addressColumn === -1) {
      setError('주소열을 선택해주세요.');
      setIsModalOpen(true);
      return;
    }
    try {
      handleStateChange('processing');
      let response;
      try {
        response = await backendApi.uploadAddressExcel(
          numberToExcelColumn(postCodeColumn + 1),
          numberToExcelColumn(addressColumn + 1),
          files[0]
        );
      } catch (error) {
        const new_workbook = new Workbook();
        const new_worksheet = new_workbook.addWorksheet('Sheet1');
        rows.forEach((row) => {
          new_worksheet.addRow(row);
        });
        let buffer = await new_workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        blob.name = files[0].name;
        response = await backendApi.uploadAddressExcel(
          numberToExcelColumn(postCodeColumn + 1),
          numberToExcelColumn(addressColumn + 1),
          blob
        );
        setXlsxMade(true);
      }
      const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      setIsFinish(true);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        '주소변환_' +
          files[0].name.normalize('NFC').split('.').slice(0, -1).join('.') +
          '.xlsx'
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.warn('Error uploading excel:', error);
      setError('파일 업로드에 실패했습니다.');
      handleStateChange('start');
      setIsModalOpen(true);
    }
  };

  const images = [
    imageLogoKakao,
    imageLogoNaver,
    imageLogo11,
    imageLogoAuction,
    imageLogoGmarket,
    imageLogoAliexpress,
  ];
  const dropRef = useRef(null);
  const { validateFiles } = useUpload();
  const handleUpload = async (e) => {
    let validFiles = await validateFiles(e);
    if (validFiles.length === 1) {
      await readColumnFromExcelFile({
        target: {
          files: validFiles,
        },
      });
    } else if (validFiles.length > 1) {
      window.alert('파일은 하나씩만 업로드 가능합니다.');
    }
    e.target.value = '';
    setXlsxMade(false);
    // To do: 오류 발생 시 로딩 상태를 비활성화하는 것을 보장
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const cardStyle = {
    boxShadow: '0 0 28px rgba(54, 80, 105, 0.3)',
    backgroundColor: 'white',
  };

  const excelUploadContents = {
    start: (
      <div
        className="my-[10px] flex w-full flex-1 flex-col overflow-hidden"
        ref={dropRef}
        onDrop={handleUpload}
        onDragOver={handleDragOver}
      >
        <div className="flex h-full flex-col">
          <div className="my-auto flex flex-col items-center justify-center pb-2">
            <img
              src={imageExcelGrey}
              alt="drag-and-drop icon"
              className=" h-[112px] w-[112x]"
            />
            <div className="mx-5 flex items-center">
              <span className="whitespace-nowrap text-xl font-medium text-gray-600">
                변환하려는 주소가 있는 파일을 여기에 끌어다 놓으세요.
              </span>
            </div>
          </div>
        </div>
        <div className="flex  w-full flex-col items-center justify-center ">
          <label
            className={`mx-[20px] mb-2 flex h-[42px] w-[200px] cursor-pointer items-center justify-center  rounded-lg bg-blue-500 text-[14px] font-bold text-white transition duration-300 ease-in-out hover:bg-blue-600`}
          >
            <input
              type="file"
              className="hidden"
              onChange={handleUpload}
              accept=".xlsx, .xls, .csv"
            />
            파일 올리기
          </label>
          <span className="text-[14px] font-medium text-[#9FB1C1]">
            (최대 3MB, xlsx, xls, csv 파일 지원)
          </span>
        </div>
      </div>
    ),
    uploaded: (
      <div className="flex w-full flex-1 flex-col items-center justify-center overflow-hidden p-5">
        <SimpleTable
          orders={rows.slice(1, 15)}
          header={rows[0]}
          addressColumn={addressColumn}
          postCodeColumn={postCodeColumn}
        />
      </div>
    ),
    processing: (
      <div className="flex w-full flex-1 flex-col items-center justify-center overflow-hidden">
        <div className="flex w-full flex-col items-center justify-center">
          {isFinish ? (
            <div className="flex flex-col items-center justify-center">
              <img
                src={imageExcelBlue}
                alt="excel blue icon"
                className="mb-5 size-[100px] opacity-100 transition-opacity duration-500"
              />
              <span className="text-[18px] font-normal">
                주소 변환 파일이 성공적으로 다운로드되었습니다.
              </span>
              {files[0].name.normalize('NFC').split('.').at(-1) !== 'xlsx' && (
                <span className="animate-blink text-[18px] font-normal text-red-500">
                  주의 :업로드하신{' '}
                  {files[0].name.normalize('NFC').split('.').at(-1)} 확장자는
                  양식이 보존되지 않습니다!
                </span>
              )}
              {xlsxMade && (
                <span className="animate-blink text-[18px] font-normal text-red-500">
                  주의 : 업로드하신 파일 ({files[0].name.normalize('NFC')})은
                  양식이 보존되지 않습니다!
                </span>
              )}
            </div>
          ) : (
            <img
              src={excel_gif}
              alt="excel gif icon"
              className="size-[200px] opacity-100 transition-opacity duration-500"
            />
          )}

          <div className="mt-20 flex w-5/6 justify-center">
            <LoadingBar isFinish={isFinish} rowNum={rows.length} />
          </div>
        </div>
      </div>
    ),
  };
  const howManyColumns = 50;
  const legend = {
    fail: ['FFA6A6', '잘못된 양식의 주소'],
    postcode: ['FFCF86', '우편번호와 주소가 상이'],
    multiple: ['C2A9DD', '구주소 선택 필요'],
    fixed: ['C6FDC6', '수정됨'],
  };
  const excelDescriptionContents = {
    start: (
      <>
        <div className="flex items-center justify-center space-x-2">
          <IconTextWrong />
          <IconArrowRightXX className="text-gray-500" />
          <IconTextRight />
        </div>
        <span className="text-[18px] font-medium text-[#343D4B]">
          엑셀의 주소와 우편번호 열이 자동으로 인식됩니다.
        </span>
      </>
    ),
    uploaded: (
      <>
        <div className="flex w-full space-x-4">
          <div className="flex flex-1 items-center space-x-4">
            <span className=" whitespace-nowrap text-center text-[14px] font-medium text-[#343D4B]">
              주소 열
            </span>
            <select
              value={addressColumn}
              onChange={(e) => setAddressColumn(parseInt(e.target.value, 10))}
              className={`h-[40px] w-5/6 rounded-lg border border-gray-300 px-[16px] text-[14px]  ${
                addressColumn === -1 ? 'text-gray-400' : 'text-gray-900'
              }`}
            >
              <option value={-1}>주소열의 알파벳을 선택해주세요</option>
              {Array.from({ length: howManyColumns }, (v, i) => (
                <option key={i} value={i}>
                  {numberToExcelColumn(i + 1)}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-1 items-center space-x-4 ">
            <span className="whitespace-nowrap text-center text-[16px] font-medium text-[#343D4B]">
              우편번호 열
            </span>
            <select
              value={postCodeColumn}
              onChange={(e) => {
                setPostCodeColumn(parseInt(e.target.value, 10));
              }}
              className={`h-[40px] w-5/6 rounded-lg border border-gray-300 px-[16px] text-[14px] ${
                postCodeColumn === -1 ? 'text-gray-400' : 'text-gray-900'
              }`}
            >
              <option value={-1}>
                우편번호의 열을 선택해주세요 (미선택시 주소만)
              </option>
              {Array.from({ length: howManyColumns }, (v, i) => (
                <option key={i} value={i}>
                  {numberToExcelColumn(i + 1)}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="ml-6 flex w-[200px] flex-col items-center justify-center">
          <button
            type="button"
            className="flex h-[40px] w-full cursor-pointer items-center justify-center rounded-lg bg-blue-500 text-[14px] font-bold text-white transition duration-300 ease-in-out hover:bg-blue-600"
            onClick={handleSubmit}
          >
            주소 변환하기
            <IconDownloadFiles className="text-white" />
          </button>
        </div>
      </>
    ),
    processing: (
      <div className="flex w-full items-center justify-between">
        <div className="mr-4 flex items-center justify-center space-x-2">
          <IconTextWrong />
          <IconArrowRightXX className="text-gray-500" />
          <IconTextRight />
        </div>
        <span className="text-[16px] font-medium text-[#343D4B]">
          잘못된 주소정보는 다음과 같은{' '}
          <span className="text-blue-500">색상으로 하이라이팅</span>됩니다.
        </span>
        <div className="ml-auto flex">
          {Object.values(legend).map(([color, text], index) => (
            <div className="mr-4 flex items-center space-x-2" key={index}>
              <div
                className="size-3 rounded-full"
                style={{ backgroundColor: `#${color}` }}
              ></div>
              <span className="text-[14px] font-normal text-[#343D4B]">
                {text}
              </span>
            </div>
          ))}
        </div>
      </div>
    ),
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const timeOut = 300;
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [main_address, setMainAddress] = useState('');
  const [detail_address, setDetailAddress] = useState('');
  const handleConvert = () => {
    const address = input;
    backendApi.uploadOrders([{ address: address }]).then((res) => {
      setOutput(res.data[0].tried_data);
      setMainAddress(res.data[0].main_address);
      setDetailAddress(res.data[0].detail_address);
    });
  };
  const handleStateChange = (i) => {
    setShowContents(false);
    setTimeout(() => {
      if (i === 'start') {
        setFiles([]);
        setIsFinish(false);
      }
      setState(i);
      setShowContents(true);
    }, timeOut);
  };
  useBeforeUnload(
    state !== 'start'
      ? (event) => {
          event.preventDefault();
          return (event.returnValue =
            '파일이 저장되지 않습니다. 정말로 창을 닫으시겠습니까?');
        }
      : null
  );

  const texts = [
    [
      '인천광역시 미추홀구 405bungil inharo 11-28 201',
      '인천광역시 미추홀구 인하로405번길 11-28 201',
    ],
    [
      '세종 1202Ho 1908Dong, Paragon APT., 15, Balgeuntteul-ro, 세종특별시',
      '세종특별자치시 밝은뜰로 15 1202호 1908동 Paragon 아파트',
    ],
    [
      '아산시 37, Tangjeongmyeon-ro, Tangjeong-myeon, 아산시',
      '충청남도 아산시 탕정면 탕정면로 37 502-2101',
    ],
    [
      '경상남도 김해시 삼계동 1428-4 경남김해시삼계로232분성마을아이파크101동904호',
      '경상남도 김해시 삼계로 232 분성마을아이파크101동904호',
    ],
    [
      '아산 염치 방현리233-6 단독주택, , Republic of Korea',
      '충청남도 아산시 염치읍 충무로510번길 12-26 아산 염치 단독주택',
    ],
    [
      '경기도부천시부천로85번길.29.성미맨션.313호.부천시 심곡1동,97~27 313호',
      '경기도 부천시 원미구 부천로85번길 29 성미맨션 313호',
    ],
  ];

  return (
    <div className="w-full">
      <div className="min-h-screen">
        <div className="relative h-screen overflow-y-auto">
          <div className="relative flex h-screen snap-start flex-col items-center justify-between bg-white text-3xl">
            <img
              className="absolute top-0 w-full cursor-pointer object-cover"
              src={BannerLarge}
              alt="banner"
              onClick={() => navigate('/')}
            />
            <div className="flex size-full flex-col items-center justify-center pb-5 pt-[87px]">
              <div className="flex size-4/5 items-center justify-between">
                <div
                  className={`h-full overflow-hidden transition-all duration-1000 ease-in-out ${state !== 'start' ? 'w-0 opacity-0' : 'w-2/5 pr-20 opacity-100'}`}
                >
                  <div className="font-pretendard mx-auto flex h-full flex-col whitespace-nowrap text-[#343D4B]">
                    <img
                      onClick={() => navigate('/')}
                      src={Sweep}
                      alt="service icon"
                      className="mb-14 h-auto w-[95px]"
                    />
                    <div className="mb-[10px] space-y-4 font-bold sm:space-y-2">
                      <h1 className="mb-5 text-[48px]">주소 변환기</h1>
                    </div>

                    <div className="preserve-line-breaks flex h-full  flex-col text-[26px] font-medium">
                      <p className="max-w-[600px] ">
                        쇼핑몰에서{' '}
                        <span
                          onClick={() => setExampleModal(true)}
                          className="underline-animation text-blue-500"
                        >
                          오기입된 주소
                        </span>
                        ,
                      </p>
                      <p className="max-w-[600px] ">
                        스윕이 자동으로 변환해드릴게요.
                      </p>
                      <label
                        className={`mb-2 mt-[20px] flex h-[30px] w-[100px] cursor-pointer items-center  justify-center rounded-lg bg-blue-500 text-[14px] font-bold text-white transition duration-300 ease-in-out hover:bg-blue-600 `}
                        onClick={() => setExampleModal(true)}
                      >
                        체험하기
                      </label>
                    </div>
                    <div className="mt-auto">
                      <div className="mb-5 size-full overflow-hidden">
                        <ScrollingImages teamImages={images} />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`flex h-full flex-col justify-center p-2 transition-all duration-1000 ease-in-out ${state !== 'start' ? 'w-full' : 'w-3/5'}`}
                >
                  <div className="flex size-full flex-col items-center">
                    <CSSTransition
                      in={showContents}
                      timeout={timeOut}
                      classNames="animate-fadeInOut"
                      unmountOnExit
                      onExited={() => setShowContents(true)}
                    >
                      <div
                        className="mb-4 flex h-5/6 w-full flex-col items-center justify-between  rounded-[8px] bg-white transition duration-300 ease-in-out"
                        style={cardStyle}
                      >
                        {excelUploadContents[state]}
                      </div>
                    </CSSTransition>
                    <CSSTransition
                      in={showContents}
                      timeout={timeOut}
                      classNames="animate-fadeInOut"
                      unmountOnExit
                      onExited={() => setShowContents(true)}
                    >
                      <div
                        style={cardStyle}
                        className="flex h-1/6 w-full items-center space-x-5 overflow-hidden whitespace-nowrap rounded-[8px] bg-white  px-[40px] py-3 transition duration-300 ease-in-out"
                      >
                        {excelDescriptionContents[state]}
                      </div>
                    </CSSTransition>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex h-[10vh] w-full animate-bounce flex-col items-center justify-center pb-[70px]">
              <div
                className="hover:animate-shake group flex h-[40px] cursor-pointer items-center justify-center rounded-[20px] bg-blue-500 px-[20px] py-[9px] shadow-lg transition-transform duration-300"
                onClick={() => {
                  openUserOpinionModal();
                }}
              >
                <span className="text-bold-m text-[16px] text-white">
                  고객님의 소중한 의견을 들려주세요
                </span>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      {isModalOpen && (
        <NewModal
          backdropClick={true}
          header={error}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      {exampleModal && (
        <NewModal
          className="pl-5"
          header={'주소 변환 체험하기'}
          backdropClick={true}
          onClose={() => setExampleModal(false)}
        >
          <div className="w-full rounded-lg bg-white p-4 pr-10">
            <div className="mb-4 flex items-center">
              <input
                type="text"
                placeholder={texts[currentTextIndex][0]}
                value={input}
                onChange={(e) => setInput(e.target.value)}
                className="flex-1 rounded-lg border border-gray-300 p-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                onClick={handleConvert}
                className="ml-2 rounded-lg bg-blue-500 px-4 py-2 text-white shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                변환
              </button>
            </div>
            <div className="mb-4 flex items-center justify-center">
              <span className="text-2xl">↓</span>
            </div>
            <div>
              <input
                type="text"
                placeholder={texts[currentTextIndex][1]}
                value={output}
                readOnly
                className="w-full rounded-lg border border-gray-300 p-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="mt-2 flex w-full space-x-3">
              <span className="text-md flex items-center text-center font-bold">
                메인 주소
              </span>
              <input
                type="text"
                placeholder="메인 주소"
                value={main_address}
                readOnly
                className="w-full flex-1 rounded-lg border border-gray-300 p-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="mt-2 flex w-full space-x-3">
              <span className="text-md flex items-center text-center font-bold">
                상세 주소
              </span>
              <input
                type="text"
                placeholder="상세 주소"
                value={detail_address}
                readOnly
                className="w-full flex-1 rounded-lg border border-gray-300 p-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
        </NewModal>
      )}
      {state !== 'start' && (
        <div
          onClick={() => handleStateChange('start')}
          className="group fixed left-8 top-1/2 flex  cursor-pointer rounded-full bg-white p-4  shadow-md transition-transform duration-300 hover:scale-125"
        >
          <IconArrowRightXX className="-scale-x-100 text-gray-500" />
        </div>
      )}
    </div>
  );
};

export function openUserOpinionModal() {
  const overlayId = overlay.open(({ isOpen, close }) => (
    <UserOpinionModal open={isOpen} onClose={close} />
  ));

  return () => overlay.close(overlayId);
}

export default AddressValidScreen;
