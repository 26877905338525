import { forwardRef, useState } from 'react';
import Lottie from 'react-lottie';
import { Gift } from '@sweep/asset/lotties';
import { cva } from '@sweep/tailwind';
import { useABTest } from 'src/hooks/useABTest';
import {
  imageLandingPageFormLeft,
  imageLandingPageFormRight,
} from 'src/images';
import { preRegister } from 'src/network/user';
import { amplitude } from 'src/third-parties/amplitude';
import { toast } from 'src/third-parties/toast';
import { Checkbox } from '../../componenets/Checkbox';

interface PreRegistrationFormProps {
  hasEnoughHeight: boolean;
}

function PreRegistrationForm(
  { hasEnoughHeight }: PreRegistrationFormProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const userGroup = useABTest('landingPage');

  const [companyName, setCompanyName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [isAgeChecked, setIsAgeChecked] = useState<boolean>(false);
  const [isTermsChecked, setIsTermsChecked] = useState<boolean>(false);
  const [isPrivacyChecked, setIsPrivacyChecked] = useState<boolean>(false);

  const handleCompanyNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCompanyName(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const toggleAll = () => {
    if (isAgeChecked && isTermsChecked && isPrivacyChecked) {
      setIsAgeChecked(false);
      setIsTermsChecked(false);
      setIsPrivacyChecked(false);
    } else {
      setIsAgeChecked(true);
      setIsTermsChecked(true);
      setIsPrivacyChecked(true);
    }
  };

  const toggleIsAgeChecked = () => {
    setIsAgeChecked(!isAgeChecked);
  };

  const toggleIsTermsChecked = () => {
    setIsTermsChecked(!isTermsChecked);
  };

  const toggleIsPrivacyChecked = () => {
    setIsPrivacyChecked(!isPrivacyChecked);
  };

  const resetForm = () => {
    setCompanyName('');
    setEmail('');
    setIsAgeChecked(false);
    setIsTermsChecked(false);
    setIsPrivacyChecked(false);
  };

  const handleSubmit = async () => {
    try {
      const response = await preRegister({
        from: '신규 랜딩페이지',
        companyName,
        email,
      });
      if (response.status === 200) {
        resetForm();
        amplitude.track('[LP-B] Submit', {
          group: userGroup,
          companyName,
        });
        window.gtag_report_conversion();
        toast.success('성공적으로 접수되었습니다.');
        return;
      }
      toast.error('오류가 발생하였습니다. 다시 시도해주세요.');
    } catch (error) {
      toast.error('오류가 발생하였습니다. 다시 시도해주세요.');
    }
  };

  return (
    <div
      ref={ref}
      className="flex h-screen w-full items-center justify-center pt-[80px]"
    >
      <div className={container({ hasEnoughHeight })}>
        <div className={textContainer({ hasEnoughHeight })}>
          <p className="text-[32px] font-medium leading-[48px] tracking-tight text-gray-600">
            도입 및 세팅 과정이 번거로울까봐 걱정이신가요?
          </p>
          <p className="text-[48px] font-bold leading-[64px] tracking-tight">
            <span className="bg-gradient-to-r from-[#1891FF] to-[#0F53FF] bg-clip-text text-transparent">
              스윕
            </span>
            이 전부 세팅해드립니다!
          </p>
        </div>
        <div className="flex gap-[16px]">
          <div className="relative">
            <img
              src={imageLandingPageFormLeft}
              alt="landing-page-form-left"
              className="h-[570px] w-[450px]"
            />
            <div
              className="absolute left-1/2 top-1/2 h-[474px] w-[354px] -translate-x-1/2 -translate-y-1/2 gap-[22px] rounded-[18px] border
            border-white bg-gradient-to-b from-[rgba(255,255,255,0.96)] to-[rgba(255,255,255,0.69)] px-[40px] pb-[40px] pt-[22px]"
            >
              <Lottie
                width="274px"
                height="274px"
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: Gift,
                }}
                style={{ pointerEvents: 'none' }}
              />
              <div className="flex flex-col gap-[16px] text-left text-[16px] font-medium tracking-tighter">
                <div className="flex items-start gap-[12px]">
                  <p className="w-[40px] text-blue-500">혜택 1</p>
                  <p className="w-[222px]">
                    상반기 정식 출시 후 4개월 간 무료로 사용하실 수 있습니다.
                    <br />
                    <span className="text-[14px] text-gray-600">
                      *기본 1개월 + 1년 구독 시 3개월
                    </span>
                  </p>
                </div>
                <div className="flex items-start gap-[12px]">
                  <p className="w-[40px] text-blue-500">혜택 2</p>
                  <p className="w-[222px]">
                    추첨을 통해 평생 무료 이용권을 받으실 수 있습니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="relative">
            <img
              src={imageLandingPageFormRight}
              alt="landing-page-form-right"
              className="h-[570px] w-[718px]"
            />
            <div className="absolute left-1/2 top-1/2 flex h-[474px] w-[622px] -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-[18px] border border-white bg-gradient-to-b from-[rgba(255,255,255,0.96)] to-[rgba(255,255,255,0.69)] p-[40px] text-[16px] font-medium leading-[21px] text-gray-700">
              <div className="flex h-[394px] w-[542px] flex-col justify-between">
                <div className="flex w-full flex-col gap-[24px]">
                  <div className="flex h-[40px] w-full items-center gap-[32px]">
                    <p className="w-[54px]">
                      회사명 <span className="text-orange-500">*</span>
                    </p>
                    <input
                      type="text"
                      value={companyName}
                      onChange={handleCompanyNameChange}
                      placeholder="회사 이름을 입력해주세요."
                      className="h-[40px] grow rounded-[8px] border border-gray-300 bg-white px-[16px] focus:outline-blue-500"
                    />
                  </div>
                  <div className="flex h-[40px] w-full items-center gap-[32px]">
                    <p className="w-[54px]">
                      이메일 <span className="text-orange-500">*</span>
                    </p>
                    <input
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                      placeholder="업무용 이메일을 입력해주세요."
                      className="h-[40px] grow rounded-[8px] border border-gray-300 bg-white px-[16px] focus:outline-blue-500"
                    />
                  </div>
                </div>
                <div className="flex w-full flex-col items-start gap-[16px]">
                  <div className="flex gap-[8px]" onClick={toggleAll}>
                    <Checkbox
                      checked={
                        isAgeChecked && isTermsChecked && isPrivacyChecked
                      }
                    />
                    <p>아래 항목을 모두 확인하였습니다.</p>
                  </div>
                  <hr className="w-full" />
                  <div className="flex gap-[8px]" onClick={toggleIsAgeChecked}>
                    <Checkbox checked={isAgeChecked} />
                    <p>[필수] 만 14세 이상입니다.</p>
                  </div>
                  <div
                    className="flex gap-[8px]"
                    onClick={toggleIsTermsChecked}
                  >
                    <Checkbox checked={isTermsChecked} />
                    <p>
                      [필수]{' '}
                      <a
                        href="https://sweepoms.notion.site/17a06aede8af80d88175e4951006195f?pvs=4"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span
                          className="cursor-pointer text-blue-500"
                          onClick={(e) => e.stopPropagation()}
                        >
                          사전신청 유의사항
                        </span>
                      </a>
                      을 확인하였습니다.
                    </p>
                  </div>
                  <div
                    className="flex gap-[8px]"
                    onClick={toggleIsPrivacyChecked}
                  >
                    <Checkbox checked={isPrivacyChecked} />
                    <p>
                      [필수]{' '}
                      <a
                        href="https://sweepoms.notion.site/587bc67c6247485eab6b6972bef1a586?pvs=4"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span
                          className="cursor-pointer text-blue-500"
                          onClick={(e) => e.stopPropagation()}
                        >
                          개인정보 처리방침
                        </span>
                      </a>
                      을 확인하였습니다.
                    </p>
                  </div>
                </div>
                <button
                  disabled={isButtonDisabled({
                    companyName,
                    email,
                    isAgeChecked,
                    isTermsChecked,
                    isPrivacyChecked,
                  })}
                  className={button({
                    disabled: isButtonDisabled({
                      companyName,
                      email,
                      isAgeChecked,
                      isTermsChecked,
                      isPrivacyChecked,
                    }),
                  })}
                  onClick={handleSubmit}
                >
                  사전신청하기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const isButtonDisabled = ({
  companyName,
  email,
  isAgeChecked,
  isTermsChecked,
  isPrivacyChecked,
}: {
  companyName: string;
  email: string;
  isAgeChecked: boolean;
  isTermsChecked: boolean;
  isPrivacyChecked: boolean;
}) => {
  return (
    companyName.length === 0 ||
    email.length === 0 ||
    !isAgeChecked ||
    !isTermsChecked ||
    !isPrivacyChecked
  );
};

const container = cva('flex flex-col', {
  variants: {
    hasEnoughHeight: {
      true: 'gap-[60px]',
      false: 'gap-[20px]',
    },
  },
});

const textContainer = cva('flex flex-col items-start', {
  variants: {
    hasEnoughHeight: {
      true: ' gap-[8px]',
      false: 'gap-0',
    },
  },
});

const button = cva(
  'h-[49px] w-full rounded-[8px] px-[22px] py-[14px] text-[18px] font-bold tracking-tight text-white',
  {
    variants: {
      disabled: {
        true: 'bg-gray-300',
        false: 'bg-gradient-to-r from-[#1891FF] to-[#0E53FF]',
      },
    },
  }
);

export default forwardRef(PreRegistrationForm);
