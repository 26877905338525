import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

export function useABTest(testName: string) {
  const [userGroup, setUserGroup] = useState<'A' | 'B' | null>(null);

  useEffect(() => {
    const cookieName = `sweep-${testName}-group`;
    let group: 'A' | 'B' | null = Cookies.get(cookieName) as 'A' | 'B' | null;

    if (group == null) {
      group = Math.random() < 0.5 ? 'A' : 'B';
      Cookies.set(cookieName, group, { expires: 365 });
    }

    setUserGroup(group);
  }, []);

  return userGroup;
}
