import { groupBy } from 'es-toolkit';
import { NormalizedOrder } from '@sweep/contract';
import {
  SHIPPING_ORDER_PROCESS_STATUS_CODE_MESSAGE,
  ShippingOrderProcessStatusCode,
} from '@sweep/domain/services/order-shipping-process';
import { ProcessingItem } from '../processors/processor.type';

export function getFailedShippingOrderWithReason(
  processedItems: ProcessingItem[]
): (NormalizedOrder & { failReason: string })[] {
  const DEFAULT_FAIL_REASON = '알 수 없는 오류가 발생했습니다.';

  const failedItems = processedItems.filter(
    (item) => item.isProcessable === false
  );

  const failedItemGroupByIndex = groupBy(failedItems, (item) => item.index);

  const mergedFailedItems = Object.entries(failedItemGroupByIndex).map(
    ([_, items]) => {
      const lastHistories = items.map(
        (item) => item.histories[item.histories.length - 1]
      );

      const failReasons = lastHistories.map(
        (history) =>
          SHIPPING_ORDER_PROCESS_STATUS_CODE_MESSAGE?.[
            history as ShippingOrderProcessStatusCode
          ] ?? DEFAULT_FAIL_REASON
      );

      const mergedFailReason = failReasons.join(', ');

      const shippingOrder = items?.[0]?.value;

      return {
        ...shippingOrder,
        failReason: mergedFailReason,
      };
    }
  );

  return mergedFailedItems;
}
