import { action, computed, makeObservable, observable } from 'mobx';
import { toast } from 'sonner';
import { NormalizedOrder } from '@sweep/contract';
import {
  createUniqueCode,
  createUniqueCodeSubV2,
} from '@sweep/domain/services/order';
import { findAllUniqueCodeFromShippingOrders } from '@sweep/domain/services/order-shipping-process';
import {
  formatDate,
  getUnusedFilename,
  isContainArray,
  isNotNil,
} from '@sweep/utils';
import {
  replaceAllOrders,
  replaceNormalizedOrders,
} from 'src/network/order/order';
import { readExcelMigration } from 'src/services/file/excel/readExcel-migration';
import { OMSStore } from 'src/stores/OMSStore';
import { AddSupplierByNamePlugin } from 'src/stores/plugin/features/add-supplier-by-name';
import { amplitude } from 'src/third-parties/amplitude';
import { isEmptyString, isNotEmptyString } from 'src/utils/string';
import { openConfirmRevertShippingOrders } from '../components/dialogs/openConfirmRevertShippingOrders';
import { openHandwrittenUploadWithoutUniqueCode } from '../components/dialogs/openHandwrittenUploadWithoutUniqueCode';
import { getRemainedOrdersByUniqueCode } from '../services/getRemainedOrdersByUniqueCode';
import { getRemainedOrdersByUniqueCodeAfterCustomization } from '../services/getRemainedOrdersByUniqueCodeAfterCustomization';
import { isShippingOrderStatusProcessing } from '../services/isShippingOrderStatusProcessing';
import { transformToOrdersWithoutUniqueCode } from '../services/transformToOrderWithoutUniqueCode';

export class OrderShippingProcessingStore {
  selectedOrders: NormalizedOrder[] = [];

  get shippingStatusProcessingOrders() {
    return this.oms.order.normalizedOrders.filter(
      isShippingOrderStatusProcessing
    );
  }

  get purchaseOrderFilenames() {
    const totalPurchaseOrderFilenames = this.oms.order.normalizedOrders
      .map((order) => order.purchaseOrderFile)
      .filter(isNotNil);

    const purchaseOrderFilenames = Array.from(
      new Set(totalPurchaseOrderFilenames)
    );

    const purchaseOrderFilenamesByDesc = purchaseOrderFilenames.sort((a, b) =>
      b.localeCompare(a)
    );

    return purchaseOrderFilenamesByDesc;
  }

  constructor(private oms: OMSStore) {
    makeObservable(this, {
      selectedOrders: observable,
      purchaseOrderFilenames: computed,
      shippingStatusProcessingOrders: computed,
      setSelectedOrders: action.bound,
    });
  }

  setSelectedOrders = (orders: NormalizedOrder[]) => {
    this.selectedOrders = orders;
  };

  revertToPending = async (purchaseOrderFilenames: string[]) => {
    const purchaseOrderFilenameSet = new Set(purchaseOrderFilenames);
    const orders = this.oms.order.normalizedOrders.filter(
      (order) =>
        order.purchaseOrderFile != null &&
        purchaseOrderFilenameSet.has(order.purchaseOrderFile)
    );

    if (orders.some((order) => isNotEmptyString(order.shippingNumber))) {
      toast.error('운송장이 등록된 주문이 포함되어 되돌릴 수 없습니다.');
      return;
    }

    const isConfirmed = await openConfirmRevertShippingOrders(orders.length);
    if (!isConfirmed) {
      return;
    }

    const remainedOrders = getRemainedOrdersByUniqueCodeAfterCustomization(
      this.oms.order.normalizedOrders,
      orders.map((order) => order.uniqueCodeAfterCustomization)
    );
    const pendingOrders = orders.map<NormalizedOrder>((order) => ({
      ...order,
      shippingStatus: 'pending',
      purchaseOrderFile: null,
    }));
    const updatedOrders = [...remainedOrders, ...pendingOrders];

    await this.oms.loading.batch(() => replaceNormalizedOrders(updatedOrders));
    this.oms.order.setNormalizedOrders(updatedOrders);
    toast.success('주문이 발주 대기 상태로 이동되었습니다.');
  };

  downloadPurchaseOrder = (filenames: string[]) => {
    const ordersByFilename: Record<string, NormalizedOrder[]> = {};
    this.oms.order.normalizedOrders.forEach((order) => {
      if (order.purchaseOrderFile == null) {
        return;
      }

      if (ordersByFilename[order.purchaseOrderFile] == null) {
        ordersByFilename[order.purchaseOrderFile] = [order];
        return;
      }

      ordersByFilename[order.purchaseOrderFile].push(order);
    });

    const totalSelectedOrderCount = filenames.flatMap(
      (filename) => ordersByFilename[filename]
    )?.length;

    if (totalSelectedOrderCount === 0) {
      toast.error('선택된 주문이 없습니다.');
      return;
    }

    filenames.forEach((filename) => {
      const orders = ordersByFilename[filename];
      if (orders == null) {
        return;
      }

      const downloadPurchaseOrder = this.oms.user.isRetailer
        ? this.oms.order.file.downloadRetailerPurchaseOrder
        : this.oms.order.file.downloadSupplierPurchaseOrder;
      downloadPurchaseOrder(orders, filename);
    });
  };

  uploadHandwrittenFile = async (files: FileList) => {
    const parsedFiles = (
      await Promise.all(Array.from(files).map(readExcelMigration))
    ).filter(isNotNil);

    const headersList = parsedFiles.map((parsedFile) => parsedFile?.data.at(0));

    const isNotEqualHeadersWithUserExcel = headersList.some(
      (headers) =>
        headers == null || !isContainArray(this.oms.user.excelHeaders, headers)
    );
    if (isNotEqualHeadersWithUserExcel) {
      toast.error('통합 엑셀 양식에 맞지 않는 파일입니다. 다시 확인해주세요.');
      return;
    }

    const ordersWithoutUniqueCode = parsedFiles.flatMap((parsedFile) =>
      transformToOrdersWithoutUniqueCode(this.oms, parsedFile)
    );
    const isExistEmptyUniqueCode = ordersWithoutUniqueCode.some((order) =>
      isEmptyString(order.uniqueCode)
    );
    if (isExistEmptyUniqueCode) {
      const isConfirmed = await openHandwrittenUploadWithoutUniqueCode();
      if (!isConfirmed) {
        return;
      }
    }

    const filename = formatDate(new Date(), 'yy.MM.dd 발주서 (HHmm)');
    const usedFilenames = this.purchaseOrderFilenames;
    const unusedFilename = getUnusedFilename(filename, usedFilenames);

    const normalizedOrders = ordersWithoutUniqueCode.map<NormalizedOrder>(
      (order) => ({
        ...order,
        process: 'shipping',
        shippingStatus: 'processing',
        purchaseOrderFile: unusedFilename,
        uniqueCode: order.uniqueCode ?? createUniqueCode(),
        uniqueCodeAfterCustomization: createUniqueCodeSubV2(order),
      })
    );

    const filenameByUniqueCode = new Map(
      normalizedOrders.map((order) => [order.uniqueCode, order.originFile])
    );

    const updatedMergedOrders = this.oms.order.mergedOrders.map((order) => ({
      ...order,
      originFile:
        filenameByUniqueCode.get(order.uniqueCode) ?? order.originFile,
    }));

    const updatedMergedOrdersKeys =
      findAllUniqueCodeFromShippingOrders(updatedMergedOrders);
    const uniqueUpdatedMergedOrdersKeys = Array.from(
      new Set(updatedMergedOrdersKeys)
    );

    const remainedOrders = getRemainedOrdersByUniqueCode(
      this.oms.order.normalizedOrders,
      uniqueUpdatedMergedOrdersKeys
    );

    const supplierAddedOrders = (await new AddSupplierByNamePlugin(
      this.oms,
      undefined
    ).transform(normalizedOrders)) as NormalizedOrder[];
    const transformedOrders =
      await this.oms.order._divide.transform(supplierAddedOrders);

    const updatedNormalizedOrders = [...transformedOrders, ...remainedOrders];

    const isSomeRemoved =
      remainedOrders.length !== this.oms.order.normalizedOrders.length;
    isSomeRemoved
      ? toast.success('동일한 주문으로 인식된 기존 주문이 삭제되었습니다.')
      : toast.success('발주서가 생성되었습니다.');

    this.oms.order.setMergedOrders(updatedMergedOrders);
    this.oms.order.setNormalizedOrders(updatedNormalizedOrders);
    await this.oms.loading.batch(() =>
      replaceAllOrders(updatedMergedOrders, updatedNormalizedOrders)
    );
    this.oms.order.file.downloadRetailerPurchaseOrder(
      transformedOrders,
      unusedFilename
    );
    amplitude.track('Upload Purchase-order');
  };
}
