import copy from 'fast-copy';
import { action, computed, makeObservable, observable } from 'mobx';
import { CreateProductDTO, Unit } from '@sweep/contract';
import { getUniform } from '@sweep/utils';
import { OMSStore } from 'src/stores/OMSStore';
import { isEmptyString } from 'src/utils/string';
import { SalesChannelsSupplierMatching } from './interface';
import { getSalesChannelSupplierMatchings } from './services/getSalesChannelsSupplierMatchings';

export class ProductFormStore<T extends CreateProductDTO = CreateProductDTO> {
  readonly initialProductName: string;
  product: T;
  salesChannelSupplierMatchings: SalesChannelsSupplierMatching[] = [];
  showSupplierError: boolean = false;

  get productName() {
    return this.product.productName;
  }

  get options() {
    return this.product.units ?? [];
  }

  get supplierId() {
    return this.product.supplierId;
  }

  get useSupplierByOption() {
    return this.product.useSupplierByOption ?? false;
  }

  get useSupplierBySalesChannel() {
    return this.product.useSupplierBySalesChannel ?? false;
  }

  get isDuplicatedProductName() {
    const productNames = this.oms.product.productNames;
    if (isEmptyString(this.productName)) {
      return false;
    }
    if (this.initialProductName === this.productName) {
      return false;
    }

    return productNames.includes(this.productName);
  }

  get isExistEmptySalesChannel() {
    return this.salesChannelSupplierMatchings.some(
      (matching) =>
        matching.salesChannels.length === 0 && matching.supplierId != null
    );
  }

  constructor(
    private oms: OMSStore,
    product: T
  ) {
    this.product = copy(product);
    this.initialProductName = product.productName;
    this.salesChannelSupplierMatchings = getSalesChannelSupplierMatchings(
      this.product.salesChannelSupplierIds ?? []
    );

    makeObservable(this, {
      product: observable,
      salesChannelSupplierMatchings: observable,
      showSupplierError: observable,

      productName: computed,
      options: computed,
      supplierId: computed,
      useSupplierByOption: computed,
      useSupplierBySalesChannel: computed,
      isDuplicatedProductName: computed,
      isExistEmptySalesChannel: computed,

      setProduct: action.bound,
      setSalesChannelSupplierMatchings: action.bound,
      setShowSupplierError: action.bound,

      setProductName: action.bound,
      setOptions: action.bound,
      setSupplierId: action.bound,
      toggleUseSupplierByOption: action.bound,
      toggleUseSupplierBySalesChannel: action.bound,
    });
  }

  setProduct(product: T) {
    this.product = product;
  }

  setSalesChannelSupplierMatchings(
    salesChannelSupplierMatchings: SalesChannelsSupplierMatching[]
  ) {
    this.salesChannelSupplierMatchings = salesChannelSupplierMatchings;
  }

  setShowSupplierError(showSupplierError: boolean) {
    this.showSupplierError = showSupplierError;
  }

  setProductName(productName: string) {
    this.product.productName = productName;
  }

  setOptions(options: Unit[]) {
    this.product.units = options;
  }

  setSupplierId(supplierId: string | null) {
    this.product.supplierId = supplierId;
  }

  toggleUseSupplierByOption() {
    const prevValue = this.useSupplierByOption;
    if (prevValue) {
      const optionSupplierIds = this.product.units?.map(
        (option) => option.supplierId
      );
      const uniformSupplierId = getUniform(optionSupplierIds ?? []);

      this.product = {
        ...this.product,
        supplierId: uniformSupplierId,
        useSupplierByOption: false,
      };

      return;
    }

    const supplierId = this.product.supplierId;
    this.product = {
      ...this.product,
      units: this.product.units?.map((option) => ({
        ...option,
        supplierId,
      })),
      useSupplierByOption: true,
      useSupplierBySalesChannel: false,
    };
  }

  toggleUseSupplierBySalesChannel() {
    const prevValue = this.useSupplierBySalesChannel;
    if (prevValue) {
      this.product = {
        ...this.product,
        useSupplierBySalesChannel: false,
      };

      return;
    }

    const supplierId = this.useSupplierByOption
      ? getUniform(this.options.map((option) => option.supplierId))
      : this.supplierId;

    this.salesChannelSupplierMatchings = [
      { salesChannels: [], supplierId: null },
    ];
    this.product = {
      ...this.product,
      supplierId,
      useSupplierBySalesChannel: true,
      useSupplierByOption: false,
    };
  }
}
