import { useState } from 'react';
import { IconXDeleteModal } from '@sweep/asset/icons';
import { Button, Modal } from '@sweep/sds';
import { cva } from '@sweep/tailwind';
import backendApi from '../../../utils/backendApi';

interface UserOpinionModalProps {
  open: boolean;
  onClose: () => void;
}

function UserOpinionModal({ open, onClose }: UserOpinionModalProps) {
  const [csText, setCsText] = useState<string>('');
  const [contact, setContact] = useState<string>('');
  const [csTextErrorMessage, setCsTextErrorMessage] = useState<string | null>(
    null
  );
  const [contactErrorMessage, setContactErrorMessage] = useState<string | null>(
    null
  );

  const handleCsText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCsTextErrorMessage(null);
    setCsText(e.target.value);
  };

  const handleContact = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContactErrorMessage(null);
    setContact(e.target.value);
  };

  const validate = (): boolean => {
    const phonePattern = /^[0-9][0-9-]+[0-9]$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (csText === '') {
      setCsTextErrorMessage('의견을 입력해주세요.');
      setContactErrorMessage(null);
      return false;
    }
    if (contact === '') {
      setContactErrorMessage('연락 받을 이메일이나 전화번호를 입력해주세요.');
      return false;
    }
    if (!phonePattern.test(contact) && !emailPattern.test(contact)) {
      setContactErrorMessage('올바른 이메일/전화번호 형식으로 입력해주세요.');
      return false;
    }
    return true;
  };

  const handleCs = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    try {
      await backendApi.customerOpinionRegister({
        name: '',
        email: contact,
        opinion: csText,
      });
      window.alert('의견을 남겨주셔서 감사드립니다. 빠르게 답변드리겠습니다.');
      onClose();
    } catch (error) {
      window.alert('의견 전송에 실패했습니다. 다시 시도해주세요.');
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      dismiss={{ outsidePress: true, escapeKey: true }}
      className={modal({
        isError: csTextErrorMessage != null || contactErrorMessage != null,
      })}
    >
      <div className="flex flex-col items-center">
        <div className="flex w-full items-center justify-between">
          <div className="flex h-[48px] items-center">
            <h1 className="text-extrabold-l">
              고객님의 소중한 의견을 들려주세요.
            </h1>
          </div>
          <button
            onClick={() => {
              onClose();
            }}
          >
            <IconXDeleteModal className="text-gray-700" />
          </button>
        </div>
        <div className="gap-12px pt-8px flex w-full flex-col items-center">
          <textarea
            className={csTextarea({
              isCsTextError: csTextErrorMessage != null,
            })}
            onChange={handleCsText}
            value={csText}
            placeholder="의견을 입력해주세요"
          />
          <div className="gap-12px flex w-full">
            <span className="text-md text-semibold flex flex-col justify-center whitespace-nowrap text-neutral-900">
              연락 받을 곳
            </span>
            <input
              className={contactInput({
                isContactError: contactErrorMessage != null,
              })}
              type="text"
              value={contact}
              onChange={handleContact}
              placeholder="전화번호 혹은 이메일"
            />
            <Button
              color={csText !== '' && contact !== '' ? 'blue' : 'gray'}
              onClick={handleCs}
            >
              의견 남기기
            </Button>
          </div>
          {(csTextErrorMessage != null || contactErrorMessage != null) && (
            <div className="flex w-full justify-center">
              <p className="text-semibold-m text-red-600">
                {csTextErrorMessage ?? contactErrorMessage}
              </p>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

const modal = cva('px-32px pt-32px', {
  variants: {
    isError: {
      true: '',
      false: 'pb-32px',
    },
  },
});

const csTextarea = cva(
  'h-60 w-full resize-none rounded-lg border border-gray-300 p-4 outline-none focus:outline-none',
  {
    variants: {
      isCsTextError: {
        true: 'ring-2 ring-red-500',
        false:
          'focus:shadow-outline transition-all duration-200 ease-in-out hover:ring-2 hover:ring-blue-400 focus:ring-2 focus:ring-blue-500',
      },
    },
  }
);

const contactInput = cva(
  'h-[42px] w-full appearance-none rounded-lg border border-gray-300 p-4 leading-tight text-neutral-900 focus:outline-none',
  {
    variants: {
      isContactError: {
        true: 'ring-2 ring-red-500',
        false:
          'focus:shadow-outline transition-all duration-200 ease-in-out hover:ring-2 hover:ring-blue-400 focus:ring-2 focus:ring-blue-500',
      },
    },
  }
);

export default UserOpinionModal;
