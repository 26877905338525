import { formatDate } from '@sweep/utils';
import { Partner } from 'src/models/Partner';
import { createOrderExcelBuffer } from 'src/services/file/excel/create/createOrderExcelBuffer';
import { OMSStore } from 'src/stores/OMSStore';
import { addMissingRequiredColumns } from './addMissingRequiredColumns';
import { AdvancedShippingNoticeDocument } from './createAdvancedShippingNoticeDocuments';

export async function createExcelFromAdvancedShippingNoticeDocumentForMall(
  AdvancedShippingNoticeDocument: AdvancedShippingNoticeDocument,
  partners: Partner[],
  oms: OMSStore
): Promise<
  | {
      isSuccess: true;
      buffer: Buffer;
      filename: string;
    }
  | {
      isSuccess: false;
      failedReason: string;
    }
> {
  // const firstSampledOrder = AdvancedShippingNoticeDocument.orders[0];

  try {
    const userExcelSetting = await oms.user.setting;

    const userColumnKeys = userExcelSetting?.columnOrder ?? [];
    const userColumnMapping = userExcelSetting?.columnTranslation ?? {};
    const userColumnNames =
      userExcelSetting?.columnOrder.map(
        (key) => userColumnMapping?.[key] || key
      ) ?? [];

    const filename = getFilename(AdvancedShippingNoticeDocument.documentName);
    const { columnNames, columnMapping } = addMissingRequiredColumns(
      userColumnKeys,
      userColumnNames,
      userColumnMapping
    );

    const buffer = await createOrderExcelBuffer(
      oms,
      AdvancedShippingNoticeDocument.orders,
      filename,
      columnNames,
      columnMapping
    );

    return {
      isSuccess: true,
      buffer: buffer as Buffer,
      filename,
    };
  } catch (e) {
    const errorMessage = e instanceof Error ? e.message : 'Unknown error';
    return {
      isSuccess: false,
      failedReason: errorMessage,
    };
  }
}

const getFilename = (documentName: string) => {
  const formattedDate = formatDate(new Date(), 'yy.MM.dd');

  return `${formattedDate} ${documentName} 운송장첨부.xlsx`;
};
