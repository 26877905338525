export const HeaderMatchReasonType = {
  SUPPLIER_EXACT_MATCH: 'SUPPLIER_EXACT_MATCH',
  SUPPLIER_PARTIAL_MATCH: 'SUPPLIER_PARTIAL_MATCH',
  TEMPLATE_MATCH: 'TEMPLATE_MATCH',
  USER_SETTING_MATCH: 'USER_SETTING_MATCH',
  USER_SPREADSHEET_HEADER_MAPPING_EXACT_MATCH:
    'USER_SPREADSHEET_HEADER_MAPPING_EXACT_MATCH',
  USER_SPREADSHEET_HEADER_MAPPING_PARTIAL_MATCH:
    'USER_SPREADSHEET_HEADER_MAPPING_PARTIAL_MATCH',
  DEFAULT_MATCH: 'DEFAULT_MATCH',
} as const;

export type HeaderMatchReason =
  (typeof HeaderMatchReasonType)[keyof typeof HeaderMatchReasonType];
