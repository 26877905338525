import { Order } from '@sweep/contract';
import { SHIPPING_ORDER_PROCESS_STATUS_CODE } from '@sweep/domain/services/order-shipping-process';
import { bulkUpdateOriginalOrder } from 'src/network/order-shipping-process/bulkUpdateOriginalOrder';
import { FailedOriginalOrderWithStatusCode } from '../types';

export async function requestManualDispatchStep(orders: Order[]): Promise<{
  successOrders: Order[];
  failedOrders: FailedOriginalOrderWithStatusCode<Order>[];
}> {
  if (orders.length === 0) {
    return {
      successOrders: [],
      failedOrders: [],
    };
  }

  try {
    const updateOrderAPIResult = await bulkUpdateOriginalOrder({
      orders,
    });

    const successOrders = updateOrderAPIResult
      .filter((result) => result.isSuccess)
      .map((result) => result.data);

    const failedOrders = updateOrderAPIResult
      .filter((result) => !result.isSuccess)
      .map((result) => ({
        originalOrder: result.data,
        statusCode: result.statusCode,
      }));

    return {
      successOrders,
      failedOrders,
    };
  } catch (error) {
    return {
      successOrders: [],
      failedOrders: orders.map((order) => ({
        originalOrder: order,
        statusCode:
          SHIPPING_ORDER_PROCESS_STATUS_CODE.STEP_MANUAL_DISPATCH_FAILED_UNKNOWN_ERROR,
      })),
    };
  }
}
