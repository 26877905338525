import { IconCircleDeleteSmall } from '@sweep/asset/icons';
import { observer } from 'mobx-react-lite';
import { Partner } from 'models/Partner';
import { useId } from 'react';

interface PartnerInfoInputProps {
  partner: Partial<Partner>;
  onChange: (partner: Partial<Partner>) => void;
}

function PartnerInfoInput({ partner, onChange }: PartnerInfoInputProps) {
  return (
    <div className="grid grid-cols-3 gap-6">
      <Input
        type="text"
        label="회사명"
        value={partner.name ?? ''}
        onChange={(name) => onChange({ ...partner, name })}
      />
      <Input
        type="email"
        label="메일 주소"
        value={partner.email ?? ''}
        onChange={(email) => onChange({ ...partner, email })}
      />
      <Input
        type="tel"
        label="전화번호"
        value={partner.phone ?? ''}
        onChange={(phone) => onChange({ ...partner, phone })}
      />
    </div>
  );
}

export default observer(PartnerInfoInput);

interface InputProps {
  type: 'text' | 'email' | 'tel';
  label: string;
  value: string;
  onChange: (value: string) => void;
}

function Input({ type, label, value, onChange }: InputProps) {
  const id = useId();

  return (
    <div>
      <label
        htmlFor={id}
        className="font-pretendard text-base font-medium not-italic leading-[21px] text-gray-600"
      >
        {label}
      </label>
      <div className="relative">
        <input
          type={type}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          className="mt-[8px] block h-[40px] w-full rounded-lg border border-gray-300 bg-white px-[16px] text-sm
      font-medium not-italic text-gray-600
      shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500"
        />
        {value && (
          <div
            className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-[16px]"
            onClick={() => onChange('')}
          >
            <IconCircleDeleteSmall />
          </div>
        )}
      </div>
    </div>
  );
}
