import { isFunction, isNil, isNotNil } from 'es-toolkit';
import { NormalizedOrder, Order } from '@sweep/contract';
import { OMSStore } from 'src/stores/OMSStore';
import { createLogger } from 'src/third-parties/createLogger';
import { toast } from 'src/third-parties/toast';
import { splitAndDownloadPurchaseOrder } from '../split-and-download-purchase-order/splitAndDownloadPurchaseOrder';
import { downloadFailReasonStep } from './steps/downloadFailReasonStep';
import { requestAutoDispatchStep } from './steps/requestAutoDispatchStep';
import { requestManualDispatchStep } from './steps/requestManualDispatchStep';
import { requestShippingOrderStatusUpdateStep } from './steps/requestShippingOrderStatusUpdateStep';
import { validateAndPrepareStep } from './steps/validateAndPrepareStep';

const debug = createLogger('services:process-shipping-order-to-shipped');

export async function processShippingOrderToShipped(params: {
  shippingOrders: NormalizedOrder[];
  oms: OMSStore;
  showErrorMessage?: (message: string) => void;
}) {
  const { shippingOrders, oms, showErrorMessage } = params;
  const isShowErrorEnabled = isFunction(showErrorMessage);

  debug('운송장 등록 프로세스 시작');

  const {
    updateValidShippingOrders,
    updateValidOriginalOrders,
    updateInvalidShippingOrders,
  } = await validateAndPrepareStep({
    originalOrders: oms.order.mergedOrders,
    shippingOrders,
  });

  debug('step 1: 운송장 등록 대상 주문 검증 완료', {
    updateValidShippingOrders,
    updateValidOriginalOrders,
    updateInvalidShippingOrders,
  });

  const autoDispatchRequiredOriginalOrders = updateValidOriginalOrders.filter(
    isAutoDispatchRequired
  );

  const autoDispatchSkippedOriginalOrders = updateValidOriginalOrders.filter(
    isAlreadyAutoDispatch
  );

  const manualDispatchOriginalOrders =
    updateValidOriginalOrders.filter(isManualDispatch);

  const {
    successOrders: autoDispatchSuccessOrders,
    failedOrders: autoDispatchFailedOrders,
  } = await requestAutoDispatchStep(autoDispatchRequiredOriginalOrders);

  const isShowRequestAutoDispatchStepSuccessToast =
    checkShowRequestAutoDispatchStepSuccess({
      requestCount: autoDispatchRequiredOriginalOrders?.length ?? 0,
      successCount: autoDispatchSuccessOrders?.length ?? 0,
      failedCount: autoDispatchFailedOrders?.length ?? 0,
    });
  if (isShowRequestAutoDispatchStepSuccessToast) {
    toast.success('쇼핑몰에 운송장이 등록되었습니다.');
  }

  // 여기서 local store 업데이트: mergedOrders

  debug('step 2: 쇼핑몰 운송장 자동 등록 완료', {
    total: autoDispatchRequiredOriginalOrders.length,
    success: autoDispatchSuccessOrders.length,
    failed: autoDispatchFailedOrders.length,
  });

  const {
    successOrders: manualDispatchSuccessOrders,
    failedOrders: manualDispatchFailedOrders,
  } = await requestManualDispatchStep(manualDispatchOriginalOrders);

  debug('step 3: 쇼핑몰 운송장 수동 등록 완료', {
    total: manualDispatchOriginalOrders.length,
    success: manualDispatchSuccessOrders.length,
    failed: manualDispatchFailedOrders.length,
  });

  const dispatchFailCount =
    autoDispatchFailedOrders.length + manualDispatchFailedOrders.length;
  if (dispatchFailCount > 0 && isShowErrorEnabled) {
    showErrorMessage(
      `운송장 등록에 실패한 주문이 있습니다. (${dispatchFailCount}건) 실패 사유는 엑셀 파일을 확인해주세요.`
    );
  }

  debug('운송장 등록 완료', {
    successAutoDispatch: autoDispatchSuccessOrders.length,
    successManualDispatch: manualDispatchSuccessOrders.length,
    successAutoDispatchSkipped: autoDispatchSkippedOriginalOrders.length,
    failed: dispatchFailCount,
  });

  const dispatchCompleteOriginalOrders = [
    ...autoDispatchSuccessOrders,
    ...manualDispatchSuccessOrders,
    ...autoDispatchSkippedOriginalOrders,
  ];

  const dispatchCompleteUniqueCodes = new Set(
    dispatchCompleteOriginalOrders.map((order) => order.uniqueCode)
  );

  const dispatchCompleteShippingOrders = shippingOrders.filter((order) =>
    order.uniqueCode
      .split(',')
      .map((code) => code.trim())
      .some((code) => dispatchCompleteUniqueCodes.has(code))
  );

  const {
    successOrders: statusUpdateSuccessOrders,
    failedOrders: statusUpdateFailedOrders,
  } = await requestShippingOrderStatusUpdateStep(
    dispatchCompleteShippingOrders
  );

  if (dispatchCompleteOriginalOrders.length > 0) {
    debug('step 4: Merged Orders 업데이트 시작', {
      dispatchCompleteOriginalOrders: dispatchCompleteOriginalOrders.length,
    });

    const dispatchCompleteKeyByUniqueCode = Object.fromEntries(
      dispatchCompleteOriginalOrders.map((order) => [order.uniqueCode, order])
    );

    const updatedOriginalOrders = oms.order.mergedOrders.map((order) => {
      const updatedOriginalOrder =
        dispatchCompleteKeyByUniqueCode[order.uniqueCode];

      if (updatedOriginalOrder == null) {
        return order;
      }

      return {
        ...order,
        ...updatedOriginalOrder,
      };
    });

    debug('updatedOriginalOrders', updatedOriginalOrders);
    oms.order.setMergedOrders(updatedOriginalOrders);
    debug('step 4: Merged Orders 업데이트 완료');
  }

  if (statusUpdateSuccessOrders.length > 0) {
    const updatedShippingOrderKeyByUniqueCodeSub = Object.fromEntries(
      statusUpdateSuccessOrders.map((order) => [
        order.uniqueCodeAfterCustomization,
        order,
      ])
    );

    const updatedShippingOrders = oms.order.normalizedOrders.map((order) => {
      const updatedShippingOrder =
        updatedShippingOrderKeyByUniqueCodeSub[
          order.uniqueCodeAfterCustomization
        ];

      if (updatedShippingOrder == null) {
        return order;
      }

      return {
        ...order,
        ...updatedShippingOrder,
      };
    });

    debug('updatedShippingOrders', updatedShippingOrders);
    oms.order.setNormalizedOrders(updatedShippingOrders);
    debug('step 5: Normalized Orders 업데이트 완료', {
      updatedShippingOrders: updatedShippingOrders.length,
    });
  }

  const failedCount =
    updateInvalidShippingOrders.length +
    statusUpdateFailedOrders.length +
    autoDispatchFailedOrders.length +
    manualDispatchFailedOrders.length;

  if (failedCount > 0) {
    downloadFailReasonStep({
      updateInvalidShippingOrders,
      statusUpdateFailedOrders,
      autoDispatchFailedOrders,
      manualDispatchFailedOrders,
      shippingOrders,
      oms,
    });
    const errorMessage = `주문서 파일 생성에 실패했습니다. 다시 시도해주세요. (${failedCount}건)`;
    toast.error(errorMessage);
  }

  debug('step 6: 실패 주문 다운로드 완료', {
    shippingOrders: statusUpdateSuccessOrders.length,
  });

  const shouldShowSuccessToast = failedCount === 0;
  await splitAndDownloadPurchaseOrder({
    oms,
    shippingOrders: statusUpdateSuccessOrders,
    shouldShowSuccessToast,
  });

  debug('step 7: 구매 주문 파일 다운로드 완료', {
    shippingOrders: statusUpdateSuccessOrders.length,
  });

  debug('운송장 등록 프로세스 종료');
}

export function isAutoDispatchRequired(order: Order): boolean {
  return isNotNil(order.shoppingMallId) && isNil(order.autoFulfilled);
}

export function isAlreadyAutoDispatch(order: Order): boolean {
  return isNotNil(order.shoppingMallId) && isNotNil(order.autoFulfilled);
}

export function isManualDispatch(order: Order): boolean {
  return isNil(order.shoppingMallId);
}

function checkShowRequestAutoDispatchStepSuccess(params: {
  requestCount: number;
  successCount: number;
  failedCount: number;
}) {
  const { requestCount, successCount, failedCount } = params;

  return requestCount > 0 && successCount > 0 && failedCount === 0;
}
