import { observer } from 'mobx-react-lite';
import { CombinedShipping } from '@sweep/contract';
import { Divider, TableSelectControl } from '@sweep/sds';
import { uniqBy } from '@sweep/utils';
import { Separated } from '@sweep/utils/react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { OMSStore } from 'src/stores/OMSStore';
import CombinedShippingTableHeader from './CombinedShippingTableHeader';
import CombinedShippingTableRow from './CombinedShippingTableRow';

interface CombinedShippingTableProps {
  items: CombinedShipping[];
  selectedItems: CombinedShipping[];
  onSelect: (combinedShippings: CombinedShipping[]) => void;
  onEdit: (combinedShipping: CombinedShipping) => void;
}

function CombinedShippingTable({
  items,
  selectedItems,
  onSelect,
  onEdit,
}: CombinedShippingTableProps) {
  const oms = useOMSStore();
  const supplierNameItems = toSupplierNameCombinedShippings(oms, items);

  const selectedItemsIdSet = new Set(selectedItems.map((item) => item._id));
  const isAllSelected =
    items.length > 0 &&
    items.every((combinedShipping) =>
      selectedItemsIdSet.has(combinedShipping._id)
    );

  const handleAllSelect = () => {
    if (isAllSelected) {
      onSelect([]);
    } else {
      onSelect(
        uniqBy(
          [...items, ...selectedItems],
          (combinedShipping) => combinedShipping._id
        )
      );
    }
  };

  const isSelected = (combinedShipping: CombinedShipping) =>
    selectedItems.some(
      (selectedCombinedShipping) =>
        selectedCombinedShipping._id === combinedShipping._id
    );

  const handleSelect = (combinedShipping: CombinedShipping) => {
    if (isSelected(combinedShipping)) {
      onSelect(
        selectedItems.filter((item) => item._id !== combinedShipping._id)
      );
    } else {
      onSelect([...selectedItems, combinedShipping]);
    }
  };

  return (
    <div className="gap-12px flex flex-col">
      <TableSelectControl
        count={selectedItems.length}
        onClear={() => onSelect([])}
      />
      <div className="border-gray-2000 border">
        <CombinedShippingTableHeader
          checked={isAllSelected}
          onCheckedChange={handleAllSelect}
        />
        <Divider type="horizontal" />
        <Separated with={<Divider type="horizontal" />}>
          {supplierNameItems.map(([supplierName, combinedShippings]) => (
            <div key={supplierName} className="flex">
              <div className="flex flex-1 flex-col">
                <Separated with={<Divider type="horizontal" />}>
                  {combinedShippings.map((combinedShipping) => (
                    <CombinedShippingTableRow
                      key={combinedShipping._id}
                      item={combinedShipping}
                      isSelected={isSelected(combinedShipping)}
                      onSelect={() => handleSelect(combinedShipping)}
                      onEdit={() => onEdit(combinedShipping)}
                    />
                  ))}
                </Separated>
              </div>
              <Divider type="vertical" />
              <div className="w-136px px-16px flex items-center">
                <p className="text-medium-s text-gray-700">{supplierName}</p>
              </div>
            </div>
          ))}
        </Separated>
      </div>
    </div>
  );
}

function toSupplierNameCombinedShippings(
  oms: OMSStore,
  combinedShippings: CombinedShipping[]
): [string, CombinedShipping[]][] {
  const itemsBySupplierName = combinedShippings.reduce<
    Record<string, CombinedShipping[]>
  >((acc, combinedShipping) => {
    const supplierId = combinedShipping.supplierId;
    if (supplierId == null) {
      acc[''] = [...(acc[''] ?? []), combinedShipping];
      return acc;
    }
    const supplier = oms.supplier.getSupplier(supplierId);
    if (supplier == null) {
      return acc;
    }
    acc[supplier.name] = [...(acc[supplier.name] ?? []), combinedShipping];
    return acc;
  }, {});

  return Object.entries(itemsBySupplierName)
    .map<[string, CombinedShipping[]]>(([supplierName, combinedShippings]) => [
      supplierName,
      combinedShippings.sort((a, b) => {
        const productA = oms.product.getProduct(a.products[0].productId);
        const productB = oms.product.getProduct(b.products[0].productId);
        if (productA == null || productB == null) {
          return 0;
        }
        return productA.productName.localeCompare(productB.productName);
      }),
    ])
    .sort(([supplierNameA], [supplierNameB]) =>
      supplierNameA.localeCompare(supplierNameB)
    );
}

export default observer(CombinedShippingTable);
