import { observer } from 'mobx-react-lite';
import { IconDragFilesNoshadow } from '@sweep/asset/icons';
import { Button } from '@sweep/sds';

interface OrderAggregationEmptyScreenProps {
  onUpload: (files: File[]) => void;
}

function OrderShippingSectionEmpty({
  onUpload,
}: OrderAggregationEmptyScreenProps) {
  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    onUpload(Array.from(event.dataTransfer.files));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files == null) {
      return;
    }

    onUpload(Array.from(files));
    event.target.value = '';
  };

  return (
    <div
      className="rounded-8px flex-center py-40px shadow-line flex flex-col bg-white"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <IconDragFilesNoshadow className="mb-16px" />
      <p className="text-semibold-l mb-20px text-gray-700">
        통합하려는 판매처의 <span className="text-blue-500">주문 파일</span>
        들을 여기에 끌어다 놓으세요.
      </p>
      <p className="text-semibold-s mb-12px text-gray-400">또는</p>
      <Button className="w-200px" asChild>
        <label>
          파일 올리기
          <input
            className="hidden"
            type="file"
            accept=".xlsx, .xls, .csv"
            multiple
            onChange={handleFileChange}
          />
        </label>
      </Button>
    </div>
  );
}

export default observer(OrderShippingSectionEmpty);
