import { z } from 'zod';
import {
  OptionCodeMatching,
  optionCodeMatchingSchema,
} from '../type/OptionCodeMatching';

// GET /option-code-matching
export const GET_OPTION_CODE_MATCHING_URL = '/option-code-matching';

export type GetOptionCodeMatchingResponse = OptionCodeMatching[];

// POST /option-code-matching
export const CREATE_OPTION_CODE_MATCHING_URL = '/option-code-matching';

export const createOptionCodeMatchingDTOSchema = optionCodeMatchingSchema.omit({
  _id: true,
  createdAt: true,
  updatedAt: true,
  deletedAt: true,
  count: true,
});
export type CreateOptionCodeMatchingDTO = z.infer<
  typeof createOptionCodeMatchingDTOSchema
>;

export const createOptionCodeMatchingBodySchema =
  createOptionCodeMatchingDTOSchema;
export type CreateOptionCodeMatchingBody = z.infer<
  typeof createOptionCodeMatchingBodySchema
>;

export type CreateOptionCodeMatchingResponse = OptionCodeMatching;

// POST /option-code-matching/many
export const CREATE_OPTION_CODE_MATCHINGS_URL = '/option-code-matching/many';

export const createOptionCodeMatchingsBodySchema = z.array(
  createOptionCodeMatchingDTOSchema
);
export type CreateOptionCodeMatchingsBody = z.infer<
  typeof createOptionCodeMatchingsBodySchema
>;

export type CreateOptionCodeMatchingsResponse = OptionCodeMatching[];

// PUT /option-code-matching/:id
export const UPDATE_OPTION_CODE_MATCHING_URL = (id: string) =>
  `/option-code-matching/${id}`;

export const updateOptionCodeMatchingDTOSchema = optionCodeMatchingSchema
  .omit({
    _id: true,
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
  })
  .partial()
  .extend({
    _id: z.string(),
  });
export type UpdateOptionCodeMatchingDTO = z.infer<
  typeof updateOptionCodeMatchingDTOSchema
>;

export const updateOptionCodeMatchingBodySchema =
  updateOptionCodeMatchingDTOSchema;
export type UpdateOptionCodeMatchingBody = z.infer<
  typeof updateOptionCodeMatchingBodySchema
>;

export type UpdateOptionCodeMatchingResponse = OptionCodeMatching;

// PUT /option-code-matching/increment-counts
export const INCREMENT_OPTION_CODE_MATCHING_COUNTS_URL =
  '/option-code-matching/increment-counts';

export const incrementOptionCodeMatchingCountsBodySchema = z.object({
  optionCodeMatchingIds: z.array(z.string()),
});
export type IncrementOptionCodeMatchingCountsBody = z.infer<
  typeof incrementOptionCodeMatchingCountsBodySchema
>;
