import { z } from 'zod';
import { SpreadsheetHeaderMappingSchema } from '../../type/SpreadsheetHeaderMapping';

export const FIND_ALL_SPREADSHEET_HEADER_MAPPING_URL = `spreadsheet-header-mapping`;

export const FindAllSpreadsheetHeaderMappingResponseDTOSchema = z.object({
  spreadsheetHeaderMappings: z.array(SpreadsheetHeaderMappingSchema),
});

export type FindAllSpreadsheetHeaderMappingResponseDTO = z.infer<
  typeof FindAllSpreadsheetHeaderMappingResponseDTOSchema
>;
