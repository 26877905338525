import { observer } from 'mobx-react-lite';
import { SearchSelect, SearchSelectOption } from '@sweep/sds';
import { useOMSStore } from 'src/hooks/useOMSStore';

interface SupplierInputProps {
  value?: string | null;
  onChange: (value: string | null) => void;
  className?: string;
}

function SupplierInput({ value, onChange, className }: SupplierInputProps) {
  const oms = useOMSStore();
  const suppliers = oms.supplier.suppliers;

  const options: SearchSelectOption[] = [
    {
      value: null,
      label: '없음',
      className: 'text-gray-400',
    },
    ...suppliers
      .map((supplier) => ({
        value: supplier._id,
        label: supplier.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
  ];

  return (
    <SearchSelect
      value={value ?? null}
      onChange={onChange}
      className={className}
      options={options}
      placeholder="공급사 검색"
    />
  );
}

export default observer(SupplierInput);
