import { DispatchedOrder } from '../../type/order/DispatchedOrder';

interface CancelOrder {
  uniqueCode: string;
  shippingCompany?: string | null;
  shippingNumber?: string | null;
}

export type CanceledOrderResult =
  | ({ success: true } & CancelOrder)
  | ({ success: false; failReason: string } & CancelOrder);

// POST /cancel-order/accept-cancel
export const ACCEPT_CANCEL_ORDERS_URL = '/cancel-order/accept-cancel';
export interface AcceptCancelOrdersBody {
  orders: DispatchedOrder[];
}
export type AcceptCancelResponse = CanceledOrderResult[];

// POST /cancel-order/reject-cancel
export const REJECT_CANCEL_ORDERS_URL = '/cancel-order/reject-cancel';
export interface RejectCancelOrdersBody {
  orders: DispatchedOrder[];
  rejectReason: string;
}
export type RejectCancelResponse = CanceledOrderResult[];
