import { MatchingOrder } from '../interface';
import { stringifyProductNameOption } from './stringifyProductNameOption';

export function uniqueByProductNameOption<T>(
  items: T[],
  getMatchingOrder: (item: T) => MatchingOrder
): T[] {
  const uniqueItems: T[] = [];
  const productNameOption: Set<string> = new Set();

  items.forEach((item) => {
    const matchingOrder = getMatchingOrder(item);
    const key = stringifyProductNameOption(matchingOrder);

    if (!productNameOption.has(key)) {
      productNameOption.add(key);
      uniqueItems.push(item);
    }
  });

  return uniqueItems;
}
