import copy from 'fast-copy';
import { useEffect, useRef, useState } from 'react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import Button from '../components/buttons/Button';
import SupplierDetailHeaderMatchingComponent from './supplierForm/SupplierDetailHeaderMatchingComponent';
import SupplierExcelUpload from './supplierForm/SupplierExcelUpload';
import SupplierHeaderComponents from './supplierForm/SupplierHeaderComponents';

const SupplierForm = ({
  type = 'register',
  supplier = undefined,
  onFormSubmit,
  initialRegisterFile = { target: { files: [] } },
}) => {
  const oms = useOMSStore();
  const supplierInfoRef = useRef(null);

  const [newSupplier, setNewSupplier] = useState({ ...copy(supplier) } || {});

  useEffect(() => {
    setNewSupplier({
      file: null,
      name: '',
      email: '',
      phone: '',
      header: [],
      headerForShipping: [],
      columnMapping: {},
      customizedSettings: {},
      indexes: {},
      ...copy(supplier),
    });
  }, [supplier]);

  const handleSupplierInfoChange = (e) => {
    const { name, value } = e.target;
    setNewSupplier((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (!initialRegisterFile) {
      return;
    }
    if (initialRegisterFile?.target?.files?.length > 0) {
      supplierInfoRef.current?.handleExcelFileUpload(initialRegisterFile);
    } else if (typeof initialRegisterFile?.[0]?.[0] === 'string') {
      supplierInfoRef.current?.handleExcelFileUploadRows(initialRegisterFile);
    }
  }, [initialRegisterFile, supplierInfoRef]);

  // isChecked 상태가 변경될 때마다 실행되는 useEffect

  const validateSupplierName = (name) => {
    const suppliers = oms.supplier.suppliers;

    if (!name) {
      alert('공급사 이름을 입력해주세요.');
      return false;
    }

    const hasNameAlreadyExisted = suppliers.some((supplier) => {
      if (type === 'update') {
        return newSupplier._id !== supplier._id && supplier.name === name;
      } else {
        return supplier.name === name;
      }
    });
    if (hasNameAlreadyExisted) {
      alert('이미 등록된 공급사에요.');
      return false;
    }

    return true;
  };

  // NOTE(@현일): 기본적으로 공급사 등록 시에 스윕고유번호를 제목열의 뒤에 추가하고 있음
  // 제목열 중 다른 열에 스윕고유번호를 매핑해둔 경우, 제목열 뒤에 추가된 스윕고유번호 및 매핑된 스윕고유번호 제거
  const removeDefaultUniqueCodeMapping = (data) => {
    const hasCustomUniqueCodeMapping =
      data.columnMapping['uniqueCode'] !== '스윕고유번호';

    if (hasCustomUniqueCodeMapping) {
      const updatedMapping = { ...data.columnMapping };
      Object.entries(updatedMapping).forEach(([key, value]) => {
        if (key === '스윕고유번호' && value === '스윕고유번호') {
          delete updatedMapping[key];
        }
      });
      const updatedHeader = data.header.filter(
        (header) => header !== '스윕고유번호'
      );

      return {
        ...data,
        columnMapping: updatedMapping,
        header: updatedHeader,
      };
    }

    return data;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValidSupplierName = validateSupplierName(newSupplier?.name);

    if (!isValidSupplierName) {
      return;
    }

    let formData = {
      ...newSupplier,
    };

    formData = removeDefaultUniqueCodeMapping(formData);

    const hasSupplierFile = newSupplier.file != null;
    if (hasSupplierFile) {
      let templateFile = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (e) {
          const arrayBuffer = e.target.result;
          resolve(new Uint8Array(arrayBuffer));
        };
        reader.onerror = function (e) {
          reject(e);
        };
        reader.readAsArrayBuffer(newSupplier.file);
      });
      templateFile = await templateFile;
      templateFile = JSON.stringify(Array.from(templateFile));
      // let templateFile = reader.readAsArrayBuffer(file);
      //
      formData.customizedSettings.xlsxTemplateSetting = {
        enabled: false, // NOTE(@현일): xlsxTemplateSetting 로직이 불완전한 이유로 기본값을 false로 변경
        name: newSupplier.file.name,
        headerRowIndex: newSupplier.indexes.headerRowIndex,
        minRowIndex: newSupplier.indexes.minRowIndex,
        templateFile: templateFile,
      };
    }

    delete formData._id;
    delete formData.file;
    delete formData.indexes;

    onFormSubmit(formData);
  };

  const Divider = (margin) => (
    <div
      className={`my-[ 
      h-px bg-[#DDE4EB]${margin || 40}px] self-stretch`}
    ></div>
  );

  return (
    <form onSubmit={handleSubmit} className="mb-8 w-full px-[40px]">
      <SupplierHeaderComponents
        supplier={newSupplier}
        handleSupplierInfoChange={handleSupplierInfoChange}
      />
      {Divider()}

      <SupplierExcelUpload
        supplier={newSupplier}
        handleSupplierInfoChange={handleSupplierInfoChange}
        ref={supplierInfoRef}
      />
      {Divider()}

      <SupplierDetailHeaderMatchingComponent
        supplier={newSupplier}
        handleSupplierInfoChange={handleSupplierInfoChange}
      />
      <div className="flex justify-end">
        <Button
          name={type === 'update' ? '수정하기' : '등록하기'}
          type="submit"
        />
      </div>
    </form>
  );
};

export default SupplierForm;
