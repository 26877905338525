export const DEFAULT_FILE_FORMAT = '{date} {supplierName} 발주서';
export const DEFAULT_DATE_FORMAT = 'yy.MM.dd';

export type HasFileFormatting = {
  fileFormat?: string | null;
  dateFormat?: string | null;
};

export function selectFileFormatting<T extends HasFileFormatting>(
  options: (T | undefined)[]
): { fileFormat: string; dateFormat: string } {
  for (const option of options) {
    if (option == null) {
      continue;
    }

    const { fileFormat, dateFormat } = option;

    if (fileFormat != null) {
      return {
        fileFormat,
        dateFormat: dateFormat ?? DEFAULT_DATE_FORMAT,
      };
    }
  }

  return {
    fileFormat: DEFAULT_FILE_FORMAT,
    dateFormat: DEFAULT_DATE_FORMAT,
  };
}
