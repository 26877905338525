import { isNotNil, isPlainObject } from 'es-toolkit';
import { trimValue } from '@sweep/utils';

export function isDeepArrayEqual<T>(arr1: T[], arr2: T[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    const value1 = arr1[i];
    const value2 = arr2[i];

    // null 또는 undefined 처리
    if (!isNotNil(value1) && !isNotNil(value2)) {
      continue;
    }

    // 객체 타입 비교
    if (isPlainObject(value1) && isPlainObject(value2)) {
      if (!deepObjectEqual(value1, value2)) {
        return false;
      }
      continue;
    }

    // 기본 타입 비교
    if (trimValue(value1) !== trimValue(value2)) {
      return false;
    }
  }

  return true;
}

export function deepObjectEqual(obj1: unknown, obj2: unknown): boolean {
  if (!isPlainObject(obj1) || !isPlainObject(obj2)) {
    return obj1 === obj2;
  }

  const keys1 = Object.keys(obj1 as object);
  const keys2 = Object.keys(obj2 as object);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = (obj1 as Record<string, unknown>)[key];
    const val2 = (obj2 as Record<string, unknown>)[key];

    if (isPlainObject(val1) && isPlainObject(val2)) {
      if (!deepObjectEqual(val1, val2)) {
        return false;
      }
    } else if (val1 !== val2) {
      return false;
    }
  }

  return true;
}
