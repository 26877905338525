import { CombinedShipping, Order } from '@sweep/contract';

export function isOrderInCombinedShipping(
  order: Order,
  combinedShipping: CombinedShipping
): boolean {
  const { data } = order;
  const [product] = data ?? [];
  const { products } = combinedShipping;

  return products.some(
    ({ productId, option }) =>
      productId === product.productId &&
      isSameString(option, product.unit) &&
      isSameString(order.supplierId, combinedShipping.supplierId)
  );
}

function isSameString(
  a: string | undefined | null,
  b: string | undefined | null
): boolean {
  return (a ?? '') === (b ?? '');
}
