import { useOMSStore } from 'hooks/useOMSStore';
import { omit } from 'lodash';
import { observer } from 'mobx-react-lite';
import { IconSwitch } from '@sweep/asset/icons';
import { ColumnMapping } from 'src/models/ColumnMapping';

interface PartnerColumnMatchingInputProps {
  header: string[];
  columnMapping: ColumnMapping;
  onChange: (columnMapping: ColumnMapping) => void;
  exampleContents?: string[][];
}

function PartnerColumnMatchingInput({
  header,
  columnMapping,
  onChange,
  exampleContents,
}: PartnerColumnMatchingInputProps) {
  const oms = useOMSStore();
  const combinedExcelHeader = oms.user.excelHeaders;
  const columnTranslation = oms.user.setting?.columnTranslation ?? {};
  const hasProductNameColumn =
    Object.keys(columnTranslation).includes('productName');

  const getKey = (combinedItem: string) => {
    return Object.keys(columnTranslation).find(
      (key) => columnTranslation[key] === combinedItem
    );
  };

  const getHeaderIndex = (combinedItem: string) => {
    const key = getKey(combinedItem);

    if (key == null || columnTranslation == null) {
      return -1;
    }

    return header.findIndex((item) => item === columnMapping[key]);
  };

  const handleChange = (key: string, item: string) => {
    if (item === '') {
      onChange({ ...columnMapping, [key]: null });
      return;
    }

    const originKey = Object.keys(columnMapping).find(
      (originKey) => columnMapping[originKey] === item
    );

    if (originKey != null) {
      onChange({ ...omit(columnMapping, originKey), [key]: item });
      return;
    }

    onChange({ ...columnMapping, [key]: item });
  };

  return (
    <div className="mb-8 flex w-full flex-row">
      <div className="mr-[20px] flex w-[100px] shrink-0 flex-col items-center self-start">
        <div className="flex h-10 w-full items-center justify-center text-wrap border-y border-[#DAE9F2] bg-[#EDF5FC] px-4 text-center text-[12px]">
          판매처 엑셀 양식
        </div>
        <div className="flex h-8 w-full items-center justify-center">
          <IconSwitch className="text-gray-500" />
        </div>
        <div className="flex h-10 w-full items-center justify-center text-wrap border-y border-[#D8DEF9] bg-[#EFF0F9] px-4 text-[12px]">
          통합 엑셀양식
        </div>
      </div>
      <div className="flex gap-[24px] overflow-x-scroll whitespace-nowrap">
        {combinedExcelHeader.map((combinedItem) => {
          const key = getKey(combinedItem);
          if (key == null) {
            return null;
          }

          const isOptionColumn = columnTranslation['option'] === combinedItem;
          const columnWidth =
            isOptionColumn && !hasProductNameColumn
              ? 'min-w-[400px]'
              : 'min-w-[200px]';

          return (
            <div key={combinedItem} className="flex flex-col">
              <div
                className={`mb-[32px] flex ${columnWidth} items-center justify-between`}
              >
                <div className="flex w-full flex-col">
                  {/* 통합엑셀 양식에 '상품명(productName)' 에 해당하는 값이 없고, 판매처 양식에 상품명, 옵션 열이 각기 존재하는 경우 통합엑셀양식의 '옵션'열에 두 내용을 함께 표시 */}
                  {isOptionColumn && !hasProductNameColumn ? (
                    <div className="flex gap-2">
                      <div className="flex-1">
                        <HeaderSelect
                          header={header}
                          value={columnMapping['productName']}
                          onChange={(item) => handleChange('productName', item)}
                        />
                      </div>
                      <div className="flex-1">
                        <HeaderSelect
                          header={header}
                          value={columnMapping[key]}
                          onChange={(item) => handleChange(key, item)}
                        />
                      </div>
                    </div>
                  ) : (
                    <HeaderSelect
                      header={header}
                      value={columnMapping[key]}
                      onChange={(item) => handleChange(key, item)}
                    />
                  )}
                </div>
              </div>
              <div className="mb-2 flex h-10 items-center bg-gray-100 px-2">
                {combinedItem}
              </div>

              <div>
                {exampleContents?.map((row, rowIndex) => {
                  // isOptionColumn && !hasProductNameColumn 조건일 때 두 컬럼 함께 표시
                  if (isOptionColumn && !hasProductNameColumn) {
                    const productNameIndex = header.findIndex(
                      (item) => item === columnMapping['productName']
                    );

                    const optionIndex = header.findIndex(
                      (item) => item === columnMapping['option']
                    );

                    return (
                      <div
                        key={rowIndex}
                        className="relative mb-[8px] truncate whitespace-nowrap px-[12px] text-left text-[14px] leading-[21px] text-[#6B7683]"
                      >
                        <span>
                          • {row.at(productNameIndex)} {row.at(optionIndex)}
                        </span>
                      </div>
                    );
                  }

                  const headerIndex = getHeaderIndex(combinedItem);

                  if (headerIndex === -1) {
                    return null;
                  }

                  return (
                    <div
                      key={rowIndex}
                      className="relative mb-[8px] truncate whitespace-nowrap px-[12px] text-left text-[14px] leading-[21px] text-[#6B7683]"
                    >
                      <span>• {row.at(headerIndex)}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

interface HeaderSelectProps {
  header: string[];
  value: string | number | undefined | null;
  onChange: (value: string) => void;
  required?: boolean;
}

function HeaderSelect({
  header,
  value,
  onChange,
  required,
}: HeaderSelectProps) {
  return (
    <select
      value={value ?? ''}
      onChange={(e) => onChange(e.target.value)}
      className={`h-[40px] w-full rounded-lg border border-gray-300 px-[16px] ${
        value == null ? 'text-gray-400' : 'text-gray-900'
      }`}
      required={required}
    >
      <option value="">선택되지 않음</option>
      {header.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ))}
    </select>
  );
}

export default observer(PartnerColumnMatchingInput);
