import {
  FIND_ALL_SPREADSHEET_HEADER_MAPPING_URL,
  FindAllSpreadsheetHeaderMappingResponseDTO,
  SpreadsheetHeaderMapping,
} from '@sweep/contract';
import api from '../api';

export async function requestFindAllSpreadsheetHeaderMappingByUser(): Promise<
  SpreadsheetHeaderMapping[]
> {
  const response = await api.get<FindAllSpreadsheetHeaderMappingResponseDTO>(
    FIND_ALL_SPREADSHEET_HEADER_MAPPING_URL
  );

  const spreadsheetHeaderMappings =
    response?.data?.spreadsheetHeaderMappings ?? [];

  return spreadsheetHeaderMappings;
}
