import { SpreadsheetError } from '../../errors/SpreadsheetError';
import type {
  JSONSheetData,
  SheetData as FormulaeSheetData,
} from '../../parsers-formulae/parser.type';
import {
  convertColumnAddressToIndex,
  convertColumnIndexToAddress,
} from '../cell-address';

type CellCoordinate = {
  row: number;
  col: number;
};

function parseCellReference(cellRef: string): CellCoordinate {
  const colMatch = cellRef.match(/^[A-Z]+/)?.[0] ?? '';
  const rowMatch = cellRef.match(/\d+$/)?.[0] ?? '';

  if (!colMatch || !rowMatch) {
    throw new SpreadsheetError(`Invalid cell reference: ${cellRef}`, 'FORMAT');
  }

  const col = convertColumnAddressToIndex(colMatch);
  const row = parseInt(rowMatch, 10) - 1;

  return { row, col };
}

function getMaxDimensions(formulae: FormulaeSheetData): {
  maxRow: number;
  maxCol: number;
} {
  return formulae.reduce(
    (acc, formulaeCellValue) => {
      const [cellRef] = formulaeCellValue.split('=');
      const { row, col } = parseCellReference(cellRef);
      return {
        maxRow: Math.max(acc.maxRow, row),
        maxCol: Math.max(acc.maxCol, col),
      };
    },
    { maxRow: -1, maxCol: -1 }
  );
}

function extractCellValue(value: string): string | number | null {
  if (!value) {
    return null;
  }

  // Handle string values (prefixed with ')
  if (value.startsWith("'")) {
    return value.slice(1);
  }

  const numValue = Number(value);
  return isNaN(numValue) ? null : numValue;
}

export function convertFormulaeToJSON(
  formulae: FormulaeSheetData
): JSONSheetData {
  const { maxRow, maxCol } = getMaxDimensions(formulae);
  if (maxRow === -1 || maxCol === -1) {
    return [];
  }

  // Initialize array with empty objects
  const jsonData: JSONSheetData = Array.from(
    { length: maxRow + 1 },
    () => ({})
  );

  // Initialize all cells with null
  for (let row = 0; row <= maxRow; row++) {
    for (let col = 0; col <= maxCol; col++) {
      const colAddress = convertColumnIndexToAddress(col);
      jsonData[row][colAddress] = null;
    }
  }

  // Fill in values from formulae
  for (const formulaeCellValue of formulae) {
    if (formulaeCellValue == null || formulaeCellValue === '') {
      throw new SpreadsheetError('Invalid formula: empty value', 'FORMAT');
    }

    const firstEqualIndex = formulaeCellValue.indexOf('=');
    if (firstEqualIndex === -1) {
      throw new SpreadsheetError(
        `Invalid formula: ${formulaeCellValue}`,
        'FORMAT'
      );
    }

    const formulaeCellRef = formulaeCellValue.slice(0, firstEqualIndex);
    const formulaeValue = formulaeCellValue.slice(firstEqualIndex + 1);

    const { row, col } = parseCellReference(formulaeCellRef);
    const colAddress = convertColumnIndexToAddress(col);

    const value = extractCellValue(formulaeValue);
    jsonData[row][colAddress] = value;
  }

  return jsonData;
}

function _convertFormulaeTo2DArray(
  formulae: FormulaeSheetData,
  options?: { enableCellTrim?: boolean; enableEmptyRowTrim?: boolean }
): string[][] {
  const enableCellTrim = options?.enableCellTrim ?? false;
  const enableEmptyRowTrim = options?.enableEmptyRowTrim ?? false;

  return convertFormulaeToJSON(formulae)
    .map((row) => {
      const convertedRow = Object.values(row).map((rawValue) => {
        const value = rawValue?.toString() ?? '';
        const parsedValue = enableCellTrim ? value.trim() : value;

        return parsedValue;
      });

      if (!enableEmptyRowTrim) {
        return convertedRow;
      }

      // if enableEmptyRowTrim is true, and no values in the row are non-null, then return null
      const isNotEmptyRow = convertedRow.some(
        (value) => value != null && value !== ''
      );

      return isNotEmptyRow ? convertedRow : null;
    })
    .filter((row) => row !== null);
}

export function convertFormulaeTo2DArray(
  formulae: FormulaeSheetData
): string[][] {
  return _convertFormulaeTo2DArray(formulae, { enableCellTrim: false });
}

export function convertFormulaeTo2DArrayWithTrim(
  formulae: FormulaeSheetData
): string[][] {
  return _convertFormulaeTo2DArray(formulae, { enableCellTrim: true });
}

export function convertFormulaeTo2DArrayWithOptions(
  formulae: FormulaeSheetData,
  options?: { enableCellTrim?: boolean; enableEmptyRowTrim?: boolean }
): string[][] {
  return _convertFormulaeTo2DArray(formulae, options);
}
