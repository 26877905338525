import { Toaster as _Toaster } from 'sonner';
import { IconToastSuccess, IconToastWarning } from '@sweep/asset/icons';
import { cva } from '@sweep/tailwind';

interface ToasteProps {}

export function Toaster({}: ToasteProps) {
  return (
    <_Toaster
      position="bottom-right"
      offset={40}
      visibleToasts={4}
      // NOTE(@이지원): https://github.com/emilkowalski/sonner/issues/510
      className="w-0"
      icons={{
        success: <IconToastSuccess />,
        error: <IconToastWarning />,
      }}
      toastOptions={{
        unstyled: true,
        style: {},
        classNames: {
          icon: iconClass(),
          success: toastClass(),
          error: toastClass(),
        },
      }}
    />
  );
}

const iconClass = cva('flex-center m-0 flex');

const toastClass = cva([
  'p-20px rounded-8px gap-8px flex size-fit items-center whitespace-nowrap',
  'bg-gray-700 bg-opacity-[0.95] text-white',
  'right-full',
]);
