import { action, computed, observable } from 'mobx';
import {
  CombinedShipping,
  CreateCombinedShippingDTO,
  Product,
} from '@sweep/contract';
import {
  isEmptyArray,
  isEmptyString,
  isNotNil,
  uniq,
  uniqBy,
} from '@sweep/utils';
import { OMSStore } from 'src/stores/OMSStore';
import {
  CombinedShippingResult,
  CombinedShippingTableProduct,
  ProductNameOption,
  ProductNameOptionAmount,
} from './interface';
import { toCombinedShippingTableProductsFromCombinedShipping } from './services/toCombinedShippingTableProductsFromCombinedShipping';
import { toProductNameOptionAmount } from './services/toProductNameOptionAmount';

export class CombinedShippingFormStore {
  @observable
  accessor selectedProducts: CombinedShippingTableProduct[] = [];

  @observable
  accessor type: 'count' | 'unit' = 'count';

  @observable
  accessor amount: number | undefined = undefined;

  @observable
  accessor unit: string | undefined = undefined;

  @observable
  accessor productNameOptionAmounts: ProductNameOptionAmount[] = [];

  @observable
  accessor unitInputError: boolean = false;

  @computed
  get isSupplierUniformed() {
    if (this.selectedProducts.length === 0) {
      return true;
    }

    const [first, ...rest] = this.selectedProducts;
    const supplier = first.supplierId;

    return rest.every((product) => product.supplierId === supplier);
  }

  @computed
  get productNameOptions() {
    const productNameOptions = this.selectedProducts
      .map<ProductNameOption>((product) =>
        product.option != null
          ? { type: 'option', name: product.option }
          : { type: 'productName', name: product.productName }
      )
      .sort((a, b) => {
        return a.type === b.type
          ? a.name.localeCompare(b.name)
          : a.type === 'productName'
            ? -1
            : 1;
      });

    return uniqBy(
      productNameOptions,
      (productNameOption) =>
        `${productNameOption.type}-${productNameOption.name}`
    );
  }

  @computed
  get isInvalidAmountUnit() {
    return this.amount === 0 || this.unit == null || isEmptyString(this.unit);
  }

  @computed
  get isAmountLessThenUnitAmount() {
    return this.productNameOptionAmounts.some(
      (productNameOptionAmount) =>
        (productNameOptionAmount.amount ?? 0) > (this.amount ?? 0)
    );
  }

  @computed
  get disabled() {
    if (this.selectedProducts.length === 0) {
      return true;
    }

    if (this.isSupplierUniformed === false) {
      return true;
    }

    switch (this.type) {
      case 'count':
        if (this.amount == null) {
          return true;
        }
        const isNegativeOrZero = this.amount <= 0;
        const isFloat = !Number.isInteger(this.amount);
        return isNegativeOrZero || isFloat;

      case 'unit':
        return (
          this.amount == null ||
          this.unit == null ||
          this.productNameOptions.some(
            ({ type, name }) => (this.getAmount(type, name) ?? 0) === 0
          )
        );
    }
  }

  getAmount(type: 'productName' | 'option', name: string) {
    return this.productNameOptionAmounts.find(
      (productNameOptionAmount) =>
        productNameOptionAmount.type === type &&
        productNameOptionAmount.name === name
    )?.amount;
  }

  constructor(
    private oms: OMSStore,
    combinedShipping?: CombinedShipping
  ) {
    if (combinedShipping == null) {
      return;
    }

    this.setSelectedProducts(
      toCombinedShippingTableProductsFromCombinedShipping(
        this.oms,
        combinedShipping
      )
    );
    this.setType(combinedShipping.type);
    this.setAmount(combinedShipping.amount);

    if (combinedShipping.unit != null) {
      this.setUnit(combinedShipping.unit);
    }

    if (combinedShipping.type === 'unit') {
      const productNameOptionsAmounts = combinedShipping.products
        .map((combinedShippingProduct) =>
          toProductNameOptionAmount(this.oms, combinedShippingProduct)
        )
        .filter(isNotNil);
      this.productNameOptionAmounts = uniqBy(
        productNameOptionsAmounts,
        ({ type, name }) => `${type}${name}`
      );
    }
  }

  @action.bound
  setSelectedProducts(products: CombinedShippingTableProduct[]) {
    this.selectedProducts = products;

    products.forEach((product) => {
      const type = product.option != null ? 'option' : 'productName';
      const value =
        product.option != null ? product.option : product.productName;
      const amount = this.getAmount(type, value);
      if (amount != null) {
        return;
      }

      const NUMBER_REGEX = /\d+(\.\d+)?/;
      const number = value.match(NUMBER_REGEX)?.at(0);
      if (number == null) {
        return;
      }

      this.setProductNameOptionAmounts(type, value, parseFloat(number));
    });
  }

  @action.bound
  setType(type: 'count' | 'unit') {
    this.type = type;
    this.amount = undefined;
  }

  @action.bound
  setAmount(amount: number) {
    this.amount = amount;
  }

  @action.bound
  setUnit(unit: string | undefined) {
    this.unit = unit;
  }

  @action.bound
  setProductNameOptionAmounts(
    type: 'productName' | 'option',
    name: string,
    amount?: number
  ) {
    const productAmount = this.productNameOptionAmounts.find(
      (productAmount) =>
        productAmount.type === type && productAmount.name === name
    );
    if (productAmount != null) {
      productAmount.amount = amount;
    } else {
      this.productNameOptionAmounts.push({ type, name, amount });
    }
  }

  @action.bound
  setUnitInputError(unitInputError: boolean) {
    this.unitInputError = unitInputError;
  }

  getResult(): CombinedShippingResult | null {
    const [first] = this.selectedProducts;
    if (first == null) {
      return null;
    }

    if (this.amount == null) {
      return null;
    }

    const createCombinedShippingDTO: CreateCombinedShippingDTO = {
      type: this.type,
      products: this.selectedProducts.map((product) => ({
        productId: product.productId,
        option: product.option,
      })),
      amount: this.amount,
      unit: this.unit,
      supplierId: first.supplierId,
    };

    if (this.type === 'count') {
      return {
        combinedShipping: createCombinedShippingDTO,
        products: [],
      };
    }

    const productIds = uniq(
      this.selectedProducts.map((product) => product.productId)
    );
    const prevProducts = productIds
      .map(this.oms.product.getProduct)
      .filter(isNotNil);
    const products = prevProducts.map<Product | null>((product) => {
      const tableProducts = this.selectedProducts.filter(
        (talbeProduct) => talbeProduct.productId === product._id
      );

      if (tableProducts.length === 0) {
        return null;
      }

      if (isEmptyArray(product.units)) {
        const [first] = tableProducts;
        return {
          ...product,
          amount: this.getAmount('productName', first.productName),
        };
      }

      return {
        ...product,
        units: product.units.map((unit) => {
          const tableProduct = tableProducts.find(
            (tableProduct) => tableProduct.option === unit.unit
          );

          if (tableProduct == null || tableProduct.option == null) {
            return unit;
          }

          return {
            ...unit,
            amount: this.getAmount('option', tableProduct.option),
          };
        }),
      };
    });

    return {
      combinedShipping: createCombinedShippingDTO,
      products: products.filter(isNotNil),
    };
  }
}
