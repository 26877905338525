import { useMemo } from 'react';
import { NormalizedOrder } from '@sweep/contract';
import { ColumnDef, Table } from '@sweep/sds';
import {
  TableFilterHeaderCell,
  useTableFilter,
} from 'src/components/TableFilter';
import { useOMSStore } from 'src/hooks/useOMSStore';

export function useTableFilterColumns(params: {
  orders: NormalizedOrder[];
  useUniqueCodeSub?: boolean; // SWP-582 뉴스고 보여주기
}) {
  const { orders, useUniqueCodeSub } = params;
  const oms = useOMSStore();
  const { store, filterOrders } = useTableFilter();
  const headerKeys = oms.user.excelHeaderKeys;
  const columnMapping = oms.user.excelColumnMapping;

  const columns = useMemo(
    () =>
      headerKeys.map<ColumnDef<NormalizedOrder>>((key) => {
        if (key === 'uniqueCode' && useUniqueCodeSub === true) {
          return {
            header: (context) => (
              <TableFilterHeaderCell
                store={store}
                context={context}
                orders={orders}
                headerKey={key}
              >
                {columnMapping[key]}
              </TableFilterHeaderCell>
            ),
            accessorFn: (row) => row[key]?.toString() ?? '',
            cell: (context) => {
              const { row } = context;
              const tempUniqueCode = row.uniqueCodeAfterCustomization;

              return (
                <Table.Cell className="flex-center" context={context}>
                  {tempUniqueCode}
                </Table.Cell>
              );
            },
          };
        }

        return {
          header: (context) => (
            <TableFilterHeaderCell
              store={store}
              context={context}
              orders={orders}
              headerKey={key}
            >
              {columnMapping[key]}
            </TableFilterHeaderCell>
          ),
          accessorFn: (row) => row[key]?.toString() ?? '',
        };
      }),
    [columnMapping, headerKeys, orders, store]
  );

  const filteredOrders = useMemo(
    () => filterOrders(orders),
    [filterOrders, orders]
  );

  return { columns, filteredOrders, store };
}
