import { FormatProductNameRule, Order } from '@sweep/contract';
import { isNotEmptyString } from '@sweep/utils';

export function getRuleValue(
  order: Order,
  rules: FormatProductNameRule[]
): { prefix?: string | null; suffix?: string | null } | null {
  if (rules == null) {
    return null;
  }

  const rule = rules.find(({ condition }) => {
    const { partnerId, shoppingMallId, supplierId, productId } = condition;
    const isDifferentPartnerId =
      isNotEmptyString(partnerId) && partnerId !== order.partnerId;
    const isDifferentShoppingMallId =
      isNotEmptyString(shoppingMallId) &&
      shoppingMallId !== order.shoppingMallId;
    const isDifferentSupplierId =
      isNotEmptyString(supplierId) && supplierId !== order.supplierId;
    const isNotContainProductId =
      isNotEmptyString(productId) &&
      (order.data?.every((data) => data.productId !== productId) ?? false);

    if (
      isDifferentPartnerId ||
      isDifferentShoppingMallId ||
      isDifferentSupplierId ||
      isNotContainProductId
    ) {
      return false;
    }

    return true;
  });

  if (rule == null) {
    return null;
  }

  return rule.value;
}
