import { z } from 'zod';
import { matchingProductSchema } from './CompositionMatching';

export const optionCodeMatchingSchema = z.object({
  _id: z.string(),

  optionCode: z.string(),
  products: z.array(matchingProductSchema),

  count: z.number(),

  createdAt: z.number(),
  updatedAt: z.number(),
  deletedAt: z.number().nullish(),
});

export type OptionCodeMatching = z.infer<typeof optionCodeMatchingSchema>;
