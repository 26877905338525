import { isNil, isNotNil } from 'es-toolkit';
import {
  hasKeysInArray,
  hasKeysInObject,
  isDeepArrayEqual,
  isEitherSubsequenceOf,
} from '@sweep/utils';
import { SupplierLikeModel } from '../../../models/supplierLike.model';
import { mapKeysByColumnMapping } from '../../common/mapKeysByColumnMapping';
import { createHeaderMappingResult } from '../createHeaderMappingResult';
import {
  HeaderMappingResult,
  HeaderMatchContext,
  HeaderMatchReasonType,
} from '../types';

export const findSupplierHeaderMatch = (
  suppliers: SupplierLikeModel[],
  context: HeaderMatchContext
): HeaderMappingResult | null => {
  if (isNil(suppliers)) {
    return null;
  }

  const exactMatch = suppliers
    .map((supplier) => matchSupplierExactHeaders(supplier, context))
    .find(isNotNil);
  if (exactMatch) {
    return exactMatch;
  }

  const partialMatch = suppliers
    .map((supplier) => matchSupplierPartialHeaders(supplier, context))
    .find(isNotNil);
  if (partialMatch) {
    return partialMatch;
  }

  return null;
};

const matchSupplierExactHeaders = (
  supplier: SupplierLikeModel,
  context: HeaderMatchContext
): HeaderMappingResult | null => {
  const { headerRow, requiredHeaderKeys } = context;
  const { header, headerForShipping, columnMapping } = supplier;

  if (isNil(columnMapping)) {
    return null;
  }

  if (!hasKeysInObject(columnMapping, requiredHeaderKeys)) {
    return null;
  }

  const isHeaderMatch =
    isDeepArrayEqual(header ?? [], headerRow) ||
    isDeepArrayEqual(headerForShipping ?? [], headerRow);

  if (!isHeaderMatch) {
    return null;
  }

  const headerKeys = mapKeysByColumnMapping(headerRow, columnMapping);
  if (!hasKeysInArray(headerKeys, requiredHeaderKeys)) {
    return null;
  }

  return createHeaderMappingResult({
    headerKeys,
    mappingColumn: columnMapping,
    defaultShippingCompany:
      supplier.defaultShippingCompany ?? context.defaultShippingCompany,
    matchReason: HeaderMatchReasonType.SUPPLIER_EXACT_MATCH,
    matchReasonDetail: supplier.name,
  });
};

const matchSupplierPartialHeaders = (
  supplier: SupplierLikeModel,
  context: HeaderMatchContext
): HeaderMappingResult | null => {
  const { headerRow, requiredHeaderKeys } = context;
  const { header, headerForShipping, columnMapping } = supplier;

  if (isNil(columnMapping)) {
    return null;
  }

  if (!hasKeysInObject(columnMapping, requiredHeaderKeys)) {
    return null;
  }

  const isHeaderMatch =
    isEitherSubsequenceOf(header ?? [], headerRow) ||
    isEitherSubsequenceOf(headerForShipping ?? [], headerRow);

  if (!isHeaderMatch) {
    return null;
  }

  const headerKeys = mapKeysByColumnMapping(headerRow, columnMapping);
  if (!hasKeysInArray(headerKeys, requiredHeaderKeys)) {
    return null;
  }

  return createHeaderMappingResult({
    headerKeys,
    mappingColumn: columnMapping,
    defaultShippingCompany:
      supplier.defaultShippingCompany ?? context.defaultShippingCompany,
    matchReason: HeaderMatchReasonType.SUPPLIER_PARTIAL_MATCH,
    matchReasonDetail: supplier.name,
  });
};
