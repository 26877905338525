import { hasKeysInArray } from '@sweep/utils';
import { UserSettingLikeModel } from '../../../models/userSettingLike.model';
import { mapKeys } from '../../common/mapKeys';
import { createHeaderMappingResult } from '../createHeaderMappingResult';
import {
  HeaderMappingResult,
  HeaderMatchContext,
  HeaderMatchReasonType,
} from '../types';

/**
 * 유의어 검색
 * 모르는 단어는 유의어로 검색하고, 나머지는 통합엑셀 매핑 정보를 사용한다
 */
export const findDefaultSynonymMatch = (
  settings: UserSettingLikeModel | null | undefined,
  context: HeaderMatchContext
): HeaderMappingResult | null => {
  const { headerRow, requiredHeaderKeys } = context;
  const defaultShippingCompany =
    settings?.defaultShippingCompany ?? context.defaultShippingCompany;
  const mappingColumn = settings?.columnTranslation ?? {};

  const headerKeys = mapKeys(headerRow); // NOTE (@형준): 유의어 검색
  if (!hasKeysInArray(headerKeys, requiredHeaderKeys)) {
    return null;
  }

  return createHeaderMappingResult({
    headerKeys,
    mappingColumn,
    defaultShippingCompany,
    matchReason: HeaderMatchReasonType.DEFAULT_MATCH,
  });
};
