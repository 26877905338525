import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { NormalizedOrder } from '@sweep/contract';
import { TableCellProps, SelectableTable } from '@sweep/sds';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { upsertNormalizedOrders } from 'src/network/order/order';

interface OrderPendingTableCell
  extends Omit<TableCellProps<NormalizedOrder>, 'children'> {
  orderKey: string;
}

function OrderPendingTableCell({
  orderKey,
  context,
  onDoubleClick: givenOnDoubleClick,
  ...rest
}: OrderPendingTableCell) {
  const oms = useOMSStore();
  const { row: order } = context;

  const [isEditing, setIsEditing] = useState(false);
  const [internalValue, setInternalValue] = useState(
    getOrderValue(order, orderKey)
  );

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setInternalValue(getOrderValue(order, orderKey));
  }, [orderKey, order]);

  const handleBlur = () => {
    setIsEditing(false);
    const updatedOrder = { ...order, [orderKey]: internalValue };
    const updatedOrders = oms.order.normalizedOrders.map((prevOrder) =>
      prevOrder.uniqueCodeAfterCustomization ===
      order.uniqueCodeAfterCustomization
        ? updatedOrder
        : prevOrder
    );
    oms.order.setNormalizedOrders(updatedOrders);
    upsertNormalizedOrders([updatedOrder]);
  };

  const handleDoubleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsEditing(true);
    givenOnDoubleClick?.(event);
    setTimeout(() => inputRef.current?.focus());
  };

  return (
    <SelectableTable.Cell
      onDoubleClick={handleDoubleClick}
      context={context}
      {...rest}
    >
      {isEditing ? (
        <input
          className="w-full"
          ref={inputRef}
          value={internalValue}
          onChange={(e) => setInternalValue(e.target.value)}
          onBlur={handleBlur}
        />
      ) : (
        internalValue
      )}
    </SelectableTable.Cell>
  );
}

function getOrderValue(order: NormalizedOrder, key: string) {
  return order[key]?.toString() ?? '';
}

export default observer(OrderPendingTableCell);
