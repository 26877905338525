import { useEffect, useRef, useState } from 'react';
import { cva } from '@sweep/tailwind';
import {
  imageLandingPageVideoBackground1,
  imageLandingPageVideoBackground2,
  imageLandingPageVideoBackground3,
} from 'src/images';
import { amplitude } from 'src/third-parties/amplitude';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import video1 from '../../../../assets/videos/video-1.mp4';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import video2 from '../../../../assets/videos/video-2.mp4';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import video3 from '../../../../assets/videos/video-3.mp4';

interface DetailsProps {
  hasEnoughHeight: boolean;
}

function Details({ hasEnoughHeight }: DetailsProps) {
  const screen1Ref = useRef<HTMLDivElement>(null);
  const screen2Ref = useRef<HTMLDivElement>(null);
  const screen3Ref = useRef<HTMLDivElement>(null);
  const [currentSection, setCurrentSection] = useState<number>(1);

  const video1Ref = useRef<HTMLVideoElement>(null);
  const video2Ref = useRef<HTMLVideoElement>(null);
  const video3Ref = useRef<HTMLVideoElement>(null);

  const pauseVideos = () => {
    video1Ref.current?.pause();
    video2Ref.current?.pause();
    video3Ref.current?.pause();
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && video1Ref.current != null) {
          setCurrentSection(1);
          pauseVideos();
          video1Ref.current.currentTime = 0;
          const playerTimer = setTimeout(() => {
            video1Ref.current?.play();
          }, 1000);
          const trackTimer = setTimeout(() => {
            if (currentSection === 1) {
              amplitude.track('[LP-B] View Video 1');
            }
          }, 1000);

          return () => {
            clearTimeout(playerTimer);
            clearTimeout(trackTimer);
          };
        }
      },
      { threshold: 0.35 }
    );

    if (screen1Ref.current != null) {
      observer.observe(screen1Ref.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && video2Ref.current != null) {
          setCurrentSection(2);
          pauseVideos();
          video2Ref.current.currentTime = 0;
          const playerTimer = setTimeout(() => {
            video2Ref.current?.play();
          }, 1000);
          const trackTimer = setTimeout(() => {
            if (currentSection === 2) {
              amplitude.track('[LP-B] View Video 2');
            }
          }, 1000);

          return () => {
            clearTimeout(playerTimer);
            clearTimeout(trackTimer);
          };
        }
      },
      { threshold: 0.65 }
    );

    if (screen2Ref.current != null) {
      observer.observe(screen2Ref.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && video3Ref.current != null) {
          setCurrentSection(3);
          pauseVideos();
          video3Ref.current.currentTime = 0;
          const playerTimer = setTimeout(() => {
            video3Ref.current?.play();
          }, 1000);
          const trackTimer = setTimeout(() => {
            if (currentSection === 3) {
              amplitude.track('[LP-B] View Video 3');
            }
          }, 1000);

          return () => {
            clearTimeout(playerTimer);
            clearTimeout(trackTimer);
          };
        }
      },
      { threshold: 0.35 }
    );

    if (screen3Ref.current != null) {
      observer.observe(screen3Ref.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div className="relative flex h-[300vh] w-full flex-col gap-0">
      <div className="h-screen w-full bg-blue-100" ref={screen1Ref} />
      <div className="h-screen w-full bg-red-100" ref={screen2Ref} />
      <div className="h-screen w-full bg-green-100" ref={screen3Ref} />
      <div className="absolute left-0 top-0 size-full">
        <div className="sticky top-0 flex h-screen w-full flex-col items-center justify-center gap-[40px] bg-white pt-[80px]">
          <div className={container({ hasEnoughHeight })}>
            <div className={videoHeaderContainer({ hasEnoughHeight })}>
              <div className={videoHeader({ hasEnoughHeight })}>
                <div
                  className={badge({
                    section: 1,
                    visible: currentSection === 1,
                  })}
                >
                  주문 수집
                </div>
                <div className={textContainer({ hasEnoughHeight })}>
                  <div className="flex w-full items-center justify-between">
                    <p className={title({ visible: currentSection === 1 })}>
                      빠른 주문 수집, 간편한 주문 확인
                    </p>
                    {/* <button
                      className="z-10 flex items-center justify-center gap-[8px] rounded-[8px] bg-blue-500 px-[22px] py-[14px] opacity-100"
                      onClick={() => console.log(currentSection)}
                    >
                      <p className="text-[18px] font-bold leading-[21px] tracking-tight text-white">
                        자세히 알아보기
                      </p>
                      <IconDown className="text-white" />
                    </button> */}
                  </div>
                  <p className={description({ visible: currentSection === 1 })}>
                    다양한 연동 쇼핑몰의 주문을 빠르게 불러와 주문 확인, 취소
                    승인 및 거부도 간편하게 처리합니다.
                  </p>
                </div>
              </div>
              <div className={videoHeader({ hasEnoughHeight })}>
                <div
                  className={badge({
                    section: 2,
                    visible: currentSection === 2,
                  })}
                >
                  통합 엑셀 추가
                </div>
                <div className={textContainer({ hasEnoughHeight })}>
                  <div className="flex w-full items-center justify-start">
                    <p className={title({ visible: currentSection === 2 })}>
                      자동 양식 변환, 간편한 엑셀 추가
                    </p>
                  </div>
                  <p className={description({ visible: currentSection === 2 })}>
                    주문 정보를 원하는 양식으로 자동 변환합니다. 외부 엑셀
                    파일도 손쉽게 추가할 수 있습니다.
                  </p>
                </div>
              </div>
              <div className={videoHeader({ hasEnoughHeight })}>
                <div
                  className={badge({
                    section: 3,
                    visible: currentSection === 3,
                  })}
                >
                  발주서 분리
                </div>
                <div className={textContainer({ hasEnoughHeight })}>
                  <div className="flex w-full items-center justify-start">
                    <p className={title({ visible: currentSection === 3 })}>
                      공급처별 발주서 분리, 발주서 자동 발송
                    </p>
                  </div>
                  <p className={description({ visible: currentSection === 3 })}>
                    합배송 처리를 자동으로 하고 공급처별 양식에 맞춰 발주서
                    파일을 생성합니다. 메일, 카카오톡 등으로 발주서를 자동
                    발송합니다.
                  </p>
                </div>
              </div>
            </div>
            <div className="relative h-[558px] w-[1184px] px-[168px]">
              <img
                src={imageLandingPageVideoBackground1}
                alt="landing-page-video1"
                className={videoBackground({
                  visible: currentSection === 1,
                })}
              />
              <img
                src={imageLandingPageVideoBackground2}
                alt="landing-page-video1"
                className={videoBackground({
                  visible: currentSection === 2,
                })}
              />
              <img
                src={imageLandingPageVideoBackground3}
                alt="landing-page-video1"
                className={videoBackground({
                  visible: currentSection === 3,
                })}
              />
              <div className="relative z-10 flex h-[558px] w-full flex-col items-center justify-center gap-[80px] overflow-hidden">
                <div
                  className={videoContainer({
                    currentSection: currentSection as 1 | 2 | 3,
                  })}
                >
                  <video
                    className={video()}
                    ref={video1Ref}
                    loop
                    muted
                    playsInline
                  >
                    <source src={video1} />
                  </video>
                  <video
                    className={video()}
                    ref={video2Ref}
                    loop
                    muted
                    playsInline
                  >
                    <source src={video2} />
                  </video>
                  <video
                    className={video()}
                    ref={video3Ref}
                    loop
                    muted
                    playsInline
                  >
                    <source src={video3} />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const container = cva('flex w-[1184px] flex-col', {
  variants: {
    hasEnoughHeight: {
      true: 'gap-[40px]',
      false: 'gap-[20px]',
    },
  },
});

const videoHeaderContainer = cva('relative w-full', {
  variants: {
    hasEnoughHeight: {
      true: 'h-[141px]',
      false: 'h-[121px]',
    },
  },
});

const videoHeader = cva(
  'absolute left-0 top-0 flex w-full flex-col items-start transition-opacity duration-500',
  {
    variants: {
      hasEnoughHeight: {
        true: 'gap-[24px]',
        false: 'gap-[8px]',
      },
    },
  }
);

const textContainer = cva('flex w-full flex-col items-start', {
  variants: {
    hasEnoughHeight: {
      true: 'gap-[8px]',
      false: 'gap-[4px]',
    },
  },
});

const badge = cva(
  'rounded-8px flex h-[34px] items-center justify-center px-[12px] text-[14px] font-semibold leading-[24px] tracking-tight transition-opacity duration-500',
  {
    variants: {
      section: {
        1: 'bg-[#E4F6F8] text-[#00CAE9]',
        2: 'bg-[#E4EAF8] text-[#2869FF]',
        3: 'bg-[#EEE4F8] text-[#9428FF]',
      },
      visible: {
        true: '',
        false: 'opacity-0',
      },
    },
  }
);

const title = cva(
  'text-[40px] font-bold leading-[52px] tracking-tight transition-opacity duration-500',
  {
    variants: {
      visible: {
        true: '',
        false: 'opacity-0',
      },
    },
  }
);

const description = cva(
  'text-[16px] font-medium leading-[23px] tracking-tight text-gray-600 transition-opacity duration-500',
  {
    variants: {
      visible: {
        true: '',
        false: 'opacity-0',
      },
    },
  }
);

const videoBackground = cva(
  'absolute left-0 top-0 size-full object-cover transition-opacity duration-500',
  {
    variants: {
      visible: {
        true: '',
        false: 'opacity-0',
      },
    },
  }
);

const videoContainer = cva(
  'flex w-full flex-col gap-[80px] transition-transform duration-500',
  {
    variants: {
      currentSection: {
        1: 'translate-y-[558px]',
        2: 'translate-y-0',
        3: 'translate-y-[-558px]',
      },
    },
  }
);

const video = cva('h-[478px] w-full rounded-[16px] object-cover');

export default Details;
