import { ReactNode } from 'react';
import { RadioContextProvider } from './RadioContext';
import { RadioGroupItem } from './RadioGroupItem';

interface RadioGroupProps<T extends string> {
  value?: T;
  onChange?: (value: T) => void;
  children: ReactNode;
}

export function RadioGroup<T extends string>({
  value,
  onChange,
  children,
}: RadioGroupProps<T>) {
  return (
    <RadioContextProvider
      value={{ value, onChange: (value) => onChange?.(value as T) }}
    >
      {children}
    </RadioContextProvider>
  );
}

RadioGroup.Item = RadioGroupItem;
