import { useCallback, useMemo, useState } from 'react';

interface ColumnWidthState {
  cell?: number;
  header?: number;
  adjusted?: number | null;
}

const INITIAL_MAX_WIDTH = 400;

export interface UseColumnWidthReturn {
  columnWidths: Record<number, number | null>;
  onWidthChange: (index: number, width: number) => void;
  onAdjustedWidthChange: (index: number, width: number) => void;
  onHeaderWidthChange: (index: number, width: number) => void;
  onFitWidth: (index: number) => void;
}

export function useColumnWidth(): UseColumnWidthReturn {
  const [widthStates, setWidthStates] = useState<
    Record<number, ColumnWidthState>
  >({});

  const getRenderedWidth = useCallback(
    (index: number) => {
      const { cell, header } = widthStates[index] ?? {};
      if (cell == null || header == null) {
        return null;
      }
      return Math.max(cell, header);
    },
    [widthStates]
  );

  const getColumnWidth = useCallback(
    (index: number) => {
      const { adjusted, header } = widthStates[index] ?? {};
      if (adjusted != null) {
        return Math.max(adjusted, header ?? 0);
      }

      const renderedWidth = getRenderedWidth(index);
      if (renderedWidth == null) {
        return null;
      }

      return Math.min(renderedWidth, INITIAL_MAX_WIDTH);
    },
    [getRenderedWidth, widthStates]
  );

  const columnWidths = useMemo(
    () =>
      Object.fromEntries(
        Object.keys(widthStates).map((index) => [
          index,
          getColumnWidth(Number(index)),
        ])
      ),
    [widthStates, getColumnWidth]
  );

  const handleWidthChange = useCallback((index: number, width: number) => {
    setWidthStates((prev) => {
      const currentCell = prev[index]?.cell ?? 0;
      if (width <= currentCell) {
        return prev;
      }

      return {
        ...prev,
        [index]: {
          ...prev[index],
          cell: width,
        },
      };
    });
  }, []);

  const handleHeaderWidthChange = useCallback(
    (index: number, width: number) => {
      setWidthStates((prev) => {
        if (prev[index]?.header === width) {
          return prev;
        }

        return {
          ...prev,
          [index]: {
            ...prev[index],
            header: width,
          },
        };
      });
    },
    []
  );

  const handleAdjustedWidthChange = useCallback(
    (index: number, width: number | null) => {
      setWidthStates((prev) => {
        if (prev[index]?.adjusted === width) {
          return prev;
        }

        return {
          ...prev,
          [index]: {
            ...prev[index],
            adjusted: width,
          },
        };
      });
    },
    []
  );

  const handleFitWidth = useCallback(
    (index: number) => {
      const naturalWidth = getRenderedWidth(index);
      handleAdjustedWidthChange(index, naturalWidth);
    },
    [getRenderedWidth, handleAdjustedWidthChange]
  );

  return {
    columnWidths,
    onWidthChange: handleWidthChange,
    onAdjustedWidthChange: handleAdjustedWidthChange,
    onHeaderWidthChange: handleHeaderWidthChange,
    onFitWidth: handleFitWidth,
  };
}
