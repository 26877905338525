import { observer } from 'mobx-react-lite';
import {
  IconOrderAll,
  IconOrderComplete,
  IconOrderPending,
  IconOrderShippingCompleted,
  IconOrderShippingPending,
} from '@sweep/asset/icons';
import { cva } from '@sweep/tailwind';
import { OrderProcessType } from '../../OrderProcessScreen';

interface OrderProcessRadioProps {
  process: OrderProcessType;
  active: boolean;
  count: number;
  onSelect: () => void;
}

function OrderProcessRadio({
  process,
  active,
  count,
  onSelect,
}: OrderProcessRadioProps) {
  const Icon = getIcon(process);
  const title = getTitle(process);

  return (
    <button
      onClick={onSelect}
      className={buttonClass(process)({ process, active })}
    >
      <div className={iconContainer(process)({ active })}>
        <Icon />
      </div>
      <div className="flex flex-col items-start">
        <p className="text-medium-s text-nowrap text-gray-700">{title}</p>
        <div className="gap-1px flex items-end">
          <p className="text-semibold-2xl text-gray-700">{count}</p>
          <p className="mb-1px text-medium-s text-gray-700">건</p>
        </div>
      </div>
    </button>
  );
}

function getIcon(process: OrderProcessType) {
  switch (process) {
    case 'total':
      return IconOrderAll;
    case 'pending':
      return IconOrderPending;
    case 'processing':
      return IconOrderComplete;
    case 'reviewing':
      return IconOrderShippingPending;
    case 'shipped':
      return IconOrderShippingCompleted;
  }
}

function getTitle(process: OrderProcessType) {
  switch (process) {
    case 'total':
      return '전체';
    case 'pending':
      return '발주 대기'; // PENDING
    case 'processing':
      return '발주 완료'; // PROCESSING
    case 'reviewing':
      return '운송장 등록 대기'; // REVIEWING
    case 'shipped':
      return '운송장 등록 완료'; // SHIPPED
  }
}

const buttonClass = (process: OrderProcessType) => {
  if (process === 'total') {
    return cva(
      'gap-14px px-20px py-16px rounded-8px flex items-center  border-[1.5px]',
      {
        variants: {
          active: {
            true: 'border-gray-500 bg-gray-100',
            false: 'border-gray-300',
          },
          process: {
            total: 'w-180px',
            pending: 'flex-1',
            processing: 'flex-1',
            reviewing: 'flex-1',
            shipped: 'flex-1',
          },
        },
      }
    );
  }

  return cva(
    'gap-14px px-20px py-16px rounded-8px flex items-center border-[1.5px]',
    {
      variants: {
        active: {
          true: 'border-blue-500 bg-blue-50',
          false: 'border-gray-300',
        },
        process: {
          total: 'w-180px',
          pending: 'flex-1',
          processing: 'flex-1',
          reviewing: 'flex-1',
          shipped: 'flex-1',
        },
      },
    }
  );
};

const iconContainer = (process: OrderProcessType) => {
  if (process === 'total') {
    return cva('size-40px rounded-8px flex-center flex text-white', {
      variants: {
        active: {
          true: 'bg-gray-500',
          false: 'bg-gray-200',
        },
      },
    });
  }

  return cva('size-40px rounded-8px flex-center flex text-white', {
    variants: {
      active: {
        true: 'bg-blue-500',
        false: 'bg-gray-200',
      },
    },
  });
};

export default observer(OrderProcessRadio);
