import { offset, useDismiss, useFloating } from '@floating-ui/react';
import { maxHeightOnViewport } from '../../../hooks/useDialog';

interface SearchSelectFloatingOptions {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  options: {
    withFooter: boolean;
  };
}

export function useSearchSelectFloating({
  open,
  onOpenChange,
  options,
}: SearchSelectFloatingOptions) {
  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom',
    open,
    onOpenChange,
    middleware: [
      offset(6),
      maxHeightOnViewport(options.withFooter ? 308 : 276),
    ],
  });

  useDismiss(context, {
    ancestorScroll: true,
    outsidePress: true,
    escapeKey: false,
  });

  return { refs, floatingStyles };
}
