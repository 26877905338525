import { http, HttpHandler, HttpResponse } from 'msw';
import {
  FULFILL_ORDERS_URL,
  FulfilledOrderResult,
  FulfillOrder,
  FulfillOrdersBody,
  FulfillOrdersResponse,
} from '@sweep/contract';
import { isNotNil, sleep } from '@sweep/utils';
import { LocalStorageValue } from '@sweep/utils/mobx';
import { APIResponse } from 'src/network/api';
import { LOCALSTORAGE_DISPATCH_PREFIX } from '../../../common/constants';

export function createFulfillOrderHandlers(): HttpHandler[] {
  return [createFulfillOrderHandler()].filter(isNotNil);
}

export type FulfillOrdersType = 'success' | 'partial-fail' | 'fail' | 'none';
export const fulfillOrderTypeStorage = new LocalStorageValue<FulfillOrdersType>(
  {
    key: `${LOCALSTORAGE_DISPATCH_PREFIX}/fullfill-order-type`,
    defaultValue: 'none',
  }
);

function createFulfillOrderHandler() {
  const fulfillOrderType = fulfillOrderTypeStorage.value;

  if (fulfillOrderType === 'none') {
    return null;
  }

  return http.post<any, FulfillOrdersBody, APIResponse<FulfillOrdersResponse>>(
    FULFILL_ORDERS_URL,
    async ({ request }) => {
      await sleep(1000);
      const { orders } = await request.json();

      switch (fulfillOrderType) {
        case 'success':
          return HttpResponse.json({
            success: true,
            data: orders.map<FulfilledOrderResult>((order) => ({
              ...order,
              success: true,
            })),
          });

        case 'partial-fail':
          return HttpResponse.json({
            success: true,
            data: mapPartialFailOrders(orders),
          });

        case 'fail':
          return HttpResponse.json({
            success: false,
            error: 'msw-order-error',
          });
      }
    }
  );
}

function mapPartialFailOrders(orders: FulfillOrder[]) {
  return orders.map<FulfilledOrderResult>((order, index) =>
    index % 2 === 0
      ? { ...order, success: true }
      : {
          ...order,
          success: false,
          failReason: 'msw-order-error',
        }
  );
}
