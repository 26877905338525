import { Workbook } from 'exceljs';
import { Order, UserSetting } from '@sweep/contract';
import { ColumnMapping } from 'src/models/ColumnMapping';
import { addWorkSheet } from '../create/addWorkSheet';

export async function createOrderExcelBufferV2(params: {
  orders: Order[];
  fileName: string;
  headerKeys: string[];
  columnMapping: ColumnMapping;
  userSetting: UserSetting;
}): Promise<ArrayBuffer | null> {
  const { userSetting, orders, fileName, headerKeys, columnMapping } = params;

  if (orders.length === 0) {
    return null;
  }

  const workbook = addWorkSheet(userSetting, new Workbook(), orders, fileName, {
    headerKeys,
    columnMapping,
  });

  const buffer = await workbook.xlsx.writeBuffer();
  return buffer;
}
