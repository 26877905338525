import { cva } from '@sweep/tailwind';
import { ExpandedCheckbox } from '../../Checkbox';
import { Slot } from '../../Slot';
import DefaultTableCell from '../core/components/DefaultTableCell';
import { CellContext, ColumnDef } from '../core/interface';
import { Table, TableProps } from '../core/components/Table';
import { useSelectableTable } from './useSelectableTable';

interface SelectableTableProps<T>
  extends Omit<TableProps<T>, 'items' | 'columns'> {
  items: (T | T[])[];
  columns: SelectableColumnDef<T>[];
  selectedItems: T[];
  onSelect: (items: T[]) => void;
  getKey: (data: T) => string;
  disabledItems?: T[];
  highlightSelectedRow?: boolean;
}

export type SelectableColumnDef<T> = ColumnDef<T, SelectableCellContext<T>>;

export interface SelectableCellContext<T> extends CellContext<T> {
  selected: boolean;
  disabled: boolean;
}

export function SelectableTable<T>({
  items,
  columns: givenColumns,
  getKey: gievnGetKey,
  selectedItems,
  onSelect,
  disabledItems,
  highlightSelectedRow = true,
  ...rest
}: SelectableTableProps<T>) {
  const {
    isAllSelected,
    isSelected,
    isDisabled,
    onAllSelectedChange,
    onSelectedChange,
  } = useSelectableTable({
    items,
    selectedItems,
    disabledItems,
    onSelect,
    getKey: gievnGetKey,
  });

  const columns: ColumnDef<T | T[]>[] = [
    {
      header: (context) => (
        <Table.HeaderCell
          className={checkboxHeaderClass({
            selected: isAllSelected,
            highlight: highlightSelectedRow,
          })}
          context={context}
        >
          <ExpandedCheckbox
            className="z-[1]"
            checked={isAllSelected}
            onCheckedChange={onAllSelectedChange}
          />
        </Table.HeaderCell>
      ),
      cell: (context) => (
        <Table.Cell
          className={checkboxCellClass({
            selected: isSelected(context.row),
            highlight: highlightSelectedRow,
          })}
          context={context}
        >
          <ExpandedCheckbox
            checked={isSelected(context.row)}
            onCheckedChange={onSelectedChange(context.row)}
            disabled={isDisabled(context.row)}
          />
        </Table.Cell>
      ),
    },
    ...givenColumns.map<ColumnDef<T | T[]>>((column) => ({
      header: column.header,
      cell: (context) => {
        const { row } = context;
        const rows = Array.isArray(row) ? row : [row];
        const selected = isSelected(row);

        const component = (context: SelectableCellContext<T>) => {
          if (column.cell != null) {
            const cell = column.cell(context);
            if (cell != null) {
              return cell;
            }
          }

          return (
            <DefaultTableCell context={context}>
              {column.accessorFn?.(context.row)}
            </DefaultTableCell>
          );
        };

        return (
          <div className="flex flex-col">
            {rows.map((item, index) => (
              <Slot
                key={index}
                className={cellClass({
                  selected,
                  highlight: highlightSelectedRow,
                })}
              >
                {component({
                  ...context,
                  row: item,
                  lastRow: context.lastRow && index === rows.length - 1,
                  selected,
                  disabled: isDisabled(item),
                })}
              </Slot>
            ))}
          </div>
        );
      },
    })),
  ];

  const getKey = (item: T | T[]) => {
    if (Array.isArray(item)) {
      const keys = item.map(gievnGetKey);
      return keys.join(',');
    }

    return gievnGetKey(item);
  };

  return <Table items={items} columns={columns} getKey={getKey} {...rest} />;
}

const checkboxHeaderClass = cva('sticky left-0 items-start bg-gray-200 px-0', {
  variants: {
    selected: {
      true: '',
    },
    highlight: {
      true: '',
    },
  },
  compoundVariants: [
    {
      selected: true,
      highlight: true,
      class: 'bg-gray-200',
    },
  ],
});

const checkboxCellClass = cva('sticky left-0 h-auto items-start px-0', {
  variants: {
    selected: {
      true: '',
      false: '',
    },
    highlight: {
      true: '',
    },
  },
  compoundVariants: [
    {
      selected: true,
      highlight: true,
      class: 'bg-gray-100',
    },
  ],
});

const cellClass = cva('', {
  variants: {
    selected: {
      true: '',
      false: '',
    },
    highlight: {
      true: '',
    },
  },
  compoundVariants: [
    {
      selected: true,
      highlight: true,
      class: 'bg-gray-100',
    },
  ],
});

SelectableTable.Cell = DefaultTableCell;
SelectableTable.HeaderCell = Table.HeaderCell;
