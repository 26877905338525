import { observer } from 'mobx-react-lite';
import { Fragment } from 'react/jsx-runtime';
import { Product, Unit } from '@sweep/contract';
import { isNotEmptyArray, isNotNil, uniq } from '@sweep/utils';
import { Separated } from '@sweep/utils/react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { getOptionSupplierMatchings } from '../../services/getOptionSupplierMatchings';
import Divider from './Divider';

interface OptionSupplierCellsProps {
  product: Product;
}

function OptionSupplierCells({ product }: OptionSupplierCellsProps) {
  if (product.useSupplierByOption === true) {
    const optionSupplierMatchings = getOptionSupplierMatchings(product);

    return (
      <div className="flex h-full flex-col">
        <Separated with={<Divider type="horizontal" />}>
          {optionSupplierMatchings
            ?.sort((ma, mb) => mb.options.length - ma.options.length)
            .map((matching, index) => (
              <Fragment key={index}>
                <div className="flex">
                  <OptionCell options={matching.options} />
                  <Divider type="vertical" />
                  <SupplierCell
                    suppliers={
                      matching.supplierId != null ? [matching.supplierId] : []
                    }
                  />
                </div>
              </Fragment>
            ))}
        </Separated>
      </div>
    );
  }

  if (product.useSupplierBySalesChannel === true) {
    const salesChannelSuppliers =
      product.salesChannelSupplierIds?.map(
        (salesChannelSupplierId) => salesChannelSupplierId.supplierId
      ) ?? [];
    const suppliers = uniq([
      ...salesChannelSuppliers,
      product.supplierId,
    ]).filter(isNotNil);

    return (
      <div className="flex h-full">
        <OptionCell options={product.units} />
        <Divider type="vertical" />
        <SupplierCell suppliers={suppliers} useSupplierBySalesChannel />
      </div>
    );
  }

  return (
    <div className="flex h-full">
      <OptionCell options={product.units} />
      <Divider type="vertical" />
      <SupplierCell
        suppliers={product.supplierId != null ? [product.supplierId] : []}
        useSupplierBySalesChannel={product.useSupplierBySalesChannel}
      />
    </div>
  );
}

function OptionCell({ options }: { options?: Unit[] }) {
  const optionNames = options
    ?.map((option) => option.unit)
    .sort((a, b) => a.localeCompare(b));

  return (
    <div className="gap-8px p-8px flex w-3/5 min-w-0 flex-wrap">
      {optionNames?.map((option) => (
        <p
          key={option}
          className="rounded-8px text-medium-s px-12px py-4px size-fit bg-gray-100 text-blue-700"
        >
          {option}
        </p>
      ))}
    </div>
  );
}

function _SupplierCell({
  suppliers,
  useSupplierBySalesChannel,
}: {
  suppliers?: string[] | null;
  useSupplierBySalesChannel?: boolean;
}) {
  const oms = useOMSStore();

  const supplierNames = suppliers
    ?.map((supplierId) => oms.supplier.getSupplierName(supplierId))
    .filter(isNotNil)
    .sort((a, b) => a.localeCompare(b));

  return (
    <div className="px-16px py-8px gap-6px flex h-full w-2/5 flex-wrap items-center">
      {isNotEmptyArray(supplierNames) && (
        <p className="text-medium-s break-keep text-gray-700">
          {supplierNames.join(', ')}
        </p>
      )}
      {useSupplierBySalesChannel === true && (
        <p className="rounded-4px px-8px py-4px text-semibold-xs bg-gray-100 text-green-500">
          판매처별 설정
        </p>
      )}
    </div>
  );
}

const SupplierCell = observer(_SupplierCell);

export default observer(OptionSupplierCells);
