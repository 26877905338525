import {
  BULK_UPDATE_ORIGINAL_ORDER_URL,
  BulkUpdateOriginalOrderPayload,
  BulkUpdateOriginalOrderResponse,
} from '@sweep/contract';
import { assert } from '@sweep/utils';
import api from '../api';

export const bulkUpdateOriginalOrder = async (
  payload: BulkUpdateOriginalOrderPayload
): Promise<BulkUpdateOriginalOrderResponse> => {
  const response = await api.post<BulkUpdateOriginalOrderResponse>(
    BULK_UPDATE_ORIGINAL_ORDER_URL,
    payload
  );
  assert(response != null, 'Failed to bulk update original order');

  return response.data;
};
