import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Badge, NumberInput, TextInput } from '@sweep/sds';
import { CombinedShippingFormStore } from '../../CombinedShippingFormStore';
import { parseAmountUnit } from '../../services/parseAmountUnit';
import ProductListSubSection from './ProductListSubSection';
import SubSection from './SubSection';

interface UnitInputProps {
  store: CombinedShippingFormStore;
}

function UnitInput({ store }: UnitInputProps) {
  const [value, setValue] = useState<string>(
    `${store.amount ?? ''}${store.unit ?? ''}`
  );
  const handleValueChange = (value: string) => {
    setValue(value);
    const { amount, unit } = parseAmountUnit(value);
    store.setAmount(amount);
    store.setUnit(unit);
  };

  return (
    <div className="gap-12px flex flex-col">
      <div className="gap-8px flex-center flex">
        <p className="text-medium-s text-orange-500">총</p>
        <TextInput
          value={value}
          onChange={handleValueChange}
          className="w-84px min-w-0"
          deleteButton={false}
          placeholder="0kg, 0입"
          status={
            store.unitInputError &&
            (store.isInvalidAmountUnit || store.isAmountLessThenUnitAmount)
              ? 'error'
              : undefined
          }
        />
        <p className="text-medium-s text-orange-500">까지 합배송</p>
      </div>
      <ProductListSubSection
        products={store.selectedProducts}
        helperText={
          store.isSupplierUniformed
            ? undefined
            : '다른 공급사의 상품은 합배송할 수 없습니다.'
        }
      />
      <div className="gap-12px flex flex-col">
        <p className="text-medium-s text-gray-500">단위 정보를 확인해주세요</p>
        <SubSection className="gap-4px py-8px flex flex-col">
          {store.productNameOptions.map(({ type, name }) => (
            <div key={type + name} className="gap-12px flex items-center">
              <div className="flex flex-1 justify-end">
                <Badge variant="soft" color="gray" className="gap-4px flex">
                  {type === 'productName' && <span>{name}</span>}
                  {type === 'option' && (
                    <span className="text-blue-700">{name}</span>
                  )}
                </Badge>
              </div>
              <div className="gap-4px px-4px flex flex-1 items-center">
                <p className="text-medium-s text-gray-500">:</p>
                <NumberInput
                  className="w-72px"
                  value={store.getAmount(type, name) ?? undefined}
                  onChange={(value) => {
                    store.setProductNameOptionAmounts(type, name, value);
                  }}
                />
                <p className="text-medium-s text-gray-500">{store.unit}</p>
              </div>
            </div>
          ))}
        </SubSection>
      </div>
    </div>
  );
}

export default observer(UnitInput);
