import { z } from 'zod';
import { ShippingOrderProcessStatusCode } from '@sweep/domain/services/order-shipping-process';
import { BulkAPIResponse } from '../../type/BulkUpdateAPIResponse';

export const BULK_AUTO_DISPATCH_ORIGINAL_ORDER_URL =
  '/order-shipping-process/original-order/bulk-auto-dispatch';

export const BulkAutoDispatchOriginalOrderPayloadSchema = z.object({
  orders: z.array(
    z.object({
      uniqueCode: z.string(),
      shippingNumber: z.string().optional(),
      shippingCompany: z.string().optional(),
    })
  ),
});

export type BulkAutoDispatchOriginalOrderPayload = z.infer<
  typeof BulkAutoDispatchOriginalOrderPayloadSchema
>;

export const BulkAutoDispatchOriginalOrderResponseDataSchema = z.object({
  uniqueCode: z.string(),
  shippingNumber: z.string(),
  shippingCompany: z.string(),
  autoFulfilled: z.boolean(),
  failReason: z.string().optional(),
});

export type BulkAutoDispatchOriginalOrderResponseData = z.infer<
  typeof BulkAutoDispatchOriginalOrderResponseDataSchema
>;

export type BulkAutoDispatchOriginalOrderResponse = BulkAPIResponse<
  BulkAutoDispatchOriginalOrderResponseData,
  ShippingOrderProcessStatusCode
>[];
