import { computed } from 'mobx';
import { uniqBy } from '@sweep/utils';
import { OMSStore } from 'src/stores/OMSStore';
import { compareCombinedShippingTableProduct } from '../../services/compareCombinedShippingTableProduct';
import { toCombinedShippingTableProductsFromCombinedShipping } from '../../services/toCombinedShippingTableProductsFromCombinedShipping';
import { toCombinedShippingTableProductsFromProduct } from '../../services/toCombinedShippingTableProductsFromProductFromProduct';

export class CombinedShippingProductTableStore {
  @computed
  get combinedShippingTableProducts() {
    const tableProductsFromProduct = this.oms.product.products
      .map((product) =>
        toCombinedShippingTableProductsFromProduct(this.oms, product)
      )
      .flat();
    const tableProductsFromCombinedShipping =
      this.oms.combinedShipping.combinedShippings
        .map((combinedShipping) =>
          toCombinedShippingTableProductsFromCombinedShipping(
            this.oms,
            combinedShipping
          )
        )
        .flat();

    return uniqBy(
      [...tableProductsFromProduct, ...tableProductsFromCombinedShipping],
      ({ productId, option, supplierId }) =>
        `${productId}-${option ?? ''}-${supplierId}`
    ).sort(compareCombinedShippingTableProduct);
  }

  @computed
  get alreadyUsedCombinedShippingTableProducts() {
    return this.oms.combinedShipping.combinedShippings
      .filter((combinedShipping) => combinedShipping._id !== this.exceptedId)
      .map((combinedShipping) =>
        toCombinedShippingTableProductsFromCombinedShipping(
          this.oms,
          combinedShipping
        )
      )
      .flat();
  }

  constructor(
    private readonly oms: OMSStore,
    private readonly exceptedId: string | undefined = undefined
  ) {}
}
