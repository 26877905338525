import { useVirtualizer } from '@tanstack/react-virtual';
import { cva } from '@sweep/tailwind';
import { ColumnDef } from '../interface';
import TableCellRenderer from './TableCellRenderer';

interface TableBodyProps<T> {
  items: T[];
  columns: ColumnDef<T>[];
  getKey: (item: T) => string;
  containerRef: React.RefObject<HTMLDivElement>;
}

export function TableBody<T>({
  items,
  columns,
  getKey,
  containerRef,
}: TableBodyProps<T>) {
  const virtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => containerRef.current,
    estimateSize: () => 37,
    getItemKey: (index) => getKey(items[index]),
    overscan: 5,
  });

  const virtualItems = virtualizer.getVirtualItems();

  return (
    <div
      style={{
        height: `${virtualizer.getTotalSize()}px`,
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          width: '100%',
          top: 0,
          left: 0,
          transform: `translateY(${virtualItems.at(0)?.start ?? 0}px)`,
        }}
      >
        {virtualItems.map((virtualItem) => (
          <div
            key={getKey(items[virtualItem.index])}
            ref={virtualizer.measureElement}
            className={row()}
            data-index={virtualItem.index}
          >
            {columns.map((column, columnIndex) => (
              <TableCellRenderer
                key={`${virtualItem.index}-${columnIndex}`}
                row={items[virtualItem.index]}
                column={column}
                context={{
                  row: items[virtualItem.index],
                  columnIndex: columnIndex,
                  lastRow: virtualItem.index === items.length - 1,
                  lastColumn: columnIndex === columns.length - 1,
                }}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

const row = cva('min-h-36px flex w-full');
