import { isEmptyString } from '@sweep/utils';
import { MatchingOrder } from '../interface';

export function uniqueByOptionCode<T>(
  items: T[],
  getMatchingOrder: (item: T) => MatchingOrder
): T[] {
  const uniqueItems: T[] = [];
  const optionCodes: Set<string> = new Set();

  items.forEach((item) => {
    const matchingOrder = getMatchingOrder(item);
    const optionCode = matchingOrder.optionCode;

    if (isEmptyString(optionCode)) {
      uniqueItems.push(item);
      return;
    }

    if (!optionCodes.has(optionCode)) {
      optionCodes.add(optionCode);
      uniqueItems.push(item);
    }
  });

  return uniqueItems;
}
