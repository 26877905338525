import { useMemo } from 'react';
import { toast } from 'sonner';
import { IconEdit } from '@sweep/asset/icons';
import { NormalizedOrder, ORDER_SHIPPING_STATUS } from '@sweep/contract';
import { Button, ColumnDef, Table } from '@sweep/sds';
import { isEmptyString, isNotNil } from '@sweep/utils';
import {
  TableFilterHeaderCell,
  useTableFilter,
} from 'src/components/TableFilter';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { updateShippingOrder } from 'src/network/order-shipping-process/updateShippingOrder';
import { openEditShippingNumberFormModal } from '../components/EditShippingNumberFormModal/openEditShippingNumberFormModal';

export function useTableFilterColumnsWithEdit(params: {
  orders: NormalizedOrder[];
  useUniqueCodeSub?: boolean; // SWP-582 뉴스고 보여주기
}) {
  const { orders, useUniqueCodeSub } = params;

  const oms = useOMSStore();
  const { store, filterOrders } = useTableFilter();
  const headerKeys = oms.user.excelHeaderKeys;
  const columnMapping = oms.user.excelColumnMapping;

  const columns = useMemo(
    () =>
      headerKeys.map<ColumnDef<NormalizedOrder>>((key) => {
        if (key === 'uniqueCode' && useUniqueCodeSub === true) {
          return {
            header: (context) => (
              <TableFilterHeaderCell
                store={store}
                context={context}
                orders={orders}
                headerKey={key}
              >
                {columnMapping[key]}
              </TableFilterHeaderCell>
            ),
            accessorFn: (row) => row[key]?.toString() ?? '',
            cell: (context) => {
              const { row } = context;
              const tempUniqueCode = row.uniqueCodeAfterCustomization;
              return (
                <Table.Cell context={context}>{tempUniqueCode}</Table.Cell>
              );
            },
          };
        }

        if (key === 'shippingNumber') {
          return {
            header: (context) => (
              <TableFilterHeaderCell
                store={store}
                context={context}
                orders={orders}
                headerKey={key}
              >
                {columnMapping[key]}
              </TableFilterHeaderCell>
            ),
            accessorFn: (row) => row[key]?.toString() ?? '',
            cell: (context) => {
              const { row } = context;
              return (
                <Table.Cell className="flex-center" context={context}>
                  <UpdateOrderShippingInfoButton order={row} />
                </Table.Cell>
              );
            },
          };
        }

        return {
          header: (context) => (
            <TableFilterHeaderCell
              store={store}
              context={context}
              orders={orders}
              headerKey={key}
            >
              {columnMapping[key]}
            </TableFilterHeaderCell>
          ),
          accessorFn: (row) => row[key]?.toString() ?? '',
        };
      }),
    [columnMapping, headerKeys, orders, store]
  );

  const filteredOrders = useMemo(
    () => filterOrders(orders),
    [filterOrders, orders]
  );

  return { columns, filteredOrders, store };
}

function createNewNormalizedOrders(
  prevOrders: NormalizedOrder[],
  newOrder: NormalizedOrder
): NormalizedOrder[] {
  return prevOrders.map((prevOrder) => {
    const { uniqueCode, uniqueCodeAfterCustomization } = prevOrder;

    const isSameOrder =
      uniqueCode === newOrder.uniqueCode &&
      uniqueCodeAfterCustomization === newOrder.uniqueCodeAfterCustomization;

    if (isSameOrder) {
      return {
        ...prevOrder,
        ...newOrder,
      };
    }

    return prevOrder;
  });
}

const INSERT_SUCCESS_MESSAGE = '운송장이 입력되었습니다.';
const UPDATE_SUCCESS_MESSAGE = '운송장이 수정되었습니다.';
const UPDATE_ORDER_SHIPPING_INFO_BUTTON_ERROR_MESSAGE =
  '운송장 입력에 실패했습니다. 다시 시도해주세요.';

function UpdateOrderShippingInfoButton({ order }: { order: NormalizedOrder }) {
  const initShippingCompany = order.shippingCompany;
  const initShippingNumber = order.shippingNumber;

  const isUpdateAction =
    isNotNil(initShippingCompany) && isNotNil(initShippingNumber);

  const successToastMessage = isUpdateAction
    ? UPDATE_SUCCESS_MESSAGE
    : INSERT_SUCCESS_MESSAGE;

  const oms = useOMSStore();

  async function handleClick() {
    const shippingInvoice = await openEditShippingNumberFormModal({
      initShippingCompany: initShippingCompany ?? '',
      initShippingNumber: initShippingNumber ?? '',
    });

    if (shippingInvoice == null) {
      return;
    }

    const newShippingInfo = {
      ...order,
      ...shippingInvoice,
      shippingStatus: ORDER_SHIPPING_STATUS.reviewing,
    };

    oms.loading.batch(async () => {
      try {
        const isSuccess = await updateShippingOrder(newShippingInfo);
        if (isSuccess) {
          const newNormalizedOrders = createNewNormalizedOrders(
            oms.order.normalizedOrders,
            newShippingInfo
          );

          oms.order.setNormalizedOrders(newNormalizedOrders);

          toast.success(successToastMessage);
        } else {
          toast.error(UPDATE_ORDER_SHIPPING_INFO_BUTTON_ERROR_MESSAGE);
        }
      } catch (error) {
        toast.error(UPDATE_ORDER_SHIPPING_INFO_BUTTON_ERROR_MESSAGE);
      }
    });
  }

  const shippingNumber = order?.shippingNumber ?? '';

  const isEmptyShippingNumber = isEmptyString(shippingNumber);
  if (isEmptyShippingNumber) {
    return (
      <Button color="gray" size="xsmall" onClick={handleClick}>
        운송장 입력
      </Button>
    );
  }

  return (
    <div className="gap-4px flex items-center">
      <span>{shippingNumber}</span>
      <Button
        className="rounded-6px size-28px flex-center flex bg-gray-100"
        onClick={handleClick}
      >
        <IconEdit className="text-gray-500" />
      </Button>
    </div>
  );
}
