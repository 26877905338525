import { OMSStore } from 'src/stores/OMSStore';
import { Matching, MatchingWithMeta } from '../interface';

export function toMatchingWithMeta(
  oms: OMSStore,
  matching: Matching
): MatchingWithMeta | null {
  const compositionMatchingId = matching.compositionMatchingId;
  if (compositionMatchingId != null) {
    const compositionMatching = oms.cm.getCompositionMatchingById(
      compositionMatchingId
    );
    if (compositionMatching == null) {
      return null;
    }

    return {
      ...matching,
      count: compositionMatching.count,
      updatedAt: compositionMatching.updatedAt,
    };
  }

  const optionCodeMatchingId = matching.optionCodeMatchingId;
  if (optionCodeMatchingId != null) {
    const optionCodeMatching =
      oms.cm.getOptionCodeMatchingById(optionCodeMatchingId);
    if (optionCodeMatching == null) {
      return null;
    }

    return {
      ...matching,
      count: optionCodeMatching.count,
      updatedAt: optionCodeMatching.updatedAt,
    };
  }

  return null;
}
