import { useEffect, useRef } from 'react';
import { Slot } from '../../../Slot';
import { useTableContext } from '../context/TableContext';
import { ColumnDef, HeaderCellContext } from '../interface';
import DefaultTableHeaderCell from './DefaultTableHeaderCell';
import { ResizeHandler } from './ResizeHandler';

interface TableHeaderCellRendererProps<T> {
  header: ColumnDef<T>['header'];
  context: HeaderCellContext;
  index: number;
}

const BORDER_WIDTH = 1;

export function TableHeaderCellRenderer<T>({
  header,
  context,
  index,
}: TableHeaderCellRendererProps<T>) {
  const ref = useRef<HTMLDivElement | null>(null);
  const { columnWidths, onHeaderWidthChange } = useTableContext();
  const columnWidth = columnWidths[index];
  const width = columnWidth != null ? `${columnWidth}px` : undefined;
  const style: React.CSSProperties = {
    width,
    maxWidth: width,
  };

  useEffect(() => {
    if (columnWidth == null && ref.current) {
      onHeaderWidthChange(index, ref.current.clientWidth + BORDER_WIDTH);
    }
  }, [index, onHeaderWidthChange, columnWidth]);

  const headerComponent = header(context);

  if (typeof headerComponent === 'string') {
    return (
      <>
        <DefaultTableHeaderCell ref={ref} context={context} style={style}>
          {headerComponent}
        </DefaultTableHeaderCell>
        <ResizeHandler index={index} lastColumn={context.lastColumn} />
      </>
    );
  }

  return (
    <>
      <Slot key={index} ref={ref} style={style}>
        {headerComponent}
      </Slot>
      <ResizeHandler index={index} lastColumn={context.lastColumn} />
    </>
  );
}
