import { action, observable } from 'mobx';

export type Sort = {
  key: string | null;
  type: SortType;
};

export type SortType = 'ASC' | 'DESC' | null;

export class TableFilterStore {
  @observable
  accessor sort: Sort = { key: null, type: null };

  @observable
  accessor removedFields: Record<string, string[] | undefined> = {};

  isFiltered(key: string) {
    return (this.removedFields[key]?.length ?? 0) > 0;
  }

  constructor() {}

  @action.bound
  setSort(key: string | null, type: SortType) {
    this.sort = { key, type };
  }

  @action.bound
  setRemovedFields(key: string, values: string[]) {
    this.removedFields = { ...this.removedFields, [key]: values };
  }

  @action.bound
  reset() {
    this.sort = { key: null, type: null };
    this.removedFields = {};
  }
}
