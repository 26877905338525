export const OrderStatus = {
  ///////////////////////////////////////////
  //////////       결제 완료         //////////
  ///////////////////////////////////////////
  paymentComplete: '결제 완료',
  paymentCompletePartialDelivery: '결제 완료(일부 배송 완료)',

  //////////////////////////////////////////
  //////////      상품 준비 중       //////////
  //////////////////////////////////////////
  productPreparing: '상품 준비 중',
  productPreparingPartialDelivery: '상품 준비 중(일부 배송 완료)',
  partialDelivery: '일부 배송 완료',

  //////////////////////////////////////////
  //////////         배송 중        //////////
  //////////////////////////////////////////
  inDelivery: '배송 중',

  //////////////////////////////////////////
  //////////       취소 요청        //////////
  //////////////////////////////////////////
  cancelRequest: '취소 요청',
  cancelRequestPaymentComplete: '취소 요청(결제 완료)',

  //////////////////////////////////////////
  //////////         기타          //////////
  //////////////////////////////////////////
  exchange: '교환',
  exchangeRequest: '교환 요청',
  exchangeInDelivery: '교환 반송 중',
  exchangeRedelivery: '교환 재배송 중',
  exchangeHold: '교환 보류',

  refundRequest: '환불 요청',
  refundExpected: '환불 예정',
  refundRequestWaiting: '환불 요청 대기',

  returnRequest: '반품 요청',
  returnInDelivery: '반품 반송 중',

  // NOTE(@이지원): 알리
  fundProcessing: 'FUND_PROCESSING',
  riskControl: 'RISK_CONTROL',
  inIssue: 'IN_ISSUE',
  inFrozen: 'IN_FROZEN',
  waitSellerExamineMoney: 'WAIT_SELLER_EXAMINE_MONEY',

  //////////////////////////////////////////
  //////////        Hidden        //////////
  //////////////////////////////////////////
  collectionProcessing: '수거 처리 중',
  purchaseConfirmation: '구매 확정',
  teamRecruitment: '팀 모집 중',
  paymentWaiting: '결제 대기 중',
  unpaidCancel: '미결제 취소',
  complete: '완료',
  refundComplete: '환불 완료',
  deliveryComplete: '배송 완료',
  cancelComplete: '취소 완료',
  returnComplete: '반품 완료',
  returnPaymentCancelComplete: '반품 결제 취소 완료',
  returnRefundComplete: '반품 환불 완료',
  returnSendComplete: '반품 반송 완료',

  // NOTE(@이지원): 11번가
  deliveryAddressInputWaiting: '배송지입력대기',

  // NOTE(@이지원): Cafe24
  cancelBeforePayment: '입금 전 취소',

  // NOTE(@경한) 카카오메이커스
  returnApprove: '반품 승인',
  returnProcessing: '반품 처리 중',
  returnReject: '반품 반려',
  returnHold: '반품 보류',
  returnWithdraw: '반품 철회',

  cancelApprove: '취소 승인',
  cancelProcessing: '취소 처리 중',
  cancelReject: '취소 반려',
  cancelHold: '취소 보류',
  cancelWithdraw: '취소 철회',

  redeliveryRequest: '재배송 요청',
  redeliveryApprove: '재배송 승인',
  redeliveryProcessing: '재배송 처리 중',
  redeliveryComplete: '재배송 완료',
  redeliveryReject: '재배송 반려',
  redeliveryHold: '재배송 보류',
  redeliveryWithdraw: '재배송 철회',

  // NOTE(@경한) 샵바이
  exchangeProcess: '교환 처리 중',
  exchangeComplete: '교환 완료',
} as const;

export type OrderStatus = (typeof OrderStatus)[keyof typeof OrderStatus];

export function isPaymentComplete(status: OrderStatus) {
  const paymentCompleteStatusList: OrderStatus[] = [
    OrderStatus.paymentComplete,
    OrderStatus.paymentCompletePartialDelivery,
  ];

  return paymentCompleteStatusList.includes(status);
}

export function isProductPreparing(status: OrderStatus) {
  const productPreparingStatusList: OrderStatus[] = [
    OrderStatus.productPreparing,
    OrderStatus.productPreparingPartialDelivery,
    OrderStatus.partialDelivery,
  ];

  return productPreparingStatusList.includes(status);
}

export function isInDelivery(status: OrderStatus) {
  return status === OrderStatus.inDelivery;
}

export function isCancelRequest(status: OrderStatus) {
  const cancelRequestStatusList: OrderStatus[] = [
    OrderStatus.cancelRequest,
    OrderStatus.cancelRequestPaymentComplete,
  ];

  return cancelRequestStatusList.includes(status);
}

export function isEtc(status: OrderStatus) {
  const etcStatusList: OrderStatus[] = [
    OrderStatus.exchange,
    OrderStatus.exchangeRequest,
    OrderStatus.exchangeInDelivery,
    OrderStatus.exchangeRedelivery,
    OrderStatus.exchangeHold,

    OrderStatus.refundRequest,
    OrderStatus.refundExpected,
    OrderStatus.refundRequestWaiting,

    OrderStatus.returnRequest,
    OrderStatus.returnInDelivery,

    OrderStatus.riskControl,
    OrderStatus.fundProcessing,
    OrderStatus.inIssue,
    OrderStatus.inFrozen,
    OrderStatus.waitSellerExamineMoney,
  ];

  return etcStatusList.includes(status);
}

export function isHidden(status: OrderStatus) {
  const hiddenStatusList: OrderStatus[] = [
    OrderStatus.collectionProcessing,
    OrderStatus.purchaseConfirmation,
    OrderStatus.teamRecruitment,
    OrderStatus.paymentWaiting,
    OrderStatus.unpaidCancel,
    OrderStatus.complete,
    OrderStatus.refundComplete,
    OrderStatus.deliveryComplete,
    OrderStatus.cancelComplete,
    OrderStatus.returnComplete,
    OrderStatus.returnPaymentCancelComplete,
    OrderStatus.returnRefundComplete,
    OrderStatus.returnSendComplete,

    OrderStatus.deliveryAddressInputWaiting,

    OrderStatus.cancelBeforePayment,

    OrderStatus.returnApprove,
    OrderStatus.returnProcessing,
    OrderStatus.returnReject,
    OrderStatus.returnHold,
    OrderStatus.returnWithdraw,

    OrderStatus.cancelApprove,
    OrderStatus.cancelProcessing,
    OrderStatus.cancelReject,
    OrderStatus.cancelHold,
    OrderStatus.cancelWithdraw,

    OrderStatus.redeliveryRequest,
    OrderStatus.redeliveryApprove,
    OrderStatus.redeliveryProcessing,
    OrderStatus.redeliveryComplete,
    OrderStatus.redeliveryReject,
    OrderStatus.redeliveryHold,
    OrderStatus.redeliveryWithdraw,

    OrderStatus.exchangeProcess,
    OrderStatus.exchangeComplete,
  ];

  return hiddenStatusList.includes(status);
}
