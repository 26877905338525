import {
  GET_DRAFTS_URL,
  GetDragtsResponse,
  PROCESS_DRAFTS_URL,
  ProcessDraftsBody,
  REGISTER_DRAFTS_URL,
  RegisterDraftsBody,
} from '@sweep/contract';
import { tryCatch } from '@sweep/utils';
import api, { APIResponse } from './api';

export async function getDrafts() {
  const response = await tryCatch(() =>
    api.get<APIResponse<GetDragtsResponse>>(GET_DRAFTS_URL)
  );

  return response?.data;
}

export async function registerDrafts(filenames: string[]) {
  const response = await api.post<unknown, RegisterDraftsBody>(
    REGISTER_DRAFTS_URL,
    { filenames }
  );

  return response?.data;
}

export async function processDrafts(filenames: string[]) {
  const response = await api.put<unknown, ProcessDraftsBody>(
    PROCESS_DRAFTS_URL,
    { filenames }
  );

  return response?.data;
}
