import { startsWith } from 'es-toolkit/compat';
import { Order } from '@sweep/contract';
import { CheckedAddress, correctOrderAddresses } from 'src/network/fastapi';
import { isNotEmptyString } from 'src/utils/string';
import { AbstractPlugin } from '../../interface';

interface CheckAddressPluginConfig {
  fixInvalidAddress?: boolean;
}

export class CheckAddressPlugin extends AbstractPlugin<
  CheckAddressPluginConfig | undefined
> {
  transform = async (orders: Order[]): Promise<Order[]> => {
    const aliExpressOrderIndexes =
      CheckAddressPlugin.findAliExpressOrderIndexes(orders);

    const aliExpressOrders = aliExpressOrderIndexes.map(
      (index) => orders[index]
    );

    if (aliExpressOrders.length === 0) {
      return orders;
    }

    const response = await correctOrderAddresses(aliExpressOrders);

    const isFailed =
      response == null ||
      response.success === false ||
      response.data.length !== aliExpressOrders.length;

    if (isFailed) {
      return orders;
    }

    const newOrders = [...orders];

    aliExpressOrderIndexes.forEach((originalIndex, arrayIndex) => {
      newOrders[originalIndex] = CheckAddressPlugin.fixAddress(
        orders[originalIndex],
        response.data[arrayIndex],
        this.config
      );
    });

    return newOrders;
  };

  static fixAddress(
    order: Order,
    checkedAddress: CheckedAddress,
    config?: CheckAddressPluginConfig
  ) {
    const isFixInvalidAddress = config?.fixInvalidAddress ?? false;
    const newOrder = { ...order };
    const checkedAddressResult = checkedAddress.result;

    newOrder.isAddressValid = checkedAddress.result;
    newOrder.mainAddress = checkedAddress.main_address;
    newOrder.detailAddress = checkedAddress.detail_address;

    switch (checkedAddressResult) {
      case 'okay': {
        newOrder.address = checkedAddress.data;
        return newOrder;
      }
      case 'fixed': {
        if (isFixInvalidAddress) {
          newOrder.address = checkedAddress.tried_data;
          newOrder.memo = [];
          newOrder.memo.push({
            text: '기존주소:' + checkedAddress.data + '\n',
          });

          return newOrder;
        }

        newOrder.isAddressValid = 'fail';
        newOrder.address = checkedAddress.data;
        break;
      }
      case 'fail': {
        newOrder.address = isFixInvalidAddress
          ? checkedAddress.tried_data
          : checkedAddress.data;
      }
    }

    if (newOrder.isAddressValid !== 'fail') {
      return newOrder;
    }

    newOrder.memo = [];
    if (checkedAddress.message.includes('지번 주소입니다.')) {
      if (
        checkedAddress.message.length > 2 &&
        checkedAddress.message[2].includes('해당하는 도로명 주소가')
      ) {
        newOrder.isAddressValid = 'multiple';
        newOrder.memo.push({
          text: checkedAddress.message[2] + '\n',
        });
        newOrder.memo = newOrder.memo.concat(
          checkedAddress.tried_tried_data.map((item) => ({
            text: item + '\n',
            font: { bold: true },
          }))
        );

        return newOrder;
      }

      newOrder.memo = newOrder.memo.concat(
        checkedAddress.message.slice(2).map((item) => ({ text: item + '\n' }))
      );
    } else {
      const postCodeMessage = checkedAddress.message.find((message) =>
        message.includes('고객이 입력한 우편번호')
      );

      if (postCodeMessage != null) {
        newOrder.isAddressValid = 'postcode';
        newOrder.memo.push({
          text: postCodeMessage + '\n',
        });
        return newOrder;
      }
    }

    if (newOrder.isAddressValid !== 'fail') {
      return newOrder;
    }

    newOrder.memo = newOrder.memo.concat(
      checkedAddress.message.map((item) => ({
        text: item + '\n',
      }))
    );

    newOrder.memo = newOrder.memo.concat(
      checkedAddress.tried_tried_data.map((item) => ({
        text: item + '\n',
        font: { bold: true },
      }))
    );

    return newOrder;
  }

  static findAliExpressOrderIndexes(orders: Order[]): number[] {
    return orders.reduce<number[]>((acc, order, index) => {
      const isAliExpress =
        isNotEmptyString(order.shoppingMall as string) &&
        startsWith(order.shoppingMall as string, 'AliExpress');

      if (isAliExpress) {
        acc.push(index);
      }
      return acc;
    }, []);
  }
}
