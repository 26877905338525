import { Order } from '@sweep/contract';

export function groupByKey(orders: Order[]): Record<string, Order[]> {
  return orders.reduce<Record<string, Order[]>>((acc, order) => {
    const key = getKey(order);
    const prevOrdes = acc[key] ?? [];
    acc[key] = [...prevOrdes, order];
    return acc;
  }, {});
}

function getKey(order: Order): string {
  const { address, supplierId, partnerId, shoppingMallId } = order;
  return [address, supplierId, partnerId, shoppingMallId]
    .map((value) => value ?? '')
    .join('/');
}
