import { z } from 'zod';

export const SPREADSHEET_API_ERROR_CODES = {
  DECRYPT_ERROR: 'SPREADSHEET_DECRYPT_ERROR',
  PARSE_ERROR: 'SPREADSHEET_PARSE_ERROR',
} as const;

export type SpreadsheetApiErrorCodes =
  (typeof SPREADSHEET_API_ERROR_CODES)[keyof typeof SPREADSHEET_API_ERROR_CODES];

export const ParseSpreadsheetRequestSchema = z.object({
  file: z.any(),
  password: z.string().optional().nullable(),
  includeRawData: z.boolean().optional(),
  tagUploadSource: z.string().optional(),
});

export type ParseSpreadsheetRequest = z.infer<
  typeof ParseSpreadsheetRequestSchema
>;

export const ParseSpreadsheetResponseSchema = <T>() =>
  z.discriminatedUnion('success', [
    z.object({
      success: z.literal(true),
      data: z.custom<T>(),
    }),
    z.object({
      success: z.literal(false),
      error: z.unknown(),
      customErrorCode: z
        .enum([
          SPREADSHEET_API_ERROR_CODES.DECRYPT_ERROR,
          SPREADSHEET_API_ERROR_CODES.PARSE_ERROR,
        ])
        .optional(),
    }),
  ]);

export type ParseSpreadsheetResponse<T> = z.infer<
  ReturnType<typeof ParseSpreadsheetResponseSchema<T>>
>;
