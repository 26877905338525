import { removeSpaces } from '@sweep/utils';
import {
  fallbackColumnMappingArray,
  priorityColumnMappingArray,
  secondaryColumnMappingArray,
} from './mappingArrayConstants';

// NOTE(@hungjoon): SWP-447 기존 로직 그대로 가져옴, 타입만 추가
export const mapKeys = (originalKeys: string[]): string[] => {
  const processedKeys: string[] = [];
  let keys: string[] = [];
  keys = originalKeys.map((key) => {
    const cleanedKey = removeSpaces(key);

    for (const [mappedKey, possibleKeys] of Object.entries(
      priorityColumnMappingArray
    )) {
      // Check if the key is already processed to avoid duplicates
      if (processedKeys.includes(mappedKey)) {
        continue;
      }

      if (possibleKeys.includes(cleanedKey)) {
        processedKeys.push(mappedKey);
        return mappedKey;
      }
    }
    return key;
  });

  keys = keys.map((key) => {
    const cleanedKey = removeSpaces(key);
    for (const [mappedKey, possibleKeys] of Object.entries(
      secondaryColumnMappingArray
    )) {
      if (processedKeys.includes(mappedKey)) {
        continue;
      }

      for (const possibleKey of possibleKeys) {
        if (cleanedKey.includes(possibleKey)) {
          processedKeys.push(mappedKey);
          return mappedKey;
        }
      }
    }
    return key;
  });

  keys = keys.map((key) => {
    const cleanedKey = removeSpaces(key);
    for (const [mappedKey, possibleKeys] of Object.entries(
      fallbackColumnMappingArray
    )) {
      if (processedKeys.includes(mappedKey)) {
        continue;
      }

      for (const possibleKey of possibleKeys) {
        if (cleanedKey.includes(possibleKey)) {
          processedKeys.push(mappedKey);
          return mappedKey;
        }
      }
    }
    return key;
  });

  return keys;
};
