import { useState } from 'react';
import { IconBigDown, IconBigDownLeft } from '@sweep/asset/icons';
import { cva } from '@sweep/tailwind';
import { useView } from 'src/hooks/useView';
import {
  imageInterviewProfile계림농장,
  imageInterviewProfile위플,
  imageInterviewProfile주왕산가든,
} from 'src/images';
import { amplitude } from 'src/third-parties/amplitude';
import InterviewCard, { Interview } from './InterviewCard';

const interviews: Interview[] = [
  {
    logo: imageInterviewProfile위플,
    companyName: '위플',
    position: '발주관리팀장님',
    title:
      '"스윕은 셀러의 전반적인 업무 경험 자체를 굉장히 신경 쓰는 팀입니다."',
    content: (
      <>
        확실히 올웨이즈 초창기부터 오랜 시간 일하신 경험이 있어서 그런지
        이커머스 산업에 대한 이해도가 높다는 것이 느껴졌습니다.
        <br />
        그리고 언제나 저희 문제에 대해 함께 고민해주시고 해결하려고 노력해주시는
        모습에 진심이 느껴져서 정말 좋았습니다.
      </>
    ),
    description: '잇피소드, 더신선, 일류농사꾼 등 브랜드 운영',
  },
  {
    logo: imageInterviewProfile주왕산가든,
    companyName: '주왕산가든',
    position: '대표님',
    title: '"스윕은 굉장히 세련된 조직인 것 같습니다."',
    content: (
      <>
        '대부분의 쇼핑몰 통합관리 솔루션이 이커머스 초창기에 생겨났다보니 도입할
        때 오히려 공부를 해야하는 경우가 많았습니다.
        <br />
        반면 스윕은 학습비용 없이 기존의 판매 관성을 그대로 따라 운영할 수
        있다는 점이 굉장히 인상 깊습니다.'
      </>
    ),
    description: '스마트스토어 프리미엄 셀러',
  },
  {
    logo: imageInterviewProfile계림농장,
    companyName: '계림농장',
    position: '발주관리 담당자님',
    title: '"이제 스윕 없이는 일을 못할 것 같아요."',
    content: (
      <>
        스윕을 쓰기 전에는 온라인 주문 건을 처리하는 데 오전 업무시간을 모두
        써도 모자랐어요. 하지만 스윕을 쓰고나서는 30분도 안 걸리게 되었습니다.
        <br />
        특히 새로운 사람이 업무를 맡아도 하루만에 이해하고 쓸 수 있을만큼 쉬운
        게 정말 큰 장점인 것 같아요.
      </>
    ),
    description: '국내 최대 계란 생산업체',
  },
];

function Interviews() {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const handleClickLeft = () => {
    setCurrentIndex((prev) => Math.max(prev - 1, 0));
    amplitude.track('[LP-B] Click Prev Interview');
  };

  const handleClickRight = () => {
    setCurrentIndex((prev) => Math.min(prev + 1, interviews.length - 1));
    amplitude.track('[LP-B] Click Next Interview');
  };

  const viewRef = useView<HTMLDivElement>(() =>
    amplitude.track('[LP-B] View Interviews')
  );

  return (
    <div
      className="flex h-[527.58px] w-[850px] items-center justify-between"
      ref={viewRef}
    >
      <div
        className={button({ visible: currentIndex > 0 })}
        onClick={handleClickLeft}
      >
        <IconBigDownLeft />
      </div>
      <div className="relative flex items-center justify-center">
        <InterviewCard rotated={true} visible={true} />
        <InterviewCard rotated={false} visible={true} />
        {interviews.map((interview, index) => (
          <InterviewCard
            key={index}
            rotated={index > currentIndex}
            visible={index === currentIndex}
            interview={interview}
          />
        ))}
      </div>
      <div
        className={button({ visible: currentIndex < interviews.length - 1 })}
        onClick={handleClickRight}
      >
        <IconBigDown />
      </div>
    </div>
  );
}

const button = cva(
  'flex size-[49px] items-center justify-center rounded-full bg-gray-400 transition-all duration-500',
  {
    variants: {
      visible: {
        true: 'cursor-pointer',
        false: 'opacity-0',
      },
    },
  }
);

export default Interviews;
