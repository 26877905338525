interface TableCountProps {
  count: number;
}

export function TableCount({ count }: TableCountProps) {
  return (
    <p className="text-medium-s text-gray-500">
      목록 (<span className="text-semibold-s text-blue-500">{count}</span>
      건)
    </p>
  );
}
