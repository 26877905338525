import {
  CREATE_COMBINED_SHIPPING_URL,
  CreateCombinedShippingBody,
  CreateCombinedShippingDTO,
  CreateCombinedShippingResponse,
  DELETE_COMBINED_SHIPPING_URL,
  DeleteCombinedShippingResponse,
  GET_COMBINED_SHIPPING_URL,
  GetCombinedShippingResponse,
  UPDATE_COMBINED_SHIPPING_URL,
  UpdateCombinedShippingBody,
  UpdateCombinedShippingDTO,
  UpdateCombinedShippingResponse,
} from '@sweep/contract';
import { tryCatch } from '@sweep/utils';
import api, { APIResponse } from './api';

export async function getCombinedShippings() {
  const response = await tryCatch(() =>
    api.get<APIResponse<GetCombinedShippingResponse>>(GET_COMBINED_SHIPPING_URL)
  );

  return response?.data;
}

export async function createCombinedShipping(dto: CreateCombinedShippingDTO) {
  const body: CreateCombinedShippingBody = dto;
  const response = await tryCatch(() =>
    api.post<APIResponse<CreateCombinedShippingResponse>>(
      CREATE_COMBINED_SHIPPING_URL,
      body
    )
  );

  return response?.data;
}

export async function updateCombinedShipping(
  id: string,
  dto: UpdateCombinedShippingDTO
) {
  const body: UpdateCombinedShippingBody = dto;
  const response = await tryCatch(() =>
    api.put<APIResponse<UpdateCombinedShippingResponse>>(
      UPDATE_COMBINED_SHIPPING_URL(id),
      body
    )
  );

  return response?.data;
}

export async function deleteCombinedShipping(id: string) {
  const response = await tryCatch(() =>
    api.delete<APIResponse<DeleteCombinedShippingResponse>>(
      DELETE_COMBINED_SHIPPING_URL(id)
    )
  );

  return response?.data;
}
