import {
  COLUMN_MAPPING_GUIDES,
  ColumnMappingGuide,
} from '@sweep/domain/services/header-mappings';
import { getMappedEntries } from './getMappedEntries';

const SWEEP_PREFIX = 'Sweep_';

export function findEstimatedColumnMapping(
  header: string[],
  columnMappingGuides: ColumnMappingGuide[] = []
): Record<string, string> {
  const guides = [...columnMappingGuides, ...COLUMN_MAPPING_GUIDES];
  const entries = getMappedEntries(header, guides);
  const entryMapByItem = new Map(entries.map((entry) => [entry.item, entry]));

  return header.reduce<Record<string, string>>((acc, item) => {
    const entry = entryMapByItem.get(item);
    if (entry) {
      acc[entry.key] = item;
      return acc;
    }

    acc[`${SWEEP_PREFIX}${item}`] = item;
    return acc;
  }, {});
}
