import { MINUTE_IN_MS } from './constants';

export function getLocalDate(date?: Date): Date {
  const utcDate = date ?? new Date();
  const utcTime = utcDate.getTime();
  const timezoneOffset = utcDate.getTimezoneOffset();

  const localDate = new Date(utcTime - timezoneOffset * MINUTE_IN_MS);

  return localDate;
}
