import {
  cloneElement,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  isValidElement,
  ReactNode,
} from 'react';
import { useRefs } from '@sweep/utils/react';
import { useTooltipContext } from './context/TooltipContext';
import { useTooltipOuterContext } from './context/TooltipOuterContext';

export interface TooltipTriggerProps extends HTMLAttributes<HTMLElement> {
  children?: ReactNode;
}

function _TooltipTrigger(
  { children, ...props }: TooltipTriggerProps,
  ref: ForwardedRef<HTMLElement>
) {
  const { disabled } = useTooltipOuterContext();

  if (disabled) {
    return children;
  }

  return (
    <TooltipTriggerInner {...props} ref={ref}>
      {children}
    </TooltipTriggerInner>
  );
}

export const TooltipTrigger = forwardRef(_TooltipTrigger);

function _TooltipTriggerInner(
  { children, ...props }: TooltipTriggerProps,
  ref: ForwardedRef<HTMLElement>
) {
  const { open, refs, getReferenceProps } = useTooltipContext();
  const childrenRef = (children as any).ref;
  const innerRef = useRefs([refs.setReference, ref, childrenRef]);

  if (!isValidElement(children)) {
    return null;
  }

  return cloneElement(
    children,
    getReferenceProps?.({
      ref: innerRef,
      ...props,
      ...children.props,
      'data-state': open ? 'open' : 'closed',
    })
  );
}

const TooltipTriggerInner = forwardRef(_TooltipTriggerInner);
