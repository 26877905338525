import PCLandingPage from './PC/PCLandingPage';

function LandingPage() {
  return (
    <div className="relative h-screen w-full">
      <PCLandingPage />
    </div>
  );
}

export default LandingPage;
