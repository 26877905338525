import { FormatProductNameConfig } from '@sweep/contract';
import { isEmptyArray, isNotNil } from '@sweep/utils';
import { FormatOrder } from '../interface';
import { formatQuantity } from './formatQuantity';
import { getFormattedName } from './getFormattedName';

const DEFAULT_CUSTOM_SEPARATOR = ' + ';

export function formatProductName(
  order: FormatOrder,
  config?: FormatProductNameConfig
): FormatOrder {
  const {
    customFormat,
    customQuantityFormat,
    showQuantityAlways = false,
    useProductName = false,
  } = config ?? {};

  if (isEmptyArray(order.products) && !useProductName) {
    return {
      ...order,
      option: [order.productName, order.option]
        .filter(isNotNil)
        .join(' ')
        .trim(),
    };
  }

  const formattedOrder = formatQuantity(order, {
    setDataQuantity1: config?.setDataQuantity1,
    setOrderQuantity1: config?.setOrderQuantity1,
  });

  const products = formattedOrder.products ?? [];
  if (products.length === 0) {
    return formattedOrder;
  }

  const formattedNames = products.map((product) =>
    getFormattedName(product, {
      customFormat,
      customQuantityFormat,
      showQuantityAlways,
      useProductName,
    })
  );

  const customSeparator = config?.customSeparator ?? DEFAULT_CUSTOM_SEPARATOR;

  const formattedName = formattedNames.sort().join(customSeparator);

  const productName = useProductName
    ? products.at(0)?.productName
    : order.productName;

  return {
    ...formattedOrder,
    productName,
    option: formattedName,
  };
}
