import { Row } from 'exceljs';
import { ExcelFormat, ExcelModel } from '@sweep/contract';
import { getLocalDate } from '@sweep/utils';

export function applyExcelModel(
  row: Row,
  model: ExcelModel,
  headers: string[]
) {
  const { formats } = model;
  if (formats == null) {
    return;
  }

  Object.entries(formats).forEach(([key, format]) => {
    const keyIndex = headers.findIndex((col) => col === key) + 1;
    if (keyIndex === 0) {
      return;
    }

    if (format != null) {
      applyExcelFormat(row, format, keyIndex);
    }
  });
}

const DEFAULT_NUMVER_NUM_FMT = '#,##0_ ';

function applyExcelFormat(row: Row, format: ExcelFormat, keyIndex: number) {
  if (format.type === 'number') {
    const value = row.getCell(keyIndex).value;
    const isNumber = typeof value === 'number';
    const isNumberString =
      typeof value === 'string' && Number.isNaN(Number(value)) === false;
    if (isNumberString) {
      row.getCell(keyIndex).value = Number(value);
    }

    if (!isNumber && !isNumberString) {
      return;
    }

    row.getCell(keyIndex).numFmt = format.numFmt ?? DEFAULT_NUMVER_NUM_FMT;
    return;
  }

  if (format.type === 'date') {
    const value = row.getCell(keyIndex).value;
    const isDateNumber = typeof value === 'number' || value instanceof Date;
    const isDateString = typeof value === 'string' && !isNaN(Date.parse(value));
    if (isDateString) {
      const localDate = getLocalDate(new Date(value));
      // NOTE(@이지원): 엑셀에서는 UTC 시간을 기준으로 보여주기때문에, local시간에 맞는 Date값을 넣어줍니다.
      row.getCell(keyIndex).value = localDate;
    }

    if (!isDateNumber && !isDateString) {
      return;
    }

    if (format.numFmt == null) {
      return;
    }

    row.getCell(keyIndex).numFmt = format.numFmt;
    return;
  }
}
