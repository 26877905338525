import { z } from 'zod';
import { ShippingOrderProcessStatusCode } from '../../../../domain/src/services/order-shipping-process/orderShippingInfo.type';
import { BulkAPIResponse } from '../../type/BulkUpdateAPIResponse';
import { Order } from '../../type/order/Order';

export const BULK_UPDATE_ORIGINAL_ORDER_URL =
  '/order-shipping-process/original-order/bulk-update';

export const BulkUpdateOriginalOrderPayloadSchema = z.object({
  orders: z.custom<Order[]>(),
});

export type BulkUpdateOriginalOrderPayload = z.infer<
  typeof BulkUpdateOriginalOrderPayloadSchema
>;

/*
require changes
ASIS

export const RequestBulkUpdateOriginalOrdersResponseSchema = z.array(
  z.object({
    isUpdated: z.boolean(),
    originalOrders: z.custom<Order>(),
  })
);

*/
export type BulkUpdateOriginalOrderResponse = BulkAPIResponse<
  Order,
  ShippingOrderProcessStatusCode
>[];
