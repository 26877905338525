import { z } from 'zod';

export const ZCombinedShippingProduct = z.object({
  productId: z.string(),
  option: z.string().nullish(),
});
export type CombinedShippingProduct = z.infer<typeof ZCombinedShippingProduct>;

export const ZCombinedShipping = z.object({
  _id: z.string(),
  type: z.union([z.literal('count'), z.literal('unit')]),
  amount: z.number(),
  unit: z.string().nullish(),
  supplierId: z.string().nullish(),
  products: ZCombinedShippingProduct.array(),
});
export type CombinedShipping = z.infer<typeof ZCombinedShipping>;
