import { NormalizedOrder } from '@sweep/contract';
import { OrderProcessType } from '../OrderProcessScreen';
import { isShippingOrderStatusPending } from './isShippingOrderStatusPending';
import { isShippingOrderStatusProcessing } from './isShippingOrderStatusProcessing';
import { isShippingOrderStatusReviewing } from './isShippingOrderStatusReviewing';
import { isShippingOrderStatusShipped } from './isShippingOrderStatusShipped';

export function getCountByShippingStatus(
  orders: NormalizedOrder[]
): Record<OrderProcessType, number> {
  const counts: Record<OrderProcessType, number> = {
    total: orders.length,
    pending: 0,
    processing: 0,
    reviewing: 0,
    shipped: 0,
  };

  orders.forEach((order) => {
    if (isShippingOrderStatusPending(order)) {
      counts['pending'] += 1;
      return;
    }

    if (isShippingOrderStatusProcessing(order)) {
      counts['processing'] += 1;
      return;
    }

    if (isShippingOrderStatusReviewing(order)) {
      counts['reviewing'] += 1;
      return;
    }

    if (isShippingOrderStatusShipped(order)) {
      counts['shipped'] += 1;
      return;
    }
  });

  return counts;
}
