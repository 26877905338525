import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { IconInfo, IconOrderDelivery } from '@sweep/asset/icons';
import {
  Badge,
  Button,
  Modal,
  NumberInput,
  SearchInput,
  Tooltip,
} from '@sweep/sds';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { useSearchState } from 'src/hooks/useSearchState';
import {
  CombinedShippingMultiCreateResult,
  CombinedShippingTableProduct,
} from '../../interface';
import CombinedShippingProductTable from '../CombinedShippingProductTable/CombinedShippingProductTable';
import { CombinedShippingProductTableStore } from '../CombinedShippingProductTable/CombinedShippingProductTableStore';
import Section from '../common/Section';

interface CombinedShippingMultiCreateFormModalProps {
  open: boolean;
  onSubmit: (result: CombinedShippingMultiCreateResult | null) => void;
}

function CombinedShippingMultiCreateFormModal({
  open,
  onSubmit,
}: CombinedShippingMultiCreateFormModalProps) {
  const oms = useOMSStore();
  const [tableStore] = useState(
    () => new CombinedShippingProductTableStore(oms)
  );

  const [count, setCount] = useState<number | undefined>();
  const [selectedProducts, setSelectedProducts] = useState<
    CombinedShippingTableProduct[]
  >([]);

  const [search, setSearch, filterSearch] = useSearchState({
    keys: ['productName', 'option', 'supplierName'],
  });
  const filteredCombinedShippingTableProducts = filterSearch(
    tableStore.combinedShippingTableProducts
  );

  const handleSubmit = () => {
    onSubmit({ products: selectedProducts, count: count ?? 0 });
  };

  const isDisabledSubmit =
    selectedProducts.length === 0 || count == null || count === 0;

  return (
    <Modal
      open={open}
      onClose={() => onSubmit(null)}
      className="h-[690px] max-h-[calc(100vh-120px)] w-[1180px] max-w-[calc(100vw-80px)]"
    >
      <Modal.Title className="mb-20px">합배송 일괄 설정</Modal.Title>
      <div className="gap-32px mb-28px flex min-h-0 flex-1">
        <Section position="left" className="gap-16px">
          <p className="text-medium-s text-gray-500">
            함께 배송 보낼 상품을 선택해주세요
          </p>
          <SearchInput
            placeholder="검색"
            value={search}
            onChange={setSearch}
            className="shrink-0"
          />
          <CombinedShippingProductTable
            items={filteredCombinedShippingTableProducts}
            selectedItems={selectedProducts}
            onSelect={setSelectedProducts}
            disabledItems={tableStore.alreadyUsedCombinedShippingTableProducts}
          />
        </Section>
        <div className="w-1px h-full shrink-0 bg-gray-200" />
        <Section
          position="right"
          className="gap-14px flex flex-col overflow-y-auto"
        >
          <p className="text-medium-s text-gray-500">
            합배송 기준을 입력해주세요
          </p>
          <div className="flex items-center justify-center">
            <p className="text-semibold-s mr-6px text-gray-600">개수 기준</p>
            <Tooltip type="info" placement="bottom-start">
              <Tooltip.Trigger>
                <IconInfo className="mr-12px text-gray-300" />
              </Tooltip.Trigger>
              <Tooltip.Content>
                합배송할 수 있는 최대 개수를 설정합니다.
                <br />예{')'} 30구 구성은 4개까지 합배송 가능
              </Tooltip.Content>
            </Tooltip>
            <div className="gap-4px flex items-center">
              <p className="text-medium-s text-orange-500">각각</p>
              <NumberInput
                value={count}
                onChange={setCount}
                className="w-50px mr-4px shrink-0"
                placeholder="0"
              />
              <p className="text-medium-s text-orange-500">개까지 합배송</p>
            </div>
          </div>
          <div className="gap-8px flex flex-col">
            {selectedProducts.map(({ productName, option }) => (
              <div
                key={`${productName}-${option}`}
                className="p-16px rounded-8px gap-12px flex-center flex border border-gray-200 bg-white"
              >
                <Badge variant="soft" color="gray" className="gap-6px flex">
                  {productName}
                  {option != null && (
                    <span className="text-blue-700">{option}</span>
                  )}
                </Badge>
                <IconOrderDelivery className="text-orange-500" />
              </div>
            ))}
          </div>
        </Section>
      </div>
      <div className="flex justify-center">
        <Button
          disabled={isDisabledSubmit}
          onClick={handleSubmit}
          className="w-200px"
        >
          등록하기
        </Button>
      </div>
    </Modal>
  );
}

export default observer(CombinedShippingMultiCreateFormModal);
