import { isNotNil } from 'es-toolkit';
import { Order } from '@sweep/contract';
import { ColumnMapping } from 'src/models/ColumnMapping';
import { Partner } from 'src/models/Partner';

export type ExcelHeaderMatch = {
  columnKeys: string[];
  columnNames: string[];
  columnMapping: ColumnMapping;
};

export type ExcelHeaderMatchOptions = {
  headerStartIndex: number;
  headerEndIndex: number;
  orderStartIndex: number;
  validSheetIndex: number;
};

export type ExcelHeaderMatchTemplate = {
  enabled: boolean;
  templateFile?: string;
};

export type ExcelHeaderMatchResult = {
  isTemplateEnabled: boolean;
  isDefaultMatchingEnabled: boolean;
  headerMatch: ExcelHeaderMatch;
  options: ExcelHeaderMatchOptions;
  template?: ExcelHeaderMatchTemplate;
};

const DEFAULT_VALID_SHEET_INDEX = 0;
const DEFAULT_HEADER_START_INDEX = 0;
const DEFAULT_HEADER_END_INDEX = 0;
const DEFAULT_ORDER_START_INDEX = 1;

function mapKeysByColumnMapping(
  header: string[],
  columnMapping: ColumnMapping
): string[] {
  if (header == null || header.length === 0) {
    return [];
  }

  if (columnMapping == null || Object.keys(columnMapping).length === 0) {
    return [];
  }

  return header
    .map((key) =>
      Object.keys(columnMapping).find((eng) => columnMapping[eng] === key)
    )
    .filter(isNotNil);
}

export function findExcelHeaderMatchFromPartner(
  partners: Partner[],
  order: Order
): ExcelHeaderMatchResult | null {
  const { partnerId, matchingId } = order;

  const partner = partners.find(
    (partner) => partner._id.toString() === partnerId
  );

  if (partner == null) {
    return null;
  }

  const {
    headerForShipping: optionalHeaderForShipping,
    columnMappingForShipping: optionalColumnMappingForShipping,
    columnMapping: optionalColumnMapping,
  } = partner;

  const isHeaderForShippingValid =
    optionalHeaderForShipping != null && optionalHeaderForShipping.length > 0;
  const isColumnMappingForShippingValid =
    optionalColumnMappingForShipping != null &&
    Object.keys(optionalColumnMappingForShipping).length > 0;
  const isColumnMappingForPartnerValid =
    optionalColumnMapping != null &&
    Object.keys(optionalColumnMapping).length > 0;

  const isColumnMappingValid =
    isColumnMappingForShippingValid || isColumnMappingForPartnerValid;

  const useDefaultMatching = isHeaderForShippingValid && isColumnMappingValid;
  if (useDefaultMatching) {
    const columnMapping =
      optionalColumnMappingForShipping ?? optionalColumnMapping ?? {};

    const columnKeys = mapKeysByColumnMapping(
      optionalHeaderForShipping ?? [],
      columnMapping
    );

    const columnNames = optionalHeaderForShipping ?? [];

    return {
      isTemplateEnabled: false,
      isDefaultMatchingEnabled: true,
      headerMatch: {
        columnKeys,
        columnNames,
        columnMapping,
      },
      options: {
        validSheetIndex: DEFAULT_VALID_SHEET_INDEX,
        headerStartIndex: DEFAULT_HEADER_START_INDEX,
        headerEndIndex: DEFAULT_HEADER_END_INDEX,
        orderStartIndex: DEFAULT_ORDER_START_INDEX,
      },
    };
  }

  const matching = partner.matchings?.find(
    (matching) => matching.id === matchingId
  );

  if (matching == null) {
    return null;
  }

  const {
    header: columnNames,
    columnMapping,
    xlsxTemplateSetting,
    headerStartIndex: optionalHeaderStartIndex,
    headerEndIndex: optionalHeaderEndIndex,
    orderStartIndex: optionalOrderStartIndex,
    validSheetIndex: optionalValidSheetIndex,
  } = matching;

  const columnKeys = mapKeysByColumnMapping(columnNames, columnMapping);

  const validSheetIndex = optionalValidSheetIndex ?? DEFAULT_VALID_SHEET_INDEX;
  const headerStartIndex =
    optionalHeaderStartIndex ?? DEFAULT_HEADER_START_INDEX;
  const headerEndIndex = optionalHeaderEndIndex ?? DEFAULT_HEADER_END_INDEX;
  const orderStartIndex = optionalOrderStartIndex ?? DEFAULT_ORDER_START_INDEX;

  const isTemplateEnabled =
    xlsxTemplateSetting?.enabled === true &&
    xlsxTemplateSetting.templateFile != null;

  if (isTemplateEnabled) {
    const templateFile = xlsxTemplateSetting?.templateFile ?? '';

    return {
      isTemplateEnabled: true,
      isDefaultMatchingEnabled: false,
      headerMatch: {
        columnKeys: columnKeys,
        columnNames: columnNames,
        columnMapping,
      },
      options: {
        headerStartIndex,
        headerEndIndex,
        orderStartIndex,
        validSheetIndex,
      },
      template: {
        enabled: isTemplateEnabled,
        templateFile,
      },
    };
  }

  return {
    isTemplateEnabled: false,
    isDefaultMatchingEnabled: false,
    headerMatch: {
      columnKeys: columnKeys,
      columnNames: columnNames,
      columnMapping,
    },
    options: {
      headerStartIndex,
      headerEndIndex,
      orderStartIndex,
      validSheetIndex,
    },
  };
}
