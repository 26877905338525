import { Nullable } from '@sweep/utils';
import { JsonInput } from '../JsonInput';
import { ObjectSchema } from '../schema';

interface JsonObjectInputProps {
  schema: ObjectSchema;
  value: Nullable<Record<string, unknown>>;
  onChange: (value: Record<string, unknown>) => void;
  collapse?: boolean;
}

export function JsonObjectInput({
  schema,
  value,
  onChange,
  collapse = true,
}: JsonObjectInputProps) {
  const propertyInputs = Object.entries(schema.properties).map(
    ([key, propertySchema]) => (
      <div key={key}>
        <JsonInput
          schema={{
            ...propertySchema,
            label: propertySchema.label ?? key,
          }}
          value={value?.[key]}
          onChange={(newValue) =>
            onChange({
              ...(value ?? {}),
              [key]: newValue,
            })
          }
        />
      </div>
    )
  );

  if (collapse) {
    return (
      <details>
        <summary className="text-bold-m mb-6px w-fit cursor-pointer text-gray-700">
          {schema.description}
        </summary>
        <div className="pl-20px flex flex-col gap-4px">{propertyInputs}</div>
      </details>
    );
  }

  return <div className="gap-4px flex flex-col">{propertyInputs}</div>;
}
