import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Outlet } from 'react-router-dom';
import NewFooter from 'src/components/footer/NewFooter';
import { amplitude } from 'src/third-parties/amplitude';

function AliExpressLayout() {
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });
  const isTablet = useMediaQuery({
    query: '(min-width: 768px) and (max-width: 1099px)',
  });

  useEffect(() => {
    amplitude.track('[Ali] View Landing Page', {
      device: isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop',
    });
  }, [isMobile, isTablet]);

  return (
    <div className="flex w-full items-center justify-center overflow-x-hidden bg-gray-100">
      <div className="flex w-full max-w-[480px] flex-col">
        <Outlet />
        <NewFooter />
      </div>
    </div>
  );
}

export default AliExpressLayout;
