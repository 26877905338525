import { isNil } from 'es-toolkit';
import { UserSettingLikeModel } from '../../../models/userSettingLike.model';
import { hasKeysInArray, hasKeysInObject } from '@sweep/utils';
import { mapKeysByColumnMapping } from '../../common/mapKeysByColumnMapping';
import { createHeaderMappingResult } from '../createHeaderMappingResult';
import {
  HeaderMappingResult,
  HeaderMatchContext,
  HeaderMatchReasonType,
} from '../types';

export const findUserSettingHeaderMatch = (
  settings: UserSettingLikeModel | null | undefined,
  context: HeaderMatchContext
): HeaderMappingResult | null => {
  const { headerRow, requiredHeaderKeys } = context;
  const defaultMappingColumn = settings?.columnTranslation;

  if (isNil(defaultMappingColumn)) {
    return null;
  }

  if (!hasKeysInObject(defaultMappingColumn, requiredHeaderKeys)) {
    return null;
  }

  const headerKeys = mapKeysByColumnMapping(headerRow, defaultMappingColumn);
  if (!hasKeysInArray(headerKeys, requiredHeaderKeys)) {
    return null;
  }

  return createHeaderMappingResult({
    headerKeys,
    mappingColumn: defaultMappingColumn,
    defaultShippingCompany:
      settings?.defaultShippingCompany ?? context.defaultShippingCompany,
    matchReason: HeaderMatchReasonType.USER_SETTING_MATCH,
  });
};
