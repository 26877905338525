import { ReactNode } from 'react';
import { cva } from '@sweep/tailwind';

export interface Interview {
  logo: string;
  companyName: string;
  position: string;
  title: string;
  content: ReactNode;
  description: string;
}

interface InterviewCardProps {
  rotated: boolean;
  visible: boolean;
  interview?: Interview;
}

function InterviewCard({ rotated, visible, interview }: InterviewCardProps) {
  return (
    <div className={card({ rotated, visible })}>
      {interview != null && (
        <div className="flex size-full flex-col justify-between text-left">
          <div className="flex items-center gap-[16px]">
            <img className="size-[60px]" src={interview.logo} alt="profile1" />
            <p className="text-[18px] font-bold leading-[24px] tracking-tight">
              <span className="text-blue-600">{interview.companyName}</span>{' '}
              {interview.position}
            </p>
          </div>
          <div className="flex flex-col gap-[12px]">
            <p className="text-[18px] font-bold leading-[29px] tracking-tight">
              {interview.title}
            </p>
            <p className="text-[16px] font-medium leading-[25px] tracking-tight">
              {interview.content}
            </p>
          </div>
          <p className="text-[16px] font-medium leading-[24px] tracking-tight text-gray-600">
            {interview.description}
          </p>
        </div>
      )}
    </div>
  );
}

const card = cva(
  'flex h-[409px] w-[512px] flex-col items-center justify-center gap-[10px] rounded-[32px] border border-white bg-gradient-to-b from-[rgba(255,255,255,0.96)] to-[rgba(255,255,255,0.69)] p-[60px] shadow-[0_0_80px_0_#00000008] transition-all duration-1000',
  {
    variants: {
      rotated: {
        true: 'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rotate-[-15deg]',
        false: 'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
      },
      visible: {
        true: '',
        false: 'opacity-0',
      },
    },
  }
);

export default InterviewCard;
