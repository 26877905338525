import { CreatePartnerDTO, Partner } from 'models/Partner';
import { Plugin } from 'stores/plugin/interface';
import { assert } from '@sweep/utils';
import api, { APIResponse } from './api';
import { CreatePluginDTO } from './plugin';

interface GetPartnersResponse {
  result?: Partner[];
}

export async function getPartners() {
  const response = await api.get<GetPartnersResponse>('/partner');
  assert(response != null, 'Failed to get partners');

  return response.data;
}

export async function createPartner(partner: CreatePartnerDTO) {
  const response = await api.post<Partner>('/partner/register', {
    partnerInfo: partner,
  });
  assert(response != null, 'Failed to create partner');

  return response.data;
}

export async function updatePartner(
  partnerId: string,
  partner: Partial<Partner>
) {
  const response = await api.put('/partner/update', {
    partnerId,
    partnerInfo: partner,
  });
  assert(response != null, 'Failed to update partner');
  return response.data;
}

export async function deletePartner(partnerId: string) {
  const response = await api.put('/partner/delete', {
    partnerId,
  });

  return response;
}

interface CreatePartnerPlugin {
  partner: Partner;
  plugin: Plugin;
}

export async function createPartnerPlugin(
  partnerId: string,
  createPluginDTO: CreatePluginDTO
) {
  const response = await api.post<APIResponse<CreatePartnerPlugin>>(
    `/partner/${partnerId}/plugin`,
    createPluginDTO
  );
  assert(response != null, 'Failed to create partner plugin');

  return response.data;
}

export async function deletePartnerPlugin(partnerId: string, pluginId: string) {
  const response = await api.delete<APIResponse<Partner>>(
    `/partner/${partnerId}/plugin/${pluginId}`
  );
  assert(response != null, 'Failed to delete partner plugin');

  return response.data;
}
