interface HeaderSelectProps {
  header: string[];
  value: string | number | null;
  onChange: (value: string) => void;
  required?: boolean;
}

function HeaderSelect({
  header,
  value,
  onChange,
  required,
}: HeaderSelectProps) {
  return (
    <select
      value={value ?? ''}
      onChange={(e) => onChange(e.target.value)}
      className={`h-40px px-16px w-full rounded-lg border border-gray-300 ${
        value == null ? 'text-gray-400' : 'text-gray-700'
      }`}
      required={required}
    >
      <option value="">선택되지 않음</option>
      {header.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ))}
    </select>
  );
}

export default HeaderSelect;
