import { Timezone } from './interface';

export const MINUTE_IN_MS = 60 * 1000;
export const HOUR_IN_MS = 60 * MINUTE_IN_MS;
export const DAY_IN_MS = 24 * HOUR_IN_MS;
export const MONTH_IN_MS = 30 * DAY_IN_MS;

export const OFFSET_BY_TIMEZONE: Record<Timezone, number> = {
  'America/Los_Angeles': 8 * HOUR_IN_MS,
  'Asia/Seoul': -9 * HOUR_IN_MS,
  'Asia/Shanghai': -8 * HOUR_IN_MS,
};
