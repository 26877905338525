import { useState } from 'react';
import { DispatchedOrder } from '@sweep/contract';
import Button from 'src/components/buttons/Button';
import useShippingUpload from 'src/hooks/useShippingUpload';
import useUpload from 'src/hooks/useUpload';
import { IconDeleteAll, IconPapers, IconWriting } from '@sweep/asset/icons';
import { isEmptyString } from 'src/utils/string';
import { isValid } from 'src/utils/utils';

interface RejectCanceledOrderModalProps {
  onFormSubmit: (rejectReason: string, orders: DispatchedOrder[]) => void;
  orders: DispatchedOrder[];
}

interface ShippingInfo {
  shippingCompany: string;
  shippingNumber: string;
  uniqueCode?: string | undefined;
  orderNumber?: string | undefined;
}

const RejectCanceledOrderModal = ({
  onFormSubmit,
  orders,
}: RejectCanceledOrderModalProps) => {
  const [shippingOrders, setShippingOrders] = useState<DispatchedOrder[]>(
    orders.map((order) => ({
      ...order,
      shippingCompany: 'CJ대한통운',
      shippingNumber: '',
    }))
  );
  const { extractShippingInfo } = useShippingUpload();
  const { validateFiles } = useUpload();

  const [rejectReason, setRejectReason] = useState('');
  const [file, setFile] = useState<{ name: string } | null>(null);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (isEmptyString(rejectReason)) {
      alert('취소 거부 상세사유를 입력해주세요.');
      return;
    }

    if (shippingOrders.some((order) => isEmptyString(order.shippingNumber))) {
      alert('운송장번호를 입력해주세요.');
      return;
    }

    onFormSubmit(rejectReason, shippingOrders);
  };

  const handleExcelFileUpload = async (e: any) => {
    try {
      const files = await validateFiles(e);

      const shippingInfos = await extractShippingInfo(files);

      if (
        !shippingInfos[0].shippingNumber ||
        !shippingInfos[0].shippingCompany ||
        (!shippingInfos[0].uniqueCode && !shippingInfos[0].orderNumber)
      ) {
        alert(
          '송장번호, 택배사, 스윕고유번호 또는 주문번호가 포함된 엑셀 파일을 업로드해주세요.'
        );
        return;
      }

      if (isValid(shippingInfos)) {
        setFile(e.target.files[0]);

        const updatedSelectedOrders = shippingOrders.map((cancelingOrder) => {
          const updatedOrder = (
            shippingInfos as unknown as ShippingInfo[]
          ).find(
            (order) =>
              order.uniqueCode === cancelingOrder.uniqueCode?.toString() ||
              order.orderNumber === cancelingOrder.orderNumber?.toString()
          );

          if (isValid(updatedOrder)) {
            return {
              ...cancelingOrder,
              shippingCompany: updatedOrder?.shippingCompany,
              shippingNumber: updatedOrder?.shippingNumber,
            };
          }

          return cancelingOrder;
        });

        setShippingOrders(updatedSelectedOrders);
      }
    } catch (error) {
      console.error('Error reading excel file:', error);
    }
    e.target.value = '';
  };

  const handleSelectShippingCompany = (e: any, index: number) => {
    const targetOrder = shippingOrders.at(index);
    if (targetOrder == null) {
      return;
    }

    setShippingOrders([
      ...shippingOrders.slice(0, index),
      {
        ...targetOrder,
        shippingCompany: e.target.value,
      },
      ...shippingOrders.slice(index + 1),
    ]);
  };

  const handleSelectShippingNumber = (e: any, index: number) => {
    const targetOrder = shippingOrders.at(index);
    if (targetOrder == null) {
      return;
    }

    setShippingOrders([
      ...shippingOrders.slice(0, index),
      {
        ...targetOrder,
        shippingNumber: e.target.value,
      },
      ...shippingOrders.slice(index + 1),
    ]);
  };

  return (
    <form onSubmit={handleSubmit} className="w-full flex-col">
      <div className="mb-6">
        <div>
          <div className="flex items-center">
            <div className="flex size-[20px] items-center justify-center rounded-xl bg-[#343D4B] text-[12px] text-white">
              1
            </div>
            <div className="w-[6px]" />
            <label className="block text-[16px] font-medium text-[#343D4B]">
              취소 거부 상세사유 입력
            </label>
          </div>
          <div className="mt-[12px] flex-col items-center justify-between">
            <div>
              <textarea
                className="h-[137px] w-full rounded-lg border-[1.5px] border-[#CED7E0] px-[16px] py-[13px] text-[16px]"
                value={rejectReason}
                onChange={(e) => setRejectReason(e.target.value)}
                placeholder="거부 사유를 입력해주세요"
              />
            </div>

            <div className="mt-[12px] flex items-center">
              <div className="flex">
                <div>
                  <IconWriting className="text-gray-500" />
                </div>
                <div className="ml-[4px] text-[14px] font-bold text-[#6B7683]">
                  자주쓰는 문구
                </div>
              </div>
              <div className="w-[16px]" />
              <div
                className="cursor-pointer rounded-lg border border-[#CED7E0] px-[11px] py-[8px] text-[14px] font-semibold text-[#5B606C]"
                onClick={() =>
                  setRejectReason('이미 발송되어 취소가 불가합니다.')
                }
              >
                이미 발송되어 취소가 불가합니다.
              </div>
            </div>
          </div>
        </div>

        <div className="mt-[46px]">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="flex size-[20px] items-center justify-center rounded-xl bg-[#343D4B] text-[12px] text-white">
                2
              </div>
              <div className="w-[6px]" />
              <label className="block text-[16px] font-medium text-[#343D4B]">
                주문건 운송장번호 입력
              </label>
            </div>

            <div className="mx-[20px] grow">
              <hr className="border-t border-[#EBF0F5]" />
            </div>

            {file ? (
              <div className="flex items-center ">
                <div>
                  <IconPapers className="size-5 text-gray-700" />
                </div>
                <div className="ml-[2px]">
                  <span className="truncate text-sm font-medium text-gray-500">
                    {file.name}
                  </span>
                </div>
                <div
                  className="ml-[8px] flex cursor-pointer items-center"
                  onClick={() => {
                    setFile(null);
                  }}
                >
                  <IconDeleteAll className="text-gray-500" />
                </div>
              </div>
            ) : (
              <Button
                name="엑셀파일로 일괄 등록하기"
                color="black"
                type="input"
                onClick={handleExcelFileUpload}
              />
            )}
          </div>

          <div className="mt-[20px] flex justify-between gap-[24px]">
            <div className="flex w-full items-center border-b border-[#CED7E0] bg-[#F5F7FA] px-[16px] py-[10px] text-[16px] font-bold">
              주문번호
            </div>
            <div className="flex w-full items-center border-b border-[#CED7E0] bg-[#F5F7FA] px-[16px] py-[10px] text-[16px] font-bold">
              택배사
            </div>
            <div className="flex w-full items-center border-b border-[#CED7E0] bg-[#F5F7FA] px-[16px] py-[10px] text-[16px] font-bold">
              운송장번호
            </div>
          </div>

          {shippingOrders &&
            shippingOrders.map((order, index) => (
              <>
                <div
                  key={index}
                  className="flex w-full items-center justify-between gap-[24px]"
                >
                  <div className="flex-1 items-center">
                    <div className="px-[16px]">{order.orderNumber || ''}</div>
                  </div>

                  <div className="flex-1 items-center justify-center py-[12px]">
                    <div className="flex rounded-lg border border-[#CED7E0] pl-[16px] pr-[12px] text-[16px]">
                      <select
                        value={order.shippingCompany ?? ''}
                        onChange={(e) => handleSelectShippingCompany(e, index)}
                        className="w-full py-[10px]"
                      >
                        <option value="CJ대한통운">CJ대한통운</option>
                        <option value="롯데택배">롯데택배</option>
                        <option value="한진택배">한진택배</option>
                        <option value="로젠택배">로젠택배</option>
                        <option value="우체국택배">우체국택배</option>
                        <option value="경동택배">경동택배</option>
                        <option value="천일택배">천일택배</option>
                        <option value="대신택배">대신택배</option>
                        <option value="일양로지스">일양로지스</option>
                        <option value="DHL">DHL</option>
                        <option value="기타">기타</option>
                      </select>
                    </div>
                  </div>

                  <div className="flex-1 items-center justify-center rounded-lg border border-[#CED7E0]">
                    <div className="px-[16px] py-[10px] text-[16px]">
                      <input
                        value={order.shippingNumber ?? ''}
                        placeholder="번호를 입력하세요."
                        className="focus:outline-none"
                        onChange={(e) => handleSelectShippingNumber(e, index)}
                      />
                    </div>
                  </div>
                </div>

                <hr className="border-gray" />
              </>
            ))}
        </div>
      </div>

      <div className="flex justify-end">
        <Button name="주문취소 거부하기" type="submit" />
      </div>
    </form>
  );
};

export default RejectCanceledOrderModal;
