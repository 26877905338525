import { parseNumber } from '@sweep/utils';
import { FormatOrder } from '../interface';

export function formatQuantity(
  order: FormatOrder,
  options: {
    setDataQuantity1?: boolean;
    setOrderQuantity1?: boolean;
  }
): FormatOrder {
  const products = order.products;
  if (products == null || products.length === 0) {
    return order;
  }

  const quantity = parseNumber(order.quantity);
  if (quantity == null) {
    return order;
  }

  if (options.setDataQuantity1 === true) {
    if (products.length !== 1) {
      return order;
    }

    const [product] = products;
    const productQuantity = product.quantity;
    return {
      ...order,
      quantity: productQuantity * quantity,
      products: [{ ...product, quantity: 1 }],
    };
  }

  if (options.setOrderQuantity1 === true) {
    return {
      ...order,
      quantity: 1,
      products: products.map((product) => ({
        ...product,
        quantity: product.quantity * quantity,
      })),
    };
  }

  return order;
}
