import { noop } from './noop';
import { sleep } from './sleep';

interface RetryContext {
  attempts: number;
  isLastAttempt: boolean;
}

export async function retry<T>(
  fn: (context: RetryContext) => Promise<T>,
  options: {
    retries?: number;
    interval?: number;
    onRetry?: (error: unknown) => void;
    onError?: (error: unknown) => void;
    validate?: (result: T, context: RetryContext) => boolean;
    validateErrorMessage?: (result: T) => string;
  } = {}
): Promise<T> {
  const {
    retries = 3,
    interval = 1000,
    onRetry = noop,
    onError = noop,
    validate = () => true,
    validateErrorMessage,
  } = options;

  return await _retry(fn, {
    attempts: 1,
    retries,
    interval,
    onRetry,
    onError,
    validate,
    validateErrorMessage,
  });
}

async function _retry<T>(
  fn: (context: RetryContext) => Promise<T>,
  {
    attempts,
    retries,
    interval,
    onRetry,
    onError,
    validate,
    validateErrorMessage,
  }: {
    attempts: number;
    retries: number;
    interval: number;
    onRetry: (error: unknown) => void;
    onError: (error: unknown) => void;
    validate: (result: T, context: RetryContext) => boolean;
    validateErrorMessage?: (result: T) => string;
  }
): Promise<T> {
  try {
    const context: RetryContext = {
      attempts,
      isLastAttempt: attempts >= retries,
    };
    const result = await fn(context);

    if (validate(result, context)) {
      return result;
    }

    const errorMessage =
      validateErrorMessage?.(result) ??
      `Retry validation failed. result: ${JSON.stringify(result)}`;
    throw new Error(errorMessage);
  } catch (err) {
    onError(err);
    if (attempts >= retries) {
      throw err;
    }

    onRetry(err);
    await sleep(interval);
    return await _retry(fn, {
      attempts: attempts + 1,
      retries,
      interval,
      onRetry,
      onError,
      validate,
      validateErrorMessage,
    });
  }
}
