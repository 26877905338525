import { isEmptyArray } from '@sweep/utils';
import { MatchedOrder, Matching } from '../interface';
import { toMatchingOrder } from './toMatchingOrder';
import { toMatchingProduct } from './toMatchingProduct';

export function toMatching(order: MatchedOrder): Matching | null {
  const products = order.data;
  if (isEmptyArray(products)) {
    return null;
  }

  return {
    order: toMatchingOrder(order),
    products: products.map(toMatchingProduct),
    optionCodeMatchingId: order.plugin?.cm?.optionCodeMatchingId,
    compositionMatchingId: order.plugin?.cm?.compositionMatchingId,
  };
}
