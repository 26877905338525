import { OrderWithBasicInfo } from './orderShippingInfo.type';

export function findMatchingOrderUsingBasicInfo<T extends OrderWithBasicInfo>(
  targetOrder: T,
  alreadyProcessedShippingOrderRecords: Record<string, T>
): T | null {
  const {
    contactNumber: targetPhoneNumber,
    address: targetAddress,
    name: targetName,
  } = targetOrder;

  const matchingOrders = Object.values(
    alreadyProcessedShippingOrderRecords
  ).filter((order) => {
    const { contactNumber, address, name } = order;

    return (
      contactNumber === targetPhoneNumber &&
      address === targetAddress &&
      name === targetName
    );
  });

  const isOneToOneMatching = matchingOrders.length === 1;

  if (isOneToOneMatching) {
    return matchingOrders[0];
  }

  return null;
}
