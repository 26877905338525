import { useEffect, useRef, useState } from 'react';
import { cva } from '@sweep/tailwind';

interface FadeInProps {
  children: React.ReactNode;
  threshold?: number;
  delay?: number;
}

function FadeIn({ children, threshold = 1, delay = 0 }: FadeInProps) {
  const [visible, setVisible] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && ref.current != null) {
          const timer = setTimeout(() => {
            setVisible(true);
          }, delay);

          return () => clearTimeout(timer);
        }
      },
      { threshold }
    );
    if (ref.current != null) {
      observer.observe(ref.current);
    }
    return () => observer.disconnect();
  }, [delay, threshold]);

  return (
    <div ref={ref} className="size-auto overflow-hidden">
      <div className={container({ visible })}>{children}</div>
    </div>
  );
}

const container = cva('h-auto w-full transition-all duration-1000', {
  variants: {
    visible: {
      true: '',
      false: 'opacity-0',
    },
  },
});

export default FadeIn;
