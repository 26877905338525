import { cva } from '@sweep/tailwind';
import { ExpandedCheckbox } from '@sweep/sds';
import Divider from './Divider';

interface ProductTableHeaderProps {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
}

function ProductTableHeader({
  checked,
  onCheckedChange,
}: ProductTableHeaderProps) {
  return (
    <div className="flex h-[36px] bg-gray-100">
      <ExpandedCheckbox checked={checked} onCheckedChange={onCheckedChange} />
      <Divider type="vertical" />
      <div className={headerCell({ className: 'w-[50%]' })}>
        <p className={text()}>상품명</p>
      </div>
      <Divider type="vertical" />
      <div className="flex w-1/2">
        <div className={headerCell({ className: 'w-[60%]' })}>
          <p className={text()}>옵션</p>
        </div>
        <Divider type="vertical" />
        <div className={headerCell({ className: 'w-[40%]' })}>
          <p className={text()}>공급사</p>
        </div>
      </div>
      <Divider type="vertical" />
      <div className={headerCell({ className: 'w-[60px] flex-shrink-0' })}>
        <p className={text()}>수정</p>
      </div>
    </div>
  );
}

const headerCell = cva('flex h-full items-center');

const text = cva('text-medium-s ml-[16px] text-gray-700');

export default ProductTableHeader;
