import { Button } from '@sweep/sds';
import { useOMSStore } from 'hooks/useOMSStore';
import { observer } from 'mobx-react-lite';
import { DispatchModalStore } from '../../store/DispatchModalStore';
import DispatchResultContent from './DispatchResultContent';
import HelpInfo from './HelpInfo';

interface DispatchFailureResultProps {
  store: DispatchModalStore;
}

function DispatchFailureResult({ store }: DispatchFailureResultProps) {
  const oms = useOMSStore();

  const retryRejectedShoppingMalls = async () => {
    const rejectedShoppingMalls = store.failedShoppingMallNames;
    const orders = await store.getDispatchedOrders(rejectedShoppingMalls);

    oms.order.dispatch.pushDispatchedOrders(orders);
  };

  return (
    <div className="flex flex-col">
      <p className="text-medium-m text-gray-700">
        주문 수집에 실패한 쇼핑몰이 있습니다.
      </p>
      <div className="h-24px" />
      <DispatchResultContent
        successNames={store.successedShoppingMallNames}
        failedNames={store.failedShoppingMallNames}
        rightTabAccessory={<HelpInfo />}
      />
      <div className="h-20px" />
      <Button
        onClick={retryRejectedShoppingMalls}
        className="w-[200px] self-center"
      >
        실패 건 다시 시도하기
      </Button>
    </div>
  );
}

export default observer(DispatchFailureResult);
