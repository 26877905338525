import { z } from 'zod';
import { DispatchedOrder } from '../../type/order/DispatchedOrder';

export type OrderWithShippingInvoice = DispatchedOrder & {
  shippingNumber: string;
  shippingCompany: string;
};

export const FulfillOrderSchema = z.object({
  uniqueCode: z.string(),
  shippingCompany: z.string(),
  shippingNumber: z.string(),
});

export type FulfillOrder = z.infer<typeof FulfillOrderSchema>;

export type FulfilledOrderResult =
  | ({ success: true } & FulfillOrder)
  | ({ success: false; failReason: string } & FulfillOrder);

// POST /fulfill-order/fulfill-orders
export const FULFILL_ORDERS_URL = '/fulfill-order/fulfill-orders';

export const FulfillOrdersBodySchema = z.object({
  orders: z.array(FulfillOrderSchema),
});
export type FulfillOrdersBody = z.infer<typeof FulfillOrdersBodySchema>;

export type FulfillOrdersResponse = FulfilledOrderResult[];
