import { observer } from 'mobx-react-lite';
import { cva } from '@sweep/tailwind';
import { SalesChannel } from '../../interface';
import { ProductFormStore } from '../../ProductFormStore';
import DeleteButton from './DeleteButton';
import PlusButton from './PlusButton';
import SalesChannelsInput from './SalesChannelsInput';
import SupplierInput from './SupplierInput';

interface SupplierInputSectionProps {
  store: ProductFormStore;
  className?: string;
}

function SupplierInputSection({ store, className }: SupplierInputSectionProps) {
  const matchings = store.salesChannelSupplierMatchings;

  const handleMatchingCreate = () => {
    store.setSalesChannelSupplierMatchings([
      ...matchings,
      { salesChannels: [], supplierId: null },
    ]);
  };

  const handleMatchingSalesChannelsChange =
    (index: number) => (newSalesChannels: SalesChannel[]) => {
      const newMatchings = store.salesChannelSupplierMatchings.map(
        (matching, i) => {
          if (i === index) {
            return { ...matching, salesChannels: newSalesChannels };
          }

          const newSalesChannelsSet = new Set(
            newSalesChannels.map(
              (newSalesChannel) =>
                `${newSalesChannel.type}${newSalesChannel.id}`
            )
          );

          return {
            ...matching,
            salesChannels: matching.salesChannels.filter(
              (salesChannel) =>
                !newSalesChannelsSet.has(
                  `${salesChannel.type}${salesChannel.id}`
                )
            ),
          };
        }
      );
      store.setSalesChannelSupplierMatchings(newMatchings);
    };

  const handleMatchingSupplierChange =
    (index: number) => (supplierId: string | null) => {
      const newMatchings = matchings.map((matching, i) => {
        if (i === index) {
          return { ...matching, supplierId };
        }

        if (matching.supplierId === supplierId) {
          return { ...matching, supplierId: null };
        }

        return matching;
      });
      store.setSalesChannelSupplierMatchings(newMatchings);
    };

  const handleMatchingDelete = (index: number) => () => {
    const newMatchings = [...matchings];
    newMatchings.splice(index, 1);
    store.setSalesChannelSupplierMatchings(newMatchings);
  };

  if (store.useSupplierBySalesChannel) {
    return (
      <div className={container({ className })}>
        <div className={row()}>
          <p className="text-medium-m text-gray-700">판매처</p>
          <p className="text-medium-m text-gray-700">공급사</p>
          <div />
        </div>
        {matchings.map((matching, index) => (
          <div key={`${matching.supplierId ?? ''}${index}`} className={row()}>
            <SalesChannelsInput
              value={matching.salesChannels}
              onChange={handleMatchingSalesChannelsChange(index)}
              status={store.showSupplierError ? 'error' : 'default'}
            />
            <SupplierInput
              value={matching.supplierId}
              onChange={handleMatchingSupplierChange(index)}
              className="min-w-0"
            />
            <DeleteButton onClick={handleMatchingDelete(index)} />
          </div>
        ))}
        <div className={row()}>
          <div className="px-16px rounded-8px h-40px flex items-center border border-gray-300">
            <p className="text-medium-s text-gray-700">그 외 모든 판매처</p>
          </div>
          <SupplierInput
            value={store.supplierId}
            onChange={store.setSupplierId}
            className="min-w-0"
          />
          <DeleteButton onClick={() => store.setSupplierId(null)} />
        </div>
        <PlusButton onClick={handleMatchingCreate} />
      </div>
    );
  }

  return (
    <div className={container({ className })}>
      <p className="text-medium-m text-gray-700">공급사</p>
      <SupplierInput value={store.supplierId} onChange={store.setSupplierId} />
    </div>
  );
}

const container = cva('gap-8px flex flex-col');

const row = cva('gap-12px grid grid-cols-[1fr_1fr_36px]');

export default observer(SupplierInputSection);
