import { createContext, useContext } from 'react';
import { noop } from '@sweep/utils';

interface SearchSelectContextValue {
  isOpen: boolean;
  onClose: () => void;
}

const initialValue: SearchSelectContextValue = {
  isOpen: false,
  onClose: noop,
};

const SearchSelectContext =
  createContext<SearchSelectContextValue>(initialValue);

export const SearchSelectProvider = SearchSelectContext.Provider;

export function useSearchSelectContext() {
  return useContext(SearchSelectContext);
}
