import { useOMSStore } from 'hooks/useOMSStore';
import { observer } from 'mobx-react-lite';
import { IconArrowRightBtn } from '@sweep/asset/icons';
import { Button, SearchInput } from '@sweep/sds';
import { isNotNil } from '@sweep/utils';
import { usePreventBeforeUnload } from '../../hooks/usePreventBeforeUnload';
import { CreateMatching, MatchingOrder } from '../../interface';
import { toMatchingProducts } from '../../services/toMatchingProducts';
import CMHeader from '../common/CMHeader';
import CMProgress from '../common/CMProgress';
import { FormLayout } from '../common/FormLayout';
import MatchingStateInput from '../common/MatchingStateInput';
import { openHoldMatchingDialog } from '../dialogs/openHoldMatchingDialog';
import { openSkipMatchingDialog } from '../dialogs/openSkipMatchingDialog';
import { UnmatchedCMFormStore } from './UnmatchedCMFormStore';

interface UnmatchedCMFormProps {
  orders: MatchingOrder[];
  onSubmit: (value: CreateMatching[]) => void;
}

function NormalizeUnmatchedCMForm({
  orders: givenOrders,
  onSubmit: givenOnSubmit,
}: UnmatchedCMFormProps) {
  const oms = useOMSStore();
  const store = oms.getStore(UnmatchedCMFormStore, givenOrders);

  usePreventBeforeUnload();

  const onSubmit = (matchings: CreateMatching[]) => {
    oms.deleteStore(UnmatchedCMFormStore);
    givenOnSubmit(matchings);
  };

  const onSkipClick = async () => {
    const isSkip = await openSkipMatchingDialog(store.matchings.length);
    if (isSkip) {
      onSubmit([]);
    }
  };

  const onSaveClick = async () => {
    const compositionMatchings = store.matchings
      .filter((matching) => matching.status === 'confirm')
      .map<CreateMatching | null>((matching) => {
        const { order, products: partailProducts } = matching;
        const products = toMatchingProducts(partailProducts, oms);

        if (products.length === 0) {
          return null;
        }

        return {
          productName: order.productName,
          option: order.option,
          optionCode: order.optionCode,
          products,
        };
      })
      .filter(isNotNil);

    const holdCount = store.matchings.length - compositionMatchings.length;
    if (holdCount > 0) {
      const isHold = await openHoldMatchingDialog(holdCount);
      if (isHold) {
        onSubmit(compositionMatchings);
      }
      return;
    }

    oms.deleteStore(UnmatchedCMFormStore);
    onSubmit(compositionMatchings);
  };

  return (
    <FormLayout>
      <div className="h-42px flex items-center justify-between">
        <p className="text-extrabold-l flex-1">상품 구성 매칭</p>
        <Button
          color="lightGray"
          onClick={onSkipClick}
          rightAccessory={<IconArrowRightBtn />}
        >
          매칭 건너뛰기
        </Button>
      </div>
      <div className="h-[40px]" />
      <CMProgress>
        <CMProgress.Title index={1}>파트너사 매칭</CMProgress.Title>
        <CMProgress.NextIcon className="text-gray-400" />
        <CMProgress.Title index={2} active>
          상품 구성 매칭
        </CMProgress.Title>
        <CMProgress.Description>
          <span className="text-blue-500">{store.matchings.length}개</span>의
          상품을 매칭해주세요.
        </CMProgress.Description>
        <CMProgress.Description>
          <span className="text-blue-500">{store.matchedCount}</span>/
          {store.matchings.length}
        </CMProgress.Description>
      </CMProgress>
      <div className="h-20px" />
      <div className="shadow-line rounded-8px p-20px gap-16px flex flex-col bg-white">
        <SearchInput
          className="max-w-432px"
          placeholder="검색어를 입력해주세요."
          value={store.search}
          onChange={store.setSearch}
        />
        <hr />
        <div className="gap-8px flex flex-col">
          <CMHeader withConfirm />
          <div>
            {store.filteredMatchings.map((matching, index) => (
              <MatchingStateInput
                key={index}
                value={matching}
                onChange={(value) => store.setMatching(index, value)}
              />
            ))}
          </div>
        </div>
        <Button color="blue" onClick={onSaveClick} className="mx-auto">
          매칭 저장하기
        </Button>
      </div>
    </FormLayout>
  );
}

export default observer(NormalizeUnmatchedCMForm);
