export function extractDate(value: string, format: string): Date | null {
  const escapedFormat = format.replace(/[()]/g, '\\$&');

  const regex = new RegExp(
    escapedFormat
      .replace('{month}', '(?<month>\\d{1,2})')
      .replace('{day}', '(?<day>\\d{1,2})')
      .replace('{dayOfWeek}', '(?<dayOfWeek>월|화|수|목|금|토|일)')
  );
  const match = value.match(regex);
  if (match == null || match.groups == null) {
    return null;
  }

  const monthString = match.groups.month;
  const dayString = match.groups.day;
  const month = parseInt(monthString, 10);
  const day = parseInt(dayString, 10);

  const isValidMonth = !isNaN(month) && month >= 1 && month <= 12;
  const isValidDay = !isNaN(day) && day >= 1 && day <= 31;
  if (!isValidMonth || !isValidDay) {
    return null;
  }

  const year = new Date().getFullYear();
  return new Date(year, month - 1, day);
}
