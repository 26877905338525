import { isNil } from 'es-toolkit';
import { UserSettingLikeModel } from '../../models/userSettingLike.model';
import {
  createFallbackHeaderMapping,
  findTemplateHeaderMatch,
  findUserSettingHeaderMatch,
} from './strategies';
import { HeaderMappingResult, HeaderMatchContext } from './types';

const REQUIRED_HEADER_KEYS_FOR_SUPPLIER_USER = ['shippingNumber'] as const;

export function findSupplierHeaderMapping<
  UserSetting extends UserSettingLikeModel,
>({
  headerRow,
  customExcelSettings,
}: {
  headerRow: string[];
  customExcelSettings?: UserSetting | null;
}): HeaderMappingResult {
  const context: HeaderMatchContext = {
    headerRow,
    defaultShippingCompany:
      customExcelSettings?.defaultShippingCompany ?? undefined,
    requiredHeaderKeys: REQUIRED_HEADER_KEYS_FOR_SUPPLIER_USER,
  };

  if (isNil(headerRow) || headerRow.length === 0) {
    return {
      mappingColumn: {},
      defaultShippingCompany: context.defaultShippingCompany,
      headerKeys: [],
    };
  }

  return (
    findTemplateHeaderMatch(customExcelSettings, context) ??
    findUserSettingHeaderMatch(customExcelSettings, context) ??
    createFallbackHeaderMapping(customExcelSettings, context)
  );
}
