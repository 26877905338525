import { observer } from 'mobx-react-lite';
import { Badge, SelectableColumnDef, SelectableTable } from '@sweep/sds';
import { cva } from '@sweep/tailwind';
import { CombinedShippingTableProduct } from '../../interface';

interface CombinedShippingProductTableProps {
  items: CombinedShippingTableProduct[];
  disabledItems: CombinedShippingTableProduct[];
  selectedItems: CombinedShippingTableProduct[];
  onSelect: (products: CombinedShippingTableProduct[]) => void;
}

function CombinedShippingProductTable({
  items,
  disabledItems,
  selectedItems,
  onSelect,
}: CombinedShippingProductTableProps) {
  const columns: SelectableColumnDef<CombinedShippingTableProduct>[] = [
    {
      header: (context) => (
        <SelectableTable.HeaderCell context={context} className="flex-1">
          상품명
        </SelectableTable.HeaderCell>
      ),
      cell: (context) => (
        <SelectableTable.Cell
          context={context}
          className={cell({
            selected: context.selected,
            disabled: context.disabled,
          })}
        >
          {context.row.productName}
        </SelectableTable.Cell>
      ),
    },
    {
      header: (context) => (
        <SelectableTable.HeaderCell context={context} className="flex-1">
          옵션
        </SelectableTable.HeaderCell>
      ),
      cell: (context) => (
        <SelectableTable.Cell
          context={context}
          className={cell({ padding: 'narrow' })}
        >
          {context.row.option != null && (
            <Badge
              variant="soft"
              color={
                context.selected || context.disabled ? 'lightGray' : 'blue'
              }
              className="whitespace-nowrap"
            >
              {context.row.option}
            </Badge>
          )}
        </SelectableTable.Cell>
      ),
    },
    {
      header: (context) => (
        <SelectableTable.HeaderCell context={context} className="flex-1">
          공급사
        </SelectableTable.HeaderCell>
      ),
      cell: (context) => (
        <SelectableTable.Cell
          context={context}
          className={cell({
            empty: context.row.supplierName == null,
            selected: context.selected,
            disabled: context.disabled,
          })}
        >
          {context.row.supplierName ?? '없음'}
        </SelectableTable.Cell>
      ),
    },
  ];

  return (
    <SelectableTable
      className="h-fit w-full overflow-y-auto bg-white"
      items={items}
      columns={columns}
      getKey={getKey}
      selectedItems={selectedItems}
      onSelect={onSelect}
      disabledItems={disabledItems}
      highlightSelectedRow={false}
    />
  );
}

function getKey(product: CombinedShippingTableProduct) {
  return `${product.productId}-${product.option}-${product.supplierId ?? ''}`;
}

const cell = cva('h-40px', {
  variants: {
    empty: {
      true: 'text-gray-400',
      false: '',
    },
    disabled: {
      true: 'text-gray-400',
      false: '',
    },
    selected: {
      true: 'text-gray-400',
      false: '',
    },
    padding: {
      narrow: 'px-8px',
    },
  },
});

export default observer(CombinedShippingProductTable);
