import {
  CONFIRM_ORDERS_URL,
  ConfirmOrdersBody,
  ConfirmOrdersResponse,
  DispatchedOrder,
} from '@sweep/contract';
import { amplitude } from 'src/third-parties/amplitude';
import api, { APIResponse } from '../api';

export async function confirmOrders(orders: DispatchedOrder[]) {
  const response = await api.post<
    APIResponse<ConfirmOrdersResponse>,
    ConfirmOrdersBody
  >(CONFIRM_ORDERS_URL, { orders });

  const data = response?.data;

  if (
    data?.success === true &&
    data.data.some((order) => order.success === true)
  ) {
    amplitude.track('Confirm Order (Server)', { 'Order Count': orders.length });
  }

  return data;
}
