import { IconHeaderLogotype } from '@sweep/asset/icons';

function Devider() {
  return <span className="opacity-20">|</span>;
}

function Footer() {
  return (
    <div className="flex w-full min-w-[1200px] flex-col items-start gap-[60px] bg-[#152338] px-[200px] py-[240px] text-white">
      <div className="flex w-full flex-col gap-[64px]">
        <IconHeaderLogotype className="h-[32px]" />
        <div className="flex w-full justify-between text-[14px] font-medium leading-[21px] tracking-tight">
          <div className="flex flex-col gap-[8px]">
            <div className="flex gap-[8px]">
              <span>스윕</span>
              <Devider />
              <span>서울특별시 관악구 관악로 158, 스프링캠프 5층</span>
              <Devider />
              <span>사업자등록번호: 491-87-02864</span>
            </div>
            <div className="flex gap-[8px]">
              <span>대표 : 윤현일 010-8451-2670</span>
              <Devider />
              <span>통신판매업 신고번호 : 제2024-서울관악-0637호</span>
              <Devider />
              <span>대표 전화번호 : 1566-5529</span>
            </div>
          </div>
          <div className="flex cursor-pointer flex-col gap-[8px] text-end text-blue-200">
            <a
              href="https://sweepoms.notion.site/476a63cf5c3842569775438ae80be500?pvs=4"
              target="_blank"
              rel="noreferrer"
            >
              <span>서비스 이용약관</span>
            </a>
            <a
              href="https://sweepoms.notion.site/587bc67c6247485eab6b6972bef1a586?pvs=4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>개인정보 처리방침</span>
            </a>
          </div>
        </div>
      </div>
      <hr className="w-full opacity-20" />
      <p className="text-[14px] font-medium leading-[21px] tracking-tight">
        © 2022-2025 Sweep Corp. All rights reserved.
      </p>
    </div>
  );
}

export default Footer;
