import { CombinedShipping, Order } from '@sweep/contract';
import { isOrderInCombinedShipping } from './isOrderInCombinedShipping';

export function groupByCombinedShipping(
  orders: Order[],
  combinedShippings: CombinedShipping[]
): Record<string, Order[]> {
  const groupedOrders: Record<string, Order[]> = {};
  orders.forEach((order) => {
    const targetCombinedShipping = combinedShippings.find((combinedShipping) =>
      isOrderInCombinedShipping(order, combinedShipping)
    );

    if (targetCombinedShipping != null) {
      const prevOrdes = groupedOrders[targetCombinedShipping._id] ?? [];
      groupedOrders[targetCombinedShipping._id] = [...prevOrdes, order];
    }
  });

  return groupedOrders;
}
