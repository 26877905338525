import { action, observable, runInAction } from 'mobx';
import { CMStore } from './CMStore';
import { CombinedShippingStore } from './CombinedShippingStore';
import { LoadingStore } from './loading/NewLoadingStore';
import { UserStore } from './NewUserStore';
import { OrderStore } from './order/OrderStore';
import { PartnerStore } from './PartnerStore';
import { PlguinStore } from './plugin/PluginStore';
import { ProductStore } from './ProductStore';
import { SpreadsheetHeaderMappingStore } from './SpreadsheetHeaderMappingStore';
import { SupplierStore } from './SupplierStore';

export class OMSStore {
  stores: Record<string, unknown> = {};

  user: UserStore;
  partner: PartnerStore;
  supplier: SupplierStore;
  order: OrderStore;
  product: ProductStore;
  plugin: PlguinStore;
  cm: CMStore;
  loading: LoadingStore;
  combinedShipping: CombinedShippingStore;
  spreadsheetHeaderMapping: SpreadsheetHeaderMappingStore;

  @observable
  accessor isInitialized = false;

  constructor() {
    this.loading = new LoadingStore();

    this.plugin = new PlguinStore();
    this.user = new UserStore(this);
    this.partner = new PartnerStore(this);
    this.supplier = new SupplierStore(this);
    this.order = new OrderStore(this);
    this.product = new ProductStore(this);
    this.cm = new CMStore(this);
    this.combinedShipping = new CombinedShippingStore(this);
    this.spreadsheetHeaderMapping = new SpreadsheetHeaderMappingStore(this);
  }

  @action.bound
  async init() {
    await Promise.all([
      this.user.init(),
      this.partner.init(),
      this.supplier.init(),
      this.product.init(),
      this.order.init(),
      this.plugin.init(),
      this.cm.init(),
      this.combinedShipping.init(),
      this.spreadsheetHeaderMapping.init(),
    ]);

    runInAction(() => {
      this.isInitialized = true;
    });
  }

  getStore<T, Args extends unknown[]>(
    store: new (omsStore: OMSStore, ...args: Args) => T,
    ...args: Args
  ): T {
    const storeName = store.name;
    if (this.stores[storeName] == null) {
      this.stores[storeName] = new store(this, ...args);
    }

    return this.stores[storeName] as T;
  }

  deleteStore(store: new (omsStore: OMSStore, ...args: any[]) => any) {
    const storeName = store.name;
    if (this.stores[storeName] != null) {
      delete this.stores[storeName];
    }
  }
}
