import { chunk, partition } from 'es-toolkit';
import { Order } from '@sweep/contract';
import { assert, retry } from '@sweep/utils';
import ErrorReporter from '../third-parties/ErrorReporter';
import api from './api';

const CHUNK_SIZE = 3000;
const MAX_RETRY_COUNT = 3;
const RETRY_DELAY_MS = 1000;

export type CheckedAddress =
  | {
      result: 'okay';
      data: string;
      main_address: string;
      detail_address: string;
    }
  | {
      result: 'fixed';
      data: string;
      orig_data: string;
      main_address: string;
      detail_address: string;
      tried_data: string;
      message: string[];
      tried_tried_data: unknown[];
    }
  | {
      result: 'fail';
      data: string;
      orig_data: string;
      main_address: string;
      detail_address: string;
      tried_data: string;
      message: string[];
      tried_tried_data: unknown[];
    };

type CheckOrdersAddressResultSuccess = {
  success: true;
  data: CheckedAddress[];
};

type CheckOrdersAddressResultFailed = {
  success: false;
};

export type CheckOrdersAddressResponse =
  | CheckOrdersAddressResultSuccess
  | CheckOrdersAddressResultFailed;

const requestAddressCheck = async (
  orders: Order[]
): Promise<CheckOrdersAddressResponse> => {
  const url = '/fastapi/correct_orders';
  const response = await api.post<CheckOrdersAddressResponse>(url, orders);
  assert(response != null, 'response is null');

  return response.data;
};

const requestAddressCheckWithRetry = async (
  orders: Order[]
): Promise<CheckOrdersAddressResponse> => {
  return retry(() => requestAddressCheck(orders), {
    retries: MAX_RETRY_COUNT,
    interval: RETRY_DELAY_MS,
    onError: (error) => {
      reportCheckAddressApiFailError({
        error,
        orders,
      });
    },
    onRetry: () => {
      ErrorReporter.addBreadcrumb({
        message: `Address check API failed after MAX retries`,
        extra: {
          orderCount: orders.length,
        },
      });
    },
  });
};

export async function correctOrderAddresses(
  orders: Order[]
): Promise<CheckOrdersAddressResponse | null> {
  try {
    const chunks = chunk(orders, CHUNK_SIZE);
    const results: CheckOrdersAddressResponse[] = [];

    for (const [index, currentChunk] of chunks.entries()) {
      try {
        const result = await requestAddressCheckWithRetry(currentChunk);
        results.push(result);
      } catch (error) {
        reportCheckAddressFailError(
          orders,
          chunks,
          `index (${index}) 에서 실패`
        );
        return null;
      }
    }

    const [successResult, failedResult] = partitionAPIResult(results);

    const successChunkCount = successResult.length;
    const failedChunkCount = failedResult.length;
    if (failedChunkCount > 0) {
      reportCheckAddressFailError(
        orders,
        chunks,
        `일부 요청이 FAILED: (successChunkCount: ${successChunkCount}, failedChunkCount: ${failedChunkCount})`
      );
      return null;
    }

    const successfulResults = successResult.map((result) => result.data).flat();

    return {
      success: true,
      data: successfulResults,
    };
  } catch (e) {
    ErrorReporter.captureError(e, {
      extra: {
        orderCount: orders.length,
      },
    });

    return null;
  }
}

function partitionAPIResult(
  results: CheckOrdersAddressResponse[]
): [CheckOrdersAddressResultSuccess[], CheckOrdersAddressResultFailed[]] {
  const [successResult, failedResult] = partition(
    results,
    (result) => result.success
  ) as [CheckOrdersAddressResultSuccess[], CheckOrdersAddressResultFailed[]];

  return [successResult, failedResult];
}

function reportCheckAddressApiFailError(params: {
  error: unknown;
  orders: Order[];
}) {
  try {
    const { error, orders } = params;

    ErrorReporter.captureMessage(
      `[SWP-629] Address check API failed: ${error instanceof Error ? error.message : String(error)}`,
      {
        extra: {
          orderCount: orders.length,
        },
      }
    );
  } catch (e) {
    // 로직과 무관한 모니터링 로직이라서 예외처리 시킴
    ErrorReporter.captureError(e);
  }
}

function reportCheckAddressFailError(
  orders: Order[],
  chunks: Order[][],
  note: string
) {
  // NOTE(@형준): SWP-629 문제 상황 모니터링을 위해서 추가한 임시 에러 리포트, 문제 상황 모니터링 종료 후 삭제 필요
  try {
    const orderCount = orders.length;
    const requestCountTotal = chunks.length;
    const errorMessageForSWP629 = `[SWP-629] 일부 주문 주소 검증이 실패했습니다. 주문 수: ${orderCount}, 요청수: ${requestCountTotal}, ${note}`;
    ErrorReporter.captureMessage(errorMessageForSWP629, {
      extra: {
        orderCount,
        requestCountTotal,
      },
    });
  } catch (error) {
    // 로직과 무관한 모니터링 로직이라서 예외처리 시킴
    ErrorReporter.captureError(error);
  }
}
