import { isString } from 'es-toolkit';
import { COLUMN_MAPPING_GUIDES } from './columnMappingGuide';

export type MappingGuideKey = string;
export type MappingGuide<TKey extends MappingGuideKey = MappingGuideKey> =
  Record<TKey, string[]>;

export type Mapping = Record<string, string>;

export interface MappedEntry {
  item: string;
  key: MappingGuideKey;
  value: string;
}

function normalizeString(str: string): string {
  return str.trim();
}

export function getMappedEntries(
  row: string[],
  guides: MappingGuide[]
): MappedEntry[] {
  const entries: MappedEntry[] = [];

  for (const guide of guides) {
    for (const key in guide) {
      const values = guide[key];
      const value = row.find((value) => values.includes(value));
      const isAlreadyMappedValue = entries.some((info) => info.value === value);
      const isAlreadyMappedKey = entries.some((info) => info.key === key);

      if (!isAlreadyMappedValue && !isAlreadyMappedKey && value) {
        entries.push({ item: value, key, value });
      }
    }
  }

  return entries;
}

export function getMappedEntriesV2(
  row: string[],
  guides: MappingGuide[]
): MappedEntry[] {
  const entries: MappedEntry[] = [];

  for (const guide of guides) {
    for (const key in guide) {
      const values = guide[key];
      const value = row.find((value) => {
        const normalizedValue = normalizeString(value);
        return values.some(
          (guideValue) => normalizeString(guideValue) === normalizedValue
        );
      });
      const isAlreadyMappedValue = entries.some((info) => info.value === value);
      const isAlreadyMappedKey = entries.some((info) => info.key === key);

      if (!isAlreadyMappedValue && !isAlreadyMappedKey && value) {
        entries.push({ item: value, key, value });
      }
    }
  }

  return entries;
}

export function findHeaderIndex(rows: string[][]): number {
  return rows.findIndex((row) => {
    const stringRow = row.filter(isString);
    const entries = getMappedEntries(stringRow, COLUMN_MAPPING_GUIDES);
    return entries.length > 1;
  });
}

export function findHeaderIndexV2(rows: string[][]): number {
  return rows.findIndex((row) => {
    const stringRow = row.filter(isString);
    const entries = getMappedEntriesV2(stringRow, COLUMN_MAPPING_GUIDES);
    return entries.length > 1;
  });
}

export function findHeaderRowAndSlice(excelData: string[][]): string[][] {
  const headerIndex = findHeaderIndex(excelData);
  if (headerIndex === -1) {
    return excelData;
  }
  return excelData.slice(headerIndex);
}

export function findHeaderRowAndSliceV2(excelData: string[][]): string[][] {
  const headerIndex = findHeaderIndexV2(excelData);
  if (headerIndex === -1) {
    return excelData;
  }
  return excelData.slice(headerIndex);
}
