import { Control } from 'src/stores/order/OrderStore';
import { CompositionMatchingStage } from '../../CompositionMatchingPlugin';
import { CreateMatching, MatchingOrder } from '../../interface';
import NormalizeUnmatchedCMForm from './NormalizeUnmatchedCMForm';
import PreDivideUnmatchedCMForm from './PreDivideUnmatchedCMForm';

export function openUnmatchedCMForm(
  render: (control: Control) => void,
  orders: MatchingOrder[],
  stage: CompositionMatchingStage
): Promise<CreateMatching[] | null> {
  const UnmatchedCMForm =
    stage === 'normalize' ? NormalizeUnmatchedCMForm : PreDivideUnmatchedCMForm;

  return new Promise((resolve) => {
    render(({ close }) => (
      <UnmatchedCMForm
        orders={orders}
        onSubmit={(value) => {
          resolve(value);
          close();
        }}
      />
    ));
  });
}
