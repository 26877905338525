import { http, HttpResponse } from 'msw';
import {
  ACCEPT_CANCEL_ORDERS_URL,
  AcceptCancelOrdersBody,
  AcceptCancelResponse,
  CanceledOrderResult,
  Order,
  REJECT_CANCEL_ORDERS_URL,
  RejectCancelOrdersBody,
  RejectCancelResponse,
} from '@sweep/contract';
import { isNotNil, sleep } from '@sweep/utils';
import { LocalStorageValue } from '@sweep/utils/mobx';
import { APIResponse } from 'src/network/api';
import { LOCALSTORAGE_DISPATCH_PREFIX } from '../../../common/constants';

export const createCancelOrderHandlers = () => {
  return [createAcceptCancelHandler(), createRejectCancelHandler()].filter(
    isNotNil
  );
};

export type AcceptCancelType = 'success' | 'partial-fail' | 'fail' | 'none';
export const acceptCancelTypeStorage = new LocalStorageValue<AcceptCancelType>({
  key: `${LOCALSTORAGE_DISPATCH_PREFIX}/cancel-order-type`,
  defaultValue: 'none',
});

function createAcceptCancelHandler() {
  const acceptCancelType = acceptCancelTypeStorage.value;

  if (acceptCancelType === 'none') {
    return null;
  }

  return http.post<
    any,
    AcceptCancelOrdersBody,
    APIResponse<AcceptCancelResponse>
  >(ACCEPT_CANCEL_ORDERS_URL, async ({ request }) => {
    await sleep(1000);
    const { orders } = await request.json();

    switch (acceptCancelType) {
      case 'success':
        return HttpResponse.json({
          success: true,
          data: mapSuccessCanceledOrders(orders),
        });
      case 'partial-fail':
        return HttpResponse.json({
          success: true,
          data: mapPartialFailCanceledOrders(orders),
        });
      case 'fail':
        return HttpResponse.json({
          success: false,
          error: 'msw-fail',
        });
    }
  });
}

export type RejectCancelType = 'success' | 'partial-fail' | 'fail' | 'none';
export const rejectCancelTypeStorage = new LocalStorageValue<RejectCancelType>({
  key: `${LOCALSTORAGE_DISPATCH_PREFIX}/reject-cancel-type`,
  defaultValue: 'none',
});

function createRejectCancelHandler() {
  const rejectCancelType = rejectCancelTypeStorage.value;

  if (rejectCancelType === 'none') {
    return null;
  }

  return http.post<
    any,
    RejectCancelOrdersBody,
    APIResponse<RejectCancelResponse>
  >(REJECT_CANCEL_ORDERS_URL, async ({ request }) => {
    await sleep(1000);
    const { orders } = await request.json();

    switch (rejectCancelType) {
      case 'success':
        return HttpResponse.json({
          success: true,
          data: mapSuccessCanceledOrders(orders),
        });
      case 'partial-fail':
        return HttpResponse.json({
          success: true,
          data: mapPartialFailCanceledOrders(orders),
        });
      case 'fail':
        return HttpResponse.json({
          success: false,
          error: 'msw-fail',
        });
    }
  });
}

function mapSuccessCanceledOrders(orders: Order[]) {
  return orders.map<CanceledOrderResult>((order) => ({
    success: true,
    uniqueCode: order.uniqueCode,
    shippingCompany: order.shippingCompany ?? 'CJ대한통운',
    shippingNumber:
      order.shippingNumber ?? String(Math.floor(Math.random() * 1000000000)),
  }));
}

function mapPartialFailCanceledOrders(orders: Order[]) {
  return orders.map<CanceledOrderResult>((order, index) =>
    index % 2 === 0
      ? { success: true, uniqueCode: order.uniqueCode }
      : {
          success: false,
          failReason: 'msw-order-error',
          uniqueCode: order.uniqueCode,
        }
  );
}
