import { z } from 'zod';

export interface Supplier {
  _id: string;

  name: string;
  email?: string;
  phone?: string;

  header: string[];
  columnMapping: Record<string, string>;
  // 나중에 제거
  exampleContents?: string[];

  plugins?: string[];

  headerForShipping?: string[];
  defaultShippingCompany?: string;

  settings?: {
    fileFormatting?: {
      fileFormat: string;
      dateFormat?: string;
    };
  };

  customizedSettings?: {
    xlsxTemplateSetting?: {
      enabled: boolean;
      name: string;
      headerRowIndex: number;
      minRowIndex: number;
      templateFile: string;
    };
  };

  /**
   * @deprecated
   */
  productIds?: string[];

  /**
   * @deprecated
   */
  customSettings?: {
    customFileFormat?: {
      fileNameFormat: string;
      dateFormat?: string;
    };
    addFixedColumnValue?: {
      enabled: boolean;
      settings: {
        [key: string]: string;
      };
    };
    applyFieldValueToAnother?: {
      enabled: boolean;
      rules?: {
        sourceField: string;
        targetField: string;
      }[];
    };
  };
}

export const CreateSupplier = z.object({
  name: z.string(),
  email: z.string().optional(),
  phone: z.string().optional(),

  header: z.array(z.string()),
  columnMapping: z.record(z.string()),

  productIds: z.array(z.string()).optional(),

  plugins: z.array(z.string()).optional(),
});

export type CreateSupplier = z.infer<typeof CreateSupplier>;

export const UpdateSupplier = z.object({
  name: z.string().optional(),
  email: z.string().optional(),
  phone: z.string().optional(),

  header: z.array(z.string()).optional(),
  columnMapping: z.record(z.string()).optional(),

  productIds: z.array(z.string()).optional(),

  plugins: z.array(z.string()).optional(),
});

export type UpdateSupplier = z.infer<typeof UpdateSupplier>;
