import {
  CREATE_OPTION_CODE_MATCHING_URL,
  CREATE_OPTION_CODE_MATCHINGS_URL,
  CreateOptionCodeMatchingBody,
  CreateOptionCodeMatchingDTO,
  CreateOptionCodeMatchingResponse,
  CreateOptionCodeMatchingsBody,
  CreateOptionCodeMatchingsResponse,
  GET_OPTION_CODE_MATCHING_URL,
  GetOptionCodeMatchingResponse,
  INCREMENT_OPTION_CODE_MATCHING_COUNTS_URL,
  IncrementOptionCodeMatchingCountsBody,
  UPDATE_OPTION_CODE_MATCHING_URL,
  UpdateOptionCodeMatchingBody,
  UpdateOptionCodeMatchingDTO,
  UpdateOptionCodeMatchingResponse,
} from '@sweep/contract';
import { tryCatch } from '@sweep/utils';
import api, { APIResponse } from './api';

export async function getOptionCodeMatchings() {
  const response = await tryCatch(() =>
    api.get<APIResponse<GetOptionCodeMatchingResponse>>(
      GET_OPTION_CODE_MATCHING_URL
    )
  );

  return response?.data;
}

export async function createOptionCodeMatching(
  optionCodeMatching: CreateOptionCodeMatchingDTO
) {
  const response = await tryCatch(() =>
    api.post<
      APIResponse<CreateOptionCodeMatchingResponse>,
      CreateOptionCodeMatchingBody
    >(CREATE_OPTION_CODE_MATCHING_URL, optionCodeMatching)
  );

  return response?.data;
}

export async function createOptionCodeMatchings(
  optionCodeMatchings: CreateOptionCodeMatchingDTO[]
) {
  const response = await api.post<
    APIResponse<CreateOptionCodeMatchingsResponse>,
    CreateOptionCodeMatchingsBody
  >(CREATE_OPTION_CODE_MATCHINGS_URL, optionCodeMatchings);

  return response?.data;
}

export async function updateOptionCodeMatching(
  optionCodeMatching: UpdateOptionCodeMatchingDTO
) {
  const response = await api.put<
    APIResponse<UpdateOptionCodeMatchingResponse>,
    UpdateOptionCodeMatchingBody
  >(
    UPDATE_OPTION_CODE_MATCHING_URL(optionCodeMatching._id),
    optionCodeMatching
  );
  return response?.data;
}

export async function incrementOptionCodeMatchingCounts(
  optionCodeMatchingIds: string[]
) {
  await api.put<unknown, IncrementOptionCodeMatchingCountsBody>(
    INCREMENT_OPTION_CODE_MATCHING_COUNTS_URL,
    { optionCodeMatchingIds }
  );
}
