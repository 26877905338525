import { useOMSStore } from 'hooks/useOMSStore';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { toast } from 'sonner';
import { MatchingProduct } from '@sweep/contract';
import { Button, SearchInput } from '@sweep/sds';
import { isNotNil } from '@sweep/utils';
import { Matching, MatchingOrder } from '../../interface';
import { isNotEmptyMatchingProduct } from '../../services/isNotEmptyMatchingProduct';
import { toMatchingProducts } from '../../services/toMatchingProducts';
import CMHeader from '../common/CMHeader';
import MatchingOrderLabel from '../common/MatchingOrderLabel';
import MatchingProductsInput from '../common/MatchingProductsInput';

interface MatchedCMEditFormProps {
  matchings: Matching[];
  onSubmit: (matchings: Matching[]) => void;
}

interface InternalMatching {
  order: MatchingOrder;
  products: Partial<MatchingProduct>[];
}

function MatchedCMEditForm({
  matchings: givenMatchings,
  onSubmit,
}: MatchedCMEditFormProps) {
  const oms = useOMSStore();
  const [required, setRequired] = useState(false);
  const [search, setSearch] = useState('');
  const [matchings, setMatchings] =
    useState<InternalMatching[]>(givenMatchings);
  const filteredMatchings = useMemo(
    () =>
      matchings.filter(({ order }) =>
        [order.productName, order.option, order.optionCode]
          .filter(isNotNil)
          .some((value) => value.includes(search))
      ),
    [matchings, search]
  );

  const handleProductsChange =
    (index: number) => (products: Partial<MatchingProduct>[]) => {
      const newInternalMatchings = [
        ...matchings.slice(0, index),
        { ...givenMatchings[index], products },
        ...matchings.slice(index + 1),
      ];

      setMatchings(newInternalMatchings);
    };

  const onSaveClick = () => {
    const nonEmptyProductsMatchings = matchings.map((matching) => ({
      ...matching,
      products: matching.products.filter(isNotEmptyMatchingProduct),
    }));

    const validMatchings = nonEmptyProductsMatchings
      .map((matching) => {
        const matchingProducts = toMatchingProducts(matching.products, oms);
        return matchingProducts.length !== 0
          ? { ...matching, products: matchingProducts }
          : null;
      })
      .filter(isNotNil);

    if (validMatchings.length !== matchings.length) {
      const matchingsWithDefaultProducts = nonEmptyProductsMatchings.map(
        (matching) => ({
          ...matching,
          products:
            matching.products.length === 0
              ? [{ quantity: 1 }]
              : matching.products,
        })
      );
      setMatchings(matchingsWithDefaultProducts);
      setRequired(true);
      toast.error('상품 또는 옵션을 선택해주세요.');
      return;
    }

    onSubmit(validMatchings);
  };

  return (
    <div className="gap-16px flex flex-col">
      <SearchInput
        value={search}
        onChange={setSearch}
        placeholder="검색어를 입력하세요."
        className="max-w-400px"
      />
      <hr />
      <div className="gap-8px flex flex-col">
        <CMHeader />
        <div>
          {filteredMatchings.map(({ order, products }, index) => (
            <div key={index} className="flex border-b border-gray-200 py-4">
              <MatchingOrderLabel matchingOrder={order} />
              <div className="w-42px shrink-0" />
              <MatchingProductsInput
                value={products}
                onChange={handleProductsChange(index)}
                required={required}
              />
            </div>
          ))}
        </div>
      </div>
      <Button onClick={onSaveClick} className="m-auto">
        수정사항 저장하기
      </Button>
    </div>
  );
}

export default observer(MatchedCMEditForm);
