import { CombinedShipping, Order } from '@sweep/contract';
import { chunk, isNotNil, parseNumber } from '@sweep/utils';
import { OMSStore } from 'src/stores/OMSStore';
import { getSeparatedPrice } from './getSeparatedPrice';
import { mergeOrders } from './mergeOrders';

export function combineOrders(
  oms: OMSStore,
  orders: Order[],
  combinedShipping: CombinedShipping
): Order[] {
  const separatedOrders = orders.flatMap(separate).sort((a, b) => {
    const comparedByFilename = (a.originFile ?? '').localeCompare(
      b.originFile ?? ''
    );
    if (comparedByFilename !== 0) {
      return comparedByFilename;
    }

    const comparedByIndex = (a.originFilIndex ?? 0) - (b.originFilIndex ?? 0);
    return comparedByIndex;
  });

  switch (combinedShipping.type) {
    case 'count':
      return chunk(separatedOrders, combinedShipping.amount)
        .map(mergeOrders)
        .filter(isNotNil);
    case 'unit':
      return chunkByAmount(oms, separatedOrders, combinedShipping.amount)
        .map(mergeOrders)
        .filter(isNotNil);
  }
}

function separate(order: Order): Order[] {
  const [product] = order.data ?? [];

  const price = parseNumber(order.price);

  const separatedPrice =
    price != null ? getSeparatedPrice(price, product.quantity) : null;

  return new Array(product.quantity).fill(null).map((_, index) => ({
    ...order,
    price: separatedPrice?.[index] ?? order.price,
    quantity: 1,
    data: [{ ...product, quantity: 1 }],
  }));
}

function chunkByAmount(
  oms: OMSStore,
  orders: Order[],
  amount: number
): Order[][] {
  return orders
    .reduce<Array<{ orders: Order[]; amount: number }>>((acc, order) => {
      const { data } = order;
      const [product] = data ?? [];
      const productAmount =
        oms.product.getAmount(product.productId, product.unit) ?? 0;

      if (acc.length === 0) {
        acc.push({ orders: [order], amount: productAmount });
        return acc;
      }

      const last = acc[acc.length - 1];
      if (last.amount + productAmount > amount) {
        acc.push({ orders: [order], amount: productAmount });
        return acc;
      }

      last.amount += productAmount;
      last.orders.push(order);
      return acc;
    }, [])
    .map(({ orders }) => orders);
}
