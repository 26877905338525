import { SHIPPING_ORDER_PROCESS_STATUS_CODE } from '@sweep/domain/services/order-shipping-process';
import { amplitude } from 'src/third-parties/amplitude';
import { ProcessingItem } from '../processors/processor.type';

export default function reportFallbackUsage(items: ProcessingItem[]): void {
  const fallbackMatchHistoryCount = items.filter(
    (item) =>
      item.histories.includes(
        SHIPPING_ORDER_PROCESS_STATUS_CODE.INVALID_ORIGINAL_ORDER_NOT_FOUND_BY_BASIC_INFO
      ) ||
      item.histories.includes(
        SHIPPING_ORDER_PROCESS_STATUS_CODE.SUCCESS_STEP_SHIPPING_INFO_UPDATE_BY_BASIC_INFO
      )
  ).length;

  const isFallbackMatchUsed = fallbackMatchHistoryCount > 0;
  if (isFallbackMatchUsed) {
    amplitude.track('Trigger Fallback-match-shipping-order');
  }
}
