import {
  DispatchedOrder,
  isCancelRequest,
  isEtc,
  isHidden,
  isInDelivery,
  isPaymentComplete,
  isProductPreparing,
} from '@sweep/contract';
import { OrderStatusType } from '../interface';

export function filterOrderStatus(
  orders: DispatchedOrder[],
  orderStatus: OrderStatusType
) {
  const visibleOrders = orders.filter(
    (order) => isHidden(order.orderStatus) === false
  );

  if (orderStatus === 'Total') {
    return visibleOrders;
  }

  switch (orderStatus) {
    case 'PaymentComplete':
      return visibleOrders.filter((order) =>
        isPaymentComplete(order.orderStatus)
      );
    case 'ProductPreparing':
      return visibleOrders.filter((order) =>
        isProductPreparing(order.orderStatus)
      );
    case 'InDelivery':
      return visibleOrders.filter((order) => isInDelivery(order.orderStatus));
    case 'CancelRequest':
      return visibleOrders.filter((order) =>
        isCancelRequest(order.orderStatus)
      );
    case 'Etc':
      return visibleOrders.filter((order) => isEtc(order.orderStatus));
  }
}
