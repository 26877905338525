import { trimValue } from '@sweep/utils';

export function isEitherSubsequenceOf<T>(arr1: T[], arr2: T[]): boolean {
  if (arr1.length === 0 || arr2.length === 0) {
    return false;
  }

  return isSubsequence(arr1, arr2) || isSubsequence(arr2, arr1);
}

function isSubsequence<T>(subsequence: T[], sequence: T[]): boolean {
  // Early return for impossible cases
  if (subsequence.length > sequence.length) {
    return false;
  }
  if (subsequence.length === 0) {
    return true;
  }

  const lastPossibleStartIndex = sequence.length - subsequence.length;
  let subsequenceIndex = 0;

  for (
    let sequenceIndex = 0;
    sequenceIndex <= lastPossibleStartIndex + subsequenceIndex;
    sequenceIndex++
  ) {
    if (
      trimValue(subsequence[subsequenceIndex]) ===
      trimValue(sequence[sequenceIndex])
    ) {
      subsequenceIndex++;

      if (subsequenceIndex === subsequence.length) {
        return true;
      }
    }
  }

  return false;
}
