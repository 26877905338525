import { getTimezoneOffset } from './getTimezonOffset';
import { Timezone } from './interface';

interface FormatDateOptions {
  timezone?: Timezone;
}

export function formatDate(
  date: Date,
  format: string,
  option?: FormatDateOptions
): string {
  const localOffset = getTimezoneOffset();
  const targetOffset = getTimezoneOffset(option?.timezone);
  const adjustedDate = new Date(date.getTime() + localOffset - targetOffset);

  const year = adjustedDate.getFullYear();
  const month = adjustedDate.getMonth() + 1;
  const day = adjustedDate.getDate();

  const hour = adjustedDate.getHours();
  const twelveHour = hour % 12 === 0 ? 12 : hour % 12;
  const minute = adjustedDate.getMinutes();
  const second = adjustedDate.getSeconds();

  const shortDayOfWeek = adjustedDate.toLocaleDateString('ko-KR', {
    weekday: 'short',
  });
  const longDayOfWeek = adjustedDate.toLocaleDateString('ko-KR', {
    weekday: 'long',
  });

  const ampm = hour < 12 ? '오전' : '오후';

  return format
    .replace('yyyy', `${year}`)
    .replace('yy', `${year}`.slice(2))
    .replace('MM', applyPadding(month))
    .replace('M', `${month}`)
    .replace('dddd', longDayOfWeek)
    .replace('ddd', shortDayOfWeek)
    .replace('dd', applyPadding(day))
    .replace('d', `${day}`)
    .replace('HH', applyPadding(hour))
    .replace('H', `${hour}`)
    .replace('hh', applyPadding(twelveHour))
    .replace('h', `${twelveHour}`)
    .replace('mm', applyPadding(minute))
    .replace('m', `${minute}`)
    .replace('ss', applyPadding(second))
    .replace('s', `${second}`)
    .replace('a', ampm);
}

function applyPadding(num: number): string {
  return num < 10 ? `0${num}` : `${num}`;
}
