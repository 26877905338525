import { createContext, useContext } from 'react';
import { noop } from '@sweep/utils';
import { UseColumnWidthReturn } from '../hooks/useColumnWidth';

export interface TableContextValue extends UseColumnWidthReturn {}

const tableContext = createContext<TableContextValue>({
  columnWidths: {},
  onWidthChange: noop,
  onHeaderWidthChange: noop,
  onAdjustedWidthChange: noop,
  onFitWidth: noop,
});

export const TableContextProvider = tableContext.Provider;

export const useTableContext = () => useContext(tableContext);
