import {
  CREATE_COMPOSITION_MATCHING_URL,
  CREATE_COMPOSITION_MATCHINGS_URL,
  CreateCompositionMatchingBody,
  CreateCompositionMatchingDTO,
  CreateCompositionMatchingResponse,
  CreateCompositionMatchingsBody,
  CreateCompositionMatchingsResponse,
  GET_COMPOSITION_MATCHING_URL,
  GetCompositionMatchingResponse,
  INCREMENT_COMPOSITION_MATCHING_COUNTS_URL,
  IncrementCompositionMatchingCountsBody,
  UPDATE_COMPOSITION_MATCHING_URL,
  UpdateCompositionMatchingBody,
  UpdateCompositionMatchingDTO,
  UpdateCompositionMatchingResponse,
} from '@sweep/contract';
import { tryCatch } from '@sweep/utils';
import api, { APIResponse } from './api';

export async function getCompositionMatchings() {
  const response = await tryCatch(() =>
    api.get<APIResponse<GetCompositionMatchingResponse>>(
      GET_COMPOSITION_MATCHING_URL
    )
  );

  return response?.data;
}

export async function createCompositionMatching(
  compositionMatching: CreateCompositionMatchingDTO
) {
  const response = await tryCatch(() =>
    api.post<
      APIResponse<CreateCompositionMatchingResponse>,
      CreateCompositionMatchingBody
    >(CREATE_COMPOSITION_MATCHING_URL, { productInfo: compositionMatching })
  );

  return response?.data;
}

export async function createCompositionMatchings(
  compositionMatchings: CreateCompositionMatchingDTO[]
) {
  const response = await tryCatch(() =>
    api.post<
      APIResponse<CreateCompositionMatchingsResponse>,
      CreateCompositionMatchingsBody
    >(CREATE_COMPOSITION_MATCHINGS_URL, {
      compositionMatchings,
    })
  );

  return response?.data;
}

export async function updateCompositionMatching(
  compositionMatching: UpdateCompositionMatchingDTO
) {
  const response = await tryCatch(() =>
    api.put<
      APIResponse<UpdateCompositionMatchingResponse>,
      UpdateCompositionMatchingBody
    >(UPDATE_COMPOSITION_MATCHING_URL, {
      composition: compositionMatching,
    })
  );

  return response?.data;
}

export async function incrementCompositionMatchingCounts(
  compositionMatchingIds: string[]
) {
  await tryCatch(() =>
    api.put<unknown, IncrementCompositionMatchingCountsBody>(
      INCREMENT_COMPOSITION_MATCHING_COUNTS_URL,
      { compositionMatchingIds }
    )
  );
}
