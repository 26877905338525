import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

type Tag = 'title' | 'description' | 'image' | 'url';

type MetaTagsProps = Partial<Record<Tag, string>>;

function MetaTags({ title, description, image, url }: MetaTagsProps) {
  const location = useLocation();
  const currentUrl = `${window.location.origin}${location.pathname}`;

  return (
    <Helmet>
      {title != null && <title>{title}</title>}
      {title != null && <meta property="og:title" content={title} />}
      {description != null && (
        <meta property="og:description" content={description} />
      )}
      <meta property="og:url" content={url ?? currentUrl} />
      {image != null && <meta property="og:image" content={image} />}
    </Helmet>
  );
}

export default MetaTags;
