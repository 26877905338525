import {
  FULFILL_ORDERS_URL,
  FulfillOrder,
  FulfillOrdersBody,
  FulfillOrdersResponse,
} from '@sweep/contract';
import api, { APIResponse } from '../api';

export async function fulfillOrder(orders: FulfillOrder[]) {
  const response = await api.post<
    APIResponse<FulfillOrdersResponse>,
    FulfillOrdersBody
  >(FULFILL_ORDERS_URL, { orders });

  return response?.data;
}
