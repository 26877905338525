import { groupBy, partition } from 'es-toolkit';
import {
  createOriginalOrderUpdatePayload,
  OriginalOrder,
  SHIPPING_ORDER_PROCESS_STATUS_CODE,
  ShippingOrder,
  ShippingOrderProcessStatusCode,
} from '@sweep/domain/services/order-shipping-process';
import { FailedShippingOrderWithStatusCode } from '../types';

export async function validateAndPrepareStep<
  T extends ShippingOrder,
  R extends OriginalOrder,
>(params: {
  originalOrders: R[];
  shippingOrders: T[];
}): Promise<{
  updateValidShippingOrders: T[];
  updateValidOriginalOrders: R[];
  updateInvalidShippingOrders: FailedShippingOrderWithStatusCode<T>[];
}> {
  const { originalOrders, shippingOrders } = params;

  const processedResults = shippingOrders.map((shippingOrder) =>
    createOriginalOrderUpdatePayload({
      originalOrders,
      shippingOrder,
    })
  );

  const [successResults, failedResults] = partition(
    processedResults,
    (result) => isStatusCodeSuccess(result.processedShippingOrder.statusCode)
  );

  const updateValidShippingOrders: T[] = successResults.map(
    (result) => result.processedShippingOrder.shippingOrder
  );

  const updateValidOriginalOrderToMerge: R[] = successResults.flatMap(
    (result) => result.updatedOriginalOrders
  );

  const updateValidOriginalOrders = mergeShippingNumberByUniqueCode(
    updateValidOriginalOrderToMerge
  );

  const updateInvalidShippingOrders: FailedShippingOrderWithStatusCode<T>[] =
    failedResults.map((result) => ({
      shippingOrder: result.processedShippingOrder.shippingOrder,
      statusCode: result.processedShippingOrder.statusCode,
    }));

  return {
    updateValidShippingOrders,
    updateValidOriginalOrders,
    updateInvalidShippingOrders,
  };
}

function isStatusCodeSuccess(statusCode: ShippingOrderProcessStatusCode) {
  return (
    statusCode === SHIPPING_ORDER_PROCESS_STATUS_CODE.SUCCESS ||
    statusCode ===
      SHIPPING_ORDER_PROCESS_STATUS_CODE.INVALID_SHIPPING_NUMBER_DUPLICATE
  );
}

function mergeBy<T extends Record<string, any>>(
  array: T[],
  key: (item: T) => string,
  merge: (acc: T, curr: T) => T
): T[] {
  const grouped: Record<string, T[]> = groupBy(array, key);
  const mergedArray = Object.entries(grouped).map(([_, value]) => {
    return value.reduce<T>((acc, curr) => merge(acc, curr), {} as T);
  });

  return mergedArray;
}

export function mergeShippingNumberByUniqueCode<R extends OriginalOrder>(
  originalOrderUpdatePayloads: R[]
): R[] {
  return mergeBy(
    originalOrderUpdatePayloads,
    (order) => order.uniqueCode,
    (acc, curr) => {
      const { shippingNumber: dstShippingNumber } = acc;
      const { shippingNumber: srcShippingNumber } = curr;

      const srcShippingNumbers = srcShippingNumber?.split(',') ?? [];
      const dstShippingNumbers = dstShippingNumber?.split(',') ?? [];

      const mergedShippingNumbers = [
        ...dstShippingNumbers,
        ...srcShippingNumbers,
      ];
      const uniqueShippingNumbers = Array.from(new Set(mergedShippingNumbers));

      const shippingNumber =
        uniqueShippingNumbers.length > 1
          ? uniqueShippingNumbers.join(',')
          : uniqueShippingNumbers[0];

      return {
        ...acc,
        ...curr,
        shippingNumber,
      };
    }
  );
}
