import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { IconControlDownload } from '@sweep/asset/icons';
import { Button } from '@sweep/sds';
import { If, SwitchCase } from '@sweep/utils/react';
import { usePreventBeforeUnload } from '../../hooks/usePreventBeforeUnload';
import { Matching } from '../../interface';
import CMProgress from '../common/CMProgress';
import { FormLayout } from '../common/FormLayout';
import MatchedCMEditForm from './MatchedCMEditForm';
import PreDivideMatchedCMConfirmForm from './PreDivideMatchedCMConfirmForm';

interface MatchedCMFormProps {
  matchings: Matching[];
  onSubmit: (value: Matching[]) => void;
}

type Status = 'confirm' | 'edit';

function MatchedCMForm({
  matchings: givenMatching,
  onSubmit,
}: MatchedCMFormProps) {
  const [matchings, setMatchings] = useState(givenMatching);
  const [status, setStatus] = useState<Status>('confirm');

  usePreventBeforeUnload();

  const handleEdit = () => setStatus('edit');

  const handleMatchings = (value: Matching[]) => {
    setMatchings(value);
    setStatus('confirm');
  };

  const handleSubmit = () => {
    onSubmit(matchings);
  };

  return (
    <FormLayout>
      <div className="h-42px flex items-center justify-between">
        <p className="text-extrabold-l flex-1">상품 구성 매칭</p>
        <If is={status === 'confirm'}>
          <Button
            onClick={handleSubmit}
            rightAccessory={<IconControlDownload className="text-blue-200" />}
          >
            <div className="gap-8px flex items-center">
              <p className="text-bold-s text-white">완료</p>
              <div className="h-15px w-[1.5px] bg-blue-200" />
              <p className="text-semibo text-blue-200">발주서</p>
            </div>
          </Button>
        </If>
      </div>
      <div className="h-[40px]" />
      <CMProgress>
        <CMProgress.Title index={1} active>
          상품 구성 매칭
        </CMProgress.Title>
        <CMProgress.Description>
          {status === 'confirm'
            ? '매칭이 완료되었습니다.'
            : '매칭을 수정한 후 저장해주세요.'}
        </CMProgress.Description>
      </CMProgress>
      <div className="h-[20px]" />
      <div className="rounded-8px p-20px shadow-line items-center bg-white">
        <SwitchCase
          value={status}
          caseBy={{
            confirm: (
              <PreDivideMatchedCMConfirmForm
                matchings={matchings}
                onEdit={handleEdit}
                onSubmit={handleSubmit}
              />
            ),
            edit: (
              <MatchedCMEditForm
                matchings={matchings}
                onSubmit={handleMatchings}
              />
            ),
          }}
        />
      </div>
    </FormLayout>
  );
}

export default observer(MatchedCMForm);
