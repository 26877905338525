import { cva } from '@sweep/tailwind';
import { imageCardBack1, imageCardBack2, imageCardBack3 } from 'src/images';

interface CardProps {
  number: number;
}

const srcByNumber: Record<number, string> = {
  1: imageCardBack1,
  2: imageCardBack2,
  3: imageCardBack3,
};

const buttonTextByNumber: Record<number, string> = {
  1: '운송장 등록',
  2: '정산',
  3: 'CS 관리',
};

const bgColorByNumber: Record<number, string> = {
  1: 'bg-[#4EBCC1]',
  2: 'bg-[#6B9CDF]',
  3: 'bg-[#C575BC]',
};

const headerTextByNumber: Record<number, React.ReactNode> = {
  1: (
    <>
      운송장 등록도
      <br />
      간편하게
    </>
  ),
  2: (
    <>
      정산 관리는
      <br />
      정확하게
    </>
  ),
  3: (
    <>
      CS 관리는
      <br />
      신속하게
    </>
  ),
};

const descriptionTextByNumber: Record<number, React.ReactNode> = {
  1: (
    <>
      운송장 파일을 업로드만 하면
      <br />
      모든 판매처에 자동으로 반영
    </>
  ),
  2: (
    <>
      기간별, 판매처별, 공급처별
      <br />
      다양한 정산 양식 지원
    </>
  ),
  3: (
    <>
      여러 판매처의 CS 문의도
      <br />
      스윕에서 한번에 처리
    </>
  ),
};

function Card({ number }: CardProps) {
  const src = srcByNumber[number];
  const buttonText = buttonTextByNumber[number];

  return (
    <div className="relative">
      <img src={src} alt="card-back1" className="h-[512px] w-[384px]" />
      <div className="absolute left-1/2 top-1/2 flex h-[424px] w-[296px] -translate-x-1/2 -translate-y-1/2 flex-col items-start justify-between">
        <div className="flex w-full flex-col items-start gap-[32px] text-start">
          <button className={badge({ className: bgColorByNumber[number] })}>
            {buttonText}
          </button>
          <p className="text-start text-[48px] font-bold leading-[60px] tracking-tight text-[#03183A]">
            {headerTextByNumber[number]}
          </p>
          <p className="text-[18px] font-medium leading-[29px] tracking-tight text-[#03183A] opacity-[76%]">
            {descriptionTextByNumber[number]}
          </p>
        </div>
        {/* <div className="flex w-full justify-end">
          <button className={button({ className: bgColorByNumber[number] })}>
            <IconBigDown className="text-white" />
          </button>
        </div> */}
      </div>
    </div>
  );
}

const badge = cva(
  'rounded-[8px] border border-[rgba(255,255,255,0.05)] px-[12px] py-[5px] text-[16px] font-semibold leading-[24px] tracking-tighter text-white'
);

const button = cva(
  'flex size-[49px] items-center justify-center rounded-full border border-[rgba(0,0,0,0.05)]'
);

export default Card;
