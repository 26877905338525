import { cva } from '@sweep/tailwind';
import { useSearchSelectContext } from './context/SearchSelectContext';

interface SearchSelectFooterProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
}

export function SearchSelectFooter({
  onClick,
  className,
  children,
  ...rest
}: SearchSelectFooterProps) {
  const { onClose } = useSearchSelectContext();

  return (
    <button
      className={container({ className })}
      {...rest}
      onClick={(event) => {
        onClick?.(event);
        onClose();
      }}
    >
      {children}
    </button>
  );
}

const container = cva([
  'sticky bottom-0 left-0',
  'h-36px px-16px rounded-b-8px flex w-full shrink-0 items-center bg-white',
  'text-semibold-s border-t border-gray-200 text-blue-500',
]);
