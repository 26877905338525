import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { IconControlDownload, IconControlUpload } from '@sweep/asset/icons';
import { NormalizedOrder } from '@sweep/contract';
import {
  Button,
  SearchInput,
  SelectableTable,
  TableCount,
  TableSelectControl,
} from '@sweep/sds';
import { formatDate } from '@sweep/utils';
import { If } from '@sweep/utils/react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { useSearchState } from 'src/hooks/useSearchState';
import { createOrderExcel } from 'src/services/file/excel/create';
import PurchaseOrderFileList from '../components/PurchaseOrderFileList';
import { useTableFilterColumnsWithEdit } from '../hooks/useTableFilterColumnsWithEdit';
import { processShippingOrderFileForRetailer } from '../services/process-shipping-order-files/processShippingOrderFileForRetailer';
import { processShippingOrderFileForSupplier } from '../services/process-shipping-order-files/processShippingOrderFileForSupplier';
import { OrderShippingProcessingStore } from '../stores/OrderShippingProcessingStore';

function OrderShippingSectionProcessing() {
  const oms = useOMSStore();
  const store = oms.getStore(OrderShippingProcessingStore);

  const [search, setSearch, filterBySearch] = useSearchState({
    keys: oms.user.excelHeaderKeys,
  });
  const searchFilteredOrders = useMemo(
    () => filterBySearch(store.shippingStatusProcessingOrders),
    [filterBySearch, store.shippingStatusProcessingOrders]
  );

  const isUploadHandWrittenFile =
    oms.user.setting?.uploadHandWrittenFile === true;
  const { columns, filteredOrders } = useTableFilterColumnsWithEdit({
    orders: searchFilteredOrders,
    useUniqueCodeSub: !isUploadHandWrittenFile, // SWP-582 뉴스고 보여주기, uploadHandWrittenFile 이 켜져있으면 비활성화
  });

  const currentOrders =
    store.selectedOrders.length > 0 ? store.selectedOrders : filteredOrders;

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const files = event.target.files;
      if (files == null) {
        alert('파일을 선택해주세요.');
        return;
      }

      const isSupplier = oms.user.companyType === 'supplier';
      const processShippingOrderFile = isSupplier
        ? processShippingOrderFileForSupplier
        : processShippingOrderFileForRetailer;

      for (const file of files) {
        // NOTE(@형준): promise.all 로 돌릴경우, 마지막 성공한 파일 데이터 기준으로 normalizedOrders 가 업데이트되는 문제가 있어서 sync 로 돌리게 수정
        await processShippingOrderFile({ file }, { oms });
      }

      // + selectedOrders 초기화 여부 확인 필요
      store.setSelectedOrders([]);
    } finally {
      event.target.value = '';
    }
  };

  const handleRevert = async (filenames: string[]) => {
    await store.revertToPending(filenames);
    store.setSelectedOrders([]);
  };

  const download = () => {
    const isUploadHandWrittenFile =
      oms.user.setting?.uploadHandWrittenFile === true;
    // SWP-582 뉴스고 보여주기, uploadHandWrittenFile 이 켜져있으면 비활성화
    const tempModifiedOrders = isUploadHandWrittenFile
      ? currentOrders
      : currentOrders.map((order) => ({
          ...order,
          uniqueCode: order.uniqueCodeAfterCustomization,
        }));

    createOrderExcel(
      oms,
      tempModifiedOrders, // SWP-582 뉴스고 보여주기
      formatDate(new Date(), 'yy.MM.dd 통합엑셀'),
      oms.user.excelHeaders,
      oms.user.excelColumnMapping
    );
  };

  const handleCustomFileUpload = async (files: FileList) => {
    await store.uploadHandwrittenFile(files);
    store.setSelectedOrders([]);
  };

  return (
    <div className="gap-20px flex flex-col">
      <PurchaseOrderFileList
        filenames={store.purchaseOrderFilenames}
        onRevert={handleRevert}
        onDownload={store.downloadPurchaseOrder}
        onUpload={handleCustomFileUpload}
      />
      <div className="gap-16px px-20px py-24px rounded-8px shadow-line flex flex-col bg-white">
        <div className="flex justify-between">
          <SearchInput
            value={search}
            onChange={setSearch}
            placeholder="검색어를 입력하세요."
            className="max-w-400px"
          />
          <Button rightAccessory={<IconControlUpload />} asChild>
            <label>
              운송장 파일 업로드
              <input
                className="hidden"
                type="file"
                accept=".xlsx, .xls, .csv"
                multiple
                onChange={handleFileUpload}
              />
            </label>
          </Button>
        </div>
        <hr />
        <div className="flex flex-col">
          <TableCount count={filteredOrders.length} />
          <div className="flex items-end justify-between">
            <TableSelectControl
              count={store.selectedOrders.length}
              onClear={() => store.setSelectedOrders([])}
            />
            <Button
              color="gray"
              rightAccessory={<IconControlDownload />}
              onClick={download}
            >
              엑셀 파일
            </Button>
          </div>
        </div>
        <If is={store.shippingStatusProcessingOrders.length > 0}>
          <SelectableTable
            key={store.purchaseOrderFilenames.join(',')}
            items={filteredOrders}
            columns={columns}
            selectedItems={store.selectedOrders}
            onSelect={store.setSelectedOrders}
            getKey={(order: NormalizedOrder) =>
              order.uniqueCodeAfterCustomization
            }
            className="max-h-[calc(100vh-300px)]"
          />
        </If>
      </div>
    </div>
  );
}

export default observer(OrderShippingSectionProcessing);
