import { useState } from 'react';
import {
  IconControlDownload,
  IconControlReset,
  IconControlUpload,
  IconTwoCopies,
} from '@sweep/asset/icons';
import { Button, Checkbox } from '@sweep/sds';
import { cva } from '@sweep/tailwind';
import { If } from '@sweep/utils/react';
import { useOMSStore } from 'src/hooks/useOMSStore';

interface PurchaseOrderFileListProps {
  filenames: string[];
  onRevert: (filenames: string[]) => void;
  onDownload: (filenames: string[]) => void;
  onUpload?: (files: FileList) => Promise<void>;
}

function PurchaseOrderFileList({
  filenames,
  onRevert,
  onDownload,
  onUpload,
}: PurchaseOrderFileListProps) {
  const oms = useOMSStore();
  const [selectedFilenames, setSelectedFilenames] = useState<string[]>([]);

  const selected = (filename: string) => selectedFilenames.includes(filename);

  const handleSelect = (filename: string) => {
    if (selectedFilenames.includes(filename)) {
      setSelectedFilenames(
        selectedFilenames.filter(
          (selectedFilename) => selectedFilename !== filename
        )
      );
      return;
    }

    setSelectedFilenames([...selectedFilenames, filename]);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files == null) {
      return;
    }

    await onUpload?.(files);
    event.target.value = '';
  };

  return (
    <div className={container()}>
      <p className="text-medium-s text-gray-500">
        {oms.user.isRetailer ? '발주서' : '파일'}
      </p>
      <div className="gap-10px no-scrollbar flex flex-1 overflow-auto">
        {filenames.map((filename) => (
          <button
            key={filename}
            className={item({ selected: selected(filename) })}
            onClick={() => handleSelect(filename)}
          >
            <IconTwoCopies className="mr-6px text-blue-500" />
            <p className="text-semibold-s mr-8px whitespace-nowrap text-gray-600">
              {filename}
            </p>
            <Checkbox checked={selected(filename)} />
          </button>
        ))}
      </div>
      <div className="gap-10px flex">
        <If is={selectedFilenames.length > 0}>
          <Button
            color="lightGray"
            rightAccessory={<IconControlReset />}
            onClick={() => onRevert(selectedFilenames)}
          >
            되돌리기
          </Button>
          <Button
            color="gray"
            rightAccessory={<IconControlDownload />}
            onClick={() => onDownload(selectedFilenames)}
          >
            {oms.user.isRetailer ? '발주서' : '발주 통합 파일'}
          </Button>
        </If>
        <If is={oms.user.setting?.uploadHandWrittenFile === true}>
          <Button variant="line" rightAccessory={<IconControlUpload />} asChild>
            <label>
              수기 파일 업로드
              <input
                className="hidden"
                type="file"
                onChange={handleFileChange}
              />
            </label>
          </Button>
        </If>
      </div>
    </div>
  );
}

const container = cva([
  'gap-16px flex items-center',
  'pl-24px pr-28px py-20px',
  'shadow-line rounded-8px bg-white',
]);

const item = cva(
  'px-12px h-44px rounded-8px flex items-center border bg-gray-100',
  {
    variants: {
      selected: {
        true: 'border-blue-500',
        false: 'border-gray-100',
      },
    },
  }
);

export default PurchaseOrderFileList;
