import { http, HttpResponse } from 'msw';
import {
  DispatchOrderPollingResponse,
  GET_SHOPPING_MALLS_URL,
  GetShoppingMallsResponse,
} from '@sweep/contract';
import { sleep } from '@sweep/utils';
import { LocalStorageValue } from '@sweep/utils/mobx';
import { BACKEND_URL } from 'src/env';
import { APIResponse } from 'src/network/api';
import { GetFetchOrdersRes } from 'src/network/order/dispatchOrder';
import { LOCALSTORAGE_DISPATCH_PREFIX } from '../../../common/constants';
import { dispatchStore } from '../DispatchHandlerStore';

const DISPATCH_ORDER_URL = `${BACKEND_URL}/dispatch-order`;

export type DispatchOrderType = 'success' | 'fail-five' | 'fail' | 'none';
export const dispatchOrderTypeStorage =
  new LocalStorageValue<DispatchOrderType>({
    key: `${LOCALSTORAGE_DISPATCH_PREFIX}/dispatch-order-type`,
    defaultValue: 'none',
  });

export const createDispatchOrderHandlers = () => {
  const type = dispatchOrderTypeStorage.value;
  if (type === 'none') {
    return [];
  }

  return [
    createGetShoppingMallsHandler(),
    createFetchOrdersHandler(),
    createFetchOrdersPollingHandler(),
  ];
};

function createGetShoppingMallsHandler() {
  return http.get(`${BACKEND_URL}${GET_SHOPPING_MALLS_URL}`, () =>
    HttpResponse.json<APIResponse<GetShoppingMallsResponse>>({
      success: true,
      data: dispatchStore.shoppingMalls,
    })
  );
}

function createFetchOrdersHandler() {
  return http.get(`${DISPATCH_ORDER_URL}/fetch-orders`, async ({ request }) => {
    const url = new URL(request.url);
    const shoppingMall = url.searchParams.get('shoppingMall') as string;
    await sleep(1000);

    return HttpResponse.json<GetFetchOrdersRes>({
      message: 'Success',
      result: {
        requestId: shoppingMall,
      },
    });
  });
}

function createFetchOrdersPollingHandler() {
  const type = dispatchOrderTypeStorage.value;
  const pollingCounts: Record<string, number> = {};

  return http.get(
    `${DISPATCH_ORDER_URL}/fetch-orders-polling`,
    async ({ request }) => {
      await sleep(1000);
      const url = new URL(request.url);
      const shoppingMall = url.searchParams.get('requestId');
      if (shoppingMall == null) {
        return failPollingResponse();
      }

      if (type === 'success') {
        return successPollingResponse(shoppingMall);
      }

      if (type === 'fail-five') {
        const count = pollingCounts[shoppingMall] ?? 1;
        pollingCounts[shoppingMall] = count + 1;
        if (shoppingMall === 'NAVER-1' || count % 5 === 0) {
          return successPollingResponse(shoppingMall);
        }

        return failPollingResponse();
      }

      if (type === 'fail') {
        return failPollingResponse();
      }
    }
  );
}

const successPollingResponse = (shoppingMallName: string) => {
  return HttpResponse.json<APIResponse<DispatchOrderPollingResponse>>({
    success: true,
    data: {
      status: 'FINISH',
      data: dispatchStore.getOrdersByShoppingMall(shoppingMallName),
    },
  });
};

const failPollingResponse = () => {
  return HttpResponse.json<APIResponse<DispatchOrderPollingResponse>>({
    success: false,
    error: 'Failed',
  });
};
