import { ColumnMapping } from 'src/models/ColumnMapping';

export const addMissingRequiredColumns = (
  columnKeys: string[],
  columnNames: string[],
  columnMapping: ColumnMapping
): {
  columnKeys: string[];
  columnNames: string[];
  columnMapping: ColumnMapping;
  additionalColumnNames: string[];
} => {
  const REQUIRED_COLUMN_MAPPING = {
    shippingCompany: '택배사',
    shippingNumber: '운송장번호',
  };

  const additionalColumnNames: string[] = [];
  const fixedColumnNames = [...columnNames];
  const fixedColumnKeys = [...columnKeys];
  const fixedColumnMapping = { ...columnMapping };

  Object.entries(REQUIRED_COLUMN_MAPPING).forEach(([key, value]) => {
    const isColumnKeyExists = columnKeys.includes(key);

    if (!isColumnKeyExists) {
      fixedColumnKeys.push(key);
      fixedColumnNames.push(value);
      fixedColumnMapping[key] = value;
      additionalColumnNames.push(value);
    }
  });

  return {
    columnKeys: fixedColumnKeys,
    columnNames: fixedColumnNames,
    columnMapping: fixedColumnMapping,
    additionalColumnNames,
  };
};
