import { z } from 'zod';
import { CombinedShipping, ZCombinedShipping } from '../type/CombinedShipping';

// GET /combined-shipping
export const GET_COMBINED_SHIPPING_URL = '/combined-shipping';

export type GetCombinedShippingResponse = CombinedShipping[];

// POST /combined-shipping
export const CREATE_COMBINED_SHIPPING_URL = '/combined-shipping';

export const ZCreateCombinedShippingDTO = ZCombinedShipping.omit({ _id: true });
export type CreateCombinedShippingDTO = z.infer<
  typeof ZCreateCombinedShippingDTO
>;

export const ZCreateCombinedShippingBody = ZCreateCombinedShippingDTO;
export type CreateCombinedShippingBody = CreateCombinedShippingDTO;

export type CreateCombinedShippingResponse = CombinedShipping;

// PUT /combined-shipping/:id
export const UPDATE_COMBINED_SHIPPING_URL = (id: string) =>
  `/combined-shipping/${id}`;

export const ZUpdateCombinedShippingDTO = ZCombinedShipping.omit({
  _id: true,
}).partial();
export type UpdateCombinedShippingDTO = z.infer<
  typeof ZUpdateCombinedShippingDTO
>;

export const ZUpdateCombinedShippingBody = ZUpdateCombinedShippingDTO;
export type UpdateCombinedShippingBody = UpdateCombinedShippingDTO;

export type UpdateCombinedShippingResponse = CombinedShipping;

// DELETE /combined-shipping/:id
export const DELETE_COMBINED_SHIPPING_URL = (id: string) =>
  `/combined-shipping/${id}`;

export type DeleteCombinedShippingResponse = CombinedShipping;
