import { trim } from 'es-toolkit';
import { isNil } from 'lodash';
import { NormalizedOrder } from '@sweep/contract';
import { SHIPPING_ORDER_PROCESS_STATUS_CODE } from '@sweep/domain/services/order-shipping-process';
import { createLogger } from 'src/third-parties/createLogger';
import { ProcessingItem } from './processor.type';

const debug = createLogger(
  'services:process-shipping-order-files:initializeProcessingItem'
);

export const initializeProcessingItem = (
  shippingOrder: NormalizedOrder,
  index: number
): ProcessingItem[] => {
  debug('start', shippingOrder.index);
  const rawShippingOrderKey = shippingOrder.uniqueCode; // NOTE(@hungjoon): KEY 값은 uniqueCode로 사용하지만 실제 내용은 uniqueCodeAfterCustomization을 사용함

  if (isNil(rawShippingOrderKey)) {
    return [
      {
        isProcessable: true,
        key: rawShippingOrderKey,
        index,
        value: shippingOrder,
        histories: [],
        status: SHIPPING_ORDER_PROCESS_STATUS_CODE.SUCCESS,
      },
    ];
  }

  const shippingOrderKeys = rawShippingOrderKey
    ?.split(',')
    .map((key) => trim(key));

  if (shippingOrderKeys.length === 1) {
    return [
      {
        isProcessable: true,
        key: rawShippingOrderKey,
        index,
        value: shippingOrder,
        histories: [],
        status: SHIPPING_ORDER_PROCESS_STATUS_CODE.SUCCESS,
      },
    ];
  }

  return shippingOrderKeys.map((shippingOrderKey) => ({
    isProcessable: true,
    key: shippingOrderKey,
    index,
    value: shippingOrder,
    histories: [],
    status: SHIPPING_ORDER_PROCESS_STATUS_CODE.SUCCESS,
  }));
};
