import { ForwardedRef, forwardRef, HTMLProps } from 'react';
import { cva } from '@sweep/tailwind';
import { useRefs } from '@sweep/utils/react';
import { useTooltipContext } from './context/TooltipContext';
import { useTooltipOuterContext } from './context/TooltipOuterContext';

function _TooltipContent(
  props: React.HTMLProps<HTMLDivElement>,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { disabled } = useTooltipOuterContext();

  if (disabled) {
    return null;
  }

  return <TooltipContentInner {...props} ref={ref} />;
}

export const TooltipContent = forwardRef(_TooltipContent);

function _TooltipContentInner(
  { className, ...rest }: HTMLProps<HTMLDivElement>,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { open, refs, getFloatingProps, floatingStyles, type } =
    useTooltipContext();
  const innerRef = useRefs([refs.setFloating, ref]);

  if (!open) {
    return null;
  }

  return (
    <div
      ref={innerRef}
      className={content({ type, className })}
      style={floatingStyles}
      {...getFloatingProps?.(rest)}
    />
  );
}

const TooltipContentInner = forwardRef(_TooltipContentInner);

const content = cva(
  ['flex items-center justify-center', 'text-medium-xs bg-gray-700 text-white'],
  {
    variants: {
      type: {
        basic: 'px-12px py-4px rounded-full',
        info: 'px-12px py-6px rounded-8px',
      },
    },
  }
);
