type SeparatedSheetData = {
  headerRow: string[];
  dataRows: string[][];
};

const EMPTY_SEPARATED_DATA: SeparatedSheetData = {
  headerRow: [],
  dataRows: [],
};

export function separateHeaderAndItems(data: string[][]): SeparatedSheetData {
  if (!Array.isArray(data) || data.length === 0) {
    return EMPTY_SEPARATED_DATA;
  }

  const [headerRow, ...dataRows] = data;

  if (!Array.isArray(headerRow)) {
    return EMPTY_SEPARATED_DATA;
  }

  return {
    headerRow,
    dataRows,
  };
}
