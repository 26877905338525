import { action, computed, observable } from 'mobx';
import { isNotNil } from '@sweep/utils';
import { OMSStore } from 'src/stores/OMSStore';
import { MatchingOrder, MatchingState } from '../../interface';
import { getInitialProducts } from '../../services/getInitialProducts';

export class UnmatchedCMFormStore {
  @observable
  accessor matchings: MatchingState[] = [];

  @observable
  accessor search: string = '';

  @computed
  get filteredMatchings() {
    const trimmedSearch = this.search.trim();
    return this.matchings.filter(({ order }) =>
      [order.productName, order.option, order.optionCode]
        .filter(isNotNil)
        .map((value) => value.trim())
        .some((value) => value.includes(trimmedSearch))
    );
  }

  @computed
  get matchedCount() {
    const confirmedMatchings = this.matchings.filter(
      (matching) => matching.status === 'confirm'
    );

    return confirmedMatchings.length;
  }

  constructor(oms: OMSStore, orders: MatchingOrder[]) {
    this.matchings = orders.map((order) => ({
      order,
      products: getInitialProducts(order, oms),
      status: 'pending',
    }));
  }

  @action.bound
  setMatching = (index: number, value: MatchingState) => {
    this.matchings[index] = value;
  };

  @action.bound
  setSearch = (value: string) => {
    this.search = value;
  };
}
