import { z } from 'zod';

const DoseosanganStandardSchema = z.enum(['CJ', 'LOTTE']);
export type DoseosanganStandard = z.infer<typeof DoseosanganStandardSchema>;

const formatProductNameConfigSchema = z.object({
  setDataQuantity1: z.boolean().optional(),
  setOrderQuantity1: z.boolean().optional(),

  showQuantityAlways: z.boolean().optional(),
  customFormat: z.string().nullish(),
  customSeparator: z.string().nullish(),
  customQuantityFormat: z.string().nullish(),

  useProductName: z.boolean().optional(),
});

export type FormatProductNameConfig = z.infer<
  typeof formatProductNameConfigSchema
>;

const formatProductNameRuleSchema = z.object({
  condition: z.object({
    partnerId: z.string().nullish(),
    shoppingMallId: z.string().nullish(),
    supplierId: z.string().nullish(),
    productId: z.string().nullish(),
  }),
  value: z.object({
    prefix: z.string().nullish(),
    suffix: z.string().nullish(),
  }),
});

export type FormatProductNameRule = z.infer<typeof formatProductNameRuleSchema>;

const formatOrderSettingsSchema = formatProductNameConfigSchema.extend({
  suppliers: z
    .array(
      z.object({
        supplierId: z.string(),
        config: formatProductNameConfigSchema,
      })
    )
    .nullish(),
  rules: formatProductNameRuleSchema.array().nullish(),
});

export type FormatOrderSettings = z.infer<typeof formatOrderSettingsSchema>;

export const userSettingSchema = z.object({
  _id: z.string(),
  columnOrder: z.array(z.string()),
  columnTranslation: z.record(z.string()),

  doseosangan: z
    .object({ standard: DoseosanganStandardSchema.optional() })
    .optional(),
  compositionMatching: z
    .object({ optionCodeMatching: z.boolean().optional() })
    .optional(),
  uploadHandWrittenFile: z.boolean().optional(),
  supplierFileFormatting: z
    .object({
      /**
       * @example "{date} {name} {supplierName} 발주서"
       */
      fileFormat: z.string(),
      dateFormat: z.string().optional(),
    })
    .optional(),
  excel: z
    .object({
      removeSpecialCharactersInName: z.boolean().optional(),
      fields: z
        .object({ target: z.string(), type: z.enum(['number']) })
        .array()
        .optional(),
    })
    .optional(),
  combinedShipping: z
    .object({
      separateAll: z.boolean().optional(),
      combineAll: z.boolean().optional(),
    })
    .optional(),
  formatOrdersSettings: formatOrderSettingsSchema.optional(),
  convertSpecificCompositionExpressionSetting: z
    .object({
      enabled: z.boolean().optional(),
      settings: z
        .object({
          replacement: z.string(),
          productId: z.string().optional(),
          partnerId: z.string().optional(),
          object: z
            .object({
              productName: z.string(),
            })
            .optional(),
        })
        .array(),
    })
    .optional(),
  defaultShippingCompany: z.any(),

  platformNameSettings: z.any(),
  sortOrders: z.any(),
  applyMergedCellValues: z.any(),
  applyFieldValueToAnother: z.any(),

  preprocessSettings: z.any(),

  interpreteOrderSettings: z.any(),
  shippingExcelTemplate: z.any(),
  settlementType: z.any(),
});

export type UserSetting = z.infer<typeof userSettingSchema>;
