import { NormalizedOrder, Order } from '@sweep/contract';
import { SHIPPING_ORDER_PROCESS_STATUS_CODE_MESSAGE } from '@sweep/domain/services/order-shipping-process';
import { formatDate, isNotEmptyString, isNotNil } from '@sweep/utils';
import {
  CANCEL_REQUESTED_ORDER_COLUMN_MAPPING,
  CANCEL_REQUESTED_ORDER_HEADERS,
} from 'src/screens/dispatch/constants';
import { createOrderExcel } from 'src/services/file/excel/create';
import { OMSStore } from 'src/stores/OMSStore';
import {
  FailedOriginalOrderWithFailReason,
  FailedOriginalOrderWithStatusCode,
  FailedShippingOrderWithStatusCode,
  ShippingOrderWithFailReason,
} from '../types';

export async function downloadFailReasonStep(params: {
  updateInvalidShippingOrders: FailedShippingOrderWithStatusCode<NormalizedOrder>[];
  statusUpdateFailedOrders: FailedShippingOrderWithStatusCode<NormalizedOrder>[];
  autoDispatchFailedOrders: FailedOriginalOrderWithStatusCode<Order>[];
  manualDispatchFailedOrders: FailedOriginalOrderWithStatusCode<Order>[];
  shippingOrders: NormalizedOrder[];
  oms: OMSStore;
}) {
  const {
    updateInvalidShippingOrders,
    statusUpdateFailedOrders,
    autoDispatchFailedOrders,
    manualDispatchFailedOrders,
    shippingOrders,
    oms,
  } = params;

  const invalidUpdateFailReasons = getComposedFailReasonFromShippingOrders(
    updateInvalidShippingOrders
  );

  const statusUpdateFailReasons = getComposedFailReasonFromShippingOrders(
    statusUpdateFailedOrders
  );

  const failedUniqueCodeAfterCustomizations = new Set([
    ...Object.keys(invalidUpdateFailReasons),
    ...Object.keys(statusUpdateFailReasons),
  ]);

  const checkIsFailedUniqueCodeAfterCustomization = (
    uniqueCodeAfterCustomization: string
  ) => {
    return failedUniqueCodeAfterCustomizations.has(
      uniqueCodeAfterCustomization
    );
  };

  const autoDispatchFailReasonKeyByUniqueCode =
    getComposedFailReasonFromOriginalOrders(autoDispatchFailedOrders);

  const manualDispatchFailReasonKeyByUniqueCode =
    getComposedFailReasonFromOriginalOrders(manualDispatchFailedOrders);

  const failedUniqueCodes = new Set([
    ...Object.keys(autoDispatchFailReasonKeyByUniqueCode),
    ...Object.keys(manualDispatchFailReasonKeyByUniqueCode),
  ]);

  const checkIsFailedUniqueCode = (uniqueCode: string) => {
    return failedUniqueCodes.has(uniqueCode);
  };

  const failedShippingOrdersWithStatusCode: (NormalizedOrder & {
    failReason?: string;
  })[] = shippingOrders
    .filter((order) => {
      const uniqueCodes = order.uniqueCode
        .split(',')
        .map((code) => code.trim());

      const isFailedUniqueCode = uniqueCodes.some((uniqueCode) =>
        checkIsFailedUniqueCode(uniqueCode)
      );
      const isFailedByUniqueCodeAfterCustomization =
        checkIsFailedUniqueCodeAfterCustomization(
          order.uniqueCodeAfterCustomization
        );

      return isFailedUniqueCode || isFailedByUniqueCodeAfterCustomization;
    })
    .map((order) => {
      const uniqueCodes = order.uniqueCode
        .split(',')
        .map((code) => code.trim());
      const failReasonByUniqueCode = uniqueCodes.flatMap((uniqueCode) => {
        const autoDispatchFailReasons =
          autoDispatchFailReasonKeyByUniqueCode[uniqueCode] ?? [];
        const manualDispatchFailReasons =
          manualDispatchFailReasonKeyByUniqueCode[uniqueCode] ?? [];
        return [...autoDispatchFailReasons, ...manualDispatchFailReasons];
      });

      const uniqueCodeAfterCustomization = order.uniqueCodeAfterCustomization;

      const invalidUpdateFailReason =
        invalidUpdateFailReasons[uniqueCodeAfterCustomization] ?? null;
      const statusUpdateFailReason =
        statusUpdateFailReasons[uniqueCodeAfterCustomization] ?? null;

      const failReasons = [
        ...failReasonByUniqueCode,
        invalidUpdateFailReason,
        statusUpdateFailReason,
      ].filter(isNotNil);

      const composedFailReason = failReasons.join(', ');

      return {
        ...order,
        failReason: composedFailReason,
      };
    }) as ShippingOrderWithFailReason<NormalizedOrder>[];

  if (failedShippingOrdersWithStatusCode.length > 0) {
    await createFailedShippingOrdersExcel({
      oms,
      failedOrders: failedShippingOrdersWithStatusCode,
    });
  }
}

function getComposedFailReasonFromShippingOrders(
  results: FailedShippingOrderWithStatusCode<NormalizedOrder>[]
): Record<string, string[]> {
  const failReasons = results.reduce(
    (acc, result) => {
      const uniqueCodeAfterCustomization =
        result.shippingOrder.uniqueCodeAfterCustomization;
      const statusCode = result.statusCode;

      const failReasonByStatusCode =
        SHIPPING_ORDER_PROCESS_STATUS_CODE_MESSAGE?.[statusCode];

      const failReason = isNotNil(failReasonByStatusCode)
        ? failReasonByStatusCode
        : SHIPPING_ORDER_PROCESS_STATUS_CODE_MESSAGE?.[
            'STEP_UPDATE_SHIPPING_INFO_FAILED_UNKNOWN_ERROR'
          ];

      const prev = acc[uniqueCodeAfterCustomization] ?? [];
      const next = [...prev, failReason];
      acc[uniqueCodeAfterCustomization] = next;

      return acc;
    },
    {} as Record<string, string[]>
  );

  return failReasons;
}

function getComposedFailReasonFromOriginalOrders(
  orders: FailedOriginalOrderWithFailReason<Order>[]
): Record<string, string[]> {
  const failedReasons = orders.map(getFailReasonFromOriginalOrder);

  const failedReasonByUniqueCode = failedReasons.reduce(
    (acc, [uniqueCode, failReason]) => {
      const prev = acc[uniqueCode] ?? [];
      const next = [...prev, failReason];
      acc[uniqueCode] = next;
      return acc;
    },
    {} as Record<string, string[]>
  );

  return failedReasonByUniqueCode;
}

function getFailReasonFromOriginalOrder(
  order: FailedOriginalOrderWithFailReason<Order>
): [string, string] {
  const uniqueCode = order.originalOrder.uniqueCode;
  const statusCode = order.statusCode;
  const failReason = order.failReason;

  if (isNotEmptyString(failReason)) {
    return [uniqueCode, failReason];
  }

  const failReasonByStatusCode =
    SHIPPING_ORDER_PROCESS_STATUS_CODE_MESSAGE?.[statusCode];

  if (isNotEmptyString(failReasonByStatusCode)) {
    return [uniqueCode, failReasonByStatusCode];
  }

  const defaultFailReason =
    SHIPPING_ORDER_PROCESS_STATUS_CODE_MESSAGE['INVALID_UNKNOWN_ERROR'];

  return [uniqueCode, defaultFailReason];
}

export async function createFailedShippingOrdersExcel({
  oms,
  failedOrders,
}: {
  oms: OMSStore;
  failedOrders: (NormalizedOrder & { failReason?: string })[];
}) {
  await createOrderExcel(
    oms,
    failedOrders,
    formatDate(new Date(), 'yy.MM.dd 운송장 등록 실패 건'),
    ['실패 사유', ...CANCEL_REQUESTED_ORDER_HEADERS],
    { failReason: '실패 사유', ...CANCEL_REQUESTED_ORDER_COLUMN_MAPPING }
  );
}
