import { SHIPPING_ORDER_PROCESS_STATUS_CODE } from '@sweep/domain/services/order-shipping-process';
import { createLogger } from 'src/third-parties/createLogger';
import { ProcesserFunction } from './processor.type';

const debug = createLogger(
  'services:process-shipping-order-files:processDuplicateKeyError'
);

export const processDuplicateKeyError: ProcesserFunction = (item, context) => {
  debug('start', item.index);
  if (!item.isProcessable) {
    return item;
  }

  const { key } = item;
  const { alreadyProcessedKeys } = context;

  const isDuplicateKey = alreadyProcessedKeys.has(key);
  if (isDuplicateKey) {
    const nextStatus = SHIPPING_ORDER_PROCESS_STATUS_CODE.INVALID_DUPLICATE_KEY;
    const prevHistories = item.histories;
    const nextHistories = [...prevHistories, nextStatus];

    return {
      ...item,
      isProcessable: false,
      status: nextStatus,
      histories: nextHistories,
    };
  }

  alreadyProcessedKeys.add(key);
  return item;
};
