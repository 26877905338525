import { observer } from 'mobx-react-lite';
import { useId } from 'react';
import { TextInput } from '@sweep/sds';
import { ProductFormStore } from '../../ProductFormStore';

interface ProductNameInputProps {
  store: ProductFormStore;
}

function ProductNameInput({ store }: ProductNameInputProps) {
  const id = useId();
  const isDuplicated = store.isDuplicatedProductName;

  return (
    <div className="flex flex-col gap-[8px]">
      <label htmlFor={id} className="text-medium-m w-fit text-gray-700">
        상품명
      </label>
      <TextInput
        id={id}
        value={store.productName}
        onChange={(value) => store.setProductName(value.trim())}
        status={isDuplicated ? 'error' : 'default'}
        helperText={isDuplicated ? '이미 존재하는 상품명입니다.' : ''}
        saveOnBlur
      />
    </div>
  );
}

export default observer(ProductNameInput);
