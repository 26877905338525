import { observer } from 'mobx-react-lite';
import { IconControlDownload, IconInfo } from '@sweep/asset/icons';
import { DispatchedOrder } from '@sweep/contract';
import { Button, ColumnDef, Table } from '@sweep/sds';
import { formatDate } from '@sweep/utils';
import { If } from '@sweep/utils/react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { createOrderExcel } from 'src/services/file/excel/create';
import { amplitude } from 'src/third-parties/amplitude';
import { formatPhoneNumber } from 'src/utils/string';
import ShoppingMallSelect from './components/ShoppingMallSelect';
import TextInputWithLabel from './components/TextInputWithLabelProps';
import {
  ORDER_SEARCH_COLUMN_MAPPING,
  ORDER_SEARCH_HEADERS,
} from './constants/orderSearchColumns';
import { OrderDispatchSearchScreenStore } from './OrderDispatchSearchScreenStore';
import { getShippingCompanyUrl } from './services/getShippingCompanyUrl';

function OrderDispatchSearchScreen() {
  const oms = useOMSStore();
  const store = oms.getStore(OrderDispatchSearchScreenStore);

  const downloadExcel = () => {
    if (store.orders == null) {
      return;
    }
    createOrderExcel(
      oms,
      store.orders,
      formatDate(new Date(), 'yy.MM.dd 주문조회'),
      ORDER_SEARCH_HEADERS,
      ORDER_SEARCH_COLUMN_MAPPING
    );
  };

  const search = () => {
    if (!store.searchDisabled) {
      store.search();
    }
  };

  return (
    <div className="px-40px py-20px gap-32px flex h-full flex-col bg-gray-100">
      <div className="h-42px flex items-center">
        <p className="text-extrabold-l text-gray-700">주문 조회하기</p>
      </div>
      <div className="gap-24px pt-24px pb-20px px-24px rounded-8px flex flex-col bg-white shadow-[0_1px_4px_0_rgba(0,0,0,0.1)]">
        <div className="gap-20px flex items-center">
          <p className="text-medium-s w-[49px] text-gray-500">쇼핑몰</p>
          <ShoppingMallSelect
            value={store.shoppingMall}
            onChange={store.setShoppingMall}
          />
        </div>
        <div className="gap-y-12px gap-80px flex flex-wrap">
          <TextInputWithLabel
            label="주문번호"
            placeholder="20201010123456789"
            value={store.orderNumber ?? ''}
            onChange={store.setOrderNumber}
            onEnterDown={search}
          />
          <TextInputWithLabel
            label="전화번호"
            value={store.phoneNumber ?? ''}
            onChange={store.setPhoneNumber}
            placeholder="010-0000-0000"
            onEnterDown={search}
          />
          <TextInputWithLabel
            label="이름"
            value={store.name ?? ''}
            onChange={store.setName}
            placeholder="김스윕"
            onEnterDown={search}
          />
        </div>
        <hr />
        <div className="flex">
          <div className="flex flex-1 flex-col">
            <div className="gap-6px flex">
              <IconInfo className="text-gray-400" />
              <p className="text-regular-s flex-1 text-gray-600">
                연동 쇼핑몰의 30일 이내의 주문을 조회할 수 있습니다.
              </p>
            </div>
            <div className="gap-6px flex">
              <IconInfo className="text-gray-400" />
              <p className="text-regular-s flex-1 text-gray-600">
                검색어를 정확히 입력해주세요.
              </p>
            </div>
          </div>
          <Button
            disabled={store.searchDisabled}
            className="m-auto w-fit"
            onClick={search}
          >
            검색
          </Button>
          <div className="flex-1" />
        </div>
      </div>
      {store.orders && (
        <div className="p-20px rounded-8px gap-16px flex flex-col bg-white shadow-[0_1px_4px_0_rgba(0,0,0,0.1)]">
          <If is={store.orders.length > 0}>
            <div className="pr-10px flex items-center justify-between">
              <div className="gap-4px flex items-center">
                <p className="text-medium-s text-gray-500">목록</p>
                <p className="text-medium-s text-gray-500">
                  (
                  <span className="text-semibold-s text-blue-500">
                    {store.orders?.length ?? 0}
                  </span>
                  개)
                </p>
              </div>
              <Button
                color="gray"
                rightAccessory={<IconControlDownload />}
                onClick={downloadExcel}
              >
                엑셀 파일
              </Button>
            </div>
            <Table
              getKey={(order) => order.uniqueCode}
              items={store.orders}
              columns={ORDER_SEARCH_COLUMNS}
              className="max-h-[calc(100vh-300px)]"
            />
          </If>
          <If is={store.orders.length === 0}>
            <div className="h-120px flex-center flex">
              <p className="text-medium-s text-gray-500">
                검색 결과가 없습니다.
              </p>
            </div>
          </If>
        </div>
      )}
    </div>
  );
}

const ORDER_SEARCH_COLUMNS: ColumnDef<DispatchedOrder>[] = [
  {
    header: () => '쇼핑몰',
    accessorFn: (order) => order.shoppingMall,
  },
  {
    header: () => '주문시간',
    accessorFn: (order) => order.orderDate,
  },
  {
    header: () => '결제시간',
    accessorFn: (order) => order.paymentDate,
  },
  {
    header: () => '주문상태',
    accessorFn: (order) => order.orderStatus,
  },
  {
    header: () => '배송업체',
    accessorFn: (order) => order.sweepShippingCompany,
  },
  {
    header: () => '운송장번호',
    accessorFn: (order) => order.shippingNumber,
    cell: (context) => {
      const { row: order } = context;
      const shippingNumber = order.shippingNumber;
      const shippingCompany = order.sweepShippingCompany;
      const url =
        shippingNumber != null && shippingCompany != null
          ? getShippingCompanyUrl(shippingNumber, shippingCompany)
          : null;

      if (url == null) {
        return <Table.Cell context={context}>{shippingNumber}</Table.Cell>;
      }

      return (
        <Table.Cell context={context}>
          <a
            onClick={() => amplitude.track('Click Shipping-info')}
            href={url}
            target="_blank"
            rel="noreferrer"
            className="text-medium-s text-blue-500 underline"
          >
            {shippingNumber}
          </a>
        </Table.Cell>
      );
    },
  },
  {
    header: () => '상품명',
    accessorFn: (order) => order.productName,
  },
  {
    header: () => '상품옵션',
    accessorFn: (order) => order.option,
  },
  {
    header: () => '상품관리코드',
    accessorFn: (order) => order.optionCode,
  },
  {
    header: () => '수량',
    accessorFn: (order) => order.quantity?.toString(),
  },
  {
    header: () => '주문자',
    accessorFn: (order) => order.buyerName,
  },
  {
    header: () => '주문자휴대폰번호',
    accessorFn: (order) => formatPhoneNumber(order.buyerContactNumber ?? ''),
  },
  {
    header: () => '수령인',
    accessorFn: (order) => order.name,
  },
  {
    header: () => '수령인휴대폰번호',
    accessorFn: (order) => formatPhoneNumber(order.contactNumber ?? ''),
  },
  {
    header: () => '수령인전화번호',
    accessorFn: (order) => formatPhoneNumber(order.telephoneNumber ?? ''),
  },
  {
    header: () => '주소',
    accessorFn: (order) => order.address,
  },
  {
    header: () => '우편번호',
    accessorFn: (order) => order.postCode,
  },
  {
    header: () => '배송요청사항',
    accessorFn: (order) => order.deliveryMessage,
  },
  {
    header: () => '주문번호',
    accessorFn: (order) => order.orderNumber,
  },
  {
    header: () => '상세주문번호',
    accessorFn: (order) => order.childOrderNumber,
  },
  {
    header: () => '정산예정금액',
    accessorFn: (order) => order.price?.toString(),
  },
  {
    header: () => '배송비',
    accessorFn: (order) => order.shippingPrice?.toString(),
  },
  {
    header: () => '상품코드(쇼핑몰)',
    accessorFn: (order) => order.productCode,
  },
  {
    header: () => '주문자id',
    accessorFn: (order) => order.buyerId,
  },
  {
    header: () => '스윕고유번호',
    accessorFn: (order) => order.uniqueCode,
  },
];

export default observer(OrderDispatchSearchScreen);
