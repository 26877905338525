import { setupWorker } from 'msw/browser';
import { LocalStorageBooleanValue } from '@sweep/utils/mobx';
import { handlers } from './handlers';

const worker = setupWorker(...handlers);
export const enableMockingStorage = new LocalStorageBooleanValue({
  key: 'enableMocking',
  defaultValue: false,
});

export async function startMockingOnlyDev() {
  if (enableMockingStorage.value) {
    await worker.start();
  }
}
