import axios from 'axios';
import { BACKEND_URL } from 'src/env';
import LoadingStore from '../stores/LoadingStore';
import axiosInstance from './axiosInstance';
import { isValid } from './utils';

class BackendApi {
  async loginUser(loginData) {
    try {
      const url = `${BACKEND_URL}/user/login`;

      const {
        data: { data },
      } = await axios.post(url, { loginData });

      return data;
    } catch (err) {
      console.warn(err);
      throw err;
    }
  }

  async initializeUserData() {
    try {
      const url = '/user/initialize';

      const {
        data: { data },
      } = await axiosInstance().get(url);

      return data;
    } catch (err) {
      console.error('Error initializing user data:', err);
      throw err;
    }
  }

  async fetchServiceVersion() {
    try {
      const url = '/version';

      const {
        data: { version },
      } = await axiosInstance().get(url);

      if (isValid(version)) {
        return version;
      } else {
        return null;
      }
    } catch (err) {
      console.error('Error fetching service version:', err);
      throw err;
    }
  }

  async getPriceInfo() {
    try {
      const url = '/product/price-list';
      const res = await axiosInstance().get(url);

      return res.data;
    } catch (err) {
      console.error('Error fetching price list:', err);
      throw err;
    }
  }

  async updatePriceInfo(priceList) {
    try {
      const url = '/product/price/update';
      const res = await axiosInstance().put(url, {
        priceList,
      });

      return res.data;
    } catch (err) {
      console.error('Error updating price list:', err);
      throw err;
    }
  }

  async registerSupplier(info) {
    try {
      const url = '/supplier/register';
      const res = await axiosInstance().post(url, {
        info,
      });

      return res.data;
    } catch (err) {
      console.error('Error registering supplier:', err);
      throw err;
    }
  }

  async updateSupplier(id, info) {
    try {
      const url = '/supplier/update';
      const res = await axiosInstance().put(url, {
        id,
        info,
      });

      return res.data;
    } catch (err) {
      console.error('Error updating supplier info:', err);
      throw err;
    }
  }

  async deleteSupplier(id) {
    try {
      const url = '/supplier/delete';
      const res = await axiosInstance().put(url, {
        id,
      });

      return res.data;
    } catch (err) {
      console.error('Error deleting supplier info:', err);
      throw err;
    }
  }

  async deleteCompositionMatching(compositionId) {
    try {
      const url = '/composition-matching/delete';
      const res = await axiosInstance().put(url, {
        compositionId,
      });

      return res.data;
    } catch (err) {
      console.error('Error deleting composition:', err);
      throw err;
    }
  }

  async updateCompositionMatching(composition) {
    try {
      const url = '/composition-matching/update';
      const res = await axiosInstance().put(url, {
        composition,
      });

      return res.data;
    } catch (err) {
      console.error('Error updating composition:', err);
      throw err;
    }
  }

  async updateCustomExcelSettings(columnOrder, columnTranslation) {
    try {
      const url = '/user/custom-excel';
      const res = await axiosInstance().put(url, {
        columnOrder,
        columnTranslation,
      });

      return res.data;
    } catch (err) {
      console.error('Error updating custom excel format:', err);
      throw err;
    }
  }

  async getBillingResult(customerKey, authKey, planType) {
    try {
      const url = '/billing/register-billing-key';
      const res = await axiosInstance().get(url, {
        params: { customerKey, authKey, planType },
      });

      return res;
    } catch (err) {
      throw err;
    }
  }

  async modifyBillingInfo(planType) {
    try {
      const url = '/billing/modify-billing-info';
      const res = await axiosInstance().post(url, {
        planType,
      });

      return res;
    } catch (err) {
      console.error('Error fetching shopping mall orders:', err);
      return false;
    }
  }

  async readXlsx(file) {
    try {
      const url = '/fastapi/unprotect/xlsx';
      const formData = new FormData();
      formData.append('xlsx_file', file);
      formData.append('filename', file.name.normalize('NFC'));
      formData.append('isProtected', false);

      const response = await axiosInstance().post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log(`${file.name.normalize('NFC')} 파일 업로드 성공`);
      return response.data;
    } catch (err) {
      console.error('Error reading xlsx files:', err);
      throw err;
    }
  }

  async uploadAddressExcel(phoneNumber, email, file) {
    try {
      const url = '/fastapi/correct_excel';
      const formData = new FormData();
      formData.append('xlsx_file', file);
      formData.append('filename', file.name.normalize('NFC'));
      formData.append('phoneNumber', phoneNumber);
      formData.append('email', email);
      LoadingStore.setIsLoading(true);
      const response = await axiosInstance().post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'arraybuffer',
      });
      LoadingStore.setIsLoading(false);

      if (response.status === 200) {
        console.log(`${file.name.normalize('NFC')} 파일 업로드 성공`);
        return response.data;
      }
      throw new Error('파일 업로드에 실패했습니다.');
    } catch (err) {
      console.error('Error uploading address excel:', err);
      throw err;
    }
  }

  async uploadOrders(orders) {
    try {
      const url = '/fastapi/correct_orders';
      const response = await axiosInstance().post(url, orders);

      if (response.status === 200) {
        console.log(`${orders.length}개의 orders 업로드 성공`);
        return response.data;
      }
      throw new Error('orders 업로드에 실패했습니다.');
    } catch (err) {
      console.error('Error uploading orders files:', err);
      throw err;
    }
  }

  async customerOpinionRegister({ name, email, opinion }) {
    try {
      const url = '/customer-opinion/register';
      const res = await axiosInstance().post(url, {
        name,
        email,
        data: opinion,
      });
      return res.data;
    } catch (err) {
      console.error('Error registering customer opinion:', err);
      throw err;
    }
  }
}

export default new BackendApi();
