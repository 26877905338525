import { IconErrorLarge, IconSuccessLarge } from '@sweep/asset/icons';
import { cva } from '@sweep/tailwind';

interface DispatchResultTabButtonProps {
  type: 'fail' | 'success';
  isSelected: boolean;
  onClick: () => void;
  count: number;
}

function DispatchResultTabButton({
  type,
  isSelected,
  onClick,
  count,
}: DispatchResultTabButtonProps) {
  const Icon = type === 'fail' ? IconErrorLarge : IconSuccessLarge;
  const label = type === 'fail' ? '실패' : '성공';

  return (
    <button
      className="h-[42px] w-fit rounded-t-[8px] border-x border-t border-gray-300 pl-[8px] pr-[12px]"
      onClick={onClick}
    >
      <div className={tabContent({ type, isSelected })}>
        <Icon width={36} height={36} />
        <p className={tabText({ type, isSelected })}>
          {label}({count})
        </p>
      </div>
    </button>
  );
}

const tabContent = cva('flex h-full items-center border-b-2', {
  variants: {
    type: {
      fail: '',
      success: '',
    },
    isSelected: {
      true: '',
      false: 'border-transparent',
    },
  },
  compoundVariants: [
    {
      type: 'fail',
      isSelected: true,
      className: 'border-orange-500',
    },
    {
      type: 'success',
      isSelected: true,
      className: 'border-blue-500',
    },
  ],
});

const tabText = cva('text-semibold-xs', {
  variants: {
    type: {
      fail: '',
      success: '',
    },
    isSelected: {
      true: '',
      false: 'text-gray-500',
    },
  },
  compoundVariants: [
    {
      type: 'fail',
      isSelected: true,
      className: 'text-orange-500',
    },
    {
      type: 'success',
      isSelected: true,
      className: 'text-blue-500',
    },
  ],
});

export default DispatchResultTabButton;
