import { observer } from 'mobx-react-lite';
import { Fragment } from 'react/jsx-runtime';
import { IconEdit } from '@sweep/asset/icons';
import { CombinedShipping } from '@sweep/contract';
import { Badge, Divider, ExpandedCheckbox } from '@sweep/sds';
import { Separated } from '@sweep/utils/react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { OMSStore } from 'src/stores/OMSStore';

interface CombinedShippingTableRowProps {
  item: CombinedShipping;
  isSelected: boolean;
  onSelect: () => void;
  onEdit: () => void;
}

function CombinedShippingTableRow({
  item: combinedShipping,
  isSelected,
  onSelect,
  onEdit,
}: CombinedShippingTableRowProps) {
  const oms = useOMSStore();

  return (
    <div key={combinedShipping._id} className="flex">
      <div className="w-36px flex-center flex">
        <ExpandedCheckbox checked={isSelected} onCheckedChange={onSelect} />
      </div>
      <Divider type="vertical" />
      <div
        className="grid flex-1"
        style={{
          gridTemplateColumns:
            '[name-start] 1fr [name-end] 1px [option-start] 1fr [option-end] 1px [standard-start] 1fr [standard-end]',
        }}
      >
        <Separated
          with={
            <Divider
              type="horizontal"
              className="col-[name-start/option-end]"
            />
          }
        >
          {toBundleProducts(oms, combinedShipping.products).map(
            ({ productId, productName, options }) => (
              <Fragment key={productId}>
                <div className="px-16px min-h-44px col-[name-start/name-end] flex items-center">
                  <p className="text-medium-s text-gray-700">{productName}</p>
                </div>
                <Divider type="vertical" />
                <div className="px-8px min-h-44px gap-x-8px gap-y-6px col-[option-start/option-end] flex flex-wrap items-center py-[8.5px]">
                  {options?.map((option) => (
                    <Badge key={option} color="blue" variant="soft">
                      {option}
                    </Badge>
                  ))}
                </div>
              </Fragment>
            )
          )}
        </Separated>
        <Divider
          type="vertical"
          className="col-[option-end/standard-start]"
          style={{
            gridRow: `1 / span ${combinedShipping.products.length * 2 + 1}`,
          }}
        />
        <div
          className="px-8px min-y-44px col-[standard-start/standard-end] flex items-center justify-between"
          style={{
            gridRow: `1 / span ${combinedShipping.products.length * 2 + 1}`,
          }}
        >
          <Badge color="orange" variant="soft">
            {combinedShipping.type === 'count'
              ? `${combinedShipping.amount}개까지 합배송`
              : `${combinedShipping.amount}${combinedShipping.unit}까지 합배송`}
          </Badge>
          <button
            className="rounded-6px size-28px flex-center flex bg-gray-100"
            onClick={onEdit}
          >
            <IconEdit className="text-gray-500" />
          </button>
        </div>
      </div>
    </div>
  );
}

function toBundleProducts(
  oms: OMSStore,
  products: CombinedShipping['products']
): { productId: string; productName: string; options?: string[] }[] {
  const productsByProductId = products.reduce<
    Record<string, { options?: string[] }>
  >((acc, product) => {
    const { productId, option } = product;
    if (option != null) {
      acc[productId] = {
        options: [...(acc[productId]?.options ?? []), option],
      };
    } else {
      acc[productId] = {};
    }
    return acc;
  }, {});

  return Object.entries(productsByProductId)
    .map(([productId, { options }]) => ({
      productId,
      productName: oms.product.getProduct(productId)?.productName ?? '',
      options: options?.sort(),
    }))
    .sort((a, b) => a.productName.localeCompare(b.productName));
}

export default observer(CombinedShippingTableRow);
