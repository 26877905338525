import { cva } from '@sweep/tailwind';

interface OMSContainerProps {
  children: React.ReactNode;
  className?: string;
}

function OMSContainer({ children, className = '' }: OMSContainerProps) {
  return <div className={container({ className })}>{children}</div>;
}

const container = cva(
  'py-20px px-40px flex h-full flex-col overflow-x-hidden bg-white'
);

export default OMSContainer;
