import { NormalizedOrder, ORDER_SHIPPING_STATUS } from '@sweep/contract';
import { bulkUpdateShippingOrder } from 'src/network/order-shipping-process/bulkUpdateShippingOrder';
import { FailedShippingOrderWithStatusCode } from '../types';

export async function requestShippingOrderStatusUpdateStep(
  shippingOrders: NormalizedOrder[]
): Promise<{
  successOrders: NormalizedOrder[];
  failedOrders: FailedShippingOrderWithStatusCode<NormalizedOrder>[];
}> {
  if (shippingOrders.length === 0) {
    return {
      successOrders: [],
      failedOrders: [],
    };
  }

  const payload = shippingOrders.map((order) => ({
    ...order,
    shippingStatus: ORDER_SHIPPING_STATUS.shipped,
  }));

  const result = await bulkUpdateShippingOrder({ orders: payload });

  const successOrders = result
    .filter((r) => r.isSuccess)
    .map((result) => result.data);

  const failedOrders = result
    .filter((r) => !r.isSuccess)
    .map((result) => {
      return {
        shippingOrder: result.data,
        statusCode: result.statusCode,
      };
    });

  return {
    successOrders,
    failedOrders,
  };
}
