import { sum, uniq } from 'lodash';
import { Order, OrderProduct } from '@sweep/contract';
import { isNotNil, parseNumber } from '@sweep/utils';

export function mergeOrders(orders: Order[]): Order | null {
  if (orders.length === 0) {
    return null;
  }

  const [firstOrder] = orders;

  const uniqueCodes = uniq(orders.map((order) => order.uniqueCode));
  const deliveryMessages = uniq(
    orders.map((order) => order.deliveryMessage)
  ).filter(isNotNil);
  const products = orders.map((order) => order.data?.[0]).filter(isNotNil);

  const prices = orders.map((order) => parseNumber(order.price));
  const pricesWithoutNulls = prices.filter(isNotNil);
  const combinedPrice =
    pricesWithoutNulls.length === prices.length
      ? sum(pricesWithoutNulls)
      : firstOrder.price;

  return {
    ...firstOrder,
    uniqueCode: uniqueCodes.sort().join(','),
    deliveryMessage: deliveryMessages.join(','),
    price: combinedPrice,
    quantity: 1,
    data: mergeProducts(products),
  };
}

function mergeProducts(products: OrderProduct[]): OrderProduct[] {
  const productMap = new Map<string, OrderProduct>();

  products.forEach((product) => {
    const key = `${product.productId}-${product.unit ?? ''}`;
    const existing = productMap.get(key);
    if (existing) {
      existing.quantity += product.quantity;
    } else {
      productMap.set(key, product);
    }
  });

  return Array.from(productMap.values());
}
