import { createContext, useContext } from 'react';
import { noop } from '@sweep/utils';

export interface RadioContextValue {
  value?: string;
  onChange: (value: string) => void;
}

const radioContext = createContext<RadioContextValue>({
  value: undefined,
  onChange: noop,
});

export const RadioContextProvider = radioContext.Provider;

export const useRadioContext = () => useContext(radioContext);
