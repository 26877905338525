import copy from 'fast-copy';
import { NormalizedOrder, Order, OrderShippingStatus } from '@sweep/contract';
import { createUniqueCode } from '@sweep/domain/services/order';
import { formatDate, getUnusedFilename, isNotNil } from '@sweep/utils';
import useCombineOrder from 'src/hooks/fileHandling/interpretOrder/useCombineOrder';
import usePreprocessOrder from 'src/hooks/fileHandling/interpretOrder/usePreprocessOrder';
import { useApplyCustomSettings } from 'src/hooks/fileHandling/useApplyCustomSettings';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { OrderXX } from 'src/models/OrderXX';
import { openCMRequriedDialog } from 'src/screens/order-process/components/dialogs/openCMRequiredDialog';
import { openConfirmMoveToShippingDialog } from 'src/screens/order-process/components/dialogs/openConfirmMoveToShippingDialog';
import { filterCMOrders } from 'src/screens/order-process/services/filterCMOrders';
import { getRemainedOrdersByUniqueCodeAfterCustomization } from 'src/screens/order-process/services/getRemainedOrdersByUniqueCodeAfterCustomization';
import { StoreOriginValuePlugin } from 'src/stores/plugin/default/store-origin-value';
import { AddSupplierPlugin } from 'src/stores/plugin/features/add-supplier';
import { AddSupplierNamePlugin } from 'src/stores/plugin/features/add-supplier-name/AddSupplierNamePlugin';
import {
  CompositionMatchingPlugin,
  CompositionMatchingStage,
} from 'src/stores/plugin/features/composition-matching';
import { FormatProductNamePlugin } from 'src/stores/plugin/features/format-product-name';
import usePrevDivideOrders from './usePrevDivideOrders';

const useCreatePurchaseOrderForRetailer = () => {
  const oms = useOMSStore();

  const {
    addPlatformName,
    applyPrefixToOption,
    applyFieldValueToAnother,
    applyMergedCellValues,
    sortOrders,
  } = useApplyCustomSettings();
  const { preprocessOrders } = usePreprocessOrder();
  const { hightlightCombinedOrders } = useCombineOrder();

  const { preDivideOrders } = usePrevDivideOrders();

  const createRetailerPurchaseOrder = async (
    orders: NormalizedOrder[]
  ): Promise<NormalizedOrder[] | null> => {
    const isConfirmed = await openConfirmMoveToShippingDialog(orders.length);
    if (!isConfirmed) {
      return null;
    }

    const remainedOrders = getRemainedOrdersByUniqueCodeAfterCustomization(
      oms.order.normalizedOrders,
      orders.map((order) => order.uniqueCodeAfterCustomization)
    );

    const { unmatchedOrders, matchedOrders } = filterCMOrders(orders);
    if (unmatchedOrders.length > 0) {
      const isMatchingConfirmed = await openCMRequriedDialog();
      if (!isMatchingConfirmed) {
        return null;
      }
    }

    const unmatchedUniqueCodes = unmatchedOrders.map(
      (order) => order.uniqueCode
    );
    const unmatchedUniqueCodeSet = new Set(unmatchedUniqueCodes);
    const unamtchedMergedOrders = oms.order.mergedOrders.filter((order) =>
      unmatchedUniqueCodeSet.has(order.uniqueCode)
    );
    oms.loading.start();
    const unmatchedNormalizedOrders = await normalizeOrdersBeforePreDivide(
      unamtchedMergedOrders
    );
    oms.loading.end();

    if (unmatchedNormalizedOrders == null) {
      return null;
    }

    const filename = formatDate(new Date(), 'yy.MM.dd 발주서 (HHmm)');
    const usedFilenames = oms.order.normalizedOrders
      .map((order) => order.purchaseOrderFile)
      .filter(isNotNil);
    const unusedFilename = getUnusedFilename(filename, usedFilenames);

    const shippingOrders = [
      ...unmatchedNormalizedOrders,
      ...matchedOrders,
    ].map<NormalizedOrder>((order) => ({
      ...order,
      purchaseOrderFile: unusedFilename,
      process: 'shipping',
      shippingStatus: 'processing',
    }));

    const dividedOrders = await oms.loading.batch(() =>
      oms.order.divide(shippingOrders, remainedOrders, preDivideOrders)
    );
    await oms.order.file.downloadRetailerPurchaseOrder(
      dividedOrders,
      unusedFilename
    );

    return dividedOrders;
  };

  const normalizeOrdersBeforePreDivide = async (orders: Order[]) => {
    if (orders.length === 0) {
      return [];
    }

    const newOrders = (await processNormalizeOrders(orders as OrderXX[], {
      stage: 'pre-divide',
      initialProcess: 'shipping',
      initialShippingStatus: 'processing',
    })) as NormalizedOrder[];

    if (newOrders.length === 0) {
      return null;
    }

    return newOrders;
  };

  const processNormalizeOrders = async (
    orders: OrderXX[],
    options?: {
      stage?: CompositionMatchingStage;
      initialProcess?: 'shipping' | 'pending';
      initialShippingStatus?: OrderShippingStatus;
    }
  ) => {
    const stage = options?.stage ?? 'normalize';
    const initialProcess = options?.initialProcess ?? 'pending';
    const initialShippingStatus = options?.initialShippingStatus ?? 'pending';
    if (orders.length === 0) {
      return [];
    }

    let newOrders = copy(orders);

    // TODO(@이지원): 커스텀 로직이 플러그인화 되면, default plugin으로 추가
    const storeOriginValuePlugin = new StoreOriginValuePlugin(oms, undefined);
    newOrders = (await storeOriginValuePlugin.transform(
      newOrders as Order[]
    )) as OrderXX[];

    newOrders = await preprocessOrders(newOrders);

    newOrders = (await new CompositionMatchingPlugin(oms, { stage }).transform(
      newOrders as Order[]
    )) as OrderXX[];

    // TODO(@이지원): 커스텀 로직이 플러그인화 되면, default plugin으로 추가
    newOrders = (await new AddSupplierPlugin(oms, undefined).transform(
      newOrders as Order[]
    )) as OrderXX[];
    newOrders = (await new AddSupplierNamePlugin(oms, undefined).transform(
      newOrders as Order[]
    )) as OrderXX[];

    newOrders = hightlightCombinedOrders(newOrders);
    newOrders = addPlatformName(newOrders);
    newOrders = (await new FormatProductNamePlugin(oms, undefined).transform(
      newOrders as Order[]
    )) as OrderXX[];
    newOrders = applyMergedCellValues(newOrders);
    newOrders = applyFieldValueToAnother(newOrders);
    newOrders = applyPrefixToOption(newOrders);

    newOrders = sortOrders(newOrders);

    newOrders = (await oms.order._normalize.normalizeOrders(
      newOrders as unknown as Order[]
    )) as unknown as OrderXX[];

    if (
      oms.user.setting?.interpreteOrderSettings?.useOptionCodeAsRawProductName
    ) {
      newOrders = newOrders.map((order) => {
        if (!order?.needToInterpret) {
          order.option = order.optionCode || order.option;
        }
        return order;
      });
    }

    newOrders = (newOrders as Order[]).map<NormalizedOrder>((order) => ({
      ...order,
      uniqueCodeAfterCustomization:
        order.uniqueCodeAfterCustomization ?? createUniqueCode(),
      process: initialProcess,
      shippingStatus: initialShippingStatus,
    })) as OrderXX[];

    return newOrders;
  };

  return {
    createRetailerPurchaseOrder,
  };
};

export default useCreatePurchaseOrderForRetailer;
