import { observer } from 'mobx-react-lite';
import { IconInfo } from '@sweep/asset/icons';
import { RadioGroup, Tooltip } from '@sweep/sds';
import { cva } from '@sweep/tailwind';
import { CombinedShippingFormStore } from '../../CombinedShippingFormStore';

interface TypeInputProps {
  store: CombinedShippingFormStore;
  className?: string;
}

function TypeInput({ store, className }: TypeInputProps) {
  return (
    <RadioGroup value={store.type} onChange={store.setType}>
      <div className={container({ className })}>
        <div className="gap-6px flex items-center">
          <label htmlFor="count" className="gap-12px flex items-center">
            <RadioGroup.Item value="count" id="count" />
            <p className="text-semibold-s text-gray-600">개수 기준</p>
          </label>
          <Tooltip type="info" placement="bottom-start">
            <Tooltip.Trigger>
              <IconInfo className="text-gray-300" />
            </Tooltip.Trigger>
            <Tooltip.Content>
              합배송할 수 있는 최대 개수를 설정합니다.
              <br />예{')'} 30구 구성은 4개까지 합배송 가능
            </Tooltip.Content>
          </Tooltip>
        </div>
        <div className="gap-6px flex items-center">
          <label htmlFor="unit" className="gap-12px flex items-center">
            <RadioGroup.Item value="unit" id="unit" />
            <p className="text-semibold-s text-gray-600">단위 기준</p>
          </label>
          <Tooltip type="info" placement="bottom-start">
            <Tooltip.Trigger>
              <IconInfo className="text-gray-300" />
            </Tooltip.Trigger>
            <Tooltip.Content>
              총합 기준의 최대 단위를 설정합니다.
              <br />예{')'} 총 무게 기준 10kg까지 합배송 가능
            </Tooltip.Content>
          </Tooltip>
        </div>
      </div>
    </RadioGroup>
  );
}

const container = cva('gap-36px flex justify-center');

export default observer(TypeInput);
