import { MappedEntry, MappingGuide } from './interface';

/** @deprecated use @sweep/domain/services/header-mapping */
export function getMappedEntries(
  row: string[],
  guides: MappingGuide[]
): MappedEntry[] {
  const entries: MappedEntry[] = [];

  for (const guide of guides) {
    for (const key in guide) {
      const values = guide[key];
      const value = row.find((value) => values.includes(value));
      const isAlreadyMappedValue = entries.some((info) => info.value === value);
      const isAlreadyMappedKey = entries.some((info) => info.key === key);

      if (!isAlreadyMappedValue && !isAlreadyMappedKey && value) {
        entries.push({ item: value, key, value });
      }
    }
  }

  return entries;
}
