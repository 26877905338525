import {
  UPDATE_SHIPPING_ORDER_URL,
  UpdateShippingOrderPayload,
  UpdateShippingOrderResponse,
} from '@sweep/contract';
import api from '../api';

export const updateShippingOrder = async (
  payload: UpdateShippingOrderPayload
): Promise<boolean> => {
  const { uniqueCodeAfterCustomization } = payload;
  const url = UPDATE_SHIPPING_ORDER_URL.replace(
    ':uniqueCodeAfterCustomization',
    uniqueCodeAfterCustomization
  );

  const response = await api.post<UpdateShippingOrderResponse>(url, payload);
  return response?.data.success === true;
};
