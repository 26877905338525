import { useState } from 'react';
import { IconArrowModal } from '@sweep/asset/icons';
import HeaderSelect from './HeaderSelect';

const REQUIRED_HEADER_KEYS = [
  'shippingNumber',
  'shippingCompany',
  'uniqueCode',
] as const;

type RequiredHeaderKey = (typeof REQUIRED_HEADER_KEYS)[number];

const REQUIRED_HEADER_NAMES: Record<RequiredHeaderKey, string> = {
  shippingNumber: '운송장번호',
  shippingCompany: '배송업체',
  uniqueCode: '스윕고유번호',
};

function SpreadsheetHeaderMappingsInput(props: {
  index: number;
  headers: string[];
  onUpdate: (index: number, columnMapping: Record<string, string>) => void;
  exampleContents: string[][];
}) {
  const { index, headers, onUpdate, exampleContents } = props;

  const [columnMappings, setColumnMappings] = useState<Record<string, string>>(
    {}
  );

  const onSelectionChange = (
    headerKey: RequiredHeaderKey,
    headerValue: string
  ) => {
    const prevMatchedValue = Object.keys(columnMappings).find(
      (key) => columnMappings[key] === headerValue
    );

    const prevMatchedValueRemoved =
      prevMatchedValue != null
        ? {
            ...columnMappings,
            [prevMatchedValue]: '',
          }
        : columnMappings;

    const newHeaderValueAdded = {
      ...prevMatchedValueRemoved,
      [headerKey]: headerValue,
    };

    setColumnMappings(newHeaderValueAdded);
    onUpdate(index, newHeaderValueAdded);
  };

  return (
    <div className="mb-32px flex w-full flex-row">
      <div className="mr-20px flex w-[100px] shrink-0 flex-col items-center self-start">
        <div className="h-40px px-16px text-medium-xs flex w-full items-center justify-center text-wrap border-y border-blue-50 bg-blue-50 text-center">
          운송장 파일 엑셀 양식
        </div>
        <div className="h-32px flex w-full items-center justify-center">
          <IconArrowModal className="rotate-90 text-gray-400" />
        </div>
        <div className="h-40px px-16px text-medium-xs flex w-full items-center justify-center text-wrap border-y border-purple-50 bg-purple-50">
          통합 엑셀양식
        </div>
      </div>
      <div className="gap-24px flex overflow-x-scroll whitespace-nowrap">
        {REQUIRED_HEADER_KEYS.map((headerKey) => {
          const headerName = REQUIRED_HEADER_NAMES[headerKey];
          return (
            <div key={headerKey} className="flex flex-col">
              <div
                className={`mb-32px flex min-w-[200px] items-center justify-between`}
              >
                <div className="flex w-full flex-col">
                  <HeaderSelect
                    header={headers}
                    value={columnMappings[headerKey] ?? ''}
                    onChange={(item) => onSelectionChange(headerKey, item)}
                  />
                </div>
              </div>
              <div className="mb-8px h-40px px-8px flex items-center bg-gray-100">
                {headerName}
              </div>
              <div>
                {exampleContents?.map((row, rowIndex) => {
                  const headerIndex = headers.findIndex(
                    (item) => item === columnMappings[headerKey]
                  );

                  if (headerIndex === -1) {
                    return null;
                  }

                  return (
                    <div
                      key={rowIndex}
                      className="mb-8px px-12px text-medium-s relative truncate whitespace-nowrap text-left text-gray-500"
                    >
                      <span>• {row.at(headerIndex)}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SpreadsheetHeaderMappingsInput;
