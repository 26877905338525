import { flow, groupBy } from 'es-toolkit';
import { filter } from 'es-toolkit/compat';
import { Order } from '@sweep/contract';
import { Partner } from 'src/models/Partner';
import ErrorReporter from 'src/third-parties/ErrorReporter';

/* 용어 정의
 *
 * ASN: Advanced Shipping Notice
 * ASN은 발송 측(공급자)이 수신 측(쇼핑몰, 유통업체 등)에게 상품의 배송 시작 또는 예정 사실과 함께
 * 운송장 번호, 배송일자, 수량, 포장 단위 등의 상세 정보를 사전에 알려주는 문서를 의미합니다.
 *
 */

export const ASN_DOCUMENT_TYPE_CUSTOM = 'custom';
export const ASN_DOCUMENT_TYPE_SHOPPING_MALL = 'shoppingMall';
export const ASN_DOCUMENT_TYPE_DEFAULT = ASN_DOCUMENT_TYPE_CUSTOM;

export type ASNDocumentType =
  | typeof ASN_DOCUMENT_TYPE_CUSTOM
  | typeof ASN_DOCUMENT_TYPE_SHOPPING_MALL;

export type AdvancedShippingNoticeDocument = {
  documentKey: string;
  documentName: string;
  documentType: ASNDocumentType;
  requireFileDownload: boolean;
  requireServerAutoDispatch: boolean;
  orders: Order[];
};

export type FailedOrderInfo = {
  uniqueCode: string;
  errorCode: string;
};

export function createAdvancedShippingNoticeDocuments(
  targetOrders: Order[],
  partnerRecords: Record<string, Partner>
): [
  AdvancedShippingNoticeDocument[],
  AdvancedShippingNoticeDocument[],
  FailedOrderInfo[],
] {
  const selectPartnerName = (partnerId: string): string => {
    const partner = partnerRecords?.[partnerId];
    return partner?.name ?? partnerId;
  };

  const ASNDocumentForCustomOrders = _createAdvancedShippingNoticeDocuments(
    targetOrders,
    {
      selectGroupedOrders: groupByPartnerIdOrders,
      selectGroupName: selectPartnerName,
      documentType: ASN_DOCUMENT_TYPE_CUSTOM,
      requireFileDownload: true,
      requireServerAutoDispatch: false,
    }
  );

  const ASNDocumentForShoppingMallOrders =
    _createAdvancedShippingNoticeDocuments(targetOrders, {
      selectGroupedOrders: groupByShoppingMallNameOrders,
      selectGroupName: (key) => key,
      documentType: ASN_DOCUMENT_TYPE_SHOPPING_MALL,
      requireFileDownload: true,
      requireServerAutoDispatch: true,
    });

  const invalidOrders = selectInvalidOrders(targetOrders);
  const invalidOrdersWithErrorCode: FailedOrderInfo[] = invalidOrders.map(
    (order) => ({
      uniqueCode: order.uniqueCode,
      errorCode: 'INVALID_PARTNER_OR_SHOPPING_MALL',
    })
  );
  ErrorReporter.captureMessage(
    'splitOrders: 파트너사, 쇼핑몰 정보가 없는 경우',
    {
      extra: {
        count: invalidOrders.length,
        invalidOrders,
      },
    }
  );

  return [
    ASNDocumentForCustomOrders,
    ASNDocumentForShoppingMallOrders,
    invalidOrdersWithErrorCode,
  ];
}

const groupByPartnerIdOrders: (orders: Order[]) => Record<string, Order[]> =
  flow(
    (orders: Order[]) => orders,
    (orders: Order[]) => filter(orders, (order) => order.partnerId != null),
    (orders: Order[]) => groupBy(orders, (order) => String(order.partnerId))
  );

const groupByShoppingMallNameOrders: (
  orders: Order[]
) => Record<string, Order[]> = flow(
  (orders: Order[]) => orders,
  (orders: Order[]) => filter(orders, (order) => order.shoppingMall != null),
  (orders: Order[]) => groupBy(orders, (order) => String(order.shoppingMall))
);

const selectInvalidOrders: (orders: Order[]) => Order[] = flow(
  (orders: Order[]) => orders,
  (orders: Order[]) =>
    filter(
      orders,
      (order) => order.partnerId == null && order.shoppingMall == null
    )
);

function _createAdvancedShippingNoticeDocuments(
  orders: Order[],
  options?: {
    selectGroupedOrders: (orders: Order[]) => Record<string, Order[]>;
    selectGroupName?: (partnerId: string) => string;
    documentType: ASNDocumentType;
    requireFileDownload: boolean;
    requireServerAutoDispatch: boolean;
  }
): AdvancedShippingNoticeDocument[] {
  const selectedOrders = options?.selectGroupedOrders(orders);
  if (selectedOrders == null) {
    return [];
  }

  const selectGroupName = options?.selectGroupName ?? ((key) => key);
  const documentType = options?.documentType ?? ASN_DOCUMENT_TYPE_DEFAULT;
  const requireFileDownload = options?.requireFileDownload ?? true;
  const requireServerAutoDispatch = options?.requireServerAutoDispatch ?? false;

  const selectedOrderGroups: AdvancedShippingNoticeDocument[] = Object.entries(
    selectedOrders
  ).map(([key, orders]) => {
    const name = selectGroupName(key);

    return {
      documentKey: key,
      documentName: name,
      documentType,
      requireFileDownload,
      requireServerAutoDispatch,
      orders,
    };
  });

  return selectedOrderGroups;
}
