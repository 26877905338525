import { observer } from 'mobx-react-lite';
import { cva } from '@sweep/tailwind';
import { isNotEmptyString } from '@sweep/utils';
import { If } from '@sweep/utils/react';
import { MatchingOrder } from '../../interface';

interface MatchingOrderLabelProps {
  matchingOrder: MatchingOrder;
  filled?: boolean;
}

function MatchingOrderLabel({
  matchingOrder,
  filled = false,
}: MatchingOrderLabelProps) {
  const isNotEmptyProductName = isNotEmptyString(matchingOrder.productName);
  const isNotEmptyOption = isNotEmptyString(matchingOrder.option);
  const isNotEmptyOptionCode = isNotEmptyString(matchingOrder.optionCode);

  return (
    <div className="gap-8px w-320px px-20px flex shrink-0 flex-col">
      {isNotEmptyProductName && (
        <p className="text-medium-m break-keep text-gray-700">
          {matchingOrder.productName}
        </p>
      )}
      <If is={isNotEmptyOption || isNotEmptyOptionCode}>
        <div className="gap-8px flex flex-wrap items-center">
          {isNotEmptyOption && (
            <p className="text-medium-s break-keep text-gray-500">
              {matchingOrder.option}
            </p>
          )}
          {isNotEmptyOptionCode && (
            <div className={badge({ filled })}>{matchingOrder.optionCode}</div>
          )}
        </div>
      </If>
    </div>
  );
}

const badge = cva(
  'rounded-4px flex-center px-8px py-4px text-medium-s flex text-gray-500',
  {
    variants: {
      filled: {
        true: 'bg-white',
        false: 'bg-gray-100',
      },
    },
  }
);

export default observer(MatchingOrderLabel);
