export function parseAmountUnit(value: string): {
  amount: number;
  unit: string;
} {
  const numberMatch = value.match(/\d+(\.\d+)?/);
  const numberStr = numberMatch?.at(0);

  if (numberMatch?.index == null || numberStr == null) {
    return {
      amount: 0,
      unit: value,
    };
  }

  const unitStr = value.slice(numberMatch.index + numberStr.length);

  if (numberMatch.index !== 0 || numberStr.length === 0) {
    return {
      amount: 0,
      unit: value,
    };
  }

  const numberValue = parseFloat(numberStr);
  if (isNaN(numberValue)) {
    return {
      amount: 0,
      unit: value,
    };
  }

  return {
    amount: numberValue,
    unit: unitStr,
  };
}
