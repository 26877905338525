import { CreateProductDTO } from '@sweep/contract';
import { getUniform, isEmptyArray, isEmptyString } from '@sweep/utils';
import { SalesChannelsSupplierMatching } from '../interface';
import { getSalesChannelSupplierIds } from './getSalesChannelSupplierIds';

export function getNormalizedProduct<
  T extends CreateProductDTO = CreateProductDTO,
>(product: T, matchings: SalesChannelsSupplierMatching[]): T {
  const givenProduct = {
    ...product,
    salesChannelSupplierIds: getSalesChannelSupplierIds(matchings),
    units: product.units?.filter((unit) => unit.unit !== ''),
  };

  if (givenProduct.useSupplierByOption === true) {
    const optionSupplierIds = givenProduct.units?.map(
      (option) => option.supplierId
    );
    const isEveryOptionSupplierIdsNull =
      optionSupplierIds?.every((supplierId) => supplierId == null) ?? false;
    const uniformSupplierId = getUniform(optionSupplierIds ?? []);

    if (
      optionSupplierIds == null ||
      isEveryOptionSupplierIdsNull ||
      uniformSupplierId != null
    ) {
      return {
        ...givenProduct,
        supplierId: uniformSupplierId,
        useSupplierByOption: false,
      };
    }
  }

  if (givenProduct.useSupplierBySalesChannel === true) {
    const salesChannelSupplierIds = givenProduct.salesChannelSupplierIds;
    if (isEmptyArray(salesChannelSupplierIds)) {
      return {
        ...givenProduct,
        useSupplierBySalesChannel: false,
      };
    }

    const supplierId = givenProduct.supplierId;
    const supplierIds = salesChannelSupplierIds.map(
      (salesChannelSupplierId) => salesChannelSupplierId.supplierId
    );
    const isEverySupplierIdsNull = supplierIds.every(
      (supplierId) => supplierId == null
    );
    if (isEverySupplierIdsNull && isEmptyString(supplierId)) {
      return {
        ...givenProduct,
        salesChannelSupplierIds: [],
        useSupplierBySalesChannel: false,
      };
    }

    const uniformSupplierId = getUniform(supplierIds ?? []);
    if (
      uniformSupplierId != null &&
      uniformSupplierId === givenProduct.supplierId
    ) {
      return {
        ...givenProduct,
        supplierId: uniformSupplierId,
        salesChannelSupplierIds: [],
        useSupplierBySalesChannel: false,
      };
    }
  }

  return givenProduct;
}
