import { useEffect, useRef, useState } from 'react';

interface UseViewOptions {
  threshold?: number;
  delay?: number;
}

export function useView<T>(callback: () => void, options: UseViewOptions = {}) {
  const ref = useRef<T>(null);
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsIntersecting(true);
          return;
        }
        setIsIntersecting(false);
      },
      { threshold: options.threshold ?? 0.8 }
    );

    if (ref.current != null) {
      observer.observe(ref.current as unknown as Element);
    }

    return () => observer.disconnect();
  }, [options.threshold]);

  useEffect(() => {
    if (!isIntersecting) {
      return;
    }
    const timer = setTimeout(() => {
      if (isIntersecting) {
        callback();
      }
    }, options.delay ?? 1000);

    return () => clearTimeout(timer);
  }, [isIntersecting]);

  return ref;
}
