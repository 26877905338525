import { useMemo } from 'react';
import { Order } from '@sweep/contract';
import { uniq } from '@sweep/utils';
import { filterOrders } from '../services/filterOrders';
import { TableFilterStore } from '../TableFilterStore';

export function useFilterField(options: {
  store: TableFilterStore;
  orders: Order[];
  headerKey: string;
  search: string;
}) {
  const { store, orders, headerKey, search } = options;

  const fieldOptions = useMemo(() => {
    const { [headerKey]: _, ...otherRemovedFields } = store.removedFields;
    const filteredOrders = filterOrders(orders, otherRemovedFields);
    const fieldOptions = filteredOrders.map(
      (order) => order[headerKey]?.toString() ?? ''
    );
    return uniq(fieldOptions).sort((a, b) => a.localeCompare(b));
  }, [headerKey, store.removedFields, orders]);

  const filteredFieldOptions = fieldOptions.filter((value) =>
    value.toLowerCase().includes(search.toLowerCase())
  );

  const removedFieldValues = store.removedFields[headerKey];

  const isNotChecked = (value: string) =>
    removedFieldValues?.includes(value) === true;

  const handleField = (field: string) => {
    if (isNotChecked(field)) {
      store.setRemovedFields(
        headerKey,
        removedFieldValues?.filter((v) => v !== field) ?? []
      );
      return;
    }

    store.setRemovedFields(headerKey, [...(removedFieldValues ?? []), field]);
  };

  const isNotAllChecked = filteredFieldOptions.some(isNotChecked);

  const handleAllField = () => {
    if (isNotAllChecked) {
      store.setRemovedFields(
        headerKey,
        removedFieldValues?.filter((v) => !filteredFieldOptions.includes(v)) ??
          []
      );
      return;
    }

    store.setRemovedFields(headerKey, filteredFieldOptions);
  };

  return {
    fields: filteredFieldOptions,
    isNotChecked,
    onFieldClick: handleField,
    isNotAllChecked,
    onAllFieldClick: handleAllField,
  };
}
