import copy from 'fast-copy';
import { Order } from '@sweep/contract';
import { OrderXX } from 'src/models/OrderXX';
import { isValid } from 'src/utils/utils';
import { AbstractPlugin } from '../../interface';

interface FormatSettings {
  deleteUnits?: boolean;
  setDataQuantity1?: boolean;
  setOrderQuantity1?: boolean;
  setQuantityWithOrig?: boolean;
  replaceAddToComma?: boolean;
  dontUseMultiplier?: boolean;
  applyQuantityToUnit?: boolean;
  useProductName?: boolean;
  showQuantityAlways?: boolean;
  customFormat?: string;
  customSeparator?: string;
  customQuantityFormat?: string;
  combineProductNameWithOptions?: boolean;
  suppliers?: SupplierSettings;
}

interface SupplierSettings {
  [key: string]: FormatSettings;
}

export class FormatProductNamePluginLegacy extends AbstractPlugin {
  transform = (orders: Order[]): Promise<Order[]> => {
    let newOrders = orders as OrderXX[];
    const formatOrdersSettings = this.oms.user.setting?.formatOrdersSettings;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (formatOrdersSettings?.enabled) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      newOrders = this.formatOrders(newOrders, formatOrdersSettings);
    } else {
      newOrders = this.formatOrders(newOrders);
    }

    // 특정 상품군의 ${특정 단위x수량} 구성 표현을 바꾸기
    newOrders = this.convertSpecificCompositionsExpression(newOrders);

    return Promise.resolve(newOrders as Order[]);
  };

  formatOrders = (orders: OrderXX[], settings: FormatSettings = {}) => {
    const newOrders = [...orders];

    const {
      deleteUnits = false,
      setDataQuantity1 = false, // data의 수량을 1로 설정 (수량을 order.quantity에 넣음) - 1개 상품만 있는 경우
      setOrderQuantity1 = false, // order의 수량을 1로 설정 (수량을 data.quantity에 넣음)
      setQuantityWithOrig = false, // 원본 수량을 quantity에 넣음
      replaceAddToComma = false, // a1k, b2k x2, ...
      dontUseMultiplier = false, // a*2+b -> a+a+b
      applyQuantityToUnit = false, // 반숙란 30구 *2 -> 반숙란 60구
      useProductName = false, //productName 사용
      showQuantityAlways = false,
      customFormat = '',
      customSeparator = ' + ',
      customQuantityFormat = 'x{quantity}',
      combineProductNameWithOptions = true,
      suppliers = [],
    } = settings;

    newOrders.forEach((order) => {
      // 각 공급사별 설정 병합
      const supplierSettings =
        (settings.suppliers as any)?.find(
          (setting: any) => setting.supplierId === order.supplierId
        ) || {};

      const finalSettings = { ...settings, ...supplierSettings }; // 기본 설정에 공급사 설정을 병합

      const {
        deleteUnits = false,
        setDataQuantity1 = false,
        setOrderQuantity1 = false,
        setQuantityWithOrig = false,
        replaceAddToComma = false,
        dontUseMultiplier = false,
        applyQuantityToUnit = false,
        useProductName = false,
        showQuantityAlways = false,
        customFormat = '',
        customSeparator = ' + ',
        customQuantityFormat = 'x{quantity}',
        combineProductNameWithOptions = true,
      } = finalSettings;

      if (setDataQuantity1 || setOrderQuantity1 || setQuantityWithOrig) {
        order.data.forEach((data) => {
          data.quantity *= order.quantity;
        });
        order.quantity = 1;
        if (setDataQuantity1) {
          if (order.data.length !== 1) {
            console.warn("quntity in quantity but data's length is not 1");
          } else {
            order.quantity = order.data[0].quantity;
            order.data[0].quantity = 1;
          }
        } else if (setQuantityWithOrig) {
          const diff = order.origQuantity / order.quantity;
          order.data.forEach((data) => {
            data.quantity /= diff;
          });
          order.quantity = order.origQuantity;
        } else if (setOrderQuantity1) {
          // pass
        }
      }

      if (useProductName || combineProductNameWithOptions === false) {
        order.productName = order.data?.[0]?.productName || '';
      }

      order.option = this.getOptionFromData(order.data, finalSettings);
    });

    return newOrders;
  };

  getOptionFromData = (
    data: OrderXX['data'],
    settings: {
      deleteUnits?: boolean;
      replaceAddToComma?: boolean;
      dontUseMultiplier?: boolean;
      showQuantityAlways?: boolean;
      customFormat?: string;
      customSeparator?: string;
      customQuantityFormat?: string;
      applyQuantityToUnit?: boolean;
      useProductName?: boolean;
      combineProductNameWithOptions?: boolean;
    } = {}
  ) => {
    const {
      deleteUnits = false,
      replaceAddToComma = false, // a1k, b2k x2, ...
      dontUseMultiplier = false, // a*2+b -> a+a+b
      showQuantityAlways = false, // 수량이 1개인 경우에도 수량을 표시
      customFormat = '', // '{productName} {unit} x{quantity}'
      customSeparator = ' + ',
      customQuantityFormat = 'x{quantity}',
      applyQuantityToUnit = false,
      combineProductNameWithOptions = true,
    } = settings;

    let option = '';
    let newData = data ?? [];
    if (dontUseMultiplier) {
      newData = [];
      data.forEach((product) => {
        const newProduct = copy(product);
        newProduct.quantity = 1;
        for (let i = 0; i < product.quantity; i++) {
          newData.push(newProduct);
        }
      });
    }

    if (customFormat !== '') {
      newData.forEach((product) => {
        let customOption = customFormat;
        if (showQuantityAlways || product.quantity > 1) {
          customOption = customOption.replace(
            '{customQuantityFormat}',
            customQuantityFormat
          );
        } else {
          customOption = customOption.replace('{customQuantityFormat}', '');
        }

        if (applyQuantityToUnit) {
          customOption = customOption
            .replace(
              '{unit}',
              this.multiplyNumbersInString(product.unit, product.quantity)
            )
            .replace('{quantity}', '');
        }
        customOption =
          customOption
            .replace('{productName}', product.productName || '')
            .replace('{unit}', product.unit)
            .replace('{quantity}', product.quantity.toString()) +
          customSeparator;
        option += customOption;
      });

      option = option.slice(0, -customSeparator.length);
    } else {
      newData.forEach((product) => {
        if (combineProductNameWithOptions) {
          option += product.productName;
        }

        if (!replaceAddToComma) {
          option += ' ';
        }
        if (!deleteUnits) {
          option += `${product.unit || ''} `;
        }
        if (showQuantityAlways || product.quantity > 1) {
          option += customQuantityFormat.replace(
            '{quantity}',
            product.quantity.toString()
          );
        }

        if (replaceAddToComma) {
          option += ', ';
        } else {
          option += ' + ';
        }
      });

      if (replaceAddToComma) {
        option = option.replace(/,\s*$/, '');
      } else {
        option = option.replace(/\+\s*$/, '');
      }
    }
    option = option.trim();

    return option;
  };

  multiplyNumbersInString = (str: string, multiplier: number) => {
    // 정규 표현식을 사용하여 문자열 내의 모든 숫자를 찾고, 이를 2배로 곱함
    return str.replace(/\d+/g, (match: string) => {
      const number = parseInt(match, 10);
      return (number * multiplier).toString();
    });
  };

  convertSpecificCompositionsExpression = (orders: OrderXX[]) => {
    if (
      !this.oms.user.setting?.convertSpecificCompositionExpressionSetting
        ?.enabled
    ) {
      return orders;
    }

    return orders.map((order) => {
      if (!isValid(order?.data)) {
        return order;
      }

      const settings =
        this.oms.user.setting?.convertSpecificCompositionExpressionSetting
          ?.settings;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      for (const setting of settings) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (setting.type === 'productName') {
          if (
            order?.data.some(
              (data) =>
                data.productName &&
                setting.object &&
                data.productName.includes(setting.object.productName)
            ) &&
            ((order.origOption &&
              order.origOption.includes(setting.replacement)) ||
              (order.origName && order.origName.includes(setting.replacement)))
          ) {
            const convertedOption = order.option.replace(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              setting.object.productName,
              setting.replacement
            );

            return { ...order, option: convertedOption };
          } else if (
            setting.partnerId === order.partnerId &&
            order?.data.some((data) => data.productId === setting.productId)
          ) {
            const productName = this.oms.product.products.find(
              (product) => product._id === setting.productId
            )?.productName;

            const convertedOption = order.option.replace(
              productName ?? '',
              setting.replacement
            );

            return { ...order, option: convertedOption };
          }
        }
      }

      return order;
    });
  };
}
