import {
  CREATE_PRODUCT_URL,
  CreateProductBody,
  CreateProductDTO,
  CreateProductResponse,
  DELETE_PRODUCT_URL,
  DeleteProductResponse,
  GetProductsResponse,
  UPDATE_PRODUCT_MANY_URL,
  UPDATE_PRODUCT_URL,
  UpdateProductBody,
  UpdateProductDTO,
  UpdateProductManyDTO,
  UpdateProductManyResponse,
  UpdateProductResponse,
} from '@sweep/contract';
import { assert, tryCatch } from '@sweep/utils';
import api, { APIResponse } from './api';

export async function getProducts() {
  const response = await tryCatch(() =>
    api.get<APIResponse<GetProductsResponse>>('/product')
  );

  return response?.data;
}

export async function registerProduct(product: CreateProductDTO) {
  const body: CreateProductBody = { productInfo: product };
  const response = await api.post<APIResponse<CreateProductResponse>>(
    CREATE_PRODUCT_URL,
    body
  );
  assert(response != null, 'Failed to register product');

  return response.data;
}

export async function updateProduct(
  productId: string,
  product: UpdateProductDTO
) {
  const body: UpdateProductBody = {
    productId,
    productInfo: product,
  };
  const response = await tryCatch(() =>
    api.put<APIResponse<UpdateProductResponse>>(UPDATE_PRODUCT_URL, body)
  );
  assert(response != null, 'Failed to update product');

  return response?.data;
}

export async function updateProductMany(products: UpdateProductManyDTO[]) {
  const response = await tryCatch(() =>
    api.put<APIResponse<UpdateProductManyResponse>>(
      UPDATE_PRODUCT_MANY_URL,
      products
    )
  );
  assert(response != null, 'Failed to update product');
  return response?.data;
}

export async function deleteProduct(productId: string) {
  const response = await tryCatch(async () =>
    api.delete<APIResponse<DeleteProductResponse>>(
      DELETE_PRODUCT_URL(productId)
    )
  );

  return response?.data;
}
