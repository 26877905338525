import { Supplier } from '@sweep/contract';
import { OrderXX } from '../../models/OrderXX';
import { isValid } from '../../utils/utils';
import { useOMSStore } from '../useOMSStore';

export function useApplyCustomSettings() {
  const oms = useOMSStore();

  const addPlatformName = (orders: OrderXX[]) => {
    // 개인 설정이 활성화되어 있을 경우 로직을 적용합니다.
    if (!oms.user.setting?.platformNameSettings?.enabled) {
      return orders;
    }

    // matchingSet 배열을 반복하여 fileName과 일치하는 platformName을 찾습니다.
    const platformNameSettings = oms.user.setting?.platformNameSettings;
    const matchingSet = platformNameSettings.matchingSet || [];

    const newOrders = [...orders];
    for (const order of newOrders) {
      const cleanFileName = order.originFile
        .replace(/\..*$/, '')
        .normalize('NFC');

      if (isValid(matchingSet)) {
        for (const item of matchingSet) {
          if (item.matchingType === 'partnerId') {
            if (isValid(order?.partnerId)) {
              if (item.partnerId === order.partnerId) {
                if (isValid(item.platformName)) {
                  order.platformName = item.platformName;
                  break;
                } else {
                  const partnerName = oms.partner.partners.find(
                    (partner) => partner._id === order.partnerId
                  )?.name;

                  if (isValid(partnerName)) {
                    order.platformName = item.partnerName;
                    break;
                  }
                }
              }
            }
          } else if (cleanFileName.includes(item.fileKeyword)) {
            order.platformName = item.platformName;
            break;
          } else if (isValid(order?.partnerId)) {
            const partnerName = oms.partner.partners.find(
              (partner) => partner._id && partner._id === order.partnerId
            )?.name;
            if (isValid(partnerName)) {
              order.platformName = partnerName;
              break;
            }
          } else {
            if (
              isValid(platformNameSettings?.defaultForm) &&
              isValid(order?.shoppingMallName)
            ) {
              order.platformName = platformNameSettings?.defaultForm.replace(
                '{platformName}',
                order?.shoppingMallName
              );
            }
          }
        }
      } else if (isValid(order?.partnerId)) {
        const partnerName = oms.partner.partners.find(
          (partner) => partner._id && partner._id === order.partnerId
        )?.name;
        if (isValid(partnerName)) {
          order.platformName = partnerName;
        }
      } else if (isValid(order?.shoppingMall)) {
        order.platformName = isValid(platformNameSettings.defaultForm)
          ? platformNameSettings?.defaultForm.replace(
              '{platformName}',
              order?.shoppingMall
            )
          : order.shoppingMall;
      }
    }

    return newOrders;
  };

  const sortOrders = (orders: OrderXX[]) => {
    if (!oms.user.setting?.sortOrders?.enabled) {
      return orders;
    }

    const sortingCriteria = oms.user.setting?.sortOrders?.sortingCriteria || '';

    if (!isValid(sortingCriteria)) {
      return orders;
    }

    if (sortingCriteria === 'addressCount') {
      const addressCountMap = new Map();

      orders.forEach((order) => {
        const address = order.address;

        const count = addressCountMap.get(address) || 0;
        addressCountMap.set(address, count + 1);
      });

      return orders.sort((a, b) => {
        const countA = addressCountMap.get(a.address);
        const countB = addressCountMap.get(b.address);
        const isCountAMultiple = countA > 1;
        const isCountBMultiple = countB > 1;

        // 중복 주소 수가 1 초과인 경우 먼저 처리
        if (isCountAMultiple && !isCountBMultiple) {
          return -1; // A만 중복 주소 수가 1보다 크면 A를 앞으로
        } else if (!isCountAMultiple && isCountBMultiple) {
          return 1; // B만 중복 주소 수가 1보다 크면 B를 뒤로
        }

        // 중복 주소 수가 같으면 주소 이름을 기준으로 오름차순 정렬
        return a.address.localeCompare(b.address);
      });
    }

    // 도서산간, 제주도의 경우 맨 아래로
    if (sortingCriteria === 'remoteArea') {
      return orders.sort((a, b) => {
        const aIsRemote = a.address.startsWith('제주');
        const bIsRemote = b.address.startsWith('제주');

        if (aIsRemote && !bIsRemote) {
          return 1;
        } else if (!aIsRemote && bIsRemote) {
          return -1;
        }

        return a.address.localeCompare(b.address);
      });
    }

    return orders.sort((a, b) => {
      for (const i of sortingCriteria.split(',')) {
        const dir = i.startsWith('-') ? 1 : -1;
        const by = i.replace('-', '');
        if (a[by] < b[by]) {
          return dir;
        }
        if (a[by] > b[by]) {
          return dir * -1;
        }
      }
      return 0;
    });
  };

  const applyMergedCellValues = (orders: OrderXX[]) => {
    // 인식할때 셀이 합쳐져있었지만, read-excel-file 라이브러리가 합쳐진 셀을 인식하지 못하여 하나의 행에만 값이 들어가는 경우가 있습니다.
    // 이를 해결하기 위해 합쳐진 셀의 값을 다른 셀에도 적용하는 로직을 추가합니다.

    const applyMergedCellValues = oms.user.setting?.applyMergedCellValues;

    if (!isValid(applyMergedCellValues?.enabled)) {
      return orders;
    }

    const newOrders = [...orders];

    // 병합된 셀의 경우 read-excel-file의 첫 번째 셀에서만 값이 들어갑니다. 이를 이용하여 값을 복사합니다.
    let mergedCellValue = '';
    newOrders.forEach((order) => {
      const settings = applyMergedCellValues?.settings || [];

      for (const setting of settings) {
        if (order.partnerId === setting.partnerId) {
          if (isValid(order[setting.targetKey])) {
            mergedCellValue = order[setting.targetKey];
          } else {
            order[setting.targetKey] = mergedCellValue;
          }
        }
      }
    });

    return newOrders;
  };

  const applyFieldValueToAnother = (orders: OrderXX[]) => {
    const applyFieldValueToAnother = oms.user.setting?.applyFieldValueToAnother;

    const newOrders = [...orders];

    if (isValid(applyFieldValueToAnother?.enabled)) {
      newOrders.forEach((order) => {
        applyFieldValueToAnother.rules.forEach(
          (rule: { sourceField: string; targetField: string }) => {
            if (isValid(order[rule.sourceField])) {
              order[rule.targetField] = order[rule.sourceField];
            }
          }
        );
      });
    }

    // 공급업체 정보가 없거나 빈 배열인 경우 로직을 종료
    if (!isValid(oms.supplier.suppliers)) {
      return newOrders;
    }

    // 유효한 공급업체 설정이 있는지 먼저 확인
    const hasValidSupplierSettings = oms.supplier.suppliers.some((supplier) =>
      isValid(supplier?.customSettings?.applyFieldValueToAnother?.enabled)
    );

    // 모든 공급업체 정보에 유효한 설정이 없으면 로직 종료
    if (!hasValidSupplierSettings) {
      return newOrders;
    }

    const supplierRulesDict: Record<
      string,
      NonNullable<Supplier['customSettings']>['applyFieldValueToAnother']
    > = {};

    oms.supplier.suppliers.forEach((supplier: Supplier) => {
      if (supplier?.customSettings?.applyFieldValueToAnother) {
        supplierRulesDict[supplier._id] =
          supplier?.customSettings?.applyFieldValueToAnother;
      }
    });

    newOrders.forEach((order) => {
      if (order?.supplierId && supplierRulesDict?.[order.supplierId]?.enabled) {
        supplierRulesDict[order.supplierId]?.rules?.forEach((rule) => {
          if (isValid(order[rule.sourceField])) {
            order[rule.targetField] = order[rule.sourceField];
          }
        });
      }
    });

    return newOrders;
  };

  const applyPrefixToOption = (orders: OrderXX[]) => {
    return orders.map((order) => {
      if (!isValid(order.optionPrefix)) {
        return order;
      }
      if (order.option.indexOf(order.optionPrefix) === 0) {
        return order;
      }
      return {
        ...order,
        option: `${order.optionPrefix}${order.option}`,
      };
    });
  };

  return {
    addPlatformName,
    applyPrefixToOption,
    sortOrders,
    applyMergedCellValues,
    applyFieldValueToAnother,
  };
}
