import { ReactNode } from 'react';
import { cva } from '@sweep/tailwind';

interface SubSectionProps {
  className?: string;
  helperText?: string;
  children?: ReactNode;
}

function SubSection({ className, helperText, children }: SubSectionProps) {
  return (
    <div className="flex flex-col">
      <div
        className={container({
          type: helperText == null ? 'default' : 'error',
          className,
        })}
      >
        {children}
      </div>
      <div className="px-8px py-4px flex h-[25px] w-full items-center">
        <p className="text-medium-xs text-red-500">{helperText}</p>
      </div>
    </div>
  );
}

const container = cva('p-16px rounded-8px border bg-white', {
  variants: {
    type: {
      default: 'border-gray-200',
      error: 'border-red-500',
    },
  },
});

export default SubSection;
