import { NormalizedOrder, Order } from '../type/order/Order';

const ORDER_URL = '/order';

// GET /order
export const GET_ORDERS_URL = ORDER_URL;
export type GetOrdersResponse = Order[];

// GET /order/normalized
export const GET_NORMALIZED_ORDERS_URL = `${ORDER_URL}/normalized`;
export type GetNormalizedOrdersResponse = NormalizedOrder[];

// PUT /order
export const UPSERT_ORDERS_URL = `${ORDER_URL}`;
export type UpsertOrdersBody = {
  orders: Order[];
};

// PUT /order/normalized
export const UPSERT_NORMALIZED_ORDERS_URL = `${ORDER_URL}/normalized`;

export type UpsertNormalizedOrdersBody = {
  orders: NormalizedOrder[];
};

// PUT /order/replace
export const REPLACE_ORDERS_URL = `${ORDER_URL}/replace`;

export type ReplaceOrdersBody = {
  orders: Order[];
};

// PUT /order/normalized/replace
export const REPLACE_NORMALIZED_ORDERS_URL = `${ORDER_URL}/normalized/replace`;

export type ReplaceNormalizedOrdersBody = {
  orders: NormalizedOrder[];
};

// PUT /order/all/replace
export const REPLACE_ALL_ORDERS_URL = `${ORDER_URL}/all/replace`;

export type ReplaceAllOrdersBody = {
  orders: Order[];
  normalizedOrders: NormalizedOrder[];
};
