import { IconOrderDelivery } from '@sweep/asset/icons';
import { cva } from '@sweep/tailwind';
import { CombinedShippingTableProduct } from '../../interface';
import { compareCombinedShippingTableProduct } from '../../services/compareCombinedShippingTableProduct';
import SubSection from './SubSection';

interface ProductListSubSectionProps {
  products: CombinedShippingTableProduct[];
  helperText?: string;
  className?: string;
}

function ProductListSubSection({
  products,
  helperText,
  className,
}: ProductListSubSectionProps) {
  return (
    <SubSection
      className="gap-16px flex justify-center"
      helperText={helperText}
    >
      <div className={container({ className })}>
        {products
          .slice()
          .sort(compareCombinedShippingTableProduct)
          .map((product, index) => (
            <div
              key={index}
              className="gap-6px px-12px py-4px rounded-8px flex w-fit items-center bg-gray-100"
            >
              <p className="text-medium-s text-gray-700">
                {product.productName}
              </p>
              {product.option != null && (
                <p className="text-medium-s text-blue-700">{product.option}</p>
              )}
            </div>
          ))}
      </div>
      <CombinedBracket bracket={products.length > 1} />
    </SubSection>
  );
}

function CombinedBracket({ bracket = true }: { bracket: boolean }) {
  if (bracket) {
    return (
      <div className="w-24px py-7px flex h-full flex-col items-center">
        <div className="flex w-full">
          <div className="h-2px flex-1 rounded-l-full bg-gray-200"></div>
          <div className="size-2px bg-gray-200"></div>
          <div className="flex-1"></div>
        </div>
        <div className="w-2px flex-1 rounded-b-full bg-gray-200"></div>
        <IconOrderDelivery className="my-8px text-orange-500" />
        <div className="w-2px flex-1 rounded-t-full bg-gray-200"></div>
        <div className="flex w-full">
          <div className="h-2px flex-1 rounded-l-full bg-gray-200"></div>
          <div className="size-2px bg-gray-200"></div>
          <div className="flex-1"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-24px flex h-full flex-col justify-center">
      <IconOrderDelivery className="text-orange-500" />
    </div>
  );
}

const container = cva('gap-8px flex flex-col items-end justify-center');

export default ProductListSubSection;
