import { useEffect, useRef } from 'react';
import { Slot } from '../../../Slot';
import { useTableContext } from '../context/TableContext';
import { CellContext, ColumnDef } from '../interface';
import DefaultTableCell from './DefaultTableCell';

interface TableCellRendererProps<T> {
  row: T;
  column: ColumnDef<T>;
  context: CellContext<T>;
}

const SCROLL_WIDTH_TOLERANCE = 3;

function TableCellRenderer<T>({
  row,
  column,
  context,
}: TableCellRendererProps<T>) {
  const ref = useRef<HTMLDivElement | null>(null);
  const { columnWidths, onWidthChange } = useTableContext();
  const columnWidth = columnWidths[context.columnIndex];
  const width = columnWidth != null ? `${columnWidth}px` : undefined;
  const style: React.CSSProperties = {
    width,
    maxWidth: width,
  };

  useEffect(() => {
    if (columnWidth == null && ref.current) {
      onWidthChange(
        context.columnIndex,
        ref.current.clientWidth + SCROLL_WIDTH_TOLERANCE
      );
    }
  }, [context.columnIndex, onWidthChange, columnWidth]);

  const cellContent = column.cell?.(context);
  if (cellContent != null) {
    return (
      <Slot ref={ref} style={style}>
        {cellContent}
      </Slot>
    );
  }

  const value = column.accessorFn?.(row);
  return (
    <DefaultTableCell context={context} ref={ref} style={style}>
      {value}
    </DefaultTableCell>
  );
}

export default TableCellRenderer;
