import { useState } from 'react';
import Lottie from 'react-lottie';
import { IconChat, IconLogo, IconX } from '@sweep/asset/icons';
import { Information } from '@sweep/asset/lotties';
import { cva } from '@sweep/tailwind';
import { amplitude } from 'src/third-parties/amplitude';
import ChatRoom, { CreateNewChatButton } from './ChatRoom';

function Chat() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [chatCount, setChatCount] = useState<number>(0);

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const handleName = (value: string) => {
    setName(value);
  };

  const handleEmail = (value: string) => {
    setEmail(value);
  };

  const createNewChat = () => {
    setChatCount((prev) => prev + 1);
  };

  return (
    <>
      <div
        className={chatIcon()}
        onClick={() => {
          setIsOpen((prev) => {
            if (!prev) {
              amplitude.track('[LP-B] Open Chat');
            }
            return !prev;
          });
        }}
      >
        {isOpen ? (
          <IconX className="size-[36px]" />
        ) : (
          <IconChat className="size-[36px]" />
        )}
      </div>
      <div className={chatBox({ isOpen })}>
        <div className="flex h-[80px] w-full items-center justify-start gap-[10px] rounded-t-[32px] border-b border-black/5 bg-white p-[16px]">
          <div className="flex size-[48px] items-center justify-center rounded-full bg-blue-500">
            <IconLogo className="size-[48px]" />
          </div>
          <p className="text-[18px] font-bold leading-[28px] tracking-tight">
            스윕
          </p>
        </div>
        <div className="relative size-full">
          <div className="absolute left-1/2 top-[72px] flex w-full -translate-x-1/2 flex-col items-center gap-[8px]">
            <Lottie
              width="226px"
              height="226px"
              options={{
                loop: true,
                autoplay: true,
                animationData: Information,
              }}
              style={{ pointerEvents: 'none' }}
            />
            <p className="text-center text-[18px] font-medium leading-[28px] tracking-tight">
              스윕의 소중한 고객님, 반갑습니다!
              <br />
              무엇을 도와드릴까요?
            </p>
          </div>
          <CreateNewChatButton onCreate={createNewChat} />
          {Array.from({ length: chatCount }).map((_, index) => (
            <div key={index} className="absolute left-0 top-0">
              <ChatRoom
                name={name}
                email={email}
                onChangeName={handleName}
                onChangeEmail={handleEmail}
                onCreate={createNewChat}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

const chatIcon = cva(
  'fixed bottom-[40px] right-[40px] z-50 flex size-[80px] cursor-pointer items-center justify-center rounded-[32px] border border-white bg-gradient-to-b from-[rgba(255,255,255,0.96)] to-[rgba(255,255,255,0.69)] shadow-[0_4px_40px_0_rgba(0,0,0,0.15)]'
);

const chatBox = cva(
  'fixed bottom-[140px] right-[40px] z-50 flex h-[600px] w-[450px] flex-col items-center justify-between overflow-hidden rounded-[32px] border border-white bg-gradient-to-t from-[#FBFCFD] to-white shadow-[0_4px_40px_0_rgba(0,0,0,0.15)]',
  {
    variants: {
      isOpen: {
        true: '',
        false: 'hidden',
      },
    },
  }
);

export default Chat;
