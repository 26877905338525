import copy from 'fast-copy';
import { Order } from '@sweep/contract';
import { AbstractPlugin } from '../../interface';
import { isAliExpressOrder } from '../../services/isAliExpressOrder';

// NOTE(@이지원): https://sweepoms.slack.com/archives/C06GP1FDYRY/p1723640037595829
export class AliSplitPlugin extends AbstractPlugin {
  transform = (orders: Order[]): Promise<Order[]> => {
    return Promise.resolve(
      orders.flatMap((order) => this.transformOrder(order))
    );
  };

  transformOrder = (order: Order): Order | Order[] => {
    if (!isAliExpressOrder(order, this.oms)) {
      return order;
    }

    const splitOrders = splitOrder(order);
    return splitOrders.map((order) => splitOption(order));
  };
}

const splitOrder = (order: Order) => {
  const reg = /【\d+】|;/;
  const newOrders = [];
  let productNames: string[] = [];
  let options: string[] = [];
  let optionCodes: string[] = [];

  productNames = (order.productName || '')
    .split(reg)
    .map((p) => p.trim())
    .filter((p) => p !== '');
  options = (order.option || '')
    .split(reg)
    .map((p) => p.trim())
    .filter((p) => p !== '');

  if (order.optionCode) {
    optionCodes = (order.optionCode ?? '')
      .split(reg)
      .map((p) => p.trim())
      .filter((p) => p !== '');
  }

  const maxLength = Math.max(
    productNames.length,
    options.length,
    optionCodes.length
  );

  if (productNames.length === 0) {
    productNames = Array(maxLength).fill('');
  }
  if (options.length === 0) {
    options = Array(maxLength).fill('');
  }
  if (optionCodes.length === 0) {
    optionCodes = Array(maxLength).fill('');
  }

  if (productNames.length + options.length < maxLength * 2) {
    return [order];
  }
  if (optionCodes.length < maxLength) {
    optionCodes = Array(maxLength).fill('');
  }

  for (let i = 0; i < maxLength; i++) {
    newOrders.push(
      copy({
        ...order,
        productName: productNames[i],
        option: options[i],
        optionCode: optionCodes[i],
      })
    );
  }
  return newOrders;
};

const splitOption = (order: Order) => {
  //(속성: 내용) 형식이 단 하나라면 option에 넣기
  const regex = /\(속성:([^)]+)\)/g;
  const nameOption = (order.productName ?? '') + (order.option ?? '');
  const matches = nameOption.match(regex);

  if (matches && matches.length === 1) {
    order.option = matches[0];
    order.productName = nameOption.replace(matches[0], '');
  }

  return order;
};
