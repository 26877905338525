import { OrderProduct } from '@sweep/contract';

const PRODUCT_NAME = '{productName}';
const OPTION = '{unit}';
const CUSTOM_QUANTITY_FORMAT = '{customQuantityFormat}';
const QUANTITY = '{quantity}';

const DEFAULT_CUSTOM_FORMAT = `${PRODUCT_NAME} ${OPTION}${CUSTOM_QUANTITY_FORMAT}`;
const DEFAULT_CUSTOM_FORMAT_WITHOUT_OPTION = `${PRODUCT_NAME}${CUSTOM_QUANTITY_FORMAT}`;
const DEFAULT_CUSTOM_FORMAT_USE_PRODUCT_NAME = `${OPTION}${CUSTOM_QUANTITY_FORMAT}`;
const DEFAULT_CUSTOM_QUANTITY_FORMAT = ` x${QUANTITY}`;

export function getFormattedName(
  product: OrderProduct,
  options: {
    customFormat?: string | null;
    customQuantityFormat?: string | null;
    showQuantityAlways: boolean;
    useProductName: boolean;
  }
): string | null {
  const { showQuantityAlways = false, useProductName = false } = options;

  const customFormat = getCustomFormat(product, {
    useProductName,
    customFormat: options.customFormat,
  });
  const customQuantityFormat =
    options.customQuantityFormat ?? DEFAULT_CUSTOM_QUANTITY_FORMAT;

  const { productName, unit: option, quantity } = product;
  const formattedQuantity = customQuantityFormat.replace(
    QUANTITY,
    quantity.toString()
  );

  return customFormat
    .replace(PRODUCT_NAME, productName)
    .replace(OPTION, option ?? '')
    .replace(
      CUSTOM_QUANTITY_FORMAT,
      quantity > 1 || showQuantityAlways ? formattedQuantity : ''
    )
    .replace(QUANTITY, quantity.toString())
    .trim();
}

function getCustomFormat(
  product: OrderProduct,
  options: { useProductName: boolean; customFormat?: string | null }
) {
  const { useProductName, customFormat } = options;
  if (customFormat != null) {
    return customFormat;
  }

  if (useProductName) {
    return DEFAULT_CUSTOM_FORMAT_USE_PRODUCT_NAME;
  }

  return product.unit == null
    ? DEFAULT_CUSTOM_FORMAT_WITHOUT_OPTION
    : DEFAULT_CUSTOM_FORMAT;
}
