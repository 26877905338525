import { CreateCompositionMatchingDTO } from '@sweep/contract';
import { CreateMatching } from '../interface';

export function toCreateCompositionMatching(
  createMatching: CreateMatching
): CreateCompositionMatchingDTO {
  return {
    productName: createMatching.productName,
    option: createMatching.option,
    data: createMatching.products,
  };
}
