import { IconCheck } from '@sweep/asset/icons';
import { cva } from '../../../../../../packages/tailwind/src/cva/cva';

type DefaultProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'onChange'
>;

export interface CheckboxProps extends DefaultProps {
  checked: boolean;
}

export function Checkbox({ checked, className }: CheckboxProps) {
  return (
    <label className={label({ checked, className })}>
      <input
        type="checkbox"
        checked={checked}
        className="absolute inset-0 cursor-pointer opacity-0"
      />
      <IconCheck className="size-[13px] text-white" />
    </label>
  );
}

const label = cva(
  'rounded-4px relative flex size-[21px] items-center justify-center',
  {
    variants: {
      checked: {
        true: 'bg-blue-500',
        false: 'bg-gray-300',
      },
    },
  }
);

export default Checkbox;
