import { ReactNode } from 'react';
import { TooltipContextProvider } from './context/TooltipContext';
import { TooltipOuterContextProvider } from './context/TooltipOuterContext';
import { useTooltip } from './hooks/useTooltip';
import { TooltipOptions } from './interface';
import { TooltipContent } from './TooltipContent';
import { TooltipTrigger } from './TooltipTrigger';

interface TooltipProps extends TooltipOptions {
  disabled?: boolean;
  children: ReactNode;
}

export function Tooltip({
  disabled = false,
  children,
  ...options
}: TooltipProps) {
  if (disabled) {
    return (
      <TooltipOuterContextProvider value={{ disabled }}>
        {children}
      </TooltipOuterContextProvider>
    );
  }

  return (
    <TooltipOuterContextProvider value={{ disabled }}>
      <TooltipInner {...options}>{children}</TooltipInner>
    </TooltipOuterContextProvider>
  );
}

function TooltipInner({ children, ...options }: TooltipProps) {
  const tooltip = useTooltip(options);

  return (
    <TooltipContextProvider value={tooltip}>{children}</TooltipContextProvider>
  );
}

Tooltip.Trigger = TooltipTrigger;
Tooltip.Content = TooltipContent;
