import switchIcon from '../../icons/switch.png';
import { isValid } from '../../utils/utils';
import ColumnMappingHandle from './ColumnMappingHandle';

const SupplierDetailHeaderMatchingComponent = ({
  supplier,
  handleSupplierInfoChange,
}) => {
  const DetailHeaderMatchingComponent = (
    <form className="mb-8 flex w-full flex-row">
      <div className="mr-[20px] flex w-[80px] shrink-0 flex-col items-center self-start">
        <div
          className={`flex items-center border-y border-[#DAE9F2] bg-[#EDF5FC] px-[16px] text-center text-[12px]`}
        >
          통합 엑셀 양식
        </div>
        <div className="flex h-[32px] w-[80px] items-center justify-center">
          <div>
            <img src={switchIcon} alt="File" className="size-[15px]" />
          </div>
        </div>
        <div
          className={`flex items-center border-y border-[#D8DEF9] bg-[#EFF0F9] px-[16px] text-center text-[12px]`}
        >
          공급사
          <br />
          엑셀 양식
        </div>
      </div>
      <div className="flex gap-[24px] overflow-x-scroll whitespace-nowrap">
        <ColumnMappingHandle
          columnMapping={supplier?.columnMapping}
          setColumnMapping={(mapping) => {
            for (let key of Object.keys(mapping)) {
              if (mapping[key] === 'undefined') {
                delete mapping[key];
              }
            }
            handleSupplierInfoChange({
              target: { name: 'columnMapping', value: mapping },
            });
          }}
          supplierKoreanHeader={supplier.header}
        />
      </div>
    </form>
  );

  return (
    <>
      {isValid(supplier.header) && isValid(supplier.columnMapping) && (
        <>
          <div className="flex flex-row items-center justify-between">
            <div>
              <div className="flex items-center">
                <label
                  htmlFor="excelFile"
                  className="block text-[24px] font-bold text-black"
                >
                  매칭 세부설정
                </label>
              </div>
              <div>
                <p className="mt-[8px] text-[16px] font-normal">
                  엑셀 파일 정보에 가장 부합하는 주요 명칭을 선택해주세요.
                </p>
              </div>
            </div>
          </div>
          <div className="h-[40px]" />
          <div>{DetailHeaderMatchingComponent}</div>
        </>
      )}
    </>
  );
};

export default SupplierDetailHeaderMatchingComponent;
