import { ReactNode, useEffect, useRef, useState } from 'react';
import { IconLogo, IconSend } from '@sweep/asset/icons';
import { cva } from '@sweep/tailwind';
import { toast } from 'src/third-parties/toast';
import backendApi from 'src/utils/backendApi';

interface ChatMessage {
  fromUser: boolean;
  message: ReactNode;
}

function ChatProfile({ className }: { className: string }) {
  return (
    <div
      className={cva(
        'flex size-[36px] items-center justify-center rounded-full bg-blue-500'
      )({ className })}
    >
      <IconLogo className="size-[36px]" />
    </div>
  );
}

export function CreateNewChatButton({ onCreate }: { onCreate: () => void }) {
  return (
    <button
      className="absolute bottom-[32px] left-[32px] h-[48px] w-[386px] rounded-[8px] bg-blue-500 px-[22px] text-[18px] font-bold text-white"
      onClick={onCreate}
    >
      새 문의하기
    </button>
  );
}

interface ChatRoomProps {
  name: string;
  email: string;
  onChangeName: (value: string) => void;
  onChangeEmail: (value: string) => void;
  onCreate: () => void;
}

function ChatRoom({
  name,
  email,
  onChangeName,
  onChangeEmail,
  onCreate,
}: ChatRoomProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const chatRef = useRef<HTMLDivElement>(null);

  const [isInformationSaved, setIsInformationSaved] = useState<boolean>(false);

  const [chats, setChats] = useState<ChatMessage[]>([]);

  const [message, setMessage] = useState<string>('');
  const handleMessage = (value: string) => {
    setMessage(value);
  };

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const [isReply1Visible, setIsReply1Visible] = useState<boolean>(false);
  const [isReply2Visible, setIsReply2Visible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (message.trim() === '') {
      return;
    }
    setIsLoading(true);
    try {
      const response = await backendApi.customerOpinionRegister({
        name,
        email,
        opinion: `*랜딩페이지 챗봇 문의입니다.\n${message.trim()}`,
      });
      if (response.success !== true) {
        toast.error('문의 등록에 실패하였습니다. 다시 시도해주세요.');
        return;
      }
    } catch (error) {
      toast.error('문의 등록에 실패하였습니다. 다시 시도해주세요.');
      return;
    } finally {
      setIsLoading(false);
    }
    setChats((prev) => [
      ...prev,
      {
        fromUser: true,
        message: (
          <>
            {message.split('\n').map((line, index) => (
              <p key={index}>{line}</p>
            ))}
          </>
        ),
      },
    ]);
    setMessage('');
    setIsSubmitted(true);
    setTimeout(() => {
      setIsReply1Visible(true);
    }, 750);
    setTimeout(() => {
      setIsReply2Visible(true);
    }, 1500);
  };

  const onEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  useEffect(() => {
    if (chatRef.current != null) {
      chatRef.current.scrollTo({
        top: chatRef.current.scrollHeight,
      });
    }
  }, [chats, isReply1Visible, isReply2Visible]);

  return (
    <div className={chatBox({ isOpen })}>
      <div
        ref={chatRef}
        className="flex h-[432px] w-full flex-col justify-start gap-[24px] overflow-y-auto p-[16px]"
      >
        <div className={chatMessages({ fromUser: false })}>
          <div className={chatMessage({ fromUser: false })}>
            <p>
              스윕의 소중한 고객님, 새 문의를 시작합니다. 원활한 상담을 위해
              연락처를 입력해주세요.
            </p>
          </div>
          <div className={chatMessage({ fromUser: false })}>
            <div className="flex w-[278px] flex-col gap-[16px]">
              <div className="flex flex-col items-start gap-[8px]">
                <label
                  htmlFor="name"
                  className="text-[16px] font-medium leading-[21px] text-gray-700"
                >
                  성함 <span className="text-orange-500">*</span>
                </label>
                {isInformationSaved ? (
                  <p className="text-[14px] font-medium leading-[19px] text-gray-500">
                    {name}
                  </p>
                ) : (
                  <input
                    id="name"
                    type="text"
                    placeholder="성함을 입력해주세요."
                    value={name}
                    onChange={(e) => onChangeName(e.target.value)}
                    className="h-[40px] w-full rounded-[8px] border border-gray-300 px-[16px] focus:outline-blue-500"
                  />
                )}
              </div>
              <div className="flex flex-col items-start gap-[8px]">
                <label
                  htmlFor="email"
                  className="text-[16px] font-medium leading-[21px] text-gray-700"
                >
                  이메일 <span className="text-orange-500">*</span>
                </label>
                {isInformationSaved ? (
                  <p className="text-[14px] font-medium leading-[19px] text-gray-500">
                    {email}
                  </p>
                ) : (
                  <input
                    id="email"
                    type="email"
                    placeholder="이메일을 입력해주세요."
                    value={email}
                    onChange={(e) => onChangeEmail(e.target.value)}
                    className="h-[40px] w-full rounded-[8px] border border-gray-300 px-[16px] focus:outline-blue-500"
                  />
                )}
              </div>
              {!isInformationSaved && (
                <button
                  disabled={name.trim() === '' || email.trim() === ''}
                  className={saveInformationButton({
                    disabled: name.trim() === '' || email.trim() === '',
                  })}
                  onClick={() => setIsInformationSaved(true)}
                >
                  저장하기
                </button>
              )}
            </div>
          </div>
          {isInformationSaved && (
            <div className={chatMessage({ fromUser: false })}>
              <p>
                스윕 도입을 고민하고 계신가요? 궁금하신 점 남겨주시면 안내
                도와드리겠습니다. ☺️
              </p>
            </div>
          )}
          <ChatProfile className="absolute bottom-0 left-0" />
        </div>
        {chats.map((chat, index) => (
          <div
            key={index}
            className={chatMessages({ fromUser: chat.fromUser })}
          >
            <div className={chatMessage({ fromUser: chat.fromUser })}>
              {chat.message}
            </div>
            {!chat.fromUser && (
              <ChatProfile className="absolute bottom-0 left-0" />
            )}
          </div>
        ))}
        {isReply1Visible && (
          <div className={chatMessages({ fromUser: false })}>
            <div className={chatMessage({ fromUser: false })}>
              <p>
                소중한 문의 감사드립니다. 빠른 시일 내에 등록하신 이메일로 답변
                드리겠습니다.
              </p>
            </div>
            {isReply2Visible && (
              <div className={chatMessage({ fromUser: false })}>
                <p>추가로 문의사항이 있으시면 새 문의를 이용해주세요.</p>
              </div>
            )}
            <ChatProfile className="absolute bottom-0 left-0" />
          </div>
        )}
      </div>
      <div className="flex h-[88px] w-full p-[16px]">
        {isSubmitted ? (
          <CreateNewChatButton onCreate={onCreate} />
        ) : isInformationSaved ? (
          <div className="relative flex size-full items-center justify-between gap-[8px] rounded-[16px] bg-gray-100 pr-[8px]">
            <textarea
              placeholder="메시지를 입력해주세요."
              value={message}
              onChange={(e) => handleMessage(e.target.value)}
              onKeyDown={onEnter}
              className="scrollbar-hide h-[56px] w-full resize-none whitespace-normal rounded-[16px] bg-transparent py-[18.5px] pl-[20px] pr-[56px] text-[14px] font-medium leading-[19px] outline-none"
            />
            <button
              disabled={message.trim() === '' || isLoading}
              className={send({ disabled: message.trim() === '' || isLoading })}
              onClick={handleSubmit}
            >
              <IconSend className="size-[20px]" />
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
}

const chatBox = cva(
  'relative flex h-[520px] w-full flex-col gap-0 bg-white transition-transform duration-500',
  {
    variants: {
      isOpen: {
        true: '',
        false: 'translate-x-full',
      },
    },
  }
);

const chatMessages = cva(
  'flex w-full flex-col gap-[8px] text-[16px] font-medium leading-[25px] tracking-tight',
  {
    variants: {
      fromUser: {
        true: 'items-end',
        false: 'relative pl-[52px] pr-[48px]',
      },
    },
  }
);

const chatMessage = cva(
  'w-fit max-w-[318px] gap-[16px] rounded-[16px] p-[20px] text-left',
  {
    variants: {
      fromUser: {
        true: 'bg-blue-500 text-white',
        false: 'bg-gray-100 text-[#03183A]',
      },
    },
  }
);

const saveInformationButton = cva(
  'h-[44px] w-full rounded-[8px] text-[16px] font-bold text-white',
  {
    variants: {
      disabled: {
        true: 'bg-gray-300',
        false: 'bg-blue-500',
      },
    },
  }
);

const send = cva('flex size-[40px] items-center justify-center rounded-[8px]', {
  variants: {
    disabled: {
      true: 'bg-gray-300',
      false: 'bg-blue-500',
    },
  },
});

export default ChatRoom;
