import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { toast } from 'sonner';
import { IconCheckSmall, IconEdit, IconTrashcan } from '@sweep/asset/icons';
import { Product, UpdateProductManyDTO } from '@sweep/contract';
import { Button, SearchInput } from '@sweep/sds';
import { isNotNil } from '@sweep/utils';
import { If, Separated } from '@sweep/utils/react';
import {
  openProductCreateFormModal,
  openProductUpdateFormModal,
} from 'src/forms/product';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { openProductDeleteDialog } from './components/ProductDeleteDialog/openProductDeleteDialog';
import ProductTableHeader from './components/ProductTable/ProductTableHeader';
import ProductTableRow from './components/ProductTable/ProductTableRow';
import { openProductUpdateSupplierFormModal } from './components/ProductUpdateSupplierFormModal/openProductUpdateSupplierFormModal';
import { useProductCheck } from './hooks/useProductCheck';
import { filterProduct } from './services/filterProduct';

function ProductManagementScreen() {
  const oms = useOMSStore();
  const products = oms.product.products;
  const suppliers = oms.supplier.suppliers;
  const [search, setSearch] = useState('');
  const filteredProducts = products.filter((product) =>
    filterProduct(search, product, oms)
  );

  const {
    selectedProductIds,
    clearSelectedProductIds,
    isChecked,
    isTotalChecked,
    onCheckedChange,
    onTotalCheckedChange,
  } = useProductCheck(filteredProducts);

  const handleCreateClick = async () => {
    const product = await openProductCreateFormModal();
    if (product == null) {
      return;
    }

    const result = await oms.product.register(product);
    result
      ? toast.success('상품이 등록되었습니다.')
      : toast.error('상품 등록에 실패했습니다. 잠시 후 다시 시도해주세요.');
  };

  const handleUpdateClick = async (product: Product) => {
    const updatedProduct = await openProductUpdateFormModal(product);
    if (updatedProduct == null) {
      return;
    }

    const result = await oms.product.update(product._id, updatedProduct);
    result
      ? toast.success('상품이 수정되었습니다.')
      : toast.error('상품 수정에 실패했습니다. 잠시 후 다시 시도해주세요.');
  };

  const handleSupplierUpdateClick = async () => {
    const supplierId = await openProductUpdateSupplierFormModal();
    if (supplierId == null) {
      return;
    }

    const updatedProducts = selectedProductIds
      .map<UpdateProductManyDTO | null>((productId) => {
        const product = oms.product.getProduct(productId);
        if (product == null) {
          return null;
        }

        return {
          _id: productId,
          units: product.units?.map((unit) => ({
            ...unit,
            supplierId: null,
          })),
          supplierId: supplierId === '' ? null : supplierId,
          salesChannelSupplierIds: [],
          useSupplierByOption: false,
          useSupplierBySalesChannel: false,
        };
      })
      .filter(isNotNil);

    clearSelectedProductIds();
    const result = await oms.product.updateMany(updatedProducts);
    result
      ? toast.success('공급사가 일괄적으로 수정되었습니다.')
      : toast.error('공급사 수정에 실패했습니다. 잠시 후 다시 시도해주세요.');
  };

  const handleDeleteClick = async () => {
    const isDelete = await openProductDeleteDialog(selectedProductIds);
    if (!isDelete) {
      return;
    }

    clearSelectedProductIds();
    const result = await oms.product.deleteMany(selectedProductIds);
    result
      ? toast.success('상품이 삭제되었습니다.')
      : toast.error('상품 삭제에 실패했습니다. 잠시 후 다시 시도해주세요.');
  };

  return (
    <div className="px-40px py-20px h-full bg-gray-100">
      <p className="mb-38px mt-6px text-extrabold-l text-gray-700">상품 관리</p>
      <div className="p-20px rounded-8px flex flex-col bg-white shadow-[0_1px_4px_0_rgba(0,0,0,0.1)]">
        <div className="mb-8px flex items-center justify-between">
          <div className="gap-20px flex flex-1 items-center">
            <div className="gap-4px flex">
              <p className="text-medium-s text-nowrap text-gray-500">
                상품{' '}
                <span className="text-semibold-s text-blue-500">
                  {products.length}
                </span>
                개
              </p>
            </div>
            <SearchInput
              placeholder="검색어를 입력하세요."
              value={search}
              onChange={setSearch}
              className="max-w-[500px]"
            />
          </div>
          <div className="gap-10px flex">
            <If is={selectedProductIds.length === 0}>
              <Button onClick={handleCreateClick}>상품 등록</Button>
            </If>
            <If is={selectedProductIds.length > 0}>
              <Button
                variant="line"
                color="lightGray"
                onClick={handleDeleteClick}
                leftAccessory={<IconTrashcan />}
              >
                삭제
              </Button>
              <If is={suppliers.length > 0}>
                <Button
                  variant="line"
                  leftAccessory={<IconEdit />}
                  onClick={handleSupplierUpdateClick}
                >
                  공급사 일괄 수정
                </Button>
              </If>
            </If>
          </div>
        </div>
        <div className="gap-6px mb-12px flex items-center">
          <IconCheckSmall className="text-gray-400" />
          <span className="text-semibold-s text-blue-500">
            {selectedProductIds.length}
          </span>
          <span className="text-medium-s text-gray-500">선택됨</span>
        </div>
        <div className="border border-gray-200">
          <Separated with={<hr />}>
            <ProductTableHeader
              checked={isTotalChecked}
              onCheckedChange={onTotalCheckedChange}
            />
            {filteredProducts.map((product) => (
              <ProductTableRow
                key={product._id}
                product={product}
                checked={isChecked(product._id)}
                onCheckedChange={onCheckedChange(product._id)}
                onUpdateClick={() => handleUpdateClick(product)}
              />
            ))}
          </Separated>
        </div>
      </div>
    </div>
  );
}

export default observer(ProductManagementScreen);
