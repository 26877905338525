import { overlay } from 'overlay-kit';
import { Dialog } from '@sweep/sds';

export function openAcceptCanceledOrderDialog(orderCount: number) {
  return new Promise<boolean>((resolve) =>
    overlay.open(({ isOpen, close }) => (
      <Dialog open={isOpen} onClose={close}>
        <Dialog.Title className="mb-[28px]">
          선택한 <span className="text-blue-500">{orderCount}건</span>의 취소
          요청 건을 승인할까요?
        </Dialog.Title>
        <Dialog.Footer>
          <Dialog.Close onClick={() => resolve(false)} color="lightGray">
            취소
          </Dialog.Close>
          <Dialog.Close onClick={() => resolve(true)}>확인</Dialog.Close>
        </Dialog.Footer>
      </Dialog>
    ))
  );
}
