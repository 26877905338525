export function sliceUntilEmptyRow<T extends string>(
  spreadsheet: T[][]
): T[][] {
  const emptyRowIndex = spreadsheet.findIndex((row) =>
    row.every((value) => value === '')
  );

  if (emptyRowIndex === -1) {
    return spreadsheet;
  }

  return spreadsheet.slice(0, emptyRowIndex);
}
