import { Order } from '@sweep/contract';
import { FormatOrder } from '../interface';

export function toFormatOrder(order: Order): FormatOrder {
  return {
    productName: order.productName,
    option: order.option,
    quantity: order.quantity,
    products: order.data,
  };
}
