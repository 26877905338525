import { CreateProductDTO } from '@sweep/contract';
import { uniq } from '@sweep/utils';

export function isDuplicatedOption(product: CreateProductDTO) {
  const options = product.units ?? [];
  if (options.length === 0) {
    return false;
  }

  const optionNames = options.map((option) => option.unit);
  const uniqOptionNames = uniq(optionNames);

  return optionNames.length !== uniqOptionNames.length;
}
