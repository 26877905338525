import { useComposition } from '@sweep/utils/react';
import { SearchSelectOption } from '../SearchSelect';

interface SearchSelectKeyDownOptions {
  closeOptions: () => void;
  options: SearchSelectOption[];
  selectedIndex: number;
  onSelectedIndexChange: (index: number) => void;
  onChange: (value: string | null) => void;
  optionRefs: React.MutableRefObject<(HTMLLIElement | null)[]>;
}

export function useSearchSelectKeyDown({
  closeOptions,
  options,
  selectedIndex,
  onSelectedIndexChange,
  onChange,
  optionRefs,
}: SearchSelectKeyDownOptions) {
  const { isComposing } = useComposition();

  const scrollOptionIntoView = (index: number) => {
    optionRefs.current.at(index)?.scrollIntoView({ block: 'nearest' });
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (isComposing) {
      return;
    }

    switch (event.key) {
      case 'Escape':
        event.stopPropagation();
        closeOptions();
        break;

      case 'Enter':
        event.preventDefault();
        const selectedOption = options.at(selectedIndex);
        if (selectedOption != null) {
          onChange(selectedOption.value);
        }
        closeOptions();
        break;

      case 'ArrowUp':
        event.preventDefault();
        if (selectedIndex > 0) {
          onSelectedIndexChange(selectedIndex - 1);
          scrollOptionIntoView(selectedIndex - 1);
        }
        break;

      case 'ArrowDown':
        event.preventDefault();
        if (selectedIndex < options.length - 1) {
          onSelectedIndexChange(selectedIndex + 1);
          scrollOptionIntoView(selectedIndex + 1);
        }
        break;
    }
  };

  return {
    onKeyDown: handleKeyDown,
  };
}
