import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useABTest } from 'src/hooks/useABTest';
import { amplitude } from 'src/third-parties/amplitude';
import PCLandingPage from './landing-page/PC/PCLandingPage';
import FullPageScreen from './NewLandingPagewithFullPager';

function LandingPageForABTest() {
  const isPC = useMediaQuery({
    query: '(min-width: 1099px) and (orientation: landscape)',
  });
  const userGroup = useABTest('landingPage');

  useEffect(() => {
    if (isPC && userGroup != null) {
      amplitude.track('[LP] View Landing Page', {
        group: userGroup,
      });
      amplitude.setGroup('landingPageTestGroup', userGroup);
    }
  }, [isPC, userGroup]);

  return userGroup === 'A' || !isPC ? <FullPageScreen /> : <PCLandingPage />;
}
export default LandingPageForABTest;
