import { OMSStore } from 'stores/OMSStore';
import { MatchingProduct } from '@sweep/contract';
import { isNotNil } from '@sweep/utils';

export function toMatchingProducts(
  products: Partial<MatchingProduct>[],
  oms: OMSStore
): MatchingProduct[] {
  return products
    .map((product) => toMatchingProductFromPartial(oms, product))
    .filter(isNotNil);
}

function toMatchingProductFromPartial(
  oms: OMSStore,
  value: Partial<MatchingProduct>
): MatchingProduct | null {
  if (value.productId == null) {
    return null;
  }

  const product = oms.product.getProduct(value.productId);
  if (product == null) {
    return null;
  }

  if (product.units == null || product.units.length === 0) {
    return {
      productId: value.productId,
      quantity: value.quantity ?? 1,
    };
  }

  if (product.units.every((unit) => unit.unit !== value.unit)) {
    return null;
  }

  return {
    productId: value.productId,
    quantity: value.quantity ?? 1,
    unit: value.unit,
  };
}
