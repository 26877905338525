import { observer } from 'mobx-react-lite';
import { ReactNode, useState } from 'react';
import { cva } from '@sweep/tailwind';
import { SwitchCase } from '@sweep/utils/react';
import DispatchResultTabButton from './DispatchResultTabButton';
import MallFail from './MallFail';
import MallSuccess from './MallSuccess';

interface DispatchResultContentProps {
  successNames: string[];
  failedNames: string[];
  initialSelectedTab?: 'fail' | 'success';
  rightTabAccessory?: ReactNode;
}

function DispatchResultContent({
  successNames,
  failedNames,
  initialSelectedTab,
  rightTabAccessory,
}: DispatchResultContentProps) {
  const [selectedTab, setSelectedTab] = useState<'fail' | 'success'>(
    initialSelectedTab ?? 'fail'
  );
  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex gap-2">
          <DispatchResultTabButton
            type="fail"
            isSelected={selectedTab === 'fail'}
            onClick={() => setSelectedTab('fail')}
            count={failedNames.length}
          />
          <DispatchResultTabButton
            type="success"
            isSelected={selectedTab === 'success'}
            onClick={() => setSelectedTab('success')}
            count={successNames.length}
          />
        </div>
        {rightTabAccessory}
      </div>
      <div className={container()}>
        <SwitchCase
          value={selectedTab}
          caseBy={{
            fail: failedNames.map((name) => (
              <MallFail key={name} name={name} />
            )),
            success: successNames.map((name) => (
              <MallSuccess key={name} name={name} />
            )),
          }}
        />
      </div>
    </>
  );
}

const container = cva([
  'flex flex-col gap-[20px]',
  'h-[360px] overflow-y-scroll px-[32px] py-[24px]',
  'rounded-r-[8px] border border-gray-300 bg-gray-100',
]);

export default observer(DispatchResultContent);
