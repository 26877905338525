import { observer } from 'mobx-react-lite';
import { IconPlusXSmall } from '@sweep/asset/icons';
import { MatchingProduct } from '@sweep/contract';
import MatchingProductInput from './MatchingProductInput';

interface MatchingProductsInputProps {
  value: Partial<MatchingProduct>[];
  onChange: (value: Partial<MatchingProduct>[]) => void;
  required?: boolean;
  filled?: boolean;
}

function MatchingProductsInput({
  value,
  onChange,
  required,
  filled,
}: MatchingProductsInputProps) {
  const handleCMProductChange = (
    index: number,
    product: Partial<MatchingProduct> | null
  ) => {
    const products = [...value];
    if (value.length === 1 && product == null) {
      onChange([{ quantity: 1 }]);
      return;
    }

    if (product == null) {
      products.splice(index, 1);
      onChange(products);
      return;
    }

    products[index] = product;
    onChange(products);
  };

  return (
    <div className="flex w-full flex-col gap-[10px]">
      {value.map((product, index) => (
        <MatchingProductInput
          key={`${product.productId}-${index}`}
          value={product}
          onChange={(product) => handleCMProductChange(index, product)}
          required={required}
          filled={filled}
        />
      ))}
      <button
        className="text-medium-s gap-4px flex w-fit items-center text-blue-500"
        onClick={() => onChange([...value, { quantity: 1 }])}
      >
        <IconPlusXSmall />
        구성 추가하기
      </button>
    </div>
  );
}

export default observer(MatchingProductsInput);
