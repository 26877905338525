import { Modal } from '@sweep/sds';

interface NotMatchedDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
}

function NotMatchedDialog({ open, onClose, onSave }: NotMatchedDialogProps) {
  return (
    <Modal open={open} onClose={onClose}>
      <p className="text-bold-l px-[32px] py-[34px]">
        일부 상품이 확정되지 않았습니다.
        <br />
        확정되지 않은 상품은 상품구성이 보류된 채로 저장됩니다.
      </p>
      <div className="flex justify-center gap-[12px] pb-[20px]">
        <Modal.Close className="w-[200px]" color="gray">
          취소하기
        </Modal.Close>
        <Modal.Close className="w-[200px]" onClick={onSave}>
          저장하기
        </Modal.Close>
      </div>
    </Modal>
  );
}

export default NotMatchedDialog;
