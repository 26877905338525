import dbsafer from '../../images/dbsafer.png';
import SweepWhite from '../../images/SweepWhite.png';

function NewFooter() {
  return (
    <div className="flex w-full flex-col justify-center space-y-[48px] bg-[#263142] px-[40px] py-[60px]">
      <div className="flex flex-col">
        <div className="mb-[12px]">
          <img className="h-[20px]" src={SweepWhite} />
        </div>
        <div className="font-pretendard font-mediumS text-[12px] leading-relaxed text-white">
          스윕 | 서울특별시 관악로 158, 스프링캠프 5층 <br />
          사업자등록번호 : 491-87-02864 <br />
          통신판매업 신고번호: 제2024-서울관악-0637호 <br />
          대표: 윤현일 | 010-8451-2670 <br />
          대표 전화번호: 1566-5529
          <br />
        </div>
      </div>
      <div className="flex flex-col space-y-[12px]">
        <img className="h-[22px] w-[103.5px]" src={dbsafer} />
        <div className="font-pretendard font-mediumS text-[12px] leading-relaxed text-white">
          스윕에서 처리되는 모든 주문데이터는 <br />
          DBSAFER를 통해 안전하게 관리됩니다.
        </div>
      </div>
      <div className="flex flex-col space-y-[12px] text-[14px] text-gray-400">
        <div>
          <a
            href="https://sweepoms.notion.site/476a63cf5c3842569775438ae80be500?pvs=4"
            className="hover:text-blue-500"
            target="_blank"
            rel="noopener noreferrer"
          >
            서비스 이용약관
          </a>
        </div>
        <div>
          <a
            href="https://sweepoms.notion.site/587bc67c6247485eab6b6972bef1a586?pvs=4"
            className="hover:text-blue-500"
            target="_blank"
            rel="noopener noreferrer"
          >
            개인정보 처리방침
          </a>
        </div>
      </div>
      <span className="text-[13px] text-gray-400">
        Copyright © 2024 Sweep Corp. <br />
        All rights reserved.
      </span>
    </div>
  );
}

export default NewFooter;
