// not found in utils
export function removeSpaces(str: string) {
  if (typeof str === 'string') {
    return str.replace(/\s+/g, '');
  }

  // console.warn('Undefined or null value passed to removeSpaces');
  // console.warn('Non-string value passed to removeSpaces: ', str);
  return '';
}
