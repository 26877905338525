import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import Button from '../../components/buttons/Button';
import deleteTextGray from '../../icons/deleteTextGray.png';
import FileCode from '../../icons/file-code';
import {
  createColumnMapping,
  removeEndNulls,
  translateHeaderRowInEnglish,
} from '../../utils/headerColumnMapping';
import { readExcelFile } from '../../utils/readExcelFile';
import { areArraysEqual, isValid } from '../../utils/utils';

export interface SupplierExcelUploadRef {
  handleExcelFileUpload: (e: any) => void;
  handleExcelFileUploadRows: (e: any) => void;
}
export interface SupplierExcelUploadProps {
  supplier: any;
  handleSupplierInfoChange: (info: any) => void;
}

const SupplierExcelUpload = forwardRef<
  SupplierExcelUploadRef,
  SupplierExcelUploadProps
>(({ supplier, handleSupplierInfoChange }, ref) => {
  const oms = useOMSStore();
  const [isChecked, setIsChecked] = useState(false);

  const 통합엑셀양식_공급사양식에_적용 = () => {
    const 통합엑셀양식_columnMapping = { ...oms.user.excelColumnMapping };
    const 통합엑셀양식_영어Header = [...oms.user.excelHeaderKeys];

    const 통합엑셀양식_한국어Header = 통합엑셀양식_영어Header?.map(
      (key) => 통합엑셀양식_columnMapping[key] || key
    );
    handleSupplierInfoChange({
      target: { name: 'columnMapping', value: 통합엑셀양식_columnMapping },
    });

    handleSupplierInfoChange({
      target: { name: 'header', value: 통합엑셀양식_한국어Header },
    });
  };

  useEffect(() => {
    if (isChecked) {
      통합엑셀양식_공급사양식에_적용();
    }
  }, [isChecked]);

  useEffect(() => {
    if (supplier) {
      setIsChecked(
        areArraysEqual(
          oms.user.excelHeaderKeys.map(
            (key) => oms.user.excelColumnMapping[key] || key
          ),
          supplier.header || []
        )
      );
    }
  }, [supplier]);

  const handleExcelFileUploadRows = async (rows: any[][]) => {
    const fileHeaders = removeEndNulls(rows[0]);

    // fileHeaders에 스윕고유번호가 없는 경우 추가
    const isMissingHeaderUniqueCode =
      fileHeaders.includes('스윕고유번호') === false;
    if (isMissingHeaderUniqueCode) {
      fileHeaders.push('스윕고유번호');
    }

    let mappedHeader = fileHeaders;

    const settings = {
      columnTranslation: oms.user.excelColumnMapping || {},
    };
    // 드는 생각 - 매칭 로직 하고, 추가로 좀 효율화 직관화 할 수 있을듯
    mappedHeader = translateHeaderRowInEnglish(mappedHeader, settings);

    const columnMapping = createColumnMapping(fileHeaders, mappedHeader);

    // uniqueCode가 없는 경우 추가
    const isMissingColumnMappingUniqueCode = columnMapping.uniqueCode == null;
    if (isMissingColumnMappingUniqueCode) {
      columnMapping.uniqueCode = '스윕고유번호';
    }

    handleSupplierInfoChange({
      target: { name: 'header', value: fileHeaders },
    });
    handleSupplierInfoChange({
      target: { name: 'columnMapping', value: columnMapping },
    });
  };

  const handleExcelFileUpload = async (e: any) => {
    try {
      const a = await readExcelFile(e.target.files[0], {
        isHeaderIndexNeeded: true,
      });

      const { rows } = a;
      let { headerRowIndex } = a;

      headerRowIndex++;
      handleSupplierInfoChange({
        target: {
          name: 'indexes',
          value: { headerRowIndex, minRowIndex: headerRowIndex + rows.length },
        },
      });

      if (isValid(rows)) {
        await handleExcelFileUploadRows(rows);

        handleSupplierInfoChange({
          target: { name: 'file', value: e.target.files[0] },
        });
      }

      e.target.value = null;
    } catch (error) {
      console.error('Error reading excel file:', error);
    }
    e.target.value = '';
  };

  useImperativeHandle(ref, () => ({
    handleExcelFileUpload,
    handleExcelFileUploadRows,
  }));

  const handleCheckboxChange = (e: any) => {
    if (e.target.checked && (supplier?.header?.length || 0) !== 0) {
      if (
        !window.confirm(
          `통합 엑셀 양식과 같은 양식을 사용하시겠습니까?\n기존에 입력된 양식은 초기화됩니다.`
        )
      ) {
        return;
      }
    }

    setIsChecked(e.target.checked);
    if (!e.target.checked) {
      handleSupplierInfoChange({
        target: { name: 'header', value: [] },
      });
      handleSupplierInfoChange({
        target: { name: 'columnMapping', value: {} },
      });
    }
  };

  return (
    <div className="flex justify-between">
      <div>
        <label
          htmlFor="excelFile"
          className="font-pretendard block self-stretch text-base font-medium not-italic leading-[21px_] text-[color:var(--Gray-600,#343D4B)]"
        >
          공급사의 엑셀 양식을 올려주세요
        </label>
        <div className="mb-4 mt-[16px] flex items-center space-x-4 rounded-md border-gray-300 bg-white ">
          {!supplier.file && (
            <div className="">
              <Button
                name=""
                onClick={handleExcelFileUpload}
                className="flex h-[45px] w-[127px] items-center justify-center bg-blue-500"
                innerComponent={
                  <span className="flex flex-row items-center justify-center">
                    <FileCode />
                    <span className="ml-[4px] text-sm font-bold not-italic leading-[19px_] text-[color:var(--Gray-100,#F2F6FA)]">
                      파일 업로드
                    </span>
                  </span>
                }
                type="input"
              />
            </div>
          )}
          {supplier.file && (
            <div className="flex h-[45px] items-center justify-center gap-[8px] rounded-lg bg-[#F2F6FA] px-5 py-0">
              <div className="flex">
                <FileCode fill="#9FB1C1" />
                <span className="ml-[4px] text-sm font-bold not-italic leading-[19px_] text-[#343D4B]">
                  업로드된 파일
                </span>
              </div>
              <span className="text-sm font-bold not-italic leading-[19px_] text-blue-500">
                {supplier.file?.name}
              </span>
              <div
                className="inset-y-0 -right-[24px] flex cursor-pointer items-center"
                onClick={() => {
                  handleSupplierInfoChange({
                    target: { name: 'file', value: null },
                  });
                  handleSupplierInfoChange({
                    target: { name: 'header', value: [] },
                  });
                  handleSupplierInfoChange({
                    target: { name: 'columnMapping', value: {} },
                  });
                }}
              >
                <img src={deleteTextGray} alt="Close" className="size-4" />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex">
        <label className="flex w-[5px] items-center">
          <input
            type="checkbox"
            className="scale-150"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
        </label>
        <span className="ml-[16px] flex items-center text-[16px] font-medium">
          통합 엑셀 양식과 같아요
        </span>
      </div>
    </div>
  );
});

export default SupplierExcelUpload;
