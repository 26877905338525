import { Order } from '@sweep/contract';
import { parseNumber } from '@sweep/utils';
import { AbstractPlugin } from '../../interface';

export interface NumberingPluginConfig {
  target: string;
  format?: string;
}

export class NumberingPlugin extends AbstractPlugin<NumberingPluginConfig> {
  transform = (orders: Order[]): Promise<Order[]> => {
    return Promise.resolve(
      orders.map((order, index) => this.numbering(order, index + 1))
    );
  };

  numbering = (order: Order, number: number) => {
    const { target, format = '{number}' } = this.config;
    const formatted = format.replace('{number}', number.toString());
    const parsed = parseNumber(formatted);
    order[target] = parsed != null ? parsed : formatted;

    return order;
  };
}
