import { observer } from 'mobx-react-lite';
import { IconArrowRightLarge } from '@sweep/asset/icons';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { OrderProcessType } from '../../OrderProcessScreen';
import { getCountByShippingStatus } from '../../services/getCountByShippingStatus';
import OrderProcessRadio from './OrderProcessRadio';

interface OrderProcessRadioGroupProps {
  value: OrderProcessType;
  onChange: (process: OrderProcessType) => void;
}

function OrderProcessRadioGroup({
  value,
  onChange,
}: OrderProcessRadioGroupProps) {
  const oms = useOMSStore();
  const counts = getCountByShippingStatus(oms.order.normalizedOrders);

  return (
    <div className="gap-24px p-20px rounded-8px shadow-line flex flex-wrap bg-white">
      <OrderProcessRadio
        process="total"
        active={value === 'total'}
        count={counts.total}
        onSelect={() => onChange('total')}
      />
      <div className="w-1px shrink-0 bg-gray-200" />
      <div className="gap-8px flex flex-1 flex-wrap items-center">
        <OrderProcessRadio
          process="pending"
          active={value === 'pending'}
          count={counts.pending}
          onSelect={() => onChange('pending')}
        />
        <IconArrowRightLarge className="text-gray-300" />
        <OrderProcessRadio
          process="processing"
          active={value === 'processing'}
          count={counts.processing}
          onSelect={() => onChange('processing')}
        />
        <IconArrowRightLarge className="text-gray-300" />
        <OrderProcessRadio
          process="reviewing"
          active={value === 'reviewing'}
          count={counts.reviewing}
          onSelect={() => onChange('reviewing')}
        />
        <IconArrowRightLarge className="text-gray-300" />
        <OrderProcessRadio
          process="shipped"
          active={value === 'shipped'}
          count={counts.shipped}
          onSelect={() => onChange('shipped')}
        />
      </div>
    </div>
  );
}

export default observer(OrderProcessRadioGroup);
