import { IconWarning } from '@sweep/asset/icons';

interface WarningBannerProps {
  children: React.ReactNode;
}

function WarningBanner({ children }: WarningBannerProps) {
  return (
    <div className="flex items-center gap-2 rounded-[8px] bg-blue-500 px-[20px] py-[12px]">
      <IconWarning color="white" />
      <div className="flex flex-col text-[14px] font-semibold text-white">
        {children}
      </div>
    </div>
  );
}

export default WarningBanner;
