import { isNil } from 'es-toolkit';
import { SupplierLikeModel } from '../../models/supplierLike.model';
import { UserSettingLikeModel } from '../../models/userSettingLike.model';
import { UserSpreadsheetHeaderMappingLikeModel } from '../../models/userSpreadsheetHeaderMappingLike.model';
import {
  findDefaultSynonymMatch,
  findSupplierHeaderMatch,
  findTemplateHeaderMatch,
  findUserSettingHeaderMatch,
  findUserSpreadsheetHeaderMappingMatch,
} from './strategies';
import { HeaderMappingResult, HeaderMatchContext } from './types';

const REQUIRED_HEADER_KEYS_FOR_RETAILER_USER = [
  'shippingNumber',
  'uniqueCode',
] as const;

export function findRetailerHeaderMapping<
  Supplier extends SupplierLikeModel,
  UserSetting extends UserSettingLikeModel,
  SpreadsheetHeaderMapping extends UserSpreadsheetHeaderMappingLikeModel,
>({
  headerRow,
  supplierInfos,
  customExcelSettings,
  userSpreadsheetHeaderMappings,
}: {
  headerRow: string[];
  supplierInfos: Supplier[];
  customExcelSettings?: UserSetting | null;
  userSpreadsheetHeaderMappings: SpreadsheetHeaderMapping[];
}): HeaderMappingResult | null {
  const context: HeaderMatchContext = {
    headerRow,
    defaultShippingCompany:
      customExcelSettings?.defaultShippingCompany ?? undefined,
    requiredHeaderKeys: REQUIRED_HEADER_KEYS_FOR_RETAILER_USER,
  };

  if (isNil(headerRow) || headerRow.length === 0) {
    return {
      headerKeys: [],
      mappingColumn: {},
      defaultShippingCompany: context.defaultShippingCompany,
    };
  }

  return (
    findSupplierHeaderMatch(supplierInfos, context) ??
    findTemplateHeaderMatch(customExcelSettings, context) ??
    findUserSettingHeaderMatch(customExcelSettings, context) ??
    findUserSpreadsheetHeaderMappingMatch(
      userSpreadsheetHeaderMappings,
      context
    ) ??
    findDefaultSynonymMatch(customExcelSettings, context)
  );
}
