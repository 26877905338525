import { IconArrowRightLarge, IconShop } from '@sweep/asset/icons';
import { cva } from '@sweep/tailwind';
import { imageLogo } from 'src/images';

interface CMHeaderProps {
  withConfirm?: boolean;
}

function CMHeader({ withConfirm = false }: CMHeaderProps) {
  return (
    <div className={container()}>
      <CMHeaderItem className="gap-8px w-320px">
        <IconShop />
        <div className="gap-10px flex items-center">
          <span className="text-semibold-m text-gray-700">상품명</span>
          <span className="text-medium-m text-gray-500">옵션</span>
          <span className="text-medium-m rounded-4px px-6px bg-white text-gray-500">
            옵션코드
          </span>
        </div>
      </CMHeaderItem>
      <IconArrowRightLarge className="text-gray-500" />
      <CMHeaderItem className="gap-8px flex flex-1 items-center">
        <img src={imageLogo} className="w-56px" />
        <p className="text-semibold-m text-gray-700">상품구성</p>
      </CMHeaderItem>
      {withConfirm && (
        <>
          <div className="w-20px"></div>
          <CMHeaderItem className="w-116px flex-center flex">
            <p className="text-semibold-m text-gray-700">매칭 확정</p>
          </CMHeaderItem>
        </>
      )}
    </div>
  );
}

const container = cva([
  'z-[2] flex bg-white',
  'top-16px sticky',
  'before:absolute before:top-[-16px] before:h-[16px] before:w-full before:bg-white',
]);

interface CMHeaderItemProps {
  className?: string;
  children?: React.ReactNode;
}

function CMHeaderItem({ children, className }: CMHeaderItemProps) {
  return <div className={item({ className })}>{children}</div>;
}

const item = cva('h-48px px-20px flex items-center bg-gray-100');

export default CMHeader;
