import copy from 'fast-copy';
import { useOMSStore } from 'src/hooks/useOMSStore';
import backendApi from '../../../utils/backendApi';
import { isValid } from '../../../utils/utils';
import { regionTranslationKRtoENSorted } from './regionTranslationKRtoENSorted';

const usePreprocessOrder = () => {
  const oms = useOMSStore();
  const preprocessOrders = async (orders) => {
    let newOrders = [...orders];
    newOrders = orderCalcAddMinus(newOrders);
    newOrders = orderCalcMultiplication(newOrders);
    newOrders = addressENtoKRForAliExpress(newOrders);
    newOrders = add0TopostCode(newOrders);
    newOrders = await addresscheck(newOrders);

    if (isValid(oms.user.setting?.preprocessSettings)) {
      newOrders = add0TocontactNumber(newOrders);
      newOrders = addDateFromNameOption(newOrders);
      newOrders = deleteDateinNameOption(newOrders);
      newOrders = deleteWonInNameOption(newOrders);
      newOrders = deleteQuantityInOption(newOrders);
    }

    return newOrders;
  };

  const addresscheck = async (orders, justDOIT = false) => {
    try {
      // TODO(@이지원): 커스텀 로직 변경할 때, check-address plugin으로 변경하기
      const fixInvalidAddress =
        oms.user.setting?.preprocessSettings?.fixInvalidAddress;
      if (fixInvalidAddress === false) {
        return orders;
      }

      let newOrders = [...orders];
      let result = await backendApi.uploadOrders(newOrders);
      if (result.success) {
        if (result.data.length !== newOrders.length) {
          throw new Error('Order length mismatch');
        }
        let order_addresses_corrected = result.data;
        for (let i = 0; i < order_addresses_corrected.length; i++) {
          let newOrder = newOrders[i];
          newOrder.isAddressValid = order_addresses_corrected[i].result;
          newOrder.mainAddress = order_addresses_corrected[i].main_address;
          newOrder.detailAddress = order_addresses_corrected[i].detail_address;
          if (newOrder.isAddressValid === 'okay') {
            newOrder.address = order_addresses_corrected[i].data;
          } else {
            newOrder.memo = [];
            if (newOrder.isAddressValid === 'fixed') {
              if (
                justDOIT ||
                oms.user.setting?.preprocessSettings?.fixInvalidAddress?.enabled
              ) {
                // console.log(`Backend Address corrected: from ${order_addresses_corrected[i].data} to ${order_addresses_corrected[i].tried_data}`);
                newOrder.address = order_addresses_corrected[i].tried_data;
                newOrder.memo.push({
                  text: '기존주소:' + order_addresses_corrected[i].data + '\n',
                });
              } else {
                // console.log(`Backend Address correction but ignored: from ${order_addresses_corrected[i].data} to ${order_addresses_corrected[i].tried_data}`);
                newOrder.address = order_addresses_corrected[i].data;
                newOrder.isAddressValid = 'fail';
              }
            } else {
              // 프론트에서도 API쏴서 처리하고 싶으면 아래처럼 하기, 백에서는 kakao나 jusogokr API 아예 안씀
              // newOrders[i] = await correctOrder(newOrder);
              // console.log(`Backend Address correction failed: from ${order_addresses_corrected[i].data} to ${order_addresses_corrected[i].tried_data}`);
              if (
                justDOIT ||
                oms.user.setting?.preprocessSettings?.fixInvalidAddress?.enabled
              ) {
                newOrder.address = order_addresses_corrected[i].tried_data;
              } else {
                newOrder.address = order_addresses_corrected[i].data;
              }
            }
            if (newOrder.isAddressValid === 'fail') {
              if (
                order_addresses_corrected[i].message.includes(
                  '지번 주소입니다.'
                )
              ) {
                // if (newOrder.isAddressValid === 'fail') {
                //   newOrder.isAddressValid = 'jibun'
                // }
                if (
                  order_addresses_corrected[i].message.length > 2 &&
                  order_addresses_corrected[i].message[2].includes(
                    '해당하는 도로명 주소가'
                  )
                ) {
                  newOrder.isAddressValid = 'multiple';
                  newOrder.memo.push({
                    text: order_addresses_corrected[i].message[2] + '\n',
                  });
                  newOrder.memo = newOrder.memo.concat(
                    order_addresses_corrected[i].tried_tried_data.map(
                      (item) => ({ text: item + '\n', font: { bold: true } })
                    )
                  );
                } else {
                  newOrder.memo = newOrder.memo.concat(
                    order_addresses_corrected[i].message
                      .slice(2)
                      .map((item) => ({ text: item + '\n' }))
                  );
                }
              } else {
                for (
                  let j = 0;
                  j < order_addresses_corrected[i].message.length;
                  j++
                ) {
                  if (
                    order_addresses_corrected[i].message[j].includes(
                      '고객이 입력한 우편번호'
                    )
                  ) {
                    newOrder.isAddressValid = 'postcode';

                    newOrder.memo.push({
                      text: order_addresses_corrected[i].message[j] + '\n',
                    });
                    break;
                  }
                }
              }
            }
            if (newOrder.isAddressValid === 'fail') {
              newOrder.memo = newOrder.memo.concat(
                order_addresses_corrected[i].message.map((item) => ({
                  text: item + '\n',
                }))
              );
              newOrder.memo = newOrder.memo.concat(
                order_addresses_corrected[i].tried_tried_data.map((item) => ({
                  text: item + '\n',
                  font: { bold: true },
                }))
              );
            }
          }
        }
        return newOrders;
      }
    } catch (error) {
      console.error('Address check with backend failed:', error);
      return orders;
    }
  };

  const add0TopostCode = (orders) => {
    let newOrders = [...orders];
    newOrders = newOrders.map((order) => {
      order.postCode = (order.postCode || '').toString().trim();
      if (order.postCode && order.postCode.length === 4) {
        order.postCode = '0' + order.postCode;
      }
      return order;
    });
    return newOrders;
  };
  const add0TocontactNumber = (orders, justDOIT = false) => {
    if (
      justDOIT ||
      oms.user.setting?.preprocessSettings?.add0TocontactNumber?.enabled
    ) {
      let newOrders = [...orders];
      newOrders = newOrders.map((order) => {
        order.contactNumber = (order.contactNumber || '').toString().trim();
        if (
          order.contactNumber &&
          order.contactNumber.length === 10 &&
          order.contactNumber.slice(0, 2) === '10'
        ) {
          order.contactNumber = '0' + order.contactNumber;
        }

        if (
          order.contactNumber &&
          order.contactNumber.length === 12 &&
          order.contactNumber.slice(0, 4) === '8210'
        ) {
          order.contactNumber = '0' + order.contactNumber.slice(2);
        }
        return order;
      });
      return newOrders;
    }
    return orders;
  };

  const orderCalcAddMinus = (orders) => {
    let newOrders = orders.map((order) => {
      //order의 fileds중 필드 이름이 Add1로 끝나는 것들을 합치기
      let keys = Object.keys(order);
      let addKeys = keys.filter((key) => key.match(/Add\d+$/));
      addKeys.forEach((addKey) => {
        let targetKey = addKey.replace(/Add\d+$/, '');
        if (isValid(order[targetKey])) {
          return;
        }
        let newValue = 0;
        for (let i = 1; i <= 5; i++) {
          let key = targetKey + 'Add' + i;
          if (order[key] !== undefined) {
            newValue += parseInt(
              order[key].toString().replace(/,|₩/g, '').trim()
            );
            delete order[key];
          }
        }
        for (let i = 1; i <= 5; i++) {
          let key = targetKey + 'Minus' + i;
          if (isValid(order[key])) {
            newValue -= parseInt(
              order[key].toString().replace(/,|₩/g, '').trim()
            );
            delete order[key];
          }
        }
        order[targetKey] = newValue;
      });
      return order;
    });
    return newOrders;
  };

  const orderCalcMultiplication = (orders) => {
    let newOrders = orders.map((order) => {
      //order의 fileds중 필드 이름이 Add1로 끝나는 것들을 합치기
      let keys = Object.keys(order);
      let multiplicationKeys = keys.filter((key) => key.match(/Multiply\d+$/));
      multiplicationKeys.forEach((multiplicationKey) => {
        let targetKey = multiplicationKey.replace(/Multiply\d+$/, '');
        if (isValid(order[targetKey])) {
          return;
        }
        let newValue = 1;
        for (let i = 1; i <= 5; i++) {
          let key = targetKey + 'Multiply' + i;
          if (order[key] !== undefined) {
            newValue *= Number(
              order[key].toString().replace(/,|₩/g, '').trim()
            );
            delete order[key];
          }
        }
        order[targetKey] = newValue;
      });
      return order;
    });
    return newOrders;
  };

  const addressENtoKRForAliExpress = (orders, justDOIT = false) => {
    let newOrders = [];
    let aliPatners = oms.partner.partners
      ?.filter(
        (partner) =>
          partner.name.includes('알리') || partner.name.includes('Ali')
      )
      .map((partner) => partner._id);
    orders.forEach((order) => {
      if (
        !aliPatners.includes(order.partnerId) &&
        !order?.shoppingMallName?.includes('알리') &&
        !justDOIT
      ) {
        newOrders.push(order);
        return;
      }
      let address = order.address || '';
      let addressList = address.split(' ');

      addressList = addressList.map((address) => {
        for (let key of regionTranslationKRtoENSorted) {
          if (key[0] === address) {
            return key[1];
          }
        }
        return address;
      });
      addressList = addressList.map((address) => {
        if (address === '세종시') {
          return '세종특별자치시';
        }
        return address;
      });

      //중복제거
      addressList = addressList.filter((item, index) => {
        return addressList.indexOf(item) === index;
      });

      //도 시 군 구 읍 면 동 순서로 정렬

      let rank = ['도', '시', '군', '구', '읍', '면'];
      addressList = addressList.sort((a, b) => {
        let findAInDict = regionTranslationKRtoENSorted.find(
          (item) => item[1] === a
        );
        let findBInDict = regionTranslationKRtoENSorted.find(
          (item) => item[1] === b
        );
        if (findAInDict && findBInDict) {
          return rank.indexOf(a.slice(-1)) - rank.indexOf(b.slice(-1));
        } else if (findAInDict) {
          return -1;
        } else if (findBInDict) {
          return 1;
        } else {
          return 0;
        }
      });
      address = addressList.join(' ');

      for (let key of regionTranslationKRtoENSorted) {
        address = address.replace(new RegExp(key[0], 'g'), key[1]);
      }
      address = address.replace(/Other/g, '');
      newOrders.push(copy({ ...order, address: address }));
    });
    return newOrders;
  };

  // 배경
  // - 이베이, 11번가, 돌쇠네 등의 판매처의 경우 수량이 옵션에 중복되어 포함되어 있음(ex. productName: '반숙란 30구 (2개)', quantity: 2인데 실제 주문한 개수가 2개인 경우)
  // - 따라서 상품명 또는 옵션에 붙어있는 수량을 삭제해서 중복을 방지
  // 질문 - 어떻게 관리하는게 좋을지? 1) 파트너사 이름으로 바른다, 2) 그런 고객사의 이름으로 바른다
  // 위 방식에서 발라지지 않는 경우, 1) 파트너사 설정(customSettings)에서 바른다, 2) 별도의 customSettings를 만든다

  const deleteQuantityInOption = (orders) => {
    if (
      !oms.user.setting?.preprocessSettings?.deleteQuantityInOption?.enabled
    ) {
      return orders;
    }

    let newOrders = [...orders];
    newOrders = newOrders.map((order) => {
      let partner;
      let partnerName;
      let partnerCustomSettings;

      partner = oms.partner.partners.find(
        (partner) => partner._id === order.partnerId
      );

      if (isValid(partner)) {
        partnerName = partner.name;
        partnerCustomSettings = partner.customSettings;
      }

      // 파트너 이름에 '이베이' 혹은 '11번가' 이 포함되어 있으면 수량을 없애기
      if (
        (isValid(partnerName) &&
          (partnerName.includes('이베이') ||
            partnerName.includes('11번가') ||
            partnerName.includes('돌쇠네'))) ||
        partnerCustomSettings?.deleteQuantityInOption
      ) {
        // 1) order.option에서 /(\d+개)$/ 패턴이 있는지 확인
        // 2) 있는 경우, order.option에서 /(\d+개)$/ 패턴을 삭제
        // 3) 없는 경우, order.productName에서 /(\d+개)$/가 있는지 확인 하고, 있을 경우 해당 패턴을 삭제
        let regex = /\s*(?:\(\d+개\)|-\d+개|\/\d+개)\s*$/;

        if (regex.test(order.option)) {
          order.option = order.option.replace(regex, '').trim();
        } else {
          if (regex.test(order.productName)) {
            order.productName = order.productName.replace(regex, '').trim();
          }
        }
      }

      if (isValid(partnerName) && partnerName.includes('알리')) {
        const regex = /\(수량:(\d+) piece\)/g;
        let match = order.productName.match(regex);
        if (match && match.length === 1) {
          order.quantity = parseInt(match[0].match(/\(수량:(\d+) piece\)/)[1]);
          order.productName = order.productName.replace(regex, '').trim();
        }
      }

      return order;
    });
    return newOrders;
  };

  const deleteDateinNameOption = (orders) => {
    if (
      !oms.user.setting?.preprocessSettings?.deleteDateinNameOption?.enabled
    ) {
      return orders;
    }

    let newOrders = [...orders];
    let days = ['월', '화', '수', '목', '금', '토', '일'];
    let nameOptions = ['productName', 'option'];

    newOrders = newOrders.map((order) => {
      nameOptions.forEach((nameOption) => {
        // (숫자)월에 해당하는 것 찾아 없애기
        order[nameOption] = order[nameOption].replace(/\d+\s?월/, '');
        order[nameOption] = order[nameOption].replace(/\d+\s?일/, '');
        // 요일 없애기
        days.forEach((day) => {
          order[nameOption] = order[nameOption].replace(' ' + day + ' ', '');
          order[nameOption] = order[nameOption].replace('(' + day + ')', '');
        });
      });
      return order;
    });
    return newOrders;
  };

  const deleteWonInNameOption = (orders) => {
    if (!oms.user.setting?.preprocessSettings?.deleteWonInNameOption?.enabled) {
      return orders;
    }

    let newOrders = [...orders];
    let nameOptions = ['productName', 'option'];
    newOrders = newOrders.map((order) => {
      nameOptions.forEach((nameOption) => {
        while (order[nameOption].match(/\d+\s?원/)) {
          // 112342 원, 112,342 원, +숫자 원, -숫자 원, (+숫자 원) 등을 삭제
          order[nameOption] = order[nameOption].replace(
            /\(?-?\+?\d{1,3}(,?\d{3})*(\.\d+)?\s?원\)?/g,
            ''
          );
          order[nameOption] = order[nameOption].trim();
        }
      });
      return order;
    });
    return newOrders;
  };

  const addDateFromNameOption = (orders) => {
    if (!oms.user.setting?.preprocessSettings?.addDateFromNameOption?.enabled) {
      return orders;
    }

    let additionalConditions = false;
    if (
      oms.user.setting?.preprocessSettings?.addDateFromNameOption
        ?.additionalCondition?.enabled
    ) {
      additionalConditions =
        oms.user.setting?.preprocessSettings?.addDateFromNameOption
          ?.additionalCondition?.conditions;
    }

    let newOrders = [...orders];

    newOrders.forEach((order) => {
      if (additionalConditions) {
        let isConditionMet = Object.entries(additionalConditions).every(
          (condition) => {
            let { key, value } = condition;
            return order[key] === value;
          }
        );
        if (!isConditionMet) {
          return;
        }
      }
      if (isValid(order.shippingDate)) {
        return;
      }
      let nameOption = order.origName + order.origOption;
      // (숫자) 월에 해당하는 숫자를 찾기

      //'발송일:' 과 '발송' 사이의 값을 찾기
      let shippingDate =
        nameOption?.match(/발송일:(.*)발송/) ||
        nameOption?.match(/발송일\/(.*)발송/) ||
        order?.origOption?.match(/^(.*?( 월 | 화 | 수 | 목 | 금 | 토 | 일 ))/);
      if (shippingDate && !shippingDate[1]?.includes('즉시')) {
        order.shippingDate = shippingDate[1].trim() + ' 발송';
      }
    });
    return newOrders;
  };

  return {
    preprocessOrders,
    addressENtoKRForAliExpress,
    add0TocontactNumber,
    addresscheck,
  };
};

export default usePreprocessOrder;
