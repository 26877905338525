import { useId } from 'react';
import { mergeProps, useHover } from 'react-aria';
import { IconCheckSmall } from '@sweep/asset/icons';
import { cva } from '@sweep/tailwind';

type DefaultProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'onChange'
>;

export interface CheckboxProps extends DefaultProps {
  checked: boolean;
  onCheckedChange?: (checked: boolean) => void;
  isHovered?: boolean;
}

export function Checkbox({
  checked,
  onCheckedChange,
  isHovered: givenIsHovered,
  id: givenId,
  disabled = false,
  className,
  ...rest
}: CheckboxProps) {
  const randomId = useId();
  const id = givenId ?? randomId;

  const { isHovered, hoverProps } = useHover({});
  const hover = givenIsHovered ?? isHovered;

  return (
    <label
      htmlFor={id}
      className={label({ checked, hover, disabled, className })}
    >
      <input
        type="checkbox"
        id={id}
        checked={checked}
        className={input({ disabled })}
        onChange={(event) => onCheckedChange?.(event.target.checked)}
        disabled={disabled}
        {...mergeProps(rest, hoverProps)}
      />
      {checked && <IconCheckSmall className="text-white" />}
    </label>
  );
}

const label = cva(
  'relative flex size-[16px] items-center justify-center rounded-4px',
  {
    variants: {
      checked: {
        true: 'bg-blue-500',
        false: 'border-inner-2px-gray-300',
      },
      hover: {
        true: '',
        false: '',
      },
      disabled: {
        true: 'bg-gray-100',
      },
    },
    compoundVariants: [
      {
        checked: false,
        hover: false,
        disabled: false,
        className: 'bg-white',
      },
      {
        checked: false,
        hover: true,
        className: 'bg-gray-100',
      },
    ],
  }
);

const input = cva('absolute inset-0 opacity-0', {
  variants: {
    disabled: {
      true: 'cursor-not-allowed',
      false: 'cursor-pointer',
    },
  },
});
