import { useCallback, useRef } from 'react';
import { sum } from '@sweep/utils';
import { useTableContext } from '../context/TableContext';

const RESIZE_HANDLER_WIDTH = 32;

export function ResizeHandler({
  index,
  lastColumn,
}: {
  index: number;
  lastColumn: boolean;
}) {
  const { columnWidths, onAdjustedWidthChange, onFitWidth } = useTableContext();
  const left =
    sum(Array.from({ length: index + 1 }, (_, i) => columnWidths[i] ?? 0)) -
    RESIZE_HANDLER_WIDTH / 2;

  const startXRef = useRef<number>(0);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      const deltaX = e.clientX - startXRef.current;
      const newWidth = Math.max((columnWidths[index] ?? 0) + deltaX, 0);
      onAdjustedWidthChange(index, newWidth);
    },
    [index, onAdjustedWidthChange, columnWidths]
  );

  const handleMouseUp = useCallback(() => {
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
  }, [handleMouseMove]);

  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      startXRef.current = e.clientX;
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    },
    [handleMouseMove, handleMouseUp]
  );

  const handleDoubleClick = useCallback(() => {
    onFitWidth(index);
  }, [index, onFitWidth]);

  return (
    <div
      style={{
        position: 'absolute',
        left,
        width: RESIZE_HANDLER_WIDTH * (lastColumn ? 0.5 : 1),
        height: '100%',
      }}
      className="select-none cursor-ew-resize"
      onMouseDown={handleMouseDown}
      onDoubleClick={handleDoubleClick}
    />
  );
}
