type OptionalSupplierId = {
  supplierId?: string | null;
};

type DivideBySupplierResult<T extends OptionalSupplierId> = {
  ordersBySupplier: Record<string, T[]>;
  unseparatedOrders: T[];
};

export function divideBySupplier<T extends OptionalSupplierId>(
  orders: T[]
): DivideBySupplierResult<T> {
  const unseparatedOrders = orders.filter((order) => order.supplierId == null);
  const ordersWithSupplier = orders.filter((order) => order.supplierId != null);

  const ordersBySupplier: Record<string, T[]> = {};

  ordersWithSupplier.forEach((order) => {
    const supplierId = order.supplierId;
    if (supplierId == null) {
      return;
    }

    const supplierOrders = ordersBySupplier[supplierId] ?? [];
    ordersBySupplier[supplierId] = [...supplierOrders, order];
  });

  return { ordersBySupplier, unseparatedOrders };
}
