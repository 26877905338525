import { OMSStore } from 'stores/OMSStore';
import { OptionCodeMatching, Order, OrderProduct } from '@sweep/contract';
import { isNotNil } from '@sweep/utils';
import { MatchedOrder } from '../interface';

export function matchByOptionCode(
  orders: Order[],
  oms: OMSStore
): MatchedOrder[] {
  const optionCodeMatchings = oms.cm.optionCodeMatchings;
  const optionCodeMap = new Map(
    optionCodeMatchings.map((ocm) => [ocm.optionCode, ocm])
  );

  return orders.map((order) => {
    const result = _matchByOptionCode(order, optionCodeMap, oms);
    return result;
  });
}

function _matchByOptionCode(
  order: Order,
  optionCodeMap: Map<string, OptionCodeMatching>,
  oms: OMSStore
): MatchedOrder {
  if (order.optionCode == null || order.optionCode === '') {
    return order;
  }

  const matching = optionCodeMap.get(order.optionCode);
  if (matching == null) {
    return order;
  }

  return {
    ...order,
    plugin: {
      ...order.plugin,
      cm: {
        optionCodeMatchingId: matching._id,
      },
    },
    data: matching.products
      .map<OrderProduct | null>((ocmProduct) => {
        const product = oms.product.getProduct(ocmProduct.productId);
        if (product == null) {
          return null;
        }

        if (ocmProduct.unit == null) {
          return {
            productId: ocmProduct.productId,
            productName: product.productName,
            quantity: ocmProduct.quantity,
          };
        }

        return {
          productId: ocmProduct.productId,
          productName: product.productName,
          unit: ocmProduct.unit,
          quantity: ocmProduct.quantity,
        };
      })
      .filter(isNotNil),
  };
}
