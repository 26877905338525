import { observer } from 'mobx-react-lite';
import { Button } from '@sweep/sds';
import { Matching } from '../../interface';
import CMHeader from '../common/CMHeader';
import { MatchedCMTitle } from '../common/MatchedCMTitle';
import MatchingList from './MatchingList';

interface NormalizeMatchedCMConfirmFormProps {
  matchings: Matching[];
  onEdit: () => void;
  onSubmit: () => void;
}

function NormalizeMatchedConfirmCM({
  matchings,
  onEdit,
  onSubmit,
}: NormalizeMatchedCMConfirmFormProps) {
  return (
    <div className="gap-24px flex flex-col">
      <MatchedCMTitle />
      <div>
        <CMHeader />
        <MatchingList matchings={matchings} />
        <hr />
        <div className="pt-20px gap-12px flex justify-center">
          <Button color="gray" onClick={onEdit} className="w-200px">
            수정하기
          </Button>
          <Button onClick={onSubmit} className="w-200px">
            완료하기
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(NormalizeMatchedConfirmCM);
