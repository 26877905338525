import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { toast } from 'sonner';
import { IconControlDownload, IconTrashcan } from '@sweep/asset/icons';
import {
  Button,
  SearchInput,
  Select,
  TableCount,
  TableSelectControl,
} from '@sweep/sds';
import { isNotNil, uniq } from '@sweep/utils';
import { If, SwitchCase } from '@sweep/utils/react';
import { useTableFilter } from 'src/components/TableFilter';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { useSearchState } from 'src/hooks/useSearchState';
import { amplitude } from 'src/third-parties/amplitude';
import OrderPendingTable from '../components/OrderPendingTable/OrderPendingTable';
import UploadedFileList from '../components/UploadedFileList';
import useCreatePurchaseOrderForRetailer from '../hooks/useCreatePurchaseOrderForRetailer';
import useCreatePurchaseOrderForSupplier from '../hooks/useCreatePurchaseOrderForSupplier';
import { useDoseosangan } from '../hooks/useDoseosangan';
import { OrderShippingPendingStore } from '../stores/OrderShippingPendingStore';
import OrderShippingSectionEmpty from './OrderShippingSectionEmpty';

function OrderShippingSectionPending() {
  const oms = useOMSStore();
  const { doseosangan, onDososanganChange, filterDoseosangan } =
    useDoseosangan();

  const { store: tableFilterStore, filterOrders } = useTableFilter();
  const store = oms.getStore(OrderShippingPendingStore, tableFilterStore);

  const pendingOrders = store.shippingStatusPendingOrders;
  const uploadedFilenames = oms.order.uploadedFileNames;

  const { createRetailerPurchaseOrder } = useCreatePurchaseOrderForRetailer();
  const { createSupplierPurchaseOrder } = useCreatePurchaseOrderForSupplier();

  const [search, setSearch, filterSearch] = useSearchState({
    keys: oms.user.excelHeaderKeys,
  });

  const searchFilteredOrders = useMemo(
    () => filterSearch(pendingOrders),
    [filterSearch, pendingOrders]
  );
  const doseosanganFilteredOrders = useMemo(
    () => filterDoseosangan(searchFilteredOrders),
    [filterDoseosangan, searchFilteredOrders]
  );
  const orders = useMemo(
    () => filterOrders(doseosanganFilteredOrders),
    [doseosanganFilteredOrders, filterOrders]
  );

  const currentOrders =
    store.selectedOrders.length === 0 ? orders : store.selectedOrders;

  const handleUpload = async (files: File[]) => {
    if (files.length > 0) {
      amplitude.track('Upload Order', { 'File Count': files.length });
    }

    oms.loading.batch(async () => {
      const orders = await oms.order.merge(Array.from(files));
      const filenames = uniq(
        orders?.map((order) => order.originFile) ?? []
      ).filter(isNotNil);

      if (filenames.length > 0) {
        await oms.order.draft.register(filenames);
      }
    });

    store.setSelectedOrders([]);
    tableFilterStore.reset();
  };

  const handleCreatePurchaseOrderClick = async () => {
    amplitude.track('Download Purchase-order');
    if (currentOrders.length === 0) {
      toast.error('선택된 주문이 없습니다.');
      return;
    }

    const createPurchaseOrder = oms.user.isRetailer
      ? createRetailerPurchaseOrder
      : createSupplierPurchaseOrder;
    const result = await createPurchaseOrder(currentOrders);
    if (result == null) {
      return;
    }

    toast.success(
      oms.user.isRetailer
        ? '공급사별 발주서 파일이 생성되었습니다.'
        : '발주 통합 파일이 생성되었습니다.'
    );
    store.setSelectedOrders([]);
    tableFilterStore.reset();
  };

  if (uploadedFilenames.length === 0) {
    return <OrderShippingSectionEmpty onUpload={handleUpload} />;
  }

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between"></div>
      <div className="gap-20px flex flex-col">
        <UploadedFileList
          filenames={uploadedFilenames}
          onUpload={handleUpload}
          onRemove={store.removeByFileIndex}
          onRemoveAll={store.removeAllOrders}
        />
        <div className="gap-16px p-20px rounded-8px flex flex-col bg-white shadow-[0_1px_4px_0_rgba(0,0,0,0.1)]">
          <div className="gap-20px flex items-center">
            <p className="text-medium-s text-gray-500">필터</p>
            <SearchInput
              value={search}
              onChange={setSearch}
              placeholder="검색어를 입력하세요."
              className="w-400px"
            />
            <div className="w-1px h-full bg-gray-200" />
            <Select
              value={doseosangan}
              onChange={onDososanganChange}
              placeholder="도서산간 지역"
              className="w-160px shrink-0"
            >
              <Select.Option value={false} className="text-gray-400">
                선택 안 함
              </Select.Option>
              <Select.Option value={true}>제주 및 도서산간</Select.Option>
            </Select>
          </div>
          <hr />
          <div>
            <TableCount count={orders.length} />
            <div className="h-40px flex items-end justify-between">
              <TableSelectControl
                count={store.selectedOrders.length}
                onClear={() => store.setSelectedOrders([])}
              />
              <div className="gap-10px flex">
                <If is={store.selectedOrders.length > 0}>
                  <Button
                    color="lightGray"
                    variant="line"
                    leftAccessory={<IconTrashcan />}
                    onClick={store.removeSelectedOrders}
                  >
                    삭제
                  </Button>
                </If>
                <SwitchCase
                  value={oms.user.companyType}
                  caseBy={{
                    supplier: (
                      <Button
                        rightAccessory={<IconControlDownload />}
                        onClick={handleCreatePurchaseOrderClick}
                      >
                        발주 통합 파일
                      </Button>
                    ),
                    retailer: [
                      <Button
                        key="excel"
                        color="gray"
                        rightAccessory={<IconControlDownload />}
                        onClick={() => store.download(currentOrders)}
                      >
                        엑셀 파일
                      </Button>,
                      <Button
                        key="order"
                        rightAccessory={<IconControlDownload />}
                        onClick={handleCreatePurchaseOrderClick}
                      >
                        발주서
                      </Button>,
                    ],
                  }}
                />
              </div>
            </div>
          </div>
          <If is={pendingOrders.length > 0}>
            <OrderPendingTable
              store={tableFilterStore}
              toatlOrders={doseosanganFilteredOrders}
              orders={orders}
              selectedOrders={store.selectedOrders}
              onSelect={store.setSelectedOrders}
            />
          </If>
        </div>
      </div>
    </div>
  );
}

export default observer(OrderShippingSectionPending);
