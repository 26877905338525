import { action, computed, makeObservable, observable } from 'mobx';
import { NormalizedOrder } from '@sweep/contract';
import { OMSStore } from 'src/stores/OMSStore';
import { isShippingOrderStatusShipped } from '../services/isShippingOrderStatusShipped';

export class OrderShippingShippedStore {
  selectedOrders: NormalizedOrder[] = [];

  get shippingStatusShippedOrders() {
    return this.oms.order.normalizedOrders.filter(isShippingOrderStatusShipped);
  }

  constructor(private oms: OMSStore) {
    makeObservable(this, {
      selectedOrders: observable,
      shippingStatusShippedOrders: computed,

      setSelectedOrders: action.bound,
    });
  }

  setSelectedOrders = (orders: NormalizedOrder[]) => {
    this.selectedOrders = orders;
  };
}
