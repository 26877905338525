import { Order } from '@sweep/contract';
import { parseNumber } from '@sweep/utils';
import { getSeparatedPrice } from './getSeparatedPrice';

export function separate(orders: Order[]): Order[] {
  return orders.map(separateOrder).flat();
}

function separateOrder(order: Order): Order[] {
  const products = order.data;
  if (products == null) {
    return [order];
  }

  const price = parseNumber(order.price);
  const quantity = parseNumber(order.quantity);

  const separatedPrice =
    price != null ? getSeparatedPrice(price, products.length) : null;

  const separatedOrders = products.map<Order>((product, index) => ({
    ...order,
    price: separatedPrice?.[index] ?? order.price,
    quantity: quantity == null ? order.quantity : 1,
    data: [{ ...product, quantity: product.quantity * (quantity ?? 1) }],
  }));

  return separatedOrders;
}
