import { round } from '@sweep/utils';

export function getSeparatedPrice(price: number, quantity: number): number[] {
  if (quantity <= 0) {
    return [];
  }
  if (quantity === 1) {
    return [price];
  }

  const base = round(price / quantity, -1);
  const result = new Array(quantity).fill(base);

  const sum = base * quantity;
  const diff = sum - price;
  result[quantity - 1] -= diff;

  return result;
}
