import { Order } from '@sweep/contract';
import { SHIPPING_ORDER_PROCESS_STATUS_CODE } from '@sweep/domain/services/order-shipping-process';
import { bulkAutoDispatchOriginalOrder } from 'src/network/order-shipping-process/bulkAutoDispatchOriginalOrder';
import { bulkUpdateOriginalOrder } from 'src/network/order-shipping-process/bulkUpdateOriginalOrder';
import { FailedOriginalOrderWithFailReason } from '../types';

export async function requestAutoDispatchStep(orders: Order[]): Promise<{
  successOrders: Order[];
  failedOrders: FailedOriginalOrderWithFailReason<Order>[];
}> {
  if (orders.length === 0) {
    return {
      successOrders: [],
      failedOrders: [],
    };
  }

  try {
    const orderWithFirstShippingNumber = orders
      .map(selectFirstShippingNumber)
      .map((order) => ({
        uniqueCode: order.uniqueCode,
        shippingNumber: order.shippingNumber ?? undefined,
        shippingCompany: order.shippingCompany ?? undefined,
      }));

    const autoDispatchAPIResult = await bulkAutoDispatchOriginalOrder({
      orders: orderWithFirstShippingNumber,
    });

    const autoDispatchSuccessOrders = autoDispatchAPIResult
      .filter((result) => result.isSuccess)
      .map((result) => {
        const originalOrder = orders.find(
          (order) => order.uniqueCode === result.data.uniqueCode
        )!;

        const { shippingNumber, shippingCompany, autoFulfilled } = result.data;

        return {
          ...originalOrder,
          shippingNumber,
          shippingCompany,
          autoFulfilled,
        };
      });

    const autoDispatchFailedOrders = autoDispatchAPIResult
      .filter((result) => !result.isSuccess)
      .map((result) => {
        const originalOrder = orders.find(
          (order) => order.uniqueCode === result.data.uniqueCode
        )!;

        const { failReason, statusCode } = result;
        const { shippingNumber, shippingCompany, autoFulfilled } = result.data;

        return {
          originalOrder: {
            ...originalOrder,
            shippingNumber,
            shippingCompany,
            autoFulfilled,
          },
          statusCode,
          failReason,
        };
      });

    if (autoDispatchSuccessOrders.length === 0) {
      return {
        successOrders: [],
        failedOrders: autoDispatchFailedOrders,
      };
    }

    const updateOrderAPIResult = await bulkUpdateOriginalOrder({
      orders: autoDispatchSuccessOrders,
    });

    const updateOriginalOrderSuccessOriginalOrders = updateOrderAPIResult
      .filter((result) => result.isSuccess)
      .map((result) => result.data);

    const updateOriginalOrderFailedOriginalOrders = updateOrderAPIResult
      .filter((result) => !result.isSuccess)
      .map((result) => ({
        originalOrder: result.data,
        statusCode: result.statusCode,
        failReason: result.failReason,
      }));

    const failedOrders = [
      ...autoDispatchFailedOrders,
      ...updateOriginalOrderFailedOriginalOrders,
    ];

    return {
      successOrders: updateOriginalOrderSuccessOriginalOrders,
      failedOrders,
    };
  } catch (error) {
    console.error('error', error);
    return {
      successOrders: [],
      failedOrders: orders.map((order) => ({
        originalOrder: order,
        statusCode:
          SHIPPING_ORDER_PROCESS_STATUS_CODE.STEP_AUTO_DISPATCH_FAILED_UNKNOWN_ERROR,
        failReason: '',
      })),
    };
  }
}

function selectFirstShippingNumber(originalOrder: Order): Order {
  const shippingNumbers = originalOrder.shippingNumber?.split(',') ?? [];
  const firstShippingNumber =
    shippingNumbers[0] ?? originalOrder.shippingNumber;

  return {
    ...originalOrder,
    shippingNumber: firstShippingNumber,
  };
}
