import { NormalizedOrder } from '@sweep/contract';
import { createUniqueCodeSubV2 } from '@sweep/domain/services/order';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { AddSupplierNamePlugin } from 'src/stores/plugin/features/add-supplier-name/AddSupplierNamePlugin';
import { CombinedShippingPlugin } from 'src/stores/plugin/features/combined-shipping';
import { FormatProductNamePlugin } from 'src/stores/plugin/features/format-product-name';
import { PluginExecutionService } from 'src/stores/plugin/PluginExecutionService';

export const usePrevDivideOrders = () => {
  const oms = useOMSStore();

  // TODO(@이지원): hook이 아니라 함수로 분리
  const preDivideOrders = async (
    orders: NormalizedOrder[]
  ): Promise<NormalizedOrder[]> => {
    if (orders.length === 0) {
      return [];
    }

    const transformedOrders = await PluginExecutionService.execute(
      oms,
      orders,
      [CombinedShippingPlugin, AddSupplierNamePlugin, FormatProductNamePlugin]
    );

    return transformedOrders.map((order) => ({
      ...order,
      uniqueCodeAfterCustomization: createUniqueCodeSubV2(order),
    }));
  };

  return {
    preDivideOrders,
  };
};

export default usePrevDivideOrders;
