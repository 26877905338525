import { isNotNil } from 'es-toolkit';
import { removeSpaces } from '@sweep/utils';

type Option = {
  retrunNullIfNotInColumnMapping?: boolean;
};

export function mapKeysByColumnMapping(
  header?: string[],
  columnMapping?: Record<string, string>
): string[] {
  if (!header) {
    return [];
  }

  const keys: string[] = header.map((headerTitle, index) => {
    const cleanedKey = removeSpaces(headerTitle);

    for (const [key, value] of Object.entries(columnMapping ?? {})) {
      //value가 0-9의 텍스트만 이뤄져있는지 알아보는 정규표현식
      if (
        parseInt(removeSpaces(value)) === index + 1 &&
        /^\d+$/.test(removeSpaces(value))
      ) {
        return key;
      }

      if (removeSpaces(value) === cleanedKey && isNotNil(cleanedKey)) {
        return key;
      }
    }

    return headerTitle;
  });

  return keys;
}

export function mapKeysByColumnMappingWithOptions<T extends Option>(
  header: string[],
  columnMapping: Record<string, string>,
  options?: T
): (string | null)[] {
  const retrunNullIfNotInColumnMapping =
    options?.retrunNullIfNotInColumnMapping ?? false;
  const keys: (string | null)[] = header.map((key, index) => {
    const cleanedKey = removeSpaces(key);

    for (const [key, value] of Object.entries(columnMapping)) {
      //value가 0-9의 텍스트만 이뤄져있는지 알아보는 정규표현식
      if (
        parseInt(removeSpaces(value)) === index + 1 &&
        /^\d+$/.test(removeSpaces(value))
      ) {
        return key;
      }

      if (removeSpaces(value) === cleanedKey && isNotNil(cleanedKey)) {
        return key;
      }
    }

    //columnmaaping에 있는 값이 아닌경우는 null을 리턴
    if (retrunNullIfNotInColumnMapping) {
      return null;
    }

    return key;
  });

  return keys;
}
