import { useOMSStore } from 'hooks/useOMSStore';
import { observer } from 'mobx-react-lite';
import { Plugin } from 'stores/plugin/interface';
import { Button } from '@sweep/sds';
import PluginItem from '../components/PluginItem';

function UserMergeDispatchPluginSection() {
  const oms = useOMSStore();

  const createDispatchPlugin = async () => {
    await oms.user.createMergeDispatchPlugin({ pluginId: '' });
  };

  const deletePlugin = (pluginId: string) => async () => {
    await oms.user.deleteMergeDispatchPlugin(pluginId);
  };

  const handleChange = (pluginId: string) => (plugin: Partial<Plugin>) => {
    oms.plugin.update(pluginId, plugin);
  };

  return (
    <div className="flex flex-col gap-2">
      <p className="text-3xl">User Merge Dispatch plugin</p>
      <div className="rounded-[12px] bg-gray-100 px-4 py-2">
        <Button
          color="lightGray"
          variant="line"
          onClick={() => createDispatchPlugin()}
        >
          추가
        </Button>
        {oms.user.mergeDispatchPlugin.map((pluginId) => (
          <PluginItem
            key={pluginId}
            pluginId={pluginId}
            onChange={handleChange(pluginId)}
            onDelete={deletePlugin(pluginId)}
          />
        ))}
      </div>
    </div>
  );
}

export default observer(UserMergeDispatchPluginSection);
