import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useOMSStore } from 'src/hooks/useOMSStore';
import OMSHeader from '../components/OMSHeader';
import Sidebar from '../components/Sidebar/Sidebar';
import useAuthentication from '../hooks/useAuthentication';
import UserStore from '../stores/UserStore';

function OMSServiceLayout() {
  const navigate = useNavigate();
  const auth = useAuthentication();
  const oms = useOMSStore();

  // TODO(@이지원): useAuthentication을 사용하지 않도록 리팩토링
  useEffect(() => {
    const checkLoginStatus = async () => {
      const isLoggedIn = await auth?.checkLoginStatus();
      if (!isLoggedIn) {
        navigate('/sign-in');
      }
    };

    checkLoginStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex w-full overflow-x-hidden" style={{ height: '100vh' }}>
      <div className="w-[280px]">
        <Sidebar />
      </div>
      <div
        className="flex w-full flex-col"
        style={{ maxWidth: 'calc(100% - 280px)' }}
      >
        <div className="h-[77px] shrink-0">
          <OMSHeader userStore={UserStore} />
        </div>
        <div className="grow">
          {oms.isInitialized ? (
            <Outlet />
          ) : (
            <div className="size-full bg-gray-100"></div>
          )}
        </div>
      </div>
    </div>
  );
}

export default observer(OMSServiceLayout);
