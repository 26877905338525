import { Order } from '@sweep/contract';
import { isEmptyString } from '@sweep/utils';
import { MatchingOrder } from '../interface';

export function toMatchingOrder(order: Order): MatchingOrder {
  return {
    productName: isEmptyString(order.productName) ? null : order.productName,
    option: isEmptyString(order.option) ? null : order.option,
    optionCode: isEmptyString(order.optionCode) ? null : order.optionCode,
  };
}
