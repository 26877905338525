import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { IconControlPlus } from '@sweep/asset/icons';
import { Button } from '@sweep/sds';
import { cva } from '@sweep/tailwind';
import { DAY_IN_MS, isNotNil, partition } from '@sweep/utils';
import { If } from '@sweep/utils/react';
import { useOMSStore } from 'src/hooks/useOMSStore';
import { Matching } from '../../interface';
import { toMatchingWithMeta } from '../../services/toMatchingWithMeta';
import MatchingOrderLabel from '../common/MatchingOrderLabel';
import MatchedCMConfirmFormRow from './MatchedCMConfirmFormRow';

interface MatchingListProps {
  matchings: Matching[];
}

function MatchingList({ matchings }: MatchingListProps) {
  const oms = useOMSStore();
  const matchingsWithMeta = matchings
    .map((matching) => toMatchingWithMeta(oms, matching))
    .filter(isNotNil);

  const [recentMatchings, oldMatchings] = useMemo(
    () =>
      partition(
        matchingsWithMeta,
        (matching) =>
          matching.updatedAt > Date.now() - 3 * DAY_IN_MS && matching.count <= 3
      ),
    [matchingsWithMeta]
  );
  const [showOld, setShowOld] = useState(oldMatchings.length === 0);

  return (
    <div className="gap-16px flex flex-col">
      <div>
        {recentMatchings.map(({ order, products }, index) => (
          <div
            key={index}
            className={matchingClass({
              border:
                index !== recentMatchings.length - 1 || oldMatchings.length > 0,
            })}
          >
            <MatchingOrderLabel matchingOrder={order} />
            <div className="w-42px shrink-0" />
            <MatchedCMConfirmFormRow products={products} />
          </div>
        ))}
        {showOld &&
          oldMatchings.map(({ order, products }, index) => (
            <div
              key={index}
              className={matchingClass({
                border: index !== oldMatchings.length - 1,
              })}
            >
              <MatchingOrderLabel matchingOrder={order} />
              <div className="w-42px shrink-0" />
              <MatchedCMConfirmFormRow products={products} />
            </div>
          ))}
      </div>
      <If is={!showOld}>
        <Button
          leftAccessory={<IconControlPlus />}
          color="lightGray"
          className="mb-16px self-center"
          onClick={() => setShowOld(!showOld)}
        >
          전체 매칭 내역 보기
        </Button>
      </If>
    </div>
  );
}

const matchingClass = cva('py-16px flex', {
  variants: {
    border: {
      true: 'border-b border-gray-200',
    },
  },
});

export default observer(MatchingList);
