import { overlay } from 'overlay-kit';
import { IconAlert } from '@sweep/asset/icons';
import { Dialog } from '@sweep/sds';

export function openHoldMatchingDialog(holdCount: number) {
  return new Promise<boolean>((resolve) =>
    overlay.open(({ isOpen, close }) => (
      <Dialog open={isOpen} onClose={close}>
        <Dialog.Title className="mb-16px gap-8px flex items-center">
          <IconAlert />
          <p>
            <span className="text-blue-500">{holdCount}개</span>의 상품 매칭을
            보류할까요?
          </p>
        </Dialog.Title>
        <Dialog.Description className="mb-28px">
          매칭이 확정되지 않은 상품은 상품명이 바뀌지 않고 보류됩니다.
        </Dialog.Description>
        <Dialog.Footer>
          <Dialog.Close onClick={() => resolve(false)} color="lightGray">
            돌아가기
          </Dialog.Close>
          <Dialog.Close onClick={() => resolve(true)}>확인</Dialog.Close>
        </Dialog.Footer>
      </Dialog>
    ))
  );
}
