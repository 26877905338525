import { createCancelOrderHandlers } from './handlers/cancelOrderHandlers';
import { createConfirmOrderHandlers } from './handlers/confirmOrderHandlers';
import { createDispatchOrderHandlers } from './handlers/dispatchOrderHandlers';
import { createFulfillOrderHandlers } from './handlers/fullfillOrderHandlers';

export const dispatchHandlers = [
  ...createDispatchOrderHandlers(),
  ...createCancelOrderHandlers(),
  ...createConfirmOrderHandlers(),
  ...createFulfillOrderHandlers(),
];
