import {
  CREATE_SPREADSHEET_HEADER_MAPPING_URL,
  CreateSpreadsheetHeaderMappingRequestDTO,
  CreateSpreadsheetHeaderMappingResponseDTO,
  SpreadsheetHeaderMapping,
} from '@sweep/contract';
import api from '../api';

export async function requestCreateSpreadsheetHeaderMapping(
  params: CreateSpreadsheetHeaderMappingRequestDTO
): Promise<SpreadsheetHeaderMapping | null> {
  const response = await api.post<CreateSpreadsheetHeaderMappingResponseDTO>(
    CREATE_SPREADSHEET_HEADER_MAPPING_URL,
    params
  );

  const newSpreadsheetHeaderMapping: SpreadsheetHeaderMapping | null =
    response?.data?.spreadsheetHeaderMapping ?? null;

  return newSpreadsheetHeaderMapping;
}
